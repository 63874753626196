import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { StatisticPotentialContraventionByZone, Zones } from '@EcamModel/model/Zones';
import { AxiosInstance } from 'axios';
import { IGetPotentialContraventionByZone, IGetZoneProps, IZoneHttpController } from '../IZoneHttpController';

export class ZoneHttpController extends BaseHttpController<Zones> implements IZoneHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'zone', client);
    }

    async statisticPotentialContravention(
        props: IGetPotentialContraventionByZone
    ): Promise<Paging<StatisticPotentialContraventionByZone>> {
        return await this.doPost({ path: 'statistic-potential-contravention', body: props }).then((res) => res.data);
    }

    async list(props: IGetZoneProps): Promise<Paging<Zones>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }
}
