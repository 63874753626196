import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraTrailAuditEvent } from '@EcamModel/model/CameraTrailAudit';
import { AxiosInstance } from 'axios';
import { ICameraTrailAuditEventHttpController, IListTrailAuditEvent } from '../ICameraTrailAuditEventHttpController';
import { Paging } from '@Core';

export class CameraTrailAuditEventHttpController
    extends BaseHttpController<CameraTrailAuditEvent>
    implements ICameraTrailAuditEventHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-trail-audit-event', client);
    }
    async list(props: IListTrailAuditEvent): Promise<Paging<CameraTrailAuditEvent>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }
}
