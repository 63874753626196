import { CameraType } from '@EcamModel/controllers/ICameraHttpController';
import { Autorenew } from '@mui/icons-material';
import { Box, CircularProgress, Dialog, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { getCenterFrame } from '@pages/cameras/details/utils';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import Konva from 'konva';
import { isNil } from 'lodash';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { Circle, Image, Layer, Line, Stage, Text } from 'react-konva';
import { formatDateByTimeZone } from 'src/helpers';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { useCameraDetailContext } from '../../../../../hook/CameraDetailsProvider';
import GeoZonesHistoriesCameraSolar from './GeoZonesHistoriesCameraSolar';
import { GeoZones, GeoZoneType } from '@EcamModel/model';
import { Paging } from '@Core';

type Props = {
    isHideAction?: boolean;
    cameraType?: CameraType;
};

export default function WrapHistoryPhoto(props: Props) {
    const backdrop = useBackdrop();

    const { isHideAction = false, cameraType } = props;

    const { blobName, notAvailable, handleRefreshImage } = useCameraDetailContext();

    const openBackdrop = () => {
        const message = <Typography>Please wait for the camera to take a picture...</Typography>;
        backdrop.setTrue(message);
    };

    return (
        <Stack>
            <Box borderRadius={2} bgcolor={color.white}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                    spacing={1}
                    p={2}
                    sx={{
                        borderRadius: 2,
                        height: 50,
                    }}
                >
                    <Stack direction={'row'} spacing={1} sx={{ alignItems: 'center' }}>
                        <Typography color={color.darkPrimary} variant="h5" width={'100%'} mb={1}>
                            Photo
                        </Typography>
                    </Stack>
                    {!isHideAction && (
                        <Stack>
                            <Tooltip title="Refresh latest photo" placement="top" arrow>
                                <IconButton
                                    sx={{
                                        borderRadius: '3px',
                                        width: '24px',
                                        height: '24px',
                                        color: 'white',
                                        backgroundColor: '#2E7D32',
                                        ':hover': {
                                            backgroundColor: '#256428',
                                        },
                                        '&.MuiIconButton-root.Mui-disabled': {
                                            backgroundColor: '#eee',
                                        },
                                        '&:hover .rotate-icon': {
                                            transform: 'rotate(90deg) scale(1.1)',
                                        },
                                    }}
                                    onClick={() => {
                                        openBackdrop();
                                        handleRefreshImage().finally(() => {
                                            backdrop.setFalse();
                                        });
                                    }}
                                    disabled={notAvailable || backdrop.open}
                                >
                                    <Autorenew
                                        sx={{ fontSize: 17, transition: 'transform 0.3s ease-in-out' }}
                                        className="rotate-icon"
                                    />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    )}
                </Stack>

                <Divider
                    orientation="vertical"
                    sx={{
                        borderBottomWidth: 1,
                        borderColor: color.grey300,
                        mx: 2,
                    }}
                />

                <Stack
                    direction="row"
                    p={1.7}
                    sx={{
                        borderRadius: '0px 0px 4px 4px',
                    }}
                    justifyContent="space-between"
                >
                    {!blobName ? (
                        <NoDataCameras sx={{ border: 'none', minHeight: 150 }} />
                    ) : (
                        <HistoriesPhoto {...props} />
                    )}
                </Stack>
            </Box>
        </Stack>
    );
}

export const HistoriesPhoto = (props: Props) => {
    const stageRef = useRef<Konva.Stage>(null);
    const { cameraType } = props;
    const {
        timeZone,
        captureAt,
        scaleFullCameraSolar,
        prevGeoZonesCameraSolar,
        scaledPrevGeoZoneCameraSolar,
        widthImage,
        scaleNotFullCameraSolar,
        image,
    } = useCameraDetailContext();

    const [openIMGPreview, setOpenIMGPreview] = useState<boolean>(false);

    const handleClickImagePreview = () => {
        setOpenIMGPreview(true);
    };

    return (
        <>
            {!image ? (
                <Stack
                    direction="row"
                    width={widthImage}
                    justifyContent="center"
                    alignItems="center"
                    minHeight={widthImage * 0.5625}
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <div style={{ width: widthImage, height: widthImage * 0.5625, position: 'relative' }}>
                    <Stage ref={stageRef} width={widthImage} height={widthImage * 0.5625}>
                        <Layer>
                            <Image
                                image={image}
                                scaleX={scaleNotFullCameraSolar}
                                scaleY={scaleNotFullCameraSolar}
                                onClick={handleClickImagePreview}
                            />
                            {!!scaledPrevGeoZoneCameraSolar.length &&
                                scaledPrevGeoZoneCameraSolar.map((frame, frameIndex) => {
                                    return (
                                        <GeoZonesHistoriesCameraSolar
                                            key={frameIndex}
                                            frame={frame}
                                            index={frameIndex}
                                        />
                                    );
                                })}
                        </Layer>
                    </Stage>

                    <BaseBoxDecoration
                        timeZone={timeZone}
                        heading="Latest photo"
                        date={captureAt ?? moment().toDate()}
                    />

                    <PreviewImageWithGeoZone
                        open={openIMGPreview}
                        onClose={() => setOpenIMGPreview(false)}
                        imgSrc={image}
                        scaleX={scaleFullCameraSolar}
                        scaleY={scaleFullCameraSolar}
                        geoZones={prevGeoZonesCameraSolar}
                        cameraType={cameraType}
                    />
                </div>
            )}
        </>
    );
};
interface BaseBoxDecorationProps {
    heading?: string;
    date: Date;
    timeZone?: string;
}
export const BaseBoxDecoration = (props: BaseBoxDecorationProps) => {
    const { heading, date, timeZone } = props;

    return (
        <>
            {heading && (
                <Stack
                    sx={{
                        p: 1,
                        background: 'rgba(0, 0, 0, 0.5)',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        textAlign: 'center',
                        color: '#fff',
                    }}
                >
                    <Stack
                        textAlign={'center'}
                        direction={'row'}
                        spacing={1}
                        alignItems={'center'}
                        justifyContent={'center'}
                    >
                        <Typography variant="body1" color={color.white}>
                            {heading}
                        </Typography>
                    </Stack>
                </Stack>
            )}

            <Stack
                sx={{
                    py: 1,
                    px: 2,
                    background: 'rgba(0, 0, 0, 0.5)',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    textAlign: 'center',
                    color: '#fff',
                }}
            >
                {formatDateByTimeZone(date, timeZone ?? undefined, true)}
            </Stack>
        </>
    );
};

export const PreviewImageWithGeoZone = ({
    open,
    onClose,
    imgSrc,
    scaleX,
    scaleY,
    geoZones,
    cameraType,
    listGeoZonesByView,
}: {
    open: boolean;
    onClose?(): void;
    imgSrc: HTMLImageElement | undefined;
    scaleX?: number;
    scaleY?: number;
    geoZones?: {
        x: number;
        y: number;
        geoZoneId: number | undefined;
    }[][];
    cameraType?: CameraType;
    listGeoZonesByView?: Paging<GeoZones>;
}) => {
    const stageRef = useRef<Konva.Stage>(null);

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose?.();
            }}
            PaperProps={{
                sx: {
                    minWidth: 1280,
                    minHeight: 720,
                },
            }}
        >
            <div style={{ width: 1280, height: 720 }}>
                {!imgSrc ? (
                    <Stack direction="row" width="100%" justifyContent="center" alignItems="center" minHeight={720}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <Stage ref={stageRef} width={1280} height={720}>
                        <Layer>
                            <Image image={imgSrc} scaleX={scaleX} scaleY={scaleY} />
                            {!!geoZones?.length &&
                                geoZones?.map((frame, frameIndex) => {
                                    if (!frame || !frame.length) {
                                        return null;
                                    }

                                    const center = getCenterFrame(frame);
                                    const firstPoint = frame[0];

                                    const matchingGeoZone = listGeoZonesByView?.rows.find(
                                        (zone) => zone.Id === frame[0].geoZoneId
                                    );
                                    const strokeColor =
                                        matchingGeoZone?.GeoZoneType === GeoZoneType.Detail ? 'blue' : color.primary;

                                    return (
                                        <React.Fragment key={`frame-${frameIndex}`}>
                                            <Line
                                                points={frame.flatMap((point) => [point.x, point.y])}
                                                stroke={
                                                    isNil(firstPoint?.geoZoneId)
                                                        ? 'red'
                                                        : cameraType === CameraType.PTZ
                                                        ? strokeColor
                                                        : 'blue'
                                                }
                                                strokeWidth={3}
                                            />
                                            {!isNil(firstPoint?.geoZoneId) && (
                                                <>
                                                    <Circle x={center.x} y={center.y} radius={15} fill={color.white} />
                                                    <Text
                                                        x={center.x}
                                                        y={center.y}
                                                        text={String(`${frameIndex + 1}`)}
                                                        fontSize={14}
                                                        fill={color.textPrimary}
                                                        align={'center'}
                                                        verticalAlign={'middle'}
                                                        offsetX={4}
                                                        offsetY={6}
                                                    />
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                        </Layer>
                    </Stage>
                )}
            </div>
        </Dialog>
    );
};
