import { BaseModel } from '@Core';

export interface Solar4gTimeInfo extends BaseModel {
    DevMac: string;

    DevName: string;

    Date: string;

    Time: string;

    Timezone: string;

    Zonename: string;

    TimeManual: string;

    DaylightSaving: TimeInfoDaylightSavingMode;

    SyncMode: TimeInfoSyncMode;

    SyncGps: number;
}

export enum TimeInfoDaylightSavingMode {
    Disable,
    Automatic,
}

export enum TimeInfoSyncMode {
    GPS,
    MANUAL,
}
