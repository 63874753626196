import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function PrevSlidesBottom(props: Props) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.9998 20.4201L8.47984 13.9001C7.70984 13.1301 7.70984 11.8701 8.47984 11.1001L14.9998 4.58008"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
