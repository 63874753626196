import { BatteryStatus, Status, WifiStatus } from '@EcamModel/model';
import { SelectionItem } from '@models/cameras/SelectionItem';

export const statuses: SelectionItem[] = [
    {
        id: Status.NotAvailable,
        value: 'Not available',
    },
    {
        id: Status.Active,
        value: 'Active',
    },
    {
        id: Status.Inactive,
        value: 'Inactive',
    },
];

export const connections: SelectionItem[] = [
    {
        id: WifiStatus.Connected,
        value: 'Connected',
    },
    {
        id: WifiStatus.DisConnected,
        value: 'Disconnected',
    },
];

export const batteries: SelectionItem[] = [
    {
        id: BatteryStatus.Critical,
        value: 'Critical (0%-20%)',
    },
    {
        id: BatteryStatus.Low,
        value: 'Low (21%-50%)',
    },
    {
        id: BatteryStatus.Normal,
        value: 'Normal (51%-100%)',
    },
];

export const wifiConnections: SelectionItem[] = [
    {
        id: 1,
        value: 'Bad',
    },
    {
        id: 2,
        value: 'Good',
    },
];

export const cameraSortByType: SelectionItem[] = [
    {
        id: 'Zone',
        value: 'Zone',
    },
    {
        id: 'Updated',
        value: 'Last Contact',
    },
    {
        id: 'Battery-Level',
        value: 'Battery Level',
    },
];
export const cameraSortByStatus: SelectionItem[] = [
    {
        id: 'desc',
        value: 'DESC',
    },
    {
        id: 'asc',
        value: 'ASC',
    },
];
export const filterUpdated: SelectionItem[] = [
    {
        id: 'not-updated-today',
        value: 'Not updated today',
    },
];
export const search = (option: string, searchText: string) => {
    return option.toLowerCase().includes(searchText.toLowerCase());
};

export const searchEnum = (option: string, searchText: string) => {
    return option.includes(searchText);
};

export function generateRandom(min = 0, max = 100) {
    let difference = max - min;
    let rand = Math.random();
    rand = Math.floor(rand * difference);
    rand = rand + min;
    return rand;
}
