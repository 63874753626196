import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { CancellationReasons } from '../../model';
import { ICancellationReasonHttpController } from '../ICancellationReasonHttpController';

export class CancellationReasonHttpController
    extends BaseHttpController<CancellationReasons>
    implements ICancellationReasonHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'cancellationReason', client);
    }
}
