import { BaseModel } from '@Core';
import { Camera } from './Camera';
import { CameraPhotoVehicles } from './CameraPhotoVehicles';

export interface CameraPhotos extends BaseModel {
    CameraId: number;
    Camera?: Camera;

    BlobName: string;

    ImageSize: number;

    CaptureAt: Date;
    CaptureType: CaptureType;

    CameraPhotoVehicles?: CameraPhotoVehicles[];

    IsWithinOperationalHours?: boolean;

    DetectPlateAt?: Date;
    DetectPlateNotes?: string;
    DetectPotentialContraventionAt?: Date;
    Version?: PhotoVersion;
}

export enum CaptureType {
    trigged,
    command,
}

export enum PhotoVersion {
    original,
    edited,
}
