import { BaseHttpController } from '@Core/controller/BaseHttpController';
import {
    Solar4gAlarmROI,
    Solar4gCamera,
    Solar4gCaptureInfo,
    Solar4gCellularInfo,
    Solar4gCellularStatus,
    Solar4gSchedule,
    Solar4gTimeInfo,
} from '@EcamModel/model';
import { AxiosInstance } from 'axios';
import { ISolar4gCameraHttpController } from '../ISolar4gCameraHttpController';

export class Solar4gCameraHttpController extends BaseHttpController<{}> implements ISolar4gCameraHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'solar4g-camera', client);
    }

    async getLatestInfoByCameraId(cameraId: number): Promise<Solar4gCamera> {
        return await this.doGet({ path: `latest-info/${cameraId}` }).then((res) => res.data);
    }

    async refreshCellularStatus(devMac: string): Promise<Solar4gCellularStatus> {
        return await this.doGet({ path: `refresh-cellular-status/${devMac}` }).then((res) => res.data);
    }

    async checkCellularStatus(devMac: string): Promise<Solar4gCellularStatus> {
        return await this.doGet({ path: `check-cellular-status/${devMac}` }).then((res) => res.data);
    }

    async updateCellularInfo(devMac: string, settings: Partial<Solar4gCellularInfo>): Promise<Solar4gCellularInfo> {
        return await this.doPost({ path: `update-cellular-info/${devMac}`, body: settings }).then((res) => res.data);
    }

    async updateCaptureInfo(devMac: string, settings: Partial<Solar4gCaptureInfo>): Promise<Solar4gCaptureInfo> {
        return await this.doPost({ path: `update-capture-info/${devMac}`, body: settings }).then((res) => res.data);
    }

    async updateSchedule(devMac: string, settings: Partial<Solar4gSchedule>): Promise<Solar4gSchedule> {
        return await this.doPost({ path: `update-schedule/${devMac}`, body: settings }).then((res) => res.data);
    }

    async updateAlarmROI(devMac: string, settings: Partial<Solar4gAlarmROI>): Promise<Solar4gAlarmROI> {
        return await this.doPost({ path: `update-alarm-roi/${devMac}`, body: settings }).then((res) => res.data);
    }

    async updateTimeInfo(devMac: string, settings: Partial<Solar4gTimeInfo>): Promise<Solar4gTimeInfo> {
        return await this.doPost({ path: `update-time-info/${devMac}`, body: settings }).then((res) => res.data);
    }
}
