import { Stack, StackProps } from '@mui/material';
import React, { ReactNode } from 'react';
import { activeStyles } from '.';

type Props<T> = {
    option: T;
    renderOption(option: T): ReactNode;
    onClick?(option: T): any;
    stackProps?: StackProps;
    select?: boolean;
};

export default function BaseOption<T>(props: Props<T>) {
    return (
        <Stack
            sx={{ cursor: 'pointer' }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onClick?.(props.option);
            }}
            {...props.stackProps}
        >
            <Stack
                sx={{
                    p: 1,
                    // borderBottom: '1px solid #eee',
                    transition: '0.2s',
                    borderRadius: '5px',
                    ...activeStyles({ backgroundColor: '#eeeeee80' }, props.select),
                    ':hover': {
                        backgroundColor: '#eeeeee80',
                        borderColor: 'transparent',
                    },
                }}
            >
                {props.renderOption(props.option)}
            </Stack>
        </Stack>
    );
}
