import { BaseIconFrame } from '@components/FramePolygonSized';
import { GeoZoneCoordinates, GeoZones, GeoZoneType } from '@EcamModel/model';
import { CircularProgress, Stack } from '@mui/material';
import { getCenterFrame } from '@pages/cameras/details/utils';
import useFramePolygonGeoZones from '@pages/cameras/hook/useFramePolygonGeoZones';
import color from '@theme/Colors';
import React from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { Circle, Image, Layer, Line, Shape, Stage, Text } from 'react-konva';
import { Mode } from '../../CameraInformation';

export type FramePolygonProps = {
    onChange?(points: GeoZoneCoordinates[]): void;
    prevGeoZone?: GeoZoneCoordinates[][];
    selectedGeoZone?: GeoZones;
    blobName?: string;
    imageElement?: HTMLImageElement;
    mode?: Mode;
    preview_width?: number;
};

export default function FramePolygonGeoOverview(props: FramePolygonProps) {
    const {
        stageRef,
        shapeRef,
        points,
        isDrag,
        scaledPrevGeoZone,
        scaleSize,
        prevGeoZone,
        selectedGeoZone,
        PREVIEW_WIDTH,
        imageElement,
        setPoints,
        handleDrawFrame,
        handleDragAnchors,
        handleDragShape,
        setIsDrag,
    } = useFramePolygonGeoZones(props);

    return (
        <>
            {!imageElement ? (
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    minHeight={PREVIEW_WIDTH * 0.5625}
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <div
                    style={{
                        width: PREVIEW_WIDTH,
                        height: PREVIEW_WIDTH * 0.5625,
                        position: 'relative',
                        marginTop: '40px',
                    }}
                >
                    <Stage
                        ref={stageRef}
                        width={PREVIEW_WIDTH}
                        height={PREVIEW_WIDTH * 0.5625}
                        onClick={handleDrawFrame}
                    >
                        <Layer>
                            <Image image={imageElement} scaleX={scaleSize} scaleY={scaleSize} />
                            {!!scaledPrevGeoZone?.length &&
                                scaledPrevGeoZone?.map((frame, frameIndex) => {
                                    const center = getCenterFrame(frame);

                                    const matchingGeoZone = prevGeoZone
                                        ?.flat()
                                        .find((geoZone) => geoZone.GeoZoneId === frame[0].geoZoneId);

                                    const strokeColor =
                                        matchingGeoZone?.GeoZone?.GeoZoneType === GeoZoneType.Detail
                                            ? 'blue'
                                            : color.primary;

                                    return (
                                        <React.Fragment key={`frame-${frameIndex}`}>
                                            <Line
                                                points={frame.flatMap((point) => [point.x, point.y])}
                                                stroke={strokeColor}
                                                strokeWidth={3}
                                                shadowColor={color.gold700}
                                                shadowBlur={20}
                                            />
                                            {frame[0].geoZoneId !==
                                                selectedGeoZone?.GeoZoneCoordinates?.[0].GeoZoneId && (
                                                <>
                                                    <Circle x={center.x} y={center.y} radius={15} fill={'#FFFFFF'} />
                                                    <Text
                                                        x={center.x}
                                                        y={center.y}
                                                        text={String(`${frameIndex + 1}`)}
                                                        fontSize={14}
                                                        fill={color.textPrimary}
                                                        align={'center'}
                                                        verticalAlign={'middle'}
                                                        offsetX={4}
                                                        offsetY={6}
                                                    />
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            {points.length >= 4 && (
                                <Shape
                                    sceneFunc={(context, shape) => {
                                        context.beginPath();
                                        const startPoint = points[0];
                                        context.moveTo(startPoint?.XPoint * scaleSize, startPoint?.YPoint * scaleSize);
                                        points.slice(1).forEach((point) => {
                                            context.lineTo(point.XPoint * scaleSize, point.YPoint * scaleSize);
                                        });
                                        context.closePath();
                                        context.strokeShape(shape);
                                        context.fillStrokeShape(shape);
                                    }}
                                    stroke="red"
                                    ref={shapeRef}
                                    draggable
                                    onDragStart={() => {
                                        setIsDrag(true);
                                    }}
                                    onDragEnd={handleDragShape}
                                    onMouseEnter={() => {
                                        if (stageRef.current) {
                                            const stageElement = stageRef.current.content;
                                            stageElement.style.cursor = 'pointer';
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (stageRef.current) {
                                            const stageElement = stageRef.current.content;
                                            stageElement.style.cursor = 'default';
                                        }
                                    }}
                                />
                            )}
                            {points.map((point, index) => (
                                <>
                                    {index < points.length - 1 && points.length <= 4 && (
                                        <Line
                                            key={`line-${index}`}
                                            points={[
                                                point.XPoint * scaleSize,
                                                point.YPoint * scaleSize,
                                                points[index + 1].XPoint * scaleSize,
                                                points[index + 1].YPoint * scaleSize,
                                            ]}
                                            stroke="red"
                                        />
                                    )}
                                    {!isDrag && (
                                        <Circle
                                            key={`circle-${index}`}
                                            x={point.XPoint * scaleSize}
                                            y={point.YPoint * scaleSize}
                                            radius={4}
                                            fill="white"
                                            stroke="black"
                                            strokeWidth={2}
                                            draggable
                                            onDragEnd={(e) => handleDragAnchors(index, e)}
                                        />
                                    )}
                                </>
                            ))}
                        </Layer>
                    </Stage>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            p: 1,
                            position: 'absolute',
                            top: -41,
                            left: 0,
                            right: 0,
                            backgroundColor: '#292929',
                        }}
                        alignItems="center"
                        spacing={1.5}
                    >
                        <BaseIconFrame
                            icon={<MdOutlineDelete fill={color.white} fontSize={17} />}
                            onClick={() => {
                                setPoints([]);
                            }}
                            style={{
                                borderRadius: '50%',
                                border: `1px solid ${color.white}`,
                                cursor: 'pointer',
                                width: 25,
                                height: 25,
                                '&:hover': {
                                    background: 'rgba(255, 255, 255, 0.2)',
                                    opacity: 0.5,
                                    transition: '0.3s ease-in-out',
                                },
                            }}
                        />
                    </Stack>
                </div>
            )}
        </>
    );
}
