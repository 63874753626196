import PopUpBase from '@components/PopUpBase';
import StyledAutocomplete from '@components/styled-autocomplete';
import { IPopUp } from '@hooks/usePopUp';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import Konva from 'konva';
import { FC, useEffect, useRef, useState } from 'react';
import { Image, Layer, Stage } from 'react-konva';
import { useCameraDetailContext } from '../../../../../hook/CameraDetailsProvider';
import { IGetPhotoSnapShotByPTZ } from '@EcamModel/controllers/ICameraViewHttpController';
import { cameraViewController } from '@controllers/index';
import { pushError } from '@components/toast';
import { ViewPTZ, ViewPreset } from '@EcamModel/model/CameraView';
import { Filter } from '@pages/cameras/list';
import { ListProps } from '@Core';
import { TypeReturn } from '@components/styled-autocomplete/useStyledAutocomplete';

type Props = Omit<IPopUp, 'onConfirm'> & {
    filter: Filter;
    listsViewPresetAutocomplete: TypeReturn<ViewPreset, ListProps<ViewPreset>>;
    listsViewPreset: ViewPreset[];
    onSetFilter: (filter: Filter | ((prev: Filter) => Filter)) => void;
};

export const base64ToHTMLImageElement = (base64: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const img = new window.Image();

        img.onload = () => {
            const { width, height } = img;
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');

            if (ctx) {
                ctx.drawImage(img, 0, 0, width, height);
                const resizedBase64 = canvas.toDataURL();
                const resizedImg = new window.Image();
                resizedImg.src = resizedBase64;
                resizedImg.onload = () => resolve(resizedImg);
                resizedImg.onerror = (err) => reject(err);
            } else {
                reject('Error loading');
            }
        };

        img.onerror = (err) => reject(err);
        img.src = `data:image/jpeg;base64,${base64}`;
    });
};

export const PREVIEW_WIDTH = 852;
const PopupConfigViewCameraPTZ: FC<Props> = (props) => {
    const { open, onClose, filter, listsViewPreset, listsViewPresetAutocomplete, onSetFilter } = props;

    const { camera, handleUpsertView, filteredCamera, isLoadingSave, photoSnapShotByPTZ, setPhotoSnapShotByPTZ } =
        useCameraDetailContext();

    const stageRef = useRef<Konva.Stage>(null);
    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
    const initialPerspectiveViewRef = useRef<Partial<ViewPTZ> | null>(null);
    const shouldSkipApiCall = useRef<Partial<ViewPTZ> | boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const cameraViewName = camera?.CameraViews?.[0]?.CameraViewName;

    const scaleSize = PREVIEW_WIDTH / (imageElement?.width ?? 1920);

    const handleClosePopup = () => {
        onClose?.();
    };

    useEffect(() => {
        if (photoSnapShotByPTZ) {
            const convertBase64ToImage = async () => {
                try {
                    const imgElement = await base64ToHTMLImageElement(photoSnapShotByPTZ.Base64PhotoSnapShot);
                    setImageElement(imgElement);
                } catch (error) {
                    console.error('Failed to convert base64 to image', error);
                }
            };
            convertBase64ToImage();
        }
    }, [photoSnapShotByPTZ]);

    const handleResetFilter = () => {
        const newPerspectiveView: Partial<ViewPreset> = {
            GotoPointId: !camera?.CameraViews?.length ? listsViewPreset?.[0]?.GotoPointId : undefined,
            PtzPanPos: camera?.CameraViews?.[0]?.PtzPanPos || listsViewPreset?.[0]?.PtzPanPos,
            PtzTiltPos: camera?.CameraViews?.[0]?.PtzTiltPos || listsViewPreset?.[0]?.PtzTiltPos,
            PtzZoomPos: camera?.CameraViews?.[0]?.PtzZoomPos || listsViewPreset?.[0]?.PtzZoomPos,
            PresetName: cameraViewName || listsViewPreset?.[0]?.PresetName,
        };

        onSetFilter((prev) => ({
            ...prev,
            perspectiveView: newPerspectiveView,
        }));

        if (!initialPerspectiveViewRef.current) {
            initialPerspectiveViewRef.current = newPerspectiveView;
        }
    };

    useEffect(() => {
        if (!open) return;
        handleResetFilter();

        shouldSkipApiCall.current = false;
    }, [open]);

    useEffect(() => {
        if (!initialPerspectiveViewRef.current) return;

        const PTZ =
            filter.perspectiveView?.PtzPanPos !== undefined ||
            filter.perspectiveView?.PtzZoomPos !== undefined ||
            filter.perspectiveView?.PtzTiltPos !== undefined
                ? {
                      PtzPanPos: filter.perspectiveView?.PtzPanPos,
                      PtzZoomPos: filter.perspectiveView?.PtzZoomPos,
                      PtzTiltPos: filter.perspectiveView?.PtzTiltPos,
                  }
                : undefined;

        if (PTZ || filter.perspectiveView?.GotoPointId !== undefined) {
            getPhotoSnapShotByView({
                PTZ:
                    filter.perspectiveView?.GotoPointId !== undefined
                        ? {
                              GotoPointId: filter.perspectiveView?.GotoPointId,
                          }
                        : PTZ!,
                PTZCommand: filteredCamera,
            });
            shouldSkipApiCall.current = true;
        }
    }, [filter.perspectiveView]);

    const getPhotoSnapShotByView = async (_filter: IGetPhotoSnapShotByPTZ) => {
        setIsLoading(true);
        await cameraViewController
            .getPhotoSnapShotByPTZ(_filter)
            .then((res) => {
                setPhotoSnapShotByPTZ(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const conditionDisabled =
        isLoadingSave || isLoading || !imageElement || filter.perspectiveView?.GotoPointId === undefined;
    const title = `Config view PTZ camera${cameraViewName ? `: ${cameraViewName}` : ''}`;

    return (
        <PopUpBase
            open={open}
            fixOverflow
            onClose={onClose}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    '& .MuiDialogContent-root': {
                        padding: '0px 24px',
                    },
                    '& .MuiPaper-root': {
                        overflowY: 'unset',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
                maxWidth: 'md',
            }}
            title={<Typography variant="h4">{title}</Typography>}
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack direction={'column'} spacing={2} width={'100%'}>
                    <Stack width={'100%'}>
                        <StyledAutocomplete
                            {...listsViewPresetAutocomplete}
                            getOptionLabel={(o) => o.PresetName ?? ''}
                            label="View"
                            isRequired
                            disabled={isLoading}
                            value={filter.perspectiveView}
                            disabledAllOption
                            onChange={(o) => {
                                onSetFilter((prev) => ({ ...prev, perspectiveView: o }));
                            }}
                        />
                    </Stack>

                    <Divider orientation="horizontal" flexItem sx={{ borderColor: color.grey300 }} />

                    {filter.perspectiveView?.GotoPointId !== undefined ||
                    filter.perspectiveView?.PtzPanPos !== undefined ? (
                        <Box>
                            {!imageElement || isLoading ? (
                                <Stack
                                    direction="row"
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    minHeight={PREVIEW_WIDTH * 0.5625}
                                >
                                    <CircularProgress />
                                </Stack>
                            ) : (
                                <Stage ref={stageRef} width={PREVIEW_WIDTH} height={PREVIEW_WIDTH * 0.5625}>
                                    <Layer>
                                        <Image image={imageElement} scaleX={scaleSize} scaleY={scaleSize} />
                                    </Layer>
                                </Stage>
                            )}
                        </Box>
                    ) : (
                        <ListNoData
                            sx={{
                                minHeight: '300px',
                                backgroundColor: color.white,
                                border: 'none',
                            }}
                        />
                    )}
                </Stack>
            }
            customActions={
                <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                    <Button sx={{ minWidth: 130 }} variant="cancel" onClick={handleClosePopup} disabled={isLoadingSave}>
                        Cancel
                    </Button>
                    <LoadingButton
                        sx={{
                            minWidth: 130,
                        }}
                        loading={isLoadingSave}
                        loadingPosition="center"
                        variant="contained"
                        disabled={conditionDisabled}
                        onClick={() => {
                            handleUpsertView(filter.perspectiveView?.PresetName || '');
                        }}
                    >
                        Save
                    </LoadingButton>
                </Stack>
            }
        />
    );
};
export default PopupConfigViewCameraPTZ;
