export interface PTZBasic {
    ZoomStatus: PTZOsdStatus;
    TiltStatus: PTZOsdStatus;
    PresetStatus: PTZOsdStatus;
    PatrolStatus: PTZBasicStatus;
    PatternStatus: PTZBasicStatus;
    AutoscanStatus: PTZBasicStatus;
    PresetFreezing: PTZBasicEnable;
    PresetSpeed: number;
    ManualSpeed: ManualSpeed;
    PatrolRecovering: PTZBasicEnable;
    PatrolRecoveryTime: number;
    FocusMode: FocusMode;
    FocusDistance: MinimumFocusDistance;
    ResumeTime: ResumeTime;
    FanWorkingMode: FanWorkingMode;
    OnvifPresetMode: OnvifPresetMode;
}

export enum PTZOsdStatus {
    AlwaysClose,
    AlwaysOpen,
    TwoSeconds, // 2 seconds
    FiveSeconds, // 5 seconds
    TenSeconds, // 10 seconds
}

export enum PTZBasicStatus {
    AlwaysClose,
    AlwaysOpen,
}

export enum PTZBasicEnable {
    No,
    Yes,
}

export enum ManualSpeed {
    Low,
    Medium,
    High,
}

export enum FocusMode {
    Auto,
    SemiAuto,
    Manual,
}

export enum MinimumFocusDistance {
    OneMeter, // 1 meter
    OnePointFiveMeter, // 1.5 meter
    ThreeMeter, // 3 meter
    SixMeter, // 6 meter
    TenMeter, // 10 meter
    TwentyMeter, // 20 meter
}

export enum ResumeTime {
    Disable,
    ThirtySeconds, // 30 seconds
    SixtySeconds, // 60 seconds
    ThreeHundredSeconds, // 300 seconds
    SixHundredSeconds, // 600 seconds
}

export enum FanWorkingMode {
    Genernal,
    Enhancement,
    Constant,
    Off,
}

export enum OnvifPresetMode {
    ValidList,
    FullList,
}
