import { BaseTextField } from '@components/BaseTextField';
import { Button, Stack, Typography } from '@mui/material';
import { Control, Controller, FieldErrors, UseFieldArrayReturn, UseFormWatch } from 'react-hook-form';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { v4 } from 'uuid';
import { getGracePeriods } from '../utils';
import { FormValues } from './AddGeoZones';

type Props = {
    gracePeriods: UseFieldArrayReturn<FormValues, 'gracePeriods', 'id'>;
    nameWidth?: React.CSSProperties['width'];
    control?: Control<FormValues, any>;
    errors?: FieldErrors<FormValues>;
    watch: UseFormWatch<FormValues>;
};

export default function GracePeriods(props: Props) {
    const { control, watch, errors } = props;

    const handleAddGracePeriods = () => {
        props.gracePeriods.append({ _fakeId: v4(), Name: '', GracePeriod: '', GZOConfigDays: [] });
    };
    const handleRemoveGracePeriods = (index: number) => {
        props.gracePeriods.remove(index);
    };
    const isDisabled =
        watch().gracePeriods.some((item) => !item.Name || !item.GracePeriod) ||
        watch().gracePeriods.length === 3 ||
        !!errors?.gracePeriods;

    const gracePeriods = watch().gracePeriods;
    return (
        <>
            {gracePeriods?.map((item, index, arr) => {
                return (
                    <Stack>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={1}
                            mt={`${props.errors?.gracePeriods?.[index] ? '-16px' : 0}`}
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography>Name</Typography>
                                <Controller
                                    control={control}
                                    name={`gracePeriods.${index}.Name`}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            placeholder={'Enter name'}
                                            maxLength={30}
                                            InputProps={{
                                                sx: {
                                                    width: props.nameWidth ?? 550,
                                                    mt: !!error?.message ? '22px' : 0,
                                                },
                                            }}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography minWidth={90}>Grace period</Typography>
                                <Controller
                                    control={control}
                                    name={`gracePeriods.${index}.GracePeriod`}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            placeholder={'Format time 1h 5m 5s'}
                                            InputProps={{
                                                sx: {
                                                    width: 180,
                                                    mt: !!error?.message ? '22px' : 0,
                                                },
                                            }}
                                            FormHelperTextProps={{
                                                sx: {
                                                    m: '3px 0px 0px 14px !important',
                                                    fontWeight: 400,
                                                    fontSize: 12,
                                                    textAlign: 'left',
                                                    lineHeight: 1.66,
                                                },
                                            }}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                flex={1}
                                spacing={1}
                                flexShrink={0}
                            >
                                <Typography textAlign="left" color="primary">{`Total seconds: ${getGracePeriods(
                                    watch().gracePeriods?.[index]?.GracePeriod
                                )}(s)`}</Typography>
                                {index === 0 ? (
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            minWidth: 30,
                                            minHeight: 30,
                                            p: '6px !important',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                color: (theme) => theme.palette.primary.light,
                                            },
                                        }}
                                        disabled={isDisabled}
                                        onClick={handleAddGracePeriods}
                                    >
                                        <AiOutlinePlus />
                                    </Button>
                                ) : (
                                    <Button
                                        variant="cancelOutlined"
                                        sx={{
                                            minWidth: 30,
                                            minHeight: 30,
                                            p: '6px !important',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                color: (theme) => theme.palette.grey[700],
                                            },
                                        }}
                                        onClick={() => {
                                            handleRemoveGracePeriods(index);
                                        }}
                                    >
                                        <AiOutlineMinus />
                                    </Button>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                );
            })}
        </>
    );
}
