import { CellularInfoAuthType, CellularInfoNetworkType } from '@EcamModel/model';
import IcEdit from '@assets/details-camera-icons/IcEdit';
import PopUpWarning from '@components/PopUpWarning';
import { pushError, pushSuccess } from '@components/toast';
import usePopUp from '@hooks/usePopUp';
import { Autorenew } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import { ReactNode, useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { useBackdrop } from 'src/providers/BackdropProvider';
import PopUpCellularSettings from './PopUpCellularSettings';
import PopUpWakeupSettings from './PopUpWakeupSettings';
import CancelIcon from './icon/CancelIcon';
import InformationIcon from './icon/InformationIcon';
import WifiConnect from './icon/WifiConnect';
import WifiOff from './icon/WifiOff';
import { useCameraDetailContext } from '../../hook/CameraDetailsProvider';

export default function CellularMilesightCameraSolar() {
    const popUpCellularSettings = usePopUp();
    const popUpWakeSettings = usePopUp();

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const { status, refreshStatus, checkCellularStatus, settings, settingsExpected, updateCellularInfo } =
        useCameraDetailContext();

    return (
        <Stack width="100%" direction={'column'} spacing={2}>
            <Stack id="cellular-status" border={'1px solid #E4E7EC'} borderRadius={2} flex={1}>
                <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                    <Typography fontSize={16}>Cellular status</Typography>
                    {status?.DevMac && (
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            onClick={() => {
                                refreshStatus();
                            }}
                        >
                            <Refresh />
                        </Stack>
                    )}
                </Stack>

                <Divider
                    orientation="vertical"
                    sx={{
                        borderBottomWidth: 1,
                        borderColor: color.grey300,
                        mx: 2,
                    }}
                />

                {status ? (
                    <Stack paddingY={1} paddingX={2} spacing={2}>
                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>ISP:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.Isp}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>Signal strength:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                {checkCellularEnum({ signalStrength: status.SignalStrength }).checkSignalStrength}
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>IMEI</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.Imei}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>ICCID:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.Iccid}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>IP address:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.IpAddr}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>Gateway:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.Gateway}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>SIM card status:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Stack direction={'row'} alignItems={'center'} spacing={3} position={'relative'}>
                                    <Typography>{status?.SimStatus}</Typography>
                                    {status?.SimStatus && status.DevMac && (
                                        <Stack
                                            position={'absolute'}
                                            left={30}
                                            bottom={0}
                                            direction={'row'}
                                            alignItems={'center'}
                                            spacing={1}
                                        >
                                            <Button
                                                sx={{
                                                    padding: 0.5,
                                                    fontSize: 12,
                                                }}
                                                variant={'orangeContained'}
                                                onClick={checkCellularStatus}
                                            >
                                                Check
                                            </Button>
                                            <Tooltip
                                                placement="top"
                                                title="Click the Check button to temporarily enable 4G moudle for network connection. After logging out of device access the 4G will be automatically closed."
                                            >
                                                <InformationIcon />
                                            </Tooltip>
                                        </Stack>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>Status:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.ConnectStatus}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>IMSI:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.Imsi}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>Data usage monthly:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                {status?.DataMonthly && (
                                    <Typography>{(status?.DataMonthly / 1024).toFixed(2)} Mib</Typography>
                                )}
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>Subnet mask:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.SubMask}</Typography>
                            </Grid>
                        </Grid>

                        <Grid xs={12} container>
                            <Grid xs={5}>
                                <Typography>DNS address:</Typography>
                            </Grid>
                            <Grid xs={7}>
                                <Typography>{status?.Dns}</Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                ) : (
                    <NoDataCameras sx={{ border: 'none', minHeight: 150 }} />
                )}
            </Stack>

            <Stack flex={1}>
                <Box id="cellular-settings" border={'1px solid #E4E7EC'} borderRadius={2}>
                    <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                        <Typography fontSize={16}>Cellular settings</Typography>
                        {settings?.DevMac && (
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Tooltip title="Edit cellular settings" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpCellularSettings.setTrue();
                                        }}
                                    >
                                        <IcEdit style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        )}
                    </Stack>

                    <Divider
                        orientation="vertical"
                        sx={{
                            borderBottomWidth: 1,
                            borderColor: color.grey300,
                            mx: 2,
                        }}
                    />

                    {settings ? (
                        <Stack paddingY={1} paddingX={2} spacing={2}>
                            <CheckData
                                title="APN"
                                value={<Typography color={color.secondary}>{settings?.Apn}</Typography>}
                                edited={settingsExpected?.Apn !== settings?.Apn}
                                valueEdited={settingsExpected?.Apn}
                                Cancel={() => updateCellularInfo({ Apn: settings.Apn })}
                            />
                            <CheckData
                                title="User name"
                                value={<Typography>{settings?.UserName}</Typography>}
                                edited={settingsExpected?.UserName !== settings?.UserName}
                                valueEdited={settingsExpected?.UserName}
                                Cancel={() => updateCellularInfo({ UserName: settings.UserName })}
                            />
                            <CheckData
                                title="Password"
                                value={
                                    settings?.Password && (
                                        <Stack direction="row" alignItems="center" spacing={4}>
                                            <Typography textAlign={'center'}>
                                                {showPassword
                                                    ? `${settings?.Password}`
                                                    : `${settings?.Password}`.replace(/./g, '*')}
                                            </Typography>
                                            {showPassword ? (
                                                <BiHide
                                                    fontSize={20}
                                                    color="#85858A"
                                                    cursor="pointer"
                                                    onClick={() => setShowPassword(false)}
                                                />
                                            ) : (
                                                <BiShow
                                                    fontSize={20}
                                                    color="#85858A"
                                                    cursor="pointer"
                                                    onClick={() => setShowPassword(true)}
                                                />
                                            )}
                                        </Stack>
                                    )
                                }
                                edited={settingsExpected?.Password !== settings?.Password}
                                valueEdited={settingsExpected?.Password}
                                Cancel={() => updateCellularInfo({ Password: settings.Password })}
                            />
                            <CheckData
                                title="PIN code"
                                value={<Typography>{settings?.PinCode}</Typography>}
                                edited={settingsExpected?.PinCode !== settings?.PinCode}
                                valueEdited={settingsExpected?.PinCode}
                                Cancel={() => updateCellularInfo({ PinCode: settings.PinCode })}
                            />
                            <CheckData
                                title="Authentication"
                                value={
                                    <Typography>
                                        {checkCellularEnum({ authType: settings?.AuthType }).checkAuthType}
                                    </Typography>
                                }
                                edited={settingsExpected?.AuthType !== settings?.AuthType}
                                valueEdited={checkCellularEnum({ authType: settingsExpected?.AuthType }).checkAuthType}
                                Cancel={() => updateCellularInfo({ AuthType: settings.AuthType })}
                            />
                            <CheckData
                                title="Network type"
                                value={
                                    <Typography>
                                        {checkCellularEnum({ networkType: settings?.NetworkType }).checkNetworkType}
                                    </Typography>
                                }
                                edited={settingsExpected?.NetworkType !== settings?.NetworkType}
                                valueEdited={
                                    checkCellularEnum({ networkType: settingsExpected?.NetworkType }).checkNetworkType
                                }
                                Cancel={() => updateCellularInfo({ NetworkType: settings.NetworkType })}
                            />
                            <CheckData
                                title="Ping detection"
                                value={
                                    settings?.PingEnable && (
                                        <Typography>{settings?.PingEnable ? 'Yes' : 'No'}</Typography>
                                    )
                                }
                                edited={
                                    settingsExpected?.PingEnable != null
                                        ? settingsExpected?.PingEnable !== settings?.PingEnable
                                        : false
                                }
                                valueEdited={settingsExpected?.PingEnable ? 'Yes' : 'No'}
                                Cancel={() => updateCellularInfo({ PingEnable: settings.PingEnable })}
                            />
                            <CheckData
                                title="IP address"
                                value={<Typography>{settings?.PingAddr}</Typography>}
                                edited={settingsExpected?.PingAddr !== settings?.PingAddr}
                                valueEdited={settingsExpected?.PingAddr}
                                Cancel={() => updateCellularInfo({ PingAddr: settings.PingAddr })}
                            />
                            <CheckData
                                title="MTU"
                                value={settings?.Mtu && <Typography>{settings?.Mtu} bytes (1200~1500)</Typography>}
                                edited={settingsExpected?.Mtu !== settings?.Mtu}
                                valueEdited={settingsExpected?.Mtu?.toString()}
                                Cancel={() => updateCellularInfo({ Mtu: settings.Mtu })}
                            />
                            <CheckData
                                title="Billing day"
                                value={
                                    settings?.BillDay && <Typography>Day {settings?.BillDay} of the month</Typography>
                                }
                                edited={settingsExpected?.BillDay !== settings?.BillDay}
                                valueEdited={settingsExpected?.BillDay?.toString()}
                                Cancel={() => updateCellularInfo({ BillDay: settings.BillDay })}
                            />
                        </Stack>
                    ) : (
                        <NoDataCameras sx={{ border: 'none', minHeight: 150 }} />
                    )}
                </Box>
                {settings && (
                    <Box id="wake-up-settings" border={'1px solid #E4E7EC'} borderRadius={2} mt={2}>
                        <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                            <Typography fontSize={16}>Wake up settings</Typography>
                            {settings?.DevMac && (
                                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                    <Tooltip title="Edit wakeup settings" placement="top" arrow>
                                        <IconButton
                                            sx={{
                                                borderRadius: '3px',
                                                width: '24px',
                                                height: '24px',
                                                color: 'white',
                                                backgroundColor: '#2E7D32',
                                                ':hover': {
                                                    backgroundColor: '#256428',
                                                },
                                                padding: '4px',
                                                '&.MuiIconButton-root.Mui-disabled': {
                                                    backgroundColor: '#eee',
                                                },
                                            }}
                                            onClick={() => {
                                                popUpWakeSettings.setTrue();
                                            }}
                                        >
                                            <IcEdit style={{ width: '14px', height: '14px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            )}
                        </Stack>

                        <Divider
                            orientation="vertical"
                            sx={{
                                borderBottomWidth: 1,
                                borderColor: color.grey300,
                                mx: 2,
                            }}
                        />

                        <Stack paddingY={1} paddingX={2} spacing={2}>
                            <CheckData
                                title="Wake up method"
                                value={
                                    <Typography>
                                        {settings?.RemoteWake && 'MQTT, '} {settings?.CallWake && ' Call '}
                                    </Typography>
                                }
                                edited={
                                    settingsExpected?.RemoteWake != null && settingsExpected?.RemoteWake != null
                                        ? settingsExpected?.RemoteWake !== settings?.RemoteWake ||
                                          settingsExpected?.CallWake !== settings?.CallWake
                                        : false
                                }
                                valueEdited={`${
                                    settingsExpected?.RemoteWake !== settings?.RemoteWake
                                        ? `MQTT(${
                                              checkCellularEnum({ boolean: settingsExpected?.RemoteWake }).checkBoolean
                                          }), `
                                        : ''
                                } ${
                                    settingsExpected?.CallWake !== settings?.CallWake
                                        ? `Call(${
                                              checkCellularEnum({ boolean: settingsExpected?.CallWake }).checkBoolean
                                          })`
                                        : ''
                                }`}
                                Cancel={() =>
                                    updateCellularInfo({ RemoteWake: settings.RemoteWake, CallWake: settings.CallWake })
                                }
                            />
                        </Stack>
                    </Box>
                )}
            </Stack>

            <PopUpCellularSettings
                onSave={(data) => updateCellularInfo(data)}
                cellularSettings={settings}
                {...popUpCellularSettings}
            />
            <PopUpWakeupSettings
                onSave={(data) => updateCellularInfo(data)}
                cellularSettings={settings}
                {...popUpWakeSettings}
            />
        </Stack>
    );
}

function Refresh() {
    return (
        <Tooltip title="Refresh Cellular status" placement="top" arrow>
            <IconButton
                sx={{
                    borderRadius: '3px',
                    width: '24px',
                    height: '24px',
                    color: 'white',
                    backgroundColor: '#2E7D32',
                    ':hover': {
                        backgroundColor: '#256428',
                    },
                    '&.MuiIconButton-root.Mui-disabled': {
                        backgroundColor: '#eee',
                    },
                    '&:hover .rotate-icon': {
                        transform: 'rotate(90deg) scale(1.1)',
                    },
                }}
            >
                <Autorenew sx={{ fontSize: 17, transition: 'transform 0.3s ease-in-out' }} className="rotate-icon" />
            </IconButton>
        </Tooltip>
    );
}

function CheckData(props: {
    title: string;
    value: ReactNode;
    edited: boolean;
    valueEdited?: string;
    Cancel: () => Promise<void>;
}) {
    const { title, value, edited, valueEdited, Cancel } = props;
    const backdrop = useBackdrop();

    const popUpWarning = usePopUp({
        onConfirm() {
            backdrop.setTrue();
            Cancel()
                .then(() => {
                    pushSuccess('Cancel successfully');
                })
                .catch((res) => {
                    pushError(res);
                })
                .finally(() => {
                    popUpWarning.onClose();
                    backdrop.setFalse();
                });
        },
    });
    return (
        <Grid xs={12} container>
            <Grid xs={5}>
                <Typography>{title}:</Typography>
            </Grid>
            <Grid xs={7}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack flex={1} direction={'row'} spacing={1}>
                        {value}
                        {edited && valueEdited && (
                            <Stack direction={'row'} spacing={1}>
                                {value && <Typography>/</Typography>}
                                <Typography color={color.grey600}>{valueEdited}</Typography>
                                <Tooltip
                                    placement="top"
                                    title={
                                        <Typography align="center" fontSize={13} fontWeight={450} color={color.grey100}>
                                            The new data will be updated when the camera wakes up
                                        </Typography>
                                    }
                                >
                                    <InformationIcon />
                                </Tooltip>
                            </Stack>
                        )}
                    </Stack>
                    {edited && valueEdited && (
                        <Tooltip placement="top" title="Cancel">
                            <CancelIcon onClick={popUpWarning.setTrue} style={{ cursor: 'pointer' }} />
                        </Tooltip>
                    )}
                    <PopUpWarning {...popUpWarning} message={`Are you sure you want to cancel edit this ${title}?`} />
                </Stack>
            </Grid>
        </Grid>
    );
}

export const checkCellularEnum = (props: {
    authType?: number;
    networkType?: number;
    boolean?: number;
    signalStrength?: number;
}) => {
    const { authType, networkType, boolean } = props;

    const authTypeDisplayValue = {
        [CellularInfoAuthType.Auto]: 'Auto',
        [CellularInfoAuthType.PAP]: 'PAP',
        [CellularInfoAuthType.CHAP]: 'CHAP',
        [CellularInfoAuthType.MS_CHAP]: 'MS-CHAP',
        [CellularInfoAuthType.MS_CHAP_V2]: 'MS-CHAPv2',
    };
    const networkTypeDisplayValue = {
        [CellularInfoNetworkType.AUTO]: 'Auto',
        [CellularInfoNetworkType.ONLY_2G]: '2G only',
        [CellularInfoNetworkType.ONLY_3G]: '3G only',
        [CellularInfoNetworkType.ONLY_4G]: '4G only',
    };
    const signalStrengthDisplayValue = {
        [0]: <WifiOff />,
        [1]: <WifiConnect status={1} />,
        [2]: <WifiConnect status={2} />,
        [3]: <WifiConnect status={3} />,
        [4]: <WifiConnect status={3} />,
        [5]: <WifiConnect status={4} />,
    };
    const booleanValue = {
        [0]: 'Disable',
        [1]: 'Enable',
    };

    return {
        checkSignalStrength: signalStrengthDisplayValue[props.signalStrength ?? 0],
        checkAuthType: authTypeDisplayValue[authType ?? -1],
        checkNetworkType: networkTypeDisplayValue[networkType ?? -1],
        checkBoolean: booleanValue[boolean ?? -1],
    };
};
