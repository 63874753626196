import { Solar4gCellularInfo } from '@EcamModel/model';
import BaseCheckbox from '@components/BaseCheckbox';
import PopUpBase from '@components/PopUpBase';
import { pushError, pushSuccess } from '@components/toast';
import { IPopUp } from '@hooks/usePopUp';
import { Box, Button, FormControlLabel, Stack, Typography } from '@mui/material';
import color from '@theme/Colors';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useBackdrop } from 'src/providers/BackdropProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {
    cellularSettings: Partial<Solar4gCellularInfo>;
    onSave: (data: Partial<Solar4gCellularInfo>) => Promise<void>;
};

export default function PopUpWakeupSettings(props: Props) {
    const { open, onClose, onSave, cellularSettings } = props;
    const backdrop = useBackdrop();

    const {
        control,
        reset,
        handleSubmit,
        formState: { isValid, isDirty },
    } = useForm<Partial<Solar4gCellularInfo>>({ mode: 'all' });

    const defaultValues = {
        RemoteWake: cellularSettings?.RemoteWake,
        CallWake: cellularSettings?.CallWake,
    };

    const disabled = !(isValid && isDirty);

    useEffect(() => {
        if (!open) return;
        reset(defaultValues);
    }, [open, cellularSettings]);
    return (
        <PopUpBase
            open={open}
            title={<Typography variant="h4">Wake up settings</Typography>}
            desc={
                <Box minWidth={500} bgcolor={color.grey100} paddingX={2} py={1.5}>
                    <Stack direction={'row'} spacing={4}>
                        <Typography>Wake method:</Typography>

                        <Controller
                            control={control}
                            name="RemoteWake"
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <BaseCheckbox
                                            {...field}
                                            value={field.value}
                                            onChange={(e, checked) => {
                                                field.onChange(Number(checked));
                                            }}
                                            checked={!!field.value}
                                        />
                                    }
                                    label={
                                        <Typography ml={0.5} mt={0.3}>
                                            MQTT
                                        </Typography>
                                    }
                                    sx={{ ml: 0 }}
                                />
                            )}
                        />

                        <Controller
                            control={control}
                            name="CallWake"
                            render={({ field }) => (
                                <FormControlLabel
                                    control={
                                        <BaseCheckbox
                                            {...field}
                                            value={field.value}
                                            onChange={(e, checked) => {
                                                field.onChange(Number(checked));
                                            }}
                                            checked={!!field.value}
                                        />
                                    }
                                    label={
                                        <Typography ml={0.5} mt={0.3}>
                                            Call
                                        </Typography>
                                    }
                                    sx={{ ml: 0 }}
                                />
                            )}
                        />
                    </Stack>
                </Box>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            onClose?.();
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        sx={{ minWidth: 130 }}
                        variant={'contained'}
                        disabled={disabled}
                        onClick={handleSubmit((data) => {
                            backdrop.setTrue();

                            const _wakeupSettings: Partial<Solar4gCellularInfo> = {} as Partial<Solar4gCellularInfo>;
                            for (const key in data) {
                                if (data[key] !== defaultValues[key]) {
                                    _wakeupSettings[key] = data[key];
                                }
                            }

                            onSave(_wakeupSettings)
                                .then(() => {
                                    pushSuccess('Saved successfully');
                                })
                                .catch((res) => {
                                    pushError(res);
                                })
                                .finally(() => {
                                    onClose?.();
                                    backdrop.setFalse();
                                });
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
            minWidthButton={150}
        />
    );
}
