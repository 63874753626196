import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraExemptionPeriodAuditEvent } from '@EcamModel/model';
import { AxiosInstance } from 'axios';
import { ICameraExemptionPeriodAuditEventHttpController } from '../ICameraExemptionPeriodAuditEventHttpController';

export class CameraExemptionPeriodAuditEventHttpController
    extends BaseHttpController<CameraExemptionPeriodAuditEvent>
    implements ICameraExemptionPeriodAuditEventHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-exemption-period-audit-event', client);
    }
}
