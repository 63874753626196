import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { CameraPhotoVehicles } from '../../model';
import { ICameraPhotoVehicleHttpController } from '../ICameraPhotoVehicleHttpController';

export class CameraPhotoVehicleHttpController
    extends BaseHttpController<CameraPhotoVehicles>
    implements ICameraPhotoVehicleHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-photo-vehicle', client);
    }

    async updateCameraPhotoVehicleCoordinates(props: CameraPhotoVehicles): Promise<CameraPhotoVehicles> {
        return await this.doPost({ path: 'update-vehicle-coordinates', body: props }).then((res) => res.data);
    }
}
