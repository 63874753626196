import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Stack, Typography } from '@mui/material';
import { DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { validateDatePickerPastDate, validateDatePickerValue } from '@pages/cameras/add-new';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Mode } from './CameraInformation';
import { ExemptionPeriods } from '@EcamModel/model';

type Props = Omit<IPopUp, 'onConfirm'> & {
    onConfirm?(data: FormValues): void;
    mode: Mode | null;
    selectedExemption?: ExemptionPeriods;
};

type FormValues = {
    id?: number;
    from?: Date | null;
    to?: Date | null;
    desc: string;
};

export default function PopUpAddExemption(props: Props) {
    const [state, setState] = useState<FormValues>({
        from: null,
        to: null,
        desc: '',
    });

    const isDisabled = !state.from || !state.to || moment(state.to).isSameOrBefore(state.from);
    // ||moment(state.from).isSameOrBefore(moment().startOf('date')) ||
    // moment(state.to).isSameOrBefore(moment().startOf('date'));

    useEffect(() => {
        if (!props.open) return;
        if (props.mode === Mode.edit) {
            setState((prev) => ({
                ...prev,
                id: props.selectedExemption?.Id,
                from: moment(props.selectedExemption?.EffectiveFrom!) as any,
                to: moment(props.selectedExemption?.EffectiveTo!) as any,
                desc: props.selectedExemption?.Notes!,
            }));
        } else {
            setState((prev) => ({ ...prev, from: null, to: null, desc: '', id: undefined }));
        }
    }, [props.open, props.selectedExemption]);

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Stack>
                    <Typography variant="h4">
                        {props.mode === Mode.create ? 'Add exemption' : 'Edit exemption'}
                    </Typography>
                    {props.mode === Mode.create ? (
                        <Typography>Are you sure you want to add exemption</Typography>
                    ) : (
                        <Typography color="#009D4F">{`From: ${moment(props.selectedExemption?.EffectiveFrom).format(
                            'DD/MM/YYYY HH:mm'
                        )} to ${moment(props.selectedExemption?.EffectiveTo).format('DD/MM/YYYY HH:mm')}`}</Typography>
                    )}
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="100%" sx={{ background: '#FAFAFA', p: 2 }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ width: 130 }}>Effective from:</Typography>
                        <DateTimePicker
                            value={state.from}
                            onChange={(value) => {
                                setState((prev) => ({ ...prev, from: value!, to: null }));
                            }}
                            sx={{ width: '100%' }}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                            ampm={false}
                            // shouldDisableDate={(date) => validateDatePickerValue(moment(date).toDate()) !== null}
                            minutesStep={5}
                            format="DD/MM/YYYY HH:mm"
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ width: 130 }}>Effective to:</Typography>
                        <DateTimePicker
                            value={state.to}
                            onChange={(value) => {
                                setState((prev) => ({ ...prev, to: value }));
                            }}
                            sx={{ width: '100%' }}
                            viewRenderers={{
                                hours: renderTimeViewClock,
                                minutes: renderTimeViewClock,
                                seconds: renderTimeViewClock,
                            }}
                            ampm={false}
                            minutesStep={5}
                            format="DD/MM/YYYY HH:mm"
                            minDateTime={moment(state.from).add(5, 'm') as any}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <Typography sx={{ lineHeight: '50px', width: 130 }}>Note:</Typography>
                        <BaseTextField
                            value={state.desc}
                            onChange={(e) => {
                                const value = e.target.value;
                                setState((prev) => ({ ...prev, desc: value }));
                            }}
                            placeholder={'Enter description'}
                            maxLength={100}
                            multiline
                            InputProps={{
                                sx: {
                                    '& .MuiInputBase-input ': {
                                        p: 0,
                                    },
                                },
                            }}
                        />
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            setState({} as FormValues);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        disabled={isDisabled}
                        variant="contained"
                        onClick={() => {
                            props.onConfirm?.(state);
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
