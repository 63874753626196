import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ContraventionFunnel, ContraventionFunnelStatistics } from '@EcamModel/model';
import { AxiosInstance } from 'axios';
import {
    IContraventionFunnelHttpController,
    IGetContraventionFunnelOverview,
} from '../IContraventionFunnelHttpController';

export class ContraventionFunnelHttpController
    extends BaseHttpController<{}>
    implements IContraventionFunnelHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'contravention-funnel', client);
    }

    async overview(props: IGetContraventionFunnelOverview): Promise<ContraventionFunnelStatistics[]> {
        return await this.doPost({ path: 'overview', body: props }).then((res) => res.data);
    }
}
