import { CameraWithInfo } from '@EcamModel/model';
import UploadImageIcon from '@assets/jsx-icon/UploadImageIcon';
import PopUpBase from '@components/PopUpBase';
import { pushError, pushSuccess } from '@components/toast';
import { appConfig } from '@configs/index';
import { cameraInstalledPhotoHttpController } from '@controllers/index';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { ServiceTypeLabel } from '@pages/cameras/add-new';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import EditCameraInstalledPhoto from './EditCamereInstalledPhoto';
import { CameraInstalledPhotos } from '@EcamModel/model/CameraInstalledPhotos';
type Props = {
    open: boolean;
    onClose?(): void;
    camera: CameraWithInfo;
    onConfirm?(camera: CameraWithInfo): void;
};

type FileWithPreview = { file: File | null; url: string };

type FormValues = {
    serviceType: ServiceTypeLabel;
    anprId: string;
    cameraPhotoInstalled: CameraInstalledPhotos[];
};

export default function PopUpEditCameraPhotoInstalled(props: Props) {
    const maxImageWidth = 8000;
    const maxImageHeight = 4000;
    const { CameraInstalledPhotos } = props.camera;
    const blobURLCameraInstallPhoto = appConfig.gateway.cameraInstalledPhotoBlobURL;
    const {
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {},
    });

    const [isLoading, setIsLoading] = useState(false);
    const [uploadFileCameraInstalledPhoto, setUploadFileCameraInstalledPhoto] = useState<FileWithPreview[]>([]);

    const [deletedImageIds, setDeletedImageIds] = useState<number[]>([]);
    const [selectedImage, setSelectedImage] = useState<number>(0);
    const [startIndex, setStartIndex] = useState<number>(0);

    useEffect(() => {
        if (props.open) {
            // Initialize uploadFileCameraInstalledPhoto based on CameraInstalledPhotos
            const initialFiles = CameraInstalledPhotos?.map((photo) => ({
                file: null,
                url: `${blobURLCameraInstallPhoto}${photo.BlobName}`,
            }));
            setUploadFileCameraInstalledPhoto(initialFiles ?? []);
        }
    }, [props.open, props.camera]);

    const hasUploadedImages = uploadFileCameraInstalledPhoto?.some((photo) => photo.file !== null);
    const hasDeletedImages = deletedImageIds.length > 0;
    const canSave = !isLoading && (hasUploadedImages || hasDeletedImages);

    const handleRemoveCameraInstalledPhoto = (index: number) => {
        const imageToRemove = uploadFileCameraInstalledPhoto?.[index];
        const matchedImage = CameraInstalledPhotos?.find(
            (photo: any) => `${blobURLCameraInstallPhoto}${photo.BlobName}` === imageToRemove.url
        );

        if (matchedImage) {
            setDeletedImageIds((prevIds) => [...prevIds, matchedImage.Id!]);
        }

        setUploadFileCameraInstalledPhoto((prevFiles) => {
            const newFiles = prevFiles.filter((_, i) => i !== index);
            setStartIndex((prevIndex) => Math.max(0, Math.min(prevIndex, newFiles.length - 6)));
            if (selectedImage >= newFiles.length && selectedImage > 0) {
                setSelectedImage(selectedImage - 1);
            }
            return newFiles;
        });
    };

    const handleUploadCameraInstalledPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
        const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp'];

        const files = Array.from(event.target.files!);

        const newFiles = files
            .filter((file) => allowedFileTypes.includes(file.type))
            .map((file) => ({
                file,
                url: URL.createObjectURL(file),
            }));

        if (newFiles.length !== files.length) {
            pushError('Some files were rejected due to unsupported file types.');
        }

        setUploadFileCameraInstalledPhoto((prevFiles) => {
            const totalFiles = prevFiles.length + newFiles.length;
            if (totalFiles > 10) {
                pushError('You can only upload a maximum of 10 images.');
                const remainingSlots = 10 - prevFiles.length;
                return [...prevFiles, ...newFiles.slice(0, remainingSlots)];
            } else {
                return [...prevFiles, ...newFiles];
            }
        });
    };

    const handleUpdateCameraInstalledPhoto = () => {
        setIsLoading(true);

        const formData = new FormData() as any;

        formData.append('CameraId', props.camera.Id);
        formData.append('CameraInstalledDeletedPhotoIds', deletedImageIds);

        for (let i = 0; i < uploadFileCameraInstalledPhoto?.length; i++) {
            if (uploadFileCameraInstalledPhoto?.[i].file) {
                formData.append('CameraInstalledPhotos', uploadFileCameraInstalledPhoto?.[i].file);
            }
        }

        cameraInstalledPhotoHttpController
            .updateCameraInstalledPhotos(formData)
            .then((res) => {
                props.onConfirm?.({ ...props.camera, ...res });
                pushSuccess('Update successfully');
                props.onClose?.();
            })
            .catch((error) => {
                {
                    pushError(error.response.data.message.message);
                }
            })
            .finally(() => {
                setIsLoading(false);
                setDeletedImageIds([]);
            });
    };

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'md',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Stack>
                    <Typography variant="h4">Edit Photo of camera as installed</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Box p={2} sx={{ border: '1px solid #ccc', borderRadius: 2 }}>
                    <Stack
                        sx={{
                            border: '1px dashed #ccc',
                            borderRadius: '5px',
                            width: '100%',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                        flex={1}
                        justifyContent="center"
                    >
                        <input
                            type="file"
                            multiple
                            onChange={handleUploadCameraInstalledPhoto}
                            style={{ display: 'none' }}
                            accept=".png,.jpg,.jpeg,.webp"
                            id="file-upload"
                        />
                        <label htmlFor="file-upload" style={{ padding: '16px' }}>
                            <UploadImageIcon />
                            <Typography sx={{ cursor: 'pointer' }}>Click to upload</Typography>
                            <Typography sx={{ cursor: 'pointer' }} variant="body2" color="textSecondary">
                                SVG, PNG, JPG or GIF (max. {maxImageWidth}x{maxImageHeight}px)
                            </Typography>
                        </label>
                    </Stack>
                    {uploadFileCameraInstalledPhoto.length > 0 && (
                        <EditCameraInstalledPhoto
                            images={uploadFileCameraInstalledPhoto}
                            handleRemoveCameraInstalledPhoto={(index) => handleRemoveCameraInstalledPhoto(index)}
                        />
                    )}
                </Box>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            setDeletedImageIds([]);
                            setUploadFileCameraInstalledPhoto([]);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        endIcon={isLoading && <CircularProgress color="inherit" size="14px" />}
                        disabled={!canSave || isLoading}
                        onClick={handleSubmit(handleUpdateCameraInstalledPhoto)}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
