import { CameraType } from '@EcamModel/controllers/ICameraHttpController';
import { ISendActionGet } from '@EcamModel/controllers/IPTZCamConfigureRequestHttpController';
import { CameraWithInfo, PurposeType, Status, WifiStatus } from '@EcamModel/model';
import EditIcon from '@assets/jsx-icon/EditIcon';
import ICDelete from '@assets/jsx-icon/ICDelete';
import NoDataIcon from '@assets/jsx-icon/NoDataIcon';
import IOSSwitch from '@components/IOSSwitch';
import PopUpWarning from '@components/PopUpWarning';
import { pushError, pushSuccess } from '@components/toast';
import { cameraController, cameraPTZConfigureRequestHttpController } from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import { Box, Chip, CircularProgress, Dialog, Stack, Tooltip, Typography } from '@mui/material';
import color from '@theme/Colors';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDateByTimeZone, formatUKDate, formatUKDateTime } from 'src/helpers';
import { BaseItem, StackRowSpace } from '../styles';
import CameraThumbnail from './CameraThumbnail';
import ColumnDivider from './ColumnDivider';
import TooltipDangerIconButton from './TooltipDangerIconButton';
import TooltipIconButton from './TooltipIconButton';
import BatteryGood from './icons/BatteryGood';
import BatteryLow from './icons/BatteryLow';
import BatteryMedium from './icons/BatteryMedium';
import ConnectedIcon from './icons/ConnectedIcon';
import DisconnectedIcon from './icons/DisconnectedIcon';
import ListNoData from './NoDataCameras';
type Props = {
    cameraItem: CameraWithInfo;
    cameraType: CameraType;
    locationId?: string;
    onChangeStatus: (id: number, checked: boolean) => void;
    onDeleteCamera: (id: number) => void;
    onChangeVerification(cameraId: number, checked: boolean): void;
};

export const PurposesReadableMapping = {
    [PurposeType.Decommissioned]: 'Decommissioned',
    [PurposeType.OnHold]: 'On hold',
    [PurposeType.Prospective]: 'Prospective',
    [PurposeType.Deleted]: 'Deleted',
    [PurposeType.Testing]: 'Testing',
    [PurposeType.Live]: 'Live',
};

export default function CameraItem({
    cameraItem,
    locationId,
    onChangeStatus,
    onDeleteCamera,
    onChangeVerification,
    cameraType,
}: Props) {
    const navigate = useNavigate();
    const [isChangeActive, setIsChangeActive] = useState(false);
    const isCameraNotAvailable = cameraItem.Status === Status.NotAvailable;
    const now = new Date();
    const deployDate = new Date(cameraItem.DeployedDate);
    const isBeforeDeployDate = moment(now).isBefore(deployDate);
    const [checked, setChecked] = useState(cameraItem.Status === Status.Active);
    const [isVerification, setIsVerification] = useState(cameraItem.IsVerificationsEnabled);

    const handleChangeActive = (cameraId: number, checked: boolean) => {
        setIsChangeActive(true);
        cameraController
            .changeStatus(cameraId)
            .then(() => {
                onChangeStatus(cameraId, checked);
                pushSuccess('Change status successfully');
            })
            .catch(() => {})
            .finally(() => setIsChangeActive(false));
    };
    const handleChangeVerification = (cameraId: number, checked: boolean) => {
        setIsChangeActive(true);
        cameraController
            .updateConfigIsVerificationsEnabled({ CameraId: cameraId, Enable: checked })
            .then(() => {
                onChangeVerification(cameraId, checked);
                pushSuccess('Updated successfully');
            })
            .catch(() => {})
            .finally(() => setIsChangeActive(false));
    };

    const navigateToEdit = () => {
        navigate(`/locations/${locationId}/cameras/${cameraItem.Id}/edit`);
    };

    const popUpWarning = usePopUp({
        onConfirm() {
            handleChangeActive(cameraItem.Id!, checked);
            popUpWarning.onClose();
        },
    });

    const popUpDeleteWarning = usePopUp({
        onConfirm() {
            onDeleteCamera(cameraItem.Id!);
            popUpDeleteWarning.onClose();
        },
    });

    const popUpWarningVerification = usePopUp({
        onConfirm() {
            popUpWarningVerification.onClose();
        },
    });

    console.log(`cameraItem:`, cameraItem);

    const getStatusColor = (status: PurposeType) => {
        const colorRender = (props: { color: string; bgcolor: string }) => ({
            colorText: props.color,
            bgColor: props.bgcolor,
        });

        const mappingColor = {
            [PurposeType.Prospective]: colorRender({
                color: color.secondary,
                bgcolor: '#E5F2FF',
            }),
            [PurposeType.Live]: colorRender({
                bgcolor: '#E5F5ED',
                color: '#009D4F',
            }),
            [PurposeType.Testing]: colorRender({
                bgcolor: '#CC00661A',
                color: '#CC0066',
            }),
            [PurposeType.Decommissioned]: colorRender({
                bgcolor: '#FCE8E5',
                color: '#E01B00',
            }),
            [PurposeType.OnHold]: colorRender({
                bgcolor: '#FFF8E5',
                color: '#FFB600',
            }),
        };
        return (
            mappingColor[status] ||
            colorRender({
                bgcolor: '#FCE8E5',
                color: '#E01B00',
            })
        );

        // switch (status) {
        //     case PurposeType.Prospective:
        //         return { colorText: color.secondary, bgColor: '#E5F2FF' };
        //     case PurposeType.Live:
        //         return { colorText: '#009D4F', bgColor: '#E5F5ED' };
        //     case PurposeType.Testing:
        //         return { colorText: '#CC0066', bgColor: '#CC00661A' };
        //     case PurposeType.Decommissioned:
        //         return { colorText: '#E01B00', bgColor: '#FCE8E5' };
        //     case PurposeType.OnHold:
        //         return { colorText: '#FFB600', bgColor: '#FFF8E5' };
        //     default:
        //         return { colorText: '#E01B00', bgColor: '#FCE8E5' };
        // }
    };
    const { colorText, bgColor } = getStatusColor(cameraItem.PurposeType!);

    const [open, setOpen] = useState<boolean>(false);
    const [imageBase64, setImageBase64] = useState<string>('');
    const [selectImagePreview, setSelectImagePreview] = useState<string>('');
    const [isLoadingIMG, setIsLoadingIMG] = useState<boolean>(true);

    const getPhotoURLSnapShot = async () => {
        const _filter: ISendActionGet = {
            ip: cameraItem.IP,
            authenticate: {
                username: cameraItem.UserName,
                password: cameraItem.PassWord,
            },
        };
        setIsLoadingIMG(true);
        await cameraPTZConfigureRequestHttpController
            .getPhotoSnapShot(_filter)
            .then((res) => {
                setImageBase64(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoadingIMG(false);
            });
    };
    useEffect(() => {
        if (cameraType === CameraType.PTZ) {
            getPhotoURLSnapShot();
        }
    }, []);

    return (
        <BaseItem component={Stack} sx={{ display: 'flex', flexDirection: 'row', py: '12px' }} gap={2}>
            {cameraType === CameraType.Solar ? (
                <CameraThumbnail blob={cameraItem.CameraPhotos?.[0]?.BlobName} geoZones={cameraItem.GeoZones || []} />
            ) : (
                <Box
                    sx={{
                        width: '208px',
                        textAlign: 'center',
                        margin: 'auto',
                        borderRadius: '4px',
                        backgroundColor: color.grey100,
                    }}
                >
                    {isLoadingIMG ? (
                        <Stack minHeight={120}>
                            <CircularProgress
                                size={20}
                                sx={{
                                    margin: 'auto',
                                }}
                            />
                        </Stack>
                    ) : (
                        <>
                            {imageBase64 ? (
                                <Box
                                    sx={{
                                        width: '100%',
                                        aspectRatio: '16/9',
                                        background: `url(data:image/png;base64,${imageBase64}) no-repeat`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        setOpen(true);
                                        setSelectImagePreview(`data:image/png;base64,${imageBase64}`);
                                    }}
                                />
                            ) : (
                                <ListNoData sx={{ minHeight: 120, border: 'none' }} />
                            )}
                        </>
                    )}
                </Box>
            )}
            <Stack flex={1} direction="column" gap={1}>
                <StackRowSpace gap={2}>
                    <StackRowSpace gap={2}>
                        <Link to={cameraItem.Id!.toString()} style={{ textDecoration: 'none' }}>
                            <Typography variant="h5" sx={{ cursor: 'pointer', '&:hover': { color: color.priText } }}>
                                {cameraItem.Name}
                            </Typography>
                        </Link>
                        <Tooltip title="Average number of PCNs issued within the last 30 days." arrow placement="top">
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    width: 25,
                                    height: 25,
                                    background: '#CC0066',
                                    borderRadius: 1,
                                    p: 2,
                                    ml: -1,
                                    cursor: 'pointer',
                                }}
                            >
                                <Typography color="#fff" textAlign="center">
                                    {cameraItem.PcnByCamera ?? 0}
                                </Typography>
                            </Stack>
                        </Tooltip>

                        {isCameraNotAvailable ? (
                            <Tooltip
                                arrow
                                placement="top"
                                title="The MAC address could not be found or is in the process of data synchronization."
                            >
                                <Chip
                                    size="small"
                                    sx={{
                                        borderRadius: '16px',
                                        height: 22,
                                        fontSize: 12,
                                        px: '5px',
                                        cursor: 'pointer',
                                    }}
                                    label={'Not available'}
                                />
                            </Tooltip>
                        ) : (
                            <>
                                {cameraType === CameraType.Solar && (
                                    <>
                                        <TooltipIconButton
                                            icon={
                                                cameraItem.Solar4gCamera?.LastWifiStatus === WifiStatus.Connected ? (
                                                    <ConnectedIcon />
                                                ) : (
                                                    <DisconnectedIcon />
                                                )
                                            }
                                            tooltipTitle={
                                                cameraItem.Solar4gCamera?.LastWifiStatus === WifiStatus.Connected
                                                    ? 'Connected'
                                                    : 'Not connected'
                                            }
                                        />
                                        <TooltipIconButton
                                            icon={getBatteryIcon(cameraItem.Solar4gCamera?.LastBatteryStatus ?? 0)}
                                            tooltipTitle={`${cameraItem.Solar4gCamera?.LastBatteryStatus ?? 0}%`}
                                        />
                                    </>
                                )}
                            </>
                        )}

                        {cameraItem.PurposeType !== null && (
                            <Tooltip title="Camera's purpose" placement="top">
                                <Chip
                                    size="small"
                                    sx={{
                                        borderRadius: '16px',
                                        fontSize: 12,
                                        px: '5px',
                                        background: bgColor,
                                        color: colorText,
                                        cursor: 'pointer',
                                    }}
                                    label={PurposesReadableMapping[cameraItem.PurposeType!]}
                                />
                            </Tooltip>
                        )}
                    </StackRowSpace>
                    <Stack direction="row" gap={2}>
                        <TooltipIconButton icon={<EditIcon />} tooltipTitle="Edit" onClick={navigateToEdit} />
                        <TooltipDangerIconButton
                            icon={<ICDelete />}
                            tooltipTitle="Delete"
                            onClick={() => popUpDeleteWarning.setTrue()}
                        />
                        <PopUpWarning {...popUpDeleteWarning} message="Are you sure you want to delete this camera?" />
                        <Tooltip
                            title={
                                isCameraNotAvailable && isBeforeDeployDate
                                    ? 'The camera will not be able to activate until the deployed date.'
                                    : ''
                            }
                            arrow
                            placement="top"
                        >
                            <Box>
                                <IOSSwitch
                                    label={
                                        <Typography sx={{ minWidth: '55px' }}>
                                            {cameraItem.Status === Status.Active ? 'Active' : 'Inactive'}
                                        </Typography>
                                    }
                                    iosSwitchProps={{
                                        checked: cameraItem.Status === Status.Active,
                                        onChange(event, checked) {
                                            setChecked(checked);
                                            popUpWarning.setTrue();
                                        },
                                        disabled: isChangeActive || isCameraNotAvailable || isBeforeDeployDate,
                                    }}
                                />
                                <PopUpWarning
                                    {...popUpWarning}
                                    message="Are you sure you want to change the status of this camera?"
                                />
                            </Box>
                        </Tooltip>
                    </Stack>
                </StackRowSpace>
                <Stack direction={'row'}>
                    <Typography sx={{ color: color.secondary }}>
                        Camera type: {cameraItem.VendorCameraId !== null ? 'Solar' : 'PTZ'}
                    </Typography>
                    <ColumnDivider />
                    <Typography sx={{ color: color.secondary }}>Zone: {cameraItem.Zone?.PublicName}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Box>
                        <Typography>Create verifications: </Typography>
                    </Box>
                    <Box>
                        <IOSSwitch
                            label={
                                <Typography sx={{ minWidth: '55px' }}>
                                    {!!cameraItem.IsVerificationsEnabled ? 'On' : 'Off'}
                                </Typography>
                            }
                            iosSwitchProps={{
                                checked: cameraItem.IsVerificationsEnabled,
                                onChange(event, checked) {
                                    setIsVerification(checked);
                                    popUpWarningVerification.setTrue();
                                },
                                disabled: isChangeActive,
                            }}
                        />
                    </Box>
                    <PopUpWarning
                        {...popUpWarningVerification}
                        message="Are you sure you want to change the status of create verifications?"
                        onConfirm={() => {
                            handleChangeVerification(cameraItem.Id!, isVerification!);
                            popUpWarningVerification.onClose();
                        }}
                    />
                </Stack>
                {cameraType === CameraType.Solar ? (
                    <>
                        <Typography>MAC: {cameraItem.Solar4gCamera?.Mac}</Typography>
                        <Stack direction={'row'}>
                            <Typography>Production: {formatUKDate(cameraItem.DeployedDate)}</Typography>
                            <ColumnDivider />
                            <Typography>
                                Latest photo:{' '}
                                {cameraItem.CameraPhotos?.[0]?.CaptureAt
                                    ? formatDateByTimeZone(cameraItem.CameraPhotos?.[0]?.CaptureAt, cameraItem.TimeZone)
                                    : 'Not available'}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Typography>
                                Last contact: {formatUKDateTime(cameraItem.Solar4gCamera?.LastContact)}
                            </Typography>
                            <ColumnDivider />
                            <Typography>Updated: {formatUKDateTime(cameraItem.Updated)}</Typography>
                        </Stack>
                    </>
                ) : (
                    <>
                        <Stack direction={'row'}>
                            <Typography>MAC: {cameraItem.PTZCamera?.Mac}</Typography>
                            <ColumnDivider />
                            <Typography>Production: {formatUKDate(cameraItem.DeployedDate)}</Typography>
                            <ColumnDivider />
                            <Typography>Updated: {formatUKDateTime(cameraItem.Updated)}</Typography>
                        </Stack>
                    </>
                )}
            </Stack>
            {selectImagePreview && (
                <Dialog
                    open={!!open}
                    onClose={() => {
                        setOpen(false);
                        setSelectImagePreview('');
                    }}
                    PaperProps={{
                        sx: {
                            minWidth: 1280,
                            minHeight: 720,
                        },
                    }}
                >
                    <img style={{ width: 1280, height: 720 }} src={`${selectImagePreview}`} alt="Zoomable" />
                </Dialog>
            )}
        </BaseItem>
    );
}

export const getBatteryIcon = (percent: number) => {
    if (percent > 50) return <BatteryGood width={18} height={18} />;
    if (percent > 20) return <BatteryMedium width={18} height={18} />;
    return <BatteryLow width={18} height={18} />;
};
