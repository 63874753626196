import { Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import color from '@theme/Colors';
import React, { ReactNode } from 'react';

export type SearchTypeProps<T> = {
    listData: T[];
    value: T;
    keyEqual: keyof T;
    keyLabel: keyof T;
    handleChange: (val: T) => void;
    label?: string;
    minWidth?: any;
    startAdornment?: ReactNode;
};

export default function SearchTypeSelect<T>(props: SearchTypeProps<T>) {
    const handleChange = (event: SelectChangeEvent<any>, child: React.ReactNode) => {
        const item = props.listData.find((item) => item[props.keyEqual] === event.target.value);
        if (item) return props.handleChange(item);
    };

    return (
        <FormControl
            fullWidth
            size="small"
            sx={{
                background: color.white,
                '& svg': {
                    width: '20px',
                    height: '20px',
                },
                '& .MuiSvgIcon-root': {
                    top: 'calc(50% - 0.4em)',
                },

                '& .MuiInputBase-root': {
                    border: '',
                    '& .MuiSelect-select': props.startAdornment
                        ? {
                              paddingLeft: 0,
                              paddingRight: 0,
                          }
                        : undefined,
                },
                minWidth: props.minWidth ?? 100,
            }}
            variant={props.startAdornment ? 'outlined' : 'standard'}
        >
            {props.startAdornment && (
                <InputLabel id="demo-simple-select-standard"> {props.label || 'Sort by'}</InputLabel>
            )}
            <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                onChange={handleChange}
                value={props.value[props.keyEqual]}
                disableUnderline
                autoFocus={false}
                label={props.label}
                startAdornment={props.startAdornment}
            >
                {props.listData.length &&
                    props.listData.map((item, index) => {
                        return [
                            <MenuItem value={item[props.keyEqual] as any} key={index}>
                                {item[props.keyLabel] as string}
                            </MenuItem>,
                            index === props.listData.length - 1 ? (
                                ''
                            ) : (
                                <Divider
                                    sx={{
                                        my: '2px !important',
                                        borderColor: color.grey200,
                                        mx: '3px',
                                    }}
                                />
                            ),
                        ];
                    })}
            </Select>
        </FormControl>
    );
}
