import { ListProps, Paging } from '@Core';
import { CameraType, IUpsertCameraProps } from '@EcamModel/controllers/ICameraHttpController';
import { IListWithECamServiceActive } from '@EcamModel/controllers/ILocationSMHttpController';
import {
    CameraServiceType,
    CameraWithInfo,
    LocationSM,
    PurposeType,
    SolarPanelPositionType,
    Status,
} from '@EcamModel/model';
import { ServiceSMType } from '@EcamModel/model/ServiceSM';
import UploadImageIcon from '@assets/jsx-icon/UploadImageIcon';
import { BaseTextField } from '@components/BaseTextField';
import IOSSwitch from '@components/IOSSwitch';
import BreadCrumbs, { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushError, pushSuccess, pushWarning } from '@components/toast';
import { cameraController, cameraInstalledPhotoHttpController, locationController } from '@controllers/index';
import styled from '@emotion/styled';
import useScrollToTop from '@hooks/useScrollToTop';
import { CancelOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    FormControl,
    FormControlLabel,
    IconButton,
    LinearProgress,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Tooltip,
    Typography,
    TypographyProps,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import color from '@theme/Colors';
import moment, { Moment } from 'moment';
import React, { ChangeEventHandler, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useBackdrop } from 'src/providers/BackdropProvider';
import CancelIcon from '../details/components/icon/CancelIcon';
import SuccessIcon from '../details/components/icon/SuccessIcon';
import { validateTypingIPAddress, validateTypingInput } from '../details/utils';
import { search } from '../list/helpers';
import myJson from './timezones.json';
import InformationIcon from '../details/components/icon/InformationIcon';
import { DrawerContext, DrawerPage } from '@components/drawer/useDrawer';
import usePopUp from '@hooks/usePopUp';
import PopUpWarning from '@components/PopUpWarning';

export type ServiceTypeLabel = {
    key: CameraServiceType;
    label: string;
};

export type PositionSolarPanelLabel = {
    key: SolarPanelPositionType;
    label: string;
};

export type CameraPurposesLabel = {
    key: PurposeType;
    label: string;
};

export const camServiceTypes: Record<CameraServiceType, ServiceTypeLabel> = {
    [CameraServiceType.ECamTechOps]: {
        key: CameraServiceType.ECamTechOps,
        label: 'eCam TechOps',
    },
    [CameraServiceType.ANPRTechOps]: {
        key: CameraServiceType.ANPRTechOps,
        label: 'ANPR TechOps',
    },
    [CameraServiceType.ECamTechOps_V2]: {
        key: CameraServiceType.ECamTechOps_V2,
        label: 'eCam TechOpsv2',
    },
    [CameraServiceType.ANPRTechOpsV2]: {
        key: CameraServiceType.ANPRTechOpsV2,
        label: 'ANPR TechOpsv2',
    },
    
};

export const positionSolarPanelReadableMapping: Record<SolarPanelPositionType, PositionSolarPanelLabel> = {
    [SolarPanelPositionType.NNE]: {
        key: SolarPanelPositionType.NNE,
        label: 'NNE (North-North-East)',
    },
    [SolarPanelPositionType.ENE]: {
        key: SolarPanelPositionType.ENE,
        label: 'ENE (East-North-East)',
    },
    [SolarPanelPositionType.ESE]: {
        key: SolarPanelPositionType.ESE,
        label: 'ESE (East-South-East)',
    },
    [SolarPanelPositionType.SSE]: {
        key: SolarPanelPositionType.SSE,
        label: 'SSE (South-South-East)',
    },
    [SolarPanelPositionType.SSW]: {
        key: SolarPanelPositionType.SSW,
        label: 'SSW (South-South-West)',
    },
    [SolarPanelPositionType.WSW]: {
        key: SolarPanelPositionType.WSW,
        label: 'WSW (West-South-West)',
    },
    [SolarPanelPositionType.WNW]: {
        key: SolarPanelPositionType.WNW,
        label: 'WNW (West-North-West)',
    },
    [SolarPanelPositionType.NNW]: {
        key: SolarPanelPositionType.NNW,
        label: 'NNW (North-North-West)',
    },
};

export const cameraPurposes: Record<PurposeType, CameraPurposesLabel> = {
    [PurposeType.Decommissioned]: {
        key: PurposeType.Decommissioned,
        label: 'Decommissioned',
    },
    [PurposeType.Deleted]: {
        key: PurposeType.Deleted,
        label: 'Deleted',
    },
    [PurposeType.Live]: {
        key: PurposeType.Live,
        label: 'Live',
    },
    [PurposeType.OnHold]: {
        key: PurposeType.OnHold,
        label: 'On hold',
    },
    [PurposeType.Prospective]: {
        key: PurposeType.Prospective,
        label: 'Prospective',
    },
    [PurposeType.Testing]: {
        key: PurposeType.Testing,
        label: 'Testing',
    },
};

type FormValues = {
    deployedDate: Moment;

    cameraInstalledPhotos?: Express.Multer.File[];

    note?: string;

    createVerification: boolean;

    informationDetails: {
        name: string;
        mac: string;
        assetName: string;
        lensCapacity?: string;
        solarPanelPosition?: PositionSolarPanelLabel;
        purposes?: CameraPurposesLabel;
    };

    service: {
        anprId?: string;
        serviceType: ServiceTypeLabel;
    };

    milesightsAccount: {
        userName: string;
        passWord: string;
        ip: string;
    };

    zoneAndGMT: {
        location?: any;
        zone?: any;
        time?: TimeZone;
    };
};

type GMT = {
    Name: string;
    Value: string;
};

interface TimeZone {
    value: string;
    utc: string;
    text: string;
}

export const RequiredTitle = (props: { title: string; required?: boolean; sx?: TypographyProps }) => {
    return (
        <Typography sx={{ lineHeight: '40px', width: 110, ...props.sx }}>
            {props.title}
            {props.required && <span style={{ color: 'red' }}>*</span>}:
        </Typography>
    );
};

export const validateDatePickerValue = (date: Date) => {
    const now = moment().startOf('date');
    const isValidDate = moment(date).isBefore(now);
    if (isValidDate) return 'Not valid date';
    return null;
};
export const validateDatePickerPastDate = (date: Date) => {
    const now = moment().startOf('date');
    const isValidDate = moment(date).isSameOrBefore(now);
    if (isValidDate) return 'Not valid date';
    return null;
};

const maxImageSize = 10 * 1024 * 1024;
const maxImageWidth = 8000;
const maxImageHeight = 4000;

export default function CameraUpsert() {
    const { locationId, id: cameraId, type } = useParams();

    const [zones, setZones] = useState<any[]>([]);
    const [showPassword, setShowPassword] = useState(false);

    const [selectedItem, setSelectedItem] = useState<string | undefined>();
    const [camera, setCamera] = useState<CameraWithInfo>({} as CameraWithInfo);
    const [loading, setLoading] = useState<boolean | undefined>(undefined);

    const { setActiveItem } = useContext(DrawerContext);

    useScrollToTop();

    useEffect(() => {
        setActiveItem(DrawerPage.Cameras);
        getInactiveCameraMacs();
    }, []);

    const popUpWarningEditMac = usePopUp();

    const [selectedCameraType, setSelectedCameraType] = useState<number | null>(null);
    const isNotCameraPTZ = selectedCameraType !== CameraType.PTZ;
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        const cameraType = cameraId
            ? camera.VendorCameraId !== null
                ? 0
                : camera.PTZCameraId !== null
                ? 1
                : Number(type) || 0
            : Number(type) || 0;

        setSelectedCameraType(cameraType);
    }, [camera, cameraId, type]);

    const valuesDefault: FormValues = {
        deployedDate: moment(),
        note: '',
        createVerification: true,
        cameraInstalledPhotos: [],
        informationDetails: {
            name: '',
            mac: '',
            assetName: '',
            lensCapacity: '',
            solarPanelPosition: undefined,
            purposes: cameraPurposes[PurposeType.Live],
        },
        service: {
            anprId: '',
            serviceType: camServiceTypes[CameraServiceType.ECamTechOps],
        },
        milesightsAccount: {
            userName: '',
            passWord: '',
            ip: '',
        },
        zoneAndGMT: {
            location: undefined,
            zone: undefined,
            time: undefined,
        },
    };

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        setError,
        clearErrors,
        formState: { isDirty, isValid, errors, dirtyFields, isSubmitting },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: valuesDefault,
    });

    const getDetailsCamera = () => {
        setLoading(true);
        cameraController
            .get(cameraId!)
            .then((res) => {
                setCamera(res);
                if (Number(locationId) !== res.Zone?.LocationId) {
                    pushError('Camera does not exist in this location');
                    navigate(`/locations/${locationId}/cameras`);
                } else {
                    reset({
                        deployedDate: moment(res.DeployedDate),
                        note: res.Notes ?? '',
                        createVerification: res.IsVerificationsEnabled,
                        informationDetails: {
                            name: res.Name,
                            mac: res.Mac,
                            assetName: res.Asset,
                            lensCapacity: res.LensCapacity,
                            solarPanelPosition: positionSolarPanelReadableMapping[res?.SolarPanelPosition!],
                            purposes: cameraPurposes[res?.PurposeType!],
                        },
                        service: {
                            anprId: res.ANPRCameraId?.toString(),
                            serviceType: camServiceTypes[res.ServiceType],
                        },
                        milesightsAccount: {
                            userName: res.UserName,
                            passWord: res.PassWord,
                            ip: res.IP,
                        },
                        zoneAndGMT: {
                            location: res.Zone.Location,
                            zone: res.Zone,
                            time: {
                                utc: res.TimeZone,
                            },
                        },
                    });
                }
            })
            .catch((err) => {
                pushError(err?.response?.data.message);
                navigate(`/locations/${locationId}/cameras`);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (cameraId) {
            getDetailsCamera();
        }
    }, [cameraId]);

    useScrollToTop();
    const serviceTypeKey = watch('service.serviceType.key');
    const isANPR = serviceTypeKey === CameraServiceType.ANPRTechOps;

    useEffect(() => {
        setActiveItem(DrawerPage.Cameras);
        getInactiveCameraMacs();
    }, []);

    const navigate = useNavigate();
    const backdrop = useBackdrop();

    const openBackdrop = () => {
        const message = <Typography>Please wait for the camera to take a picture...</Typography>;
        backdrop.setTrue(message);
    };

    const disabled = !(isValid && isDirty) || (isNotCameraPTZ && !watch().informationDetails?.solarPanelPosition);

    const fetchGMTTime = (listProps?: ListProps<GMT>) => {
        return new Promise<Paging<GMT>>((resolve, reject) => {
            setTimeout(() => {
                const paging: Paging<GMT> = {
                    page: 1,
                    pageSize: 9999,
                    rows: [],
                    total: 0,
                    totalPages: 1,
                };

                for (let i = -12; i < 14; i++) {
                    const Value = i.toString();
                    const Name = `UTC${i >= 0 ? '+' : ''}${i}`;

                    paging.rows.push({
                        Name: Name,
                        Value: Value,
                    });
                }
                // const arr = JSON.parse(myJson);

                const searchText = listProps?.search?.content;

                if (searchText) {
                    paging.rows = paging.rows.filter((l) => l.Name.toLowerCase().includes(searchText.toLowerCase()));
                }

                resolve(paging);
            }, 300);
        });
    };

    const getSelectLocations = async (props: IListWithECamServiceActive) => {
        const filteredLocation = await locationController
            .listWithECamServiceActive({
                ...props,
                pageSize: 1,
                filter: {
                    Id: Number(locationId) ?? undefined,
                },
            })
            .finally(() => {});

        const filteredLocationSelected = filteredLocation?.rows?.filter((z) => z.Id === Number(locationId));

        setValue('zoneAndGMT.location', filteredLocation?.rows?.[0]);
        setValue('zoneAndGMT.zone', filteredLocationSelected[0]?.Zones?.[0] ?? undefined);
        setZones(filteredLocationSelected[0]?.Zones ?? []);

        return filteredLocation;
    };

    useEffect(() => {
        if (locationId) {
            getSelectLocations({
                ZoneService: [isANPR ? ServiceSMType.Anpr : ServiceSMType.eCam],
            });
        }
    }, [isANPR]);

    const [listMACs, setListMACs] = useState<string[]>([]);
    const [isLoadingListMacs, setIsLoadingListMacs] = useState<boolean>(true);

    const getInactiveCameraMacs = async () => {
        setIsLoadingListMacs(true);
        await cameraController
            .getListMacCameraInActive()
            .then((res) => {
                setListMACs(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoadingListMacs(false);
            });
    };

    const MACsAutocomplete = useStyledAutocomplete({
        list: {
            options: Object.values(listMACs),
            isFiltered(option, searchText) {
                return search(option, searchText);
            },
        },
        dependencyList: [listMACs],
        loading: isLoadingListMacs,
    });

    const locationAutocomplete = useStyledAutocomplete({
        async getList(props: IListWithECamServiceActive): Promise<Paging<LocationSM>> {
            const res = await locationController.listWithECamServiceActive(props).finally(() => {});
            if (!Number(locationId)) {
                const currentLocation = watch().zoneAndGMT.location;
                const filteredLocation = res?.rows?.filter((l) => l?.Id === currentLocation?.Id);
                const firstLocation = filteredLocation[0];

                if (currentLocation) {
                    setZones(firstLocation?.Zones ?? []);
                    setValue('zoneAndGMT.zone', firstLocation?.Zones ?? []);
                }

                if (firstLocation !== currentLocation) {
                    setValue('zoneAndGMT.location', undefined);
                    setValue('zoneAndGMT.zone', undefined);
                }
            }
            return res;
        },
        listProps: {
            search: { fields: ['name'] } as any,
            ZoneService: [isANPR ? ServiceSMType.Anpr : ServiceSMType.eCam],
        },
        dependencyList: [isANPR],
    });

    const filteredServiceTypes = Object.values(camServiceTypes).filter((service) => {
        if (selectedCameraType !== CameraType.PTZ) {
            return service.key === CameraServiceType.ECamTechOps || service.key === CameraServiceType.ANPRTechOps;
        }
        return service.key === CameraServiceType.ECamTechOps_V2;
    });

    const serviceTypeAutocomplete = useStyledAutocomplete({
        list: {
            options: filteredServiceTypes,
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
        dependencyList: [selectedCameraType],
    });

    useEffect(() => {
        setValue('service.serviceType', filteredServiceTypes[0]);
    }, [selectedCameraType]);

    const positionSolarPanelAutocomplete = useStyledAutocomplete({
        list: {
            options: Object.values(positionSolarPanelReadableMapping),
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
    });

    const purposesAutocomplete = useStyledAutocomplete({
        list: {
            options: Object.values(cameraPurposes),
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
    });

    const onChangeLocation = (l: any) => {
        setValue('zoneAndGMT.location', l);
        setZones(l.Zones!);
    };
    const zoneAutocomplete = useStyledAutocomplete({
        list: {
            options: zones,
            isFiltered(option, searchText) {
                return option.PublicName.toLowerCase().includes(searchText.toLocaleLowerCase());
            },
        },
        dependencyList: [zones],
    });
    const gmtAutocomplete = useStyledAutocomplete<GMT, ListProps<GMT>>({
        getList(props: ListProps<GMT>) {
            return fetchGMTTime(props);
        },
        listProps: {
            search: {
                fields: ['Name'],
            } as any,
        },
    });
    const handleConfirmAddCamera = async (data: FormValues, isDetails?: boolean) => {
        const _cameraData: CameraWithInfo = {
            Id: cameraId ? Number(cameraId) : undefined,
            Name: data.informationDetails.name.trim(),
            Mac: data.informationDetails.mac.trim(),
            TimeZone: data.zoneAndGMT.time?.utc,
            ZoneId: Number(data.zoneAndGMT.zone?.Id),
            VendorId: 1,
            DeployedDate: moment(data.deployedDate).toDate(),
            Status: camera.Status,
            IP: data.milesightsAccount.ip,
            UserName: data.milesightsAccount.userName,
            PassWord: data.milesightsAccount.passWord,
            LensCapacity: data.informationDetails.lensCapacity,
            ServiceType: data.service.serviceType.key,
            ANPRCameraId: data.service.anprId ? parseInt(data.service.anprId) : null,
            Notes: data.note,
            Asset: data.informationDetails.assetName,
            SolarPanelPosition: data.informationDetails.solarPanelPosition?.key ?? undefined,
            PurposeType: data.informationDetails.purposes?.key ?? undefined,

            // EnablePcnVerificationAt: camera.IsVerificationsEnabled
            //     ? data.createVerification
            //         ? camera.EnablePcnVerificationAt
            //         : (null as any)
            //     : data.createVerification
            //     ? new Date()
            //     : (null as any),

            EnablePcnVerificationAt: !cameraId ? new Date() : undefined,
            IsVerificationsEnabled: cameraId ? camera.IsVerificationsEnabled : data.createVerification,

            ZoneName: cameraId ? data.zoneAndGMT.zone?.PublicName : undefined,
            LocationName: cameraId ? data.zoneAndGMT.location.Name : undefined,

            PTZCameraId: cameraId ? camera.PTZCameraId : undefined,
            VendorCameraId: cameraId ? camera.VendorCameraId : undefined,
        };

        const upsertCameraController: Promise<CameraWithInfo> = isNotCameraPTZ
            ? cameraController.upsertWithSolarCamera(_cameraData as IUpsertCameraProps)
            : cameraController.upsertWithPTZCamera(_cameraData as IUpsertCameraProps);

        cameraId ? backdrop.setTrue() : openBackdrop();

        await upsertCameraController
            .then(async (res) => {
                navigate(
                    `${
                        !!isDetails
                            ? `/locations/${res.Zone?.LocationId}/cameras/${res.Id}`
                            : isNotCameraPTZ
                            ? `/locations/${res.Zone?.LocationId}/cameras`
                            : `/locations/${res.Zone?.LocationId}/cameras?cameraType=1`
                    }`
                );
                if (cameraId) {
                    pushSuccess('Saved successfully');
                } else {
                    if (res.Id && cameraInstalledPhotos.length > 0) {
                        await handleCreateCameraInstalledPhotos(res.Id);
                    }
                    if (res.CameraPhotos?.[0]) {
                        pushSuccess('Added new camera successfully');
                    } else {
                        pushWarning("Didn't receive new photos from camera. Please try again");
                    }
                }
            })
            .catch((err) => {
                if (err.response.data.statusCode === 409)
                    setError('informationDetails.mac', { message: err.response.data.message });
                if (err.response.data.statusCode === 400)
                    setError('service.anprId', { message: err.response.data.message });
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleCreateCameraInstalledPhotos = async (cameraId: number) => {
        const formData = new FormData() as any;
        cameraInstalledPhotos.forEach((file) => {
            formData.append('CameraInstalledPhotos', file);
        });

        await cameraInstalledPhotoHttpController.createCameraInstalledPhotos(cameraId, formData);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [cameraInstalledPhotos, setCameraInstalledPhotos] = useState<File[]>([]);
    const [uploadProgress, setUploadProgress] = useState({});

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);

        if (cameraInstalledPhotos.length + newFiles.length > 10) {
            pushError('You can only upload a maximum of 10 images.');
            return;
        }

        newFiles.forEach((file: any) => {
            if (file.size > maxImageSize) {
                pushWarning(
                    `The maximum size can only be ${(maxImageSize / (1024 * 1024)).toFixed(
                        2
                    )}MB, you have just uploaded a file that is ${(file.size / (1024 * 1024)).toFixed(2)}MB in size.`
                );
                return;
            }

            const reader = new FileReader();
            const img = new Image();
            reader.onload = (e: any) => {
                img.onload = () => {
                    if (img.width > maxImageWidth || img.height > maxImageHeight) {
                        pushWarning(
                            `The maximum dimensions allowed are ${maxImageWidth}x${maxImageHeight}px. The uploaded file has dimensions ${img.width}x${img.height}px.`
                        );
                        return;
                    }
                    setCameraInstalledPhotos((prevFiles) => {
                        if (prevFiles.length >= 10) {
                            return prevFiles;
                        }
                        const fileExists = prevFiles.some((prevFile) => prevFile.name === file.name);
                        if (!fileExists) {
                            return [...prevFiles, file];
                        }
                        return prevFiles;
                    });
                    let progress = 0;
                    const interval = setInterval(() => {
                        progress += 25;
                        setUploadProgress((prev) => ({
                            ...prev,
                            [file.name]: progress,
                        }));
                        if (progress >= 100) {
                            clearInterval(interval);
                        }
                    }, 100);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const handleRemoveFile = (fileName: string) => {
        setCameraInstalledPhotos(cameraInstalledPhotos.filter((file) => file.name !== fileName));
        const updatedProgress = { ...uploadProgress };
        delete updatedProgress[fileName];
        setUploadProgress(updatedProgress);
    };

    const handleRadioChangeCameraType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCameraType(Number(event.target.value));
        setValue('informationDetails.solarPanelPosition', undefined);
    };

    const sectionRefs = useRef<HTMLDivElement[]>([]);

    const handleStepClick = (index: number) => {
        setActiveStep(index);
        sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const breadcrumbs: IBreadCrumbs[] = [
        {
            title: `${!locationId ? 'Camera locations' : cameraId ? `${camera.Name}` : 'Cameras'}`,
            onBack: true,
        },
        {
            title: `${cameraId ? 'Edit camera' : 'Add new camera'}`,
            isColorBlackText: true,
        },
    ];

    const steps: BaseFormSectionProps[] = [
        {
            id: 1,
            heading: 'Information details',
            isStatusError:
                !watch().informationDetails?.name ||
                !watch().informationDetails?.mac ||
                (isNotCameraPTZ && !watch().informationDetails?.solarPanelPosition) ||
                !!errors.informationDetails,
            content: (
                <Grid container xs={12} justifyContent={'space-between'} rowSpacing={2}>
                    <Grid xs={5.9}>
                        <Controller
                            name="informationDetails.name"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter a name.',
                                },
                                validate: validateTypingInput,
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    label="Name"
                                    required
                                    isVariantOutlined
                                    placeholder={'Camera name'}
                                    maxLength={50}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={5.9}>
                        <Controller
                            name="informationDetails.mac"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter a mac address.',
                                },
                                // validate: validateTypingInput,
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <StyledAutocomplete
                                    {...MACsAutocomplete}
                                    {...field}
                                    getOptionLabel={(o) => o}
                                    // label={
                                    //     <span>
                                    //         Mac<span style={{ color: 'red' }}>{`*`}</span>
                                    //     </span>
                                    // }
                                    placeholder="Select mac address"
                                    disabledAllOption
                                    value={field.value}
                                    onChange={field.onChange}
                                    wrapperWidth={'100%'}
                                />
                            )}
                        />
                    </Grid>

                    <Grid xs={5.9}>
                        <Controller
                            name="informationDetails.assetName"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    label="Asset"
                                    isVariantOutlined
                                    placeholder={'Asset name'}
                                    maxLength={50}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid xs={5.9}>
                        <Controller
                            name="informationDetails.lensCapacity"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    label="Len caps"
                                    isVariantOutlined
                                    placeholder={'Lens cap'}
                                    maxLength={30}
                                />
                            )}
                        />
                    </Grid>
                    {isNotCameraPTZ && (
                        <Grid xs={5.9}>
                            <Controller
                                name="informationDetails.solarPanelPosition"
                                control={control}
                                rules={{
                                    required: {
                                        value: isNotCameraPTZ,
                                        message: 'Please select a position.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <StyledAutocomplete
                                        {...positionSolarPanelAutocomplete}
                                        {...field}
                                        getOptionLabel={(o) => o.label}
                                        label={
                                            <span>
                                                Solar panel position<span style={{ color: 'red' }}>{`*`}</span>
                                            </span>
                                        }
                                        placeholder="Please select a position"
                                        disabledAllOption
                                        value={field.value}
                                        onChange={field.onChange}
                                        wrapperWidth={'100%'}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    <Grid xs={!isNotCameraPTZ ? 12 : 5.9}>
                        <Controller
                            name="informationDetails.purposes"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <StyledAutocomplete
                                    {...purposesAutocomplete}
                                    {...field}
                                    getOptionLabel={(o) => o.label}
                                    label="Purposes"
                                    value={field.value}
                                    placeholder="All purposes"
                                    disabledAllOption
                                    onChange={field.onChange}
                                    wrapperWidth={'100%'}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            ),
        },

        {
            id: 2,
            heading: 'Service',
            isStatusError:
                !watch().service?.serviceType ||
                (isANPR && (!watch().service?.anprId || isNaN(Number(watch().service?.anprId)))) ||
                !!errors.service,
            content: (
                <Stack spacing={2}>
                    <Controller
                        name="service.serviceType"
                        control={control}
                        // rules={{
                        //     required: {
                        //         value: true,
                        //         message: 'Please select a location.',
                        //     },
                        // }}
                        render={({ field, fieldState: { error } }) => (
                            <StyledAutocomplete
                                {...serviceTypeAutocomplete}
                                {...field}
                                getOptionLabel={(o) => o.label}
                                label={
                                    <span>
                                        Service type<span style={{ color: 'red' }}>{`*`}</span>
                                    </span>
                                }
                                disabledAllOption
                                value={field.value}
                                onChange={(s) => {
                                    setValue('service.serviceType', s!, { shouldValidate: true, shouldTouch: true });
                                    if (s!.key === CameraServiceType.ECamTechOps) {
                                        setValue('service.anprId', undefined);
                                        setValue('service.anprId', '');
                                    }
                                    clearErrors('service.anprId');
                                }}
                                wrapperWidth={'100%'}
                            />
                        )}
                    />
                    <Collapse in={isANPR}>
                        <Controller
                            name="service.anprId"
                            control={control}
                            rules={{
                                required: {
                                    value: isANPR,
                                    message: 'This field is required.',
                                },
                                validate: (value) => {
                                    if (isANPR) {
                                        if (!value || isNaN(Number(value))) {
                                            return 'Please enter a valid ANPR ID.';
                                        }
                                    }
                                    return true;
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    label="ANPR ID"
                                    required
                                    isVariantOutlined
                                    placeholder={'ANPR ID'}
                                    error={!!error?.message}
                                    maxLength={30}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Collapse>
                </Stack>
            ),
        },
        {
            id: 3,
            heading: 'Milesights account',
            isStatusError: Object.values(watch().milesightsAccount).some((item) => !item) || !!errors.milesightsAccount,
            content: (
                <Stack spacing={2}>
                    <Controller
                        name="milesightsAccount.ip"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please enter an IP address.',
                            },
                            validate: validateTypingIPAddress,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                label="IP"
                                required
                                isVariantOutlined
                                placeholder={'IP address'}
                                maxLength={30}
                                error={!!error?.message}
                                helperText={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name="milesightsAccount.userName"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please enter a username.',
                            },
                            validate: validateTypingInput,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                label="Username"
                                required
                                isVariantOutlined
                                placeholder={'Username'}
                                error={!!error?.message}
                                maxLength={30}
                                helperText={error?.message}
                            />
                        )}
                    />
                    <Controller
                        name="milesightsAccount.passWord"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please enter a password.',
                            },
                            validate: validateTypingInput,
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                label="Password"
                                required
                                isVariantOutlined
                                placeholder={'Password'}
                                error={!!error?.message}
                                maxLength={30}
                                helperText={error?.message}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            size="small"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff sx={{ fontSize: 20 }} />
                                            ) : (
                                                <Visibility sx={{ fontSize: 20 }} />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                            />
                        )}
                    />
                </Stack>
            ),
        },
        {
            id: 4,
            heading: 'Zone & GMT',
            isStatusError: Object.values(watch().zoneAndGMT).some((item) => !item) || !!errors.zoneAndGMT,
            content: (
                <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Controller
                            name="zoneAndGMT.location"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please select a location.',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <StyledAutocomplete
                                    {...locationAutocomplete}
                                    {...field}
                                    getOptionLabel={(o) => o.Name}
                                    label={
                                        <span>
                                            Location<span style={{ color: 'red' }}>{`*`}</span>
                                        </span>
                                    }
                                    placeholder="Select location"
                                    disabled={cameraId ? !camera.IsAbleChangeZone : false}
                                    disabledAllOption
                                    value={field.value}
                                    onChange={(newValue) => {
                                        setValue('zoneAndGMT.location', newValue, {
                                            shouldValidate: true,
                                            shouldTouch: true,
                                            shouldDirty: true,
                                        });
                                        setValue('zoneAndGMT.zone', undefined, {
                                            shouldValidate: true,
                                            shouldTouch: true,
                                            shouldDirty: true,
                                        });
                                        onChangeLocation(newValue);
                                    }}
                                    wrapperWidth="50%"
                                />
                            )}
                        />
                        <Controller
                            name="zoneAndGMT.zone"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please select a zone.',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => {
                                return (
                                    <StyledAutocomplete
                                        {...zoneAutocomplete}
                                        {...field}
                                        getOptionLabel={(o) => `${o.PublicName} (ZoneID ${o.Id})`}
                                        label={
                                            <span>
                                                Zone<span style={{ color: 'red' }}>{`*`}</span>
                                            </span>
                                        }
                                        placeholder="Select zone"
                                        disabledAllOption
                                        disabled={
                                            !watch().zoneAndGMT.location || cameraId ? !camera.IsAbleChangeZone : false
                                        }
                                        value={cameraId ? watch().zoneAndGMT.zone : field.value}
                                        onChange={(z) =>
                                            setValue('zoneAndGMT.zone', z!, {
                                                shouldValidate: true,
                                                shouldTouch: true,
                                                shouldDirty: true,
                                            })
                                        }
                                        wrapperWidth="50%"
                                    />
                                );
                            }}
                        />
                    </Stack>
                    <Controller
                        name="zoneAndGMT.time"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please select a GMT time.',
                            },
                        }}
                        render={({ field, fieldState }) => {
                            return (
                                <Autocomplete
                                    {...field}
                                    id="grouped-demo"
                                    value={field.value || null}
                                    options={transformedData}
                                    groupBy={(option) => option.text}
                                    getOptionLabel={(option) => option.utc}
                                    isOptionEqualToValue={(option, value) => option.utc === value.utc}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            padding: '9.95px 14px !important',
                                        },
                                        '& .MuiButtonBase-root': {
                                            marginRight: '5px !important',
                                        },
                                        '& .MuiAutocomplete-inputRoot': {
                                            '& 	.MuiAutocomplete-input': {
                                                padding: '0px 0px',
                                            },
                                        },
                                    }}
                                    renderGroup={(params) => (
                                        <li key={params.key}>
                                            <GroupHeader>
                                                <Typography variant="h6" color={(theme) => theme.palette.grey[600]}>
                                                    {params.group}
                                                </Typography>
                                            </GroupHeader>
                                            <GroupItems>{params.children}</GroupItems>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            label={
                                                <span>
                                                    Time<span style={{ color: 'red' }}>{`*`}</span>
                                                </span>
                                            }
                                            placeholder="Select time zone"
                                        />
                                    )}
                                    onChange={(e, val) => {
                                        console.log('🚀 ~ CameraUpsert ~ val:', val);
                                        field.onChange(val);
                                    }}
                                />
                            );
                        }}
                    />
                </Stack>
            ),
        },
        {
            id: 5,
            heading: 'Production',
            isStatusError: !!errors.deployedDate && !cameraId,
            content: (
                <Stack spacing={2}>
                    <Controller
                        name="deployedDate"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: 'Please choose a date.',
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <DesktopDatePicker
                                {...field}
                                label={
                                    <span>
                                        Production<span style={{ color: 'red' }}>{`*`}</span>
                                    </span>
                                }
                                value={field.value}
                                format="DD/MM/YYYY"
                                shouldDisableDate={(date) =>
                                    cameraId ? false : validateDatePickerValue(moment(date).toDate()) !== null
                                }
                                slotProps={{
                                    textField: {
                                        inputProps: {
                                            readOnly: true,
                                        },
                                        fullWidth: true,
                                    },
                                }}
                            />
                        )}
                    />
                </Stack>
            ),
        },

        {
            id: 6,
            heading: 'Photo of camera as installed',
            isStatusError: !!errors.cameraInstalledPhotos,
            content: (
                <Stack spacing={2}>
                    <Stack alignItems="center" spacing={2}>
                        <Controller
                            name="cameraInstalledPhotos"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <Stack
                                        sx={{
                                            border: '1px dashed #ccc',
                                            borderRadius: '5px',
                                            width: '100%',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            mt: 2,
                                        }}
                                        flex={1}
                                        justifyContent={'center'}
                                    >
                                        <input
                                            type="file"
                                            multiple
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            accept=".svg,.png,.jpg,.jpeg,.gif,.webp,.ico"
                                            id="file-upload"
                                        />
                                        <label htmlFor="file-upload" style={{ padding: '16px' }}>
                                            <UploadImageIcon />
                                            <Typography sx={{ cursor: 'pointer' }}>Click to upload</Typography>
                                            <Typography
                                                sx={{ cursor: 'pointer' }}
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                SVG, PNG, JPG or GIF (max. {maxImageWidth}x{maxImageHeight}px)
                                            </Typography>
                                        </label>
                                    </Stack>

                                    {cameraInstalledPhotos.map((file, index) => (
                                        <Box
                                            key={index}
                                            display="flex"
                                            alignItems="center"
                                            width={'100%'}
                                            border={`1px solid ${color.grey200}`}
                                            borderRadius={'5px'}
                                            p={'8px 16px'}
                                        >
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt=""
                                                width={'40px'}
                                                height={'40px'}
                                                style={{
                                                    borderRadius: 6,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    setSelectedItem(URL.createObjectURL(file));
                                                }}
                                            />
                                            <Stack flex={1} ml={3}>
                                                <Typography>{file.name}</Typography>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={uploadProgress[file.name] || 0}
                                                    sx={{
                                                        height: 5,
                                                        borderRadius: 5,
                                                        backgroundColor: color.grey300,
                                                        width: '98%',
                                                    }}
                                                />
                                                <Typography variant="caption" color={'rgba(147, 213, 0, 1)'}>
                                                    {uploadProgress[file.name] < 100
                                                        ? `${uploadProgress[file.name]}% uploaded`
                                                        : 'Uploaded successfully!'}
                                                </Typography>
                                            </Stack>

                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleRemoveFile(file.name)}
                                                disabled={uploadProgress[file.name] < 100}
                                            >
                                                <CancelOutlined
                                                    sx={{
                                                        color: `${uploadProgress[file.name] < 100 ? 'red' : 'gray'}`,
                                                        width: 16,
                                                        height: 16,
                                                    }}
                                                />
                                            </IconButton>
                                        </Box>
                                    ))}

                                    {selectedItem && (
                                        <Dialog
                                            open={true}
                                            onClose={() => {
                                                setSelectedItem(undefined);
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    minWidth: 1280,
                                                    minHeight: 720,
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    aspectRatio: '16/9',
                                                    background: `url(${selectedItem}) no-repeat`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    borderRadius: 1,
                                                    cursor: 'pointer',
                                                }}
                                            />
                                        </Dialog>
                                    )}
                                </>
                            )}
                        />
                    </Stack>
                </Stack>
            ),
        },

        {
            id: 7,
            heading: 'Note',
            isStatusError: !!errors.note,
            content: (
                <Stack spacing={2}>
                    <Controller
                        name="note"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <BaseTextField
                                {...field}
                                label={'Note'}
                                isVariantOutlined
                                placeholder={'Enter a description'}
                                maxLength={255}
                            />
                        )}
                    />
                </Stack>
            ),
        },
        {
            id: 8,
            heading: 'Data processing',
            isStatusError: !!errors.createVerification,
            isHorizontalContent: true,
            content: (
                <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <RequiredTitle title="Create verifications" sx={{ width: 150 }} />
                        <Controller
                            name="createVerification"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <IOSSwitch
                                    {...field}
                                    label={
                                        <Typography sx={{ width: 'max-content' }}>
                                            {!!field.value ? 'On' : 'Off'}
                                        </Typography>
                                    }
                                    iosSwitchProps={{
                                        checked: field.value,
                                        onChange(event, checked) {
                                            field.onChange(event);
                                        },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            ),
        },
    ];

    const excludeSteps = [6, 8];
    const filteredSteps = cameraId ? steps.filter((step) => !excludeSteps.includes(step.id)) : steps;

    return (
        <Box>
            {loading ? (
                <Stack justifyContent="center" alignItems="center" minHeight={600}>
                    <CircularProgress />
                </Stack>
            ) : (
                <Box>
                    {/* Title add new camera */}
                    <Stack direction={'column'} spacing={1}>
                        <Typography variant="h3">{cameraId ? 'Edit camera' : 'Add new camera'}</Typography>
                        <BreadCrumbs breadcrumbs={breadcrumbs} />
                    </Stack>

                    {/* Content body */}
                    <Stack
                        bgcolor={color.grey100}
                        borderRadius={1}
                        p={2}
                        direction={'column'}
                        spacing={cameraId ? 0 : 2}
                        mt={3}
                    >
                        {/* Choose camera type */}
                        <Grid container xs={12} alignItems="center">
                            {cameraId ? (
                                <Typography variant="h5" mb={2}>
                                    Type: {camera.PTZCameraId !== null ? 'Camera PTZ' : 'Camera Solar'}
                                </Typography>
                            ) : (
                                <>
                                    <Grid xs={6} lg={2.5}>
                                        <Typography variant="h5">Select camera type to add:</Typography>
                                    </Grid>
                                    <Grid
                                        xs={6}
                                        lg={9.5}
                                        sx={{
                                            textAlign: {
                                                xs: 'end',
                                                lg: 'start',
                                            },
                                        }}
                                    >
                                        <FormControl>
                                            <RadioGroup
                                                row
                                                value={selectedCameraType}
                                                onChange={handleRadioChangeCameraType}
                                            >
                                                {camera.VendorCameraId !== null && (
                                                    <FormControlLabel
                                                        sx={{
                                                            mr: 2,
                                                            '.MuiFormControlLabel-label': {
                                                                mt: '3px !important',
                                                            },
                                                        }}
                                                        control={<Radio value={0} />}
                                                        label="Camera Solar"
                                                    />
                                                )}
                                                {camera.PTZCameraId !== null && (
                                                    <FormControlLabel
                                                        sx={{
                                                            '.MuiFormControlLabel-label': {
                                                                mt: '3px !important',
                                                            },
                                                        }}
                                                        control={<Radio value={1} />}
                                                        label="Camera PTZ"
                                                    />
                                                )}
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <Grid container xs={12} gap={2}>
                            <Grid
                                xs={12}
                                lg={4}
                                p={1}
                                bgcolor={color.white}
                                borderRadius={1}
                                border={`1px solid ${color.grey300}`}
                                sx={{
                                    maxHeight: 600,
                                    overflowY: 'auto',
                                    overflowX: 'auto',
                                    '::-webkit-scrollbar': {
                                        width: '2px',
                                    },
                                }}
                            >
                                <Box sx={{ flexDirection: 'column', display: 'flex', width: '100%', gap: 1 }}>
                                    {filteredSteps.map((item, index) => {
                                        const isActive = index === activeStep;
                                        return (
                                            <Button
                                                key={index}
                                                onClick={() => handleStepClick(index)}
                                                sx={{
                                                    width: '100%',
                                                    backgroundColor: isActive ? '#E5F5ED' : color.white,
                                                    padding: '12px 16px',
                                                    borderRadius: 1,
                                                    cursor: 'pointer',
                                                    textAlign: 'start',
                                                    fontSize: '14px',
                                                    color: isActive ? `${color.success} !important` : color.textPrimary,
                                                    justifyContent: 'space-between',
                                                }}
                                                endIcon={item.isStatusError ? <CancelIcon /> : <SuccessIcon />}
                                            >
                                                {item.heading}
                                            </Button>
                                        );
                                    })}
                                </Box>
                            </Grid>

                            <Grid
                                xs={12}
                                lg={7.8}
                                container
                                spacing={2}
                                sx={{
                                    maxHeight: 600,
                                    overflowY: 'auto',
                                    overflowX: 'auto',
                                    '::-webkit-scrollbar': {
                                        width: '2px',
                                    },
                                }}
                            >
                                {filteredSteps.map((step, index) => {
                                    return (
                                        <Grid
                                            xs={12}
                                            key={index}
                                            ref={(el) => {
                                                if (el) {
                                                    sectionRefs.current[index] = el;
                                                }
                                            }}
                                        >
                                            <BaseFormSection
                                                id={step.id}
                                                isStatusError={step.isStatusError}
                                                heading={step.heading}
                                                content={step.content}
                                                isHorizontalContent={step.isHorizontalContent}
                                                isAbleChangeZone={cameraId ? camera.IsAbleChangeZone : true}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" mt={4}>
                        <Button
                            variant="cancel"
                            sx={{ flexBasis: 175 }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            Cancel
                        </Button>
                        <Stack direction="row" spacing={3} width="50%" justifyContent="flex-end">
                            {!cameraId && (
                                <Button
                                    variant="outlined"
                                    sx={{ flexBasis: 185 }}
                                    onClick={handleSubmit((data) => handleConfirmAddCamera(data, true))}
                                    disabled={disabled}
                                >
                                    Save & add geo zone
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                sx={{ flexBasis: 175 }}
                                onClick={handleSubmit((data) => {
                                    if (cameraId) {
                                        if (
                                            dirtyFields.informationDetails?.mac &&
                                            !isSubmitting &&
                                            !errors.informationDetails?.mac &&
                                            isNotCameraPTZ
                                        ) {
                                            popUpWarningEditMac.setTrue();
                                        } else {
                                            handleConfirmAddCamera(data, true);
                                        }
                                    } else {
                                        handleConfirmAddCamera(data);
                                    }
                                })}
                                disabled={disabled}
                            >
                                {cameraId ? 'Confirm' : 'Save'}
                            </Button>
                        </Stack>
                    </Stack>
                </Box>
            )}
            <PopUpWarning
                {...popUpWarningEditMac}
                title="Confirm"
                message="This action will switch the camera to the 'Not available' state until the system retrieves the latest information from this MAC. Are you sure you want to edit this MAC?"
                onConfirm={handleSubmit((data) => {
                    if (dirtyFields.informationDetails?.mac && !isSubmitting) {
                        handleConfirmAddCamera(data, true);
                        popUpWarningEditMac.onClose();
                    }
                })}
            />
        </Box>
    );
}

export const transformedData: TimeZone[] = myJson.flatMap((item) => {
    const _transformedData: TimeZone[] = [];
    item.utc.map((utc) => {
        _transformedData.push({ text: item.text, value: item.value, utc });
    });
    return _transformedData;
});

export const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    // padding: '9px 10px',
    paddingLeft: '9px',
    paddingTop: '9px',
    paddingBottom: '5px',
    color: color.grey600,
    backgroundColor: '#FFF',
}));

export const GroupItems = styled('ul')({
    padding: 0,
});

interface BaseFormSectionProps {
    id: number;
    heading: string;
    isStatusError: boolean;
    content: ReactNode;
    isHorizontalContent?: boolean;
    isAbleChangeZone?: boolean;
}

const BaseFormSection = (props: BaseFormSectionProps) => {
    const { heading, isStatusError, content, isHorizontalContent, isAbleChangeZone } = props;

    const tooltipConfig: { [key: number]: { message: string; condition: boolean } } = {
        [4]: {
            message: 'This location/zone cannot currently be edited as some PCNs and images have not been processed.',
            condition: !isAbleChangeZone,
        },
    };

    return (
        <Stack
            p={2}
            direction={'column'}
            spacing={2}
            bgcolor={color.white}
            borderRadius={1}
            border={`1px solid ${color.grey300}`}
        >
            <Stack
                width={'100%'}
                justifyContent={isHorizontalContent ? 'space-between' : ''}
                direction={isHorizontalContent ? 'row' : 'column'}
            >
                <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Stack direction={'row'} spacing={1} alignItems={'center'} mb={isHorizontalContent ? 0 : 2}>
                        <Typography variant="h6" fontSize={16}>
                            {heading}
                        </Typography>
                        <Typography variant="h6" color={isStatusError ? color.darkDanger : color.success}>
                            {isStatusError ? '(Not completed)' : '(Completed)'}
                        </Typography>
                    </Stack>
                    {tooltipConfig[props.id] && tooltipConfig[props.id].condition && (
                        <Tooltip placement="top" title={tooltipConfig[props.id].message}>
                            <InformationIcon color={color.danger} />
                        </Tooltip>
                    )}
                </Stack>

                <Stack spacing={2}>{content}</Stack>
            </Stack>
        </Stack>
    );
};
