import { Solar4gCaptureInfo } from '@EcamModel/model';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Autocomplete, Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { imageTypeMapping } from './CaptureManagementMilesightCameraSolar';
import { FormValues } from './PopUpEditUploadSetting';
import { useCameraDetailContext } from '../../hook/CameraDetailsProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {};

export default function PopUpEditImageSettings(props: Props) {
    const { open, onClose } = props;

    const { Solar4gCaptureInfo, valueImageSettings } = useCameraDetailContext();

    const { control, reset } = useForm<Partial<Solar4gCaptureInfo & FormValues>>({
        mode: 'all',
        defaultValues: {},
    });

    useEffect(() => {
        if (!open) return;
        reset({
            Width: Solar4gCaptureInfo?.Width,
            Height: Solar4gCaptureInfo?.Height,
            ImageType: Solar4gCaptureInfo?.ImageType,
        });
    }, [open]);

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            onConfirm={() => {}}
            dialogProps={{
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        maxWidth: '700px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Image setting</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="550px" sx={{ background: '#FAFAFA', p: 2 }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ width: 200 }}>Image settings:</Typography>
                        <Stack width={'100%'}>
                            <Controller
                                name="ImageSettings"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={[]}
                                        {...field}
                                        disabled
                                        value={valueImageSettings}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Image settings"
                                                sx={{
                                                    '.MuiFormLabel-root': {
                                                        marginTop: '-5px',
                                                    },
                                                }}
                                            />
                                        )}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: '40px',
                                            },
                                            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                                padding: '0px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ width: 200 }}>Image type:</Typography>
                        <Stack width={'100%'}>
                            <Controller
                                name="ImageType"
                                control={control}
                                render={({ field }) => (
                                    <Autocomplete
                                        options={[]}
                                        {...field}
                                        disabled
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Image type"
                                                sx={{
                                                    '.MuiFormLabel-root': {
                                                        marginTop: '-5px',
                                                    },
                                                }}
                                            />
                                        )}
                                        value={
                                            Solar4gCaptureInfo?.ImageType !== null
                                                ? imageTypeMapping[Solar4gCaptureInfo?.ImageType ?? 0]
                                                : ''
                                        }
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: '40px',
                                            },
                                            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                                padding: '0px',
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            }
            customActions={
                // <Stack direction={'row'} width="100%" justifyContent="space-between">
                //     <Button
                //         sx={{ minWidth: 130 }}
                //         variant="cancel"
                //         onClick={() => {
                //             props.onClose?.();
                //         }}
                //     >
                //         Cancel
                //     </Button>
                //     <Button
                //         sx={{ minWidth: 130 }}
                //         variant="contained"
                //         // disabled={disabled}
                //         // onClick={handleSubmit(handleSave)}
                //     >
                //         Save
                //     </Button>
                // </Stack>
                <Button
                    sx={{ minWidth: 130 }}
                    variant="cancel"
                    onClick={() => {
                        props.onClose?.();
                    }}
                >
                    Cancel
                </Button>
            }
        />
    );
}
