import { CameraDistributeWorkingHour } from '@EcamModel/model/CameraOverview';
import { ChartData, Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(...registerables);

const generateHoursList = (): string[] => {
    const hours: string[] = [];
    for (let i = 0; i < 24; i++) {
        hours.push(`${i}:00`);
    }
    return hours;
};
const hoursList = generateHoursList();

type Props = {
    capturedPhotos: CameraDistributeWorkingHour[];
};

export default function LineChartCameraStatus(props: Props) {
    const { capturedPhotos } = props;
    const data: ChartData<'line', number[], string> = {
        labels: capturedPhotos.map((item) => `${item.Hour.toString()}:00`),
        datasets: [
            {
                label: 'Captured photos average',
                data: capturedPhotos.map((item) => item.AvgTakenPhotos),
                fill: true,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
                pointHoverBackgroundColor: 'rgb(75, 192, 192)',
                backgroundColor: '#dddddd',
            },
        ],
    };

    return <Line options={options} data={data} />;
}

const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false,
            position: 'bottom' as const,
            align: 'center',
            labels: {
                font: {
                    size: 16,
                },
                padding: 20,
                boxWidth: 5,
                boxHeight: 5,
                usePointStyle: true,
                pointStyle: 'circle',
            },
        },
        datalabels: {
            display: false,
            color: 'black',
            anchor: 'end',
            align: 'top',
            font: {
                size: 11,
            },
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label(tooltipItems) {
                    return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue}`;
                },
                title: function (tooltipItems) {
                    const label = parseInt(tooltipItems[0].label, 10);
                    const from = hoursList[label];
                    const to = hoursList[(label + 1) % 24];
                    return `${from} - ${to}`;
                },
            },
        },
    },
    layout: {
        padding: {
            top: 20,
        },
    },
    interaction: {
        intersect: false,
        axis: 'x',
    },
    scales: {
        x: {
            grid: {
                display: false,
            },
            stacked: true,
        },
        y: {
            grid: {
                display: false,
            },
            ticks: {
                stepSize: 1000,
            },
            beginAtZero: true,
            min: 0,
            stacked: true,
        },
    },
};
