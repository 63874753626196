import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraInstalledPhotos } from '@EcamModel/model/CameraInstalledPhotos';
import { AxiosInstance } from 'axios';
import { ICameraInstalledPhotoController, IUpdateCameraInstalledPhoto } from '../ICameraInstalledPhotoHttpController';

export class CameraInstalledPhotoHttpController
    extends BaseHttpController<CameraInstalledPhotos>
    implements ICameraInstalledPhotoController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-installed-photo', client);
    }
    async createCameraInstalledPhotos(cameraId: number, file: Express.Multer.File[]) {
        return await this.doPost({
            path: `create-camera-installed-photos/${cameraId}`,
            body: file,
            config: {
                headers: { 'Content-Type': 'multipart/form-data' },
            },
        });
    }

    async updateCameraInstalledPhotos(props: IUpdateCameraInstalledPhoto) {
        return await this.doPost({
            path: 'update-camera-installed-photos',
            body: props,
            config: {
                headers: { 'Content-Type': 'multipart/form-data' },
            },
        }).then((res) => res.data);
    }
}
