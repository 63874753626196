import { Divider } from '@mui/material';
import color from '@theme/Colors';
import React from 'react';

export default function ColumnDivider() {
    return (
        <Divider
            orientation="vertical"
            flexItem
            sx={{
                mx: '10px',
                borderRightWidth: 1,
                borderColor: color.grey600,
                my: '3px',
            }}
            variant="middle"
        />
    );
}
