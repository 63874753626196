import {
    CameraWithInfo,
    CaptureInfoCaptureIndicator,
    CaptureInfoFrequency,
    CaptureInfoImageType,
    CaptureInfoIntervalUnit,
    CaptureInfoPostType,
    CaptureInfoUploadMode,
    Solar4gAlarmROI,
    Solar4gCaptureInfo,
    Solar4gSchedule,
} from '@EcamModel/model';
import IcEdit from '@assets/details-camera-icons/IcEdit';
import PopUpWarning from '@components/PopUpWarning';
import { pushError, pushSuccess } from '@components/toast';
import { solar4gCameraHttpController } from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import { Box, CircularProgress, Dialog, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import color from '@theme/Colors';
import Konva from 'konva';
import { ReactNode, useRef, useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { IoEyeOutline } from 'react-icons/io5';
import { Image, Layer, Stage } from 'react-konva';
import { useBackdrop } from 'src/providers/BackdropProvider';
import PopUpEditContinuousCapture from './PopUpEditContinuousCapture';
import PopUpEditImageSettings from './PopUpEditImageSettings';
import PopUpEditPhotoCaptureConfig from './PopUpEditPhotoCaptureConfig';
import PopUpEditTriggerCapture from './PopUpEditTriggerCapture';
import PopUpEditUploadSetting from './PopUpEditUploadSetting';
import CancelIcon from './icon/CancelIcon';
import InformationIcon from './icon/InformationIcon';
import { useCameraDetailContext } from '../../hook/CameraDetailsProvider';

interface Props {
    blobName: string | undefined;
    camera: CameraWithInfo;
}
export interface NewItem {
    id?: number;
    fromTime?: string;
    toTime?: string;
}

export const imageTypeMapping = {
    [CaptureInfoImageType.FULL_IMAGE]: 'Full Image',
    [CaptureInfoImageType.ROI_CROPPING]: 'ROI Cropping',
};

export const indicatorMapping = {
    [CaptureInfoCaptureIndicator.OFF]: 'Off',
    [CaptureInfoCaptureIndicator.ON]: 'On',
};

export const uploadModeMapping = {
    [CaptureInfoUploadMode.TIMING]: 'Timing',
    [CaptureInfoUploadMode.IMMEDIACY]: 'Immediacy',
};

export default function CaptureManagementMilesightCameraSolar(props: Props) {
    const { blobName, camera } = props;

    const captureContext = useCameraDetailContext();
    const { valueImageSettings, isLoading, partsTimeTrigger, solar4gCamera, setSolar4gCamera } = captureContext;

    const stageRef = useRef<Konva.Stage>(null);

    const [showPassword, setShowPassword] = useState(false);

    const captureROI = solar4gCamera.Solar4gAlarmROI;
    const [captureROIExpected, setCaptureROIExpected] = useState(solar4gCamera.Solar4gAlarmROIExpected);

    const captureInfo = solar4gCamera.Solar4gCaptureInfo;
    const [captureInfoExpected, setCaptureInfoExpected] = useState(solar4gCamera.Solar4gCaptureInfoExpected);

    const captureSchedule = solar4gCamera.Solar4gSchedule;
    const [captureScheduleExpected, setCaptureScheduleExpected] = useState(solar4gCamera.Solar4gScheduleExpected);

    const popUpEditImageSettings = usePopUp();
    const popUpEditTriggerCapture = usePopUp();
    const popUpEditContinuousCapture = usePopUp();
    const popUpEditPhotoCaptureConfig = usePopUp();
    const popUpEditUploadSetting = usePopUp();

    const { image } = useImageDimensions(`${blobName}`);

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    const scaleFull = 1280 / (image?.width ?? 1920);
    const scaleWidth = 1060 / (image?.width ?? 1920);
    const scaleHeight = 670 / (image?.width ?? 670);

    const convertTrigger = (trigger: string) => {
        const formatStringToArray = trigger.split(';');

        const convertedItemsArray = formatStringToArray?.map((item) => {
            const parts = item.split(':');
            const startTime = parts.slice(0, 2).join('h');
            const endTime = parts.slice(2, 4).join('h');
            return captureSchedule?.Trigger !== null ? `${startTime} - ${endTime}` : '';
        });

        return convertedItemsArray;
    };

    const updatedTimeFields: NewItem[] = [];

    partsTimeTrigger?.forEach((itemString) => {
        const parts = itemString.split(':');

        const id = parseInt(parts[4]);
        const fromTime = parts[0] + ':' + parts[1];
        const toTime = parts[2] + ':' + parts[3];

        updatedTimeFields.push({
            id: id,
            fromTime: fromTime === ':undefined' ? '00:00' : fromTime,
            toTime: toTime === 'undefined:undefined' ? '23:59' : toTime,
        });
    });

    const [timeFields, setTimeFields] = useState<NewItem[]>(updatedTimeFields);

    const backdrop = useBackdrop();

    const handleUpdateCaptureInfo = async (data: Partial<Solar4gCaptureInfo>) => {
        let dataValues = data;

        if (data['UploadMode'] == CaptureInfoUploadMode.IMMEDIACY) {
            dataValues = {
                ...data,
                Frequency: captureInfo?.Frequency,
                Time1: captureInfo?.Time1,
                Time2: captureInfo?.Time2,
            };
        } else {
            dataValues = {
                ...data,
                Frequency: data['Frequency'] ?? captureInfo?.Frequency,
                Time1: data['Time1'] ?? captureInfo?.Time1,
                Time2: data['Time2'] ?? captureInfo?.Time2,
                UploadMode: data['UploadMode'] ?? captureInfo?.UploadMode,
            };

            if (data['Frequency'] == CaptureInfoFrequency.ONCE_A_DAY) {
                dataValues.Time2 = captureInfo?.Time2;
            }
        }

        backdrop.setTrue();
        await solar4gCameraHttpController
            .updateCaptureInfo(solar4gCamera.Solar4gCaptureInfo?.DevMac!, dataValues)
            .then((res) => {
                pushSuccess('Updated successfully!');
                setCaptureInfoExpected(res);
                setSolar4gCamera((prev) => ({ ...prev, Solar4gCaptureInfoExpected: res }));
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleUpdatePhotoCaptureConfigInfo = async (data: Partial<Solar4gCaptureInfo>) => {
        backdrop.setTrue();
        await solar4gCameraHttpController
            .updateCaptureInfo(solar4gCamera.Solar4gCaptureInfo?.DevMac!, data)
            .then((res) => {
                pushSuccess('Updated successfully!');
                setCaptureInfoExpected(res);
                setSolar4gCamera((prev) => ({ ...prev, Solar4gCaptureInfoExpected: res }));
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleUpdateCaptureROI = async (data: Partial<Solar4gAlarmROI>) => {
        backdrop.setTrue();
        await solar4gCameraHttpController
            .updateAlarmROI(solar4gCamera.Solar4gCaptureInfo?.DevMac!, data)
            .then((res) => {
                pushSuccess('Updated successfully!');
                setCaptureROIExpected(res);
                setSolar4gCamera((prev) => ({ ...prev, Solar4gAlarmROIExpected: res }));
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleUpdateCaptureSchedule = async (data: Partial<Solar4gSchedule>) => {
        backdrop.setTrue();
        await solar4gCameraHttpController
            .updateSchedule(solar4gCamera.Solar4gCaptureInfo?.DevMac!, data)
            .then((res) => {
                pushSuccess('Updated successfully!');
                setCaptureScheduleExpected(res);
                setSolar4gCamera((prev) => ({ ...prev, Solar4gScheduleExpected: res }));
            })
            .catch((err) => {
                setTimeFields(updatedTimeFields);
                pushError(err);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const CheckEnum = (boolean: number) => {
        const booleanValue = {
            [0]: 'Disable',
            [1]: 'Enable',
        };
        return {
            checkBoolean: booleanValue[boolean],
        };
    };

    return (
        <>
            {isLoading ? (
                <Stack direction="row" justifyContent="center" alignItems="center" minHeight={500}>
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack direction={'column'} spacing={2} my={2}>
                    <Box id="image-settings" border={'1px solid #E4E7EC'} borderRadius={2}>
                        <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                            <Typography fontSize={16}>Image Settings</Typography>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Tooltip title="View image settings" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpEditImageSettings.setTrue();
                                        }}
                                    >
                                        <IoEyeOutline style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>

                        <Divider
                            orientation="vertical"
                            sx={{
                                borderBottomWidth: 1,
                                borderColor: color.grey300,
                                mx: 2,
                            }}
                        />

                        {solar4gCamera.Solar4gCaptureInfo ? (
                            <Stack paddingY={1} paddingX={2} spacing={2}>
                                {!image ? (
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        minHeight={1100 * 0.5625}
                                    >
                                        <CircularProgress />
                                    </Stack>
                                ) : (
                                    <div
                                        style={{
                                            width: 765,
                                            height: 370,
                                            position: 'relative',
                                        }}
                                    >
                                        <Stage ref={stageRef} width={765} height={370}>
                                            <Layer>
                                                <Image
                                                    image={image}
                                                    scaleX={scaleWidth}
                                                    scaleY={scaleHeight}
                                                    onClick={handleClick}
                                                />
                                            </Layer>
                                        </Stage>
                                        <Dialog
                                            open={open}
                                            onClose={() => {
                                                setOpen(false);
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    minWidth: 1280,
                                                    minHeight: 720,
                                                },
                                            }}
                                        >
                                            <div style={{ width: 1280, height: 720 }}>
                                                <Stage ref={stageRef} width={1280} height={720}>
                                                    <Layer>
                                                        <Image image={image} scaleX={scaleFull} scaleY={scaleFull} />
                                                    </Layer>
                                                </Stage>
                                            </div>
                                        </Dialog>
                                    </div>
                                )}
                                <Grid xs={12} container rowSpacing={1.5}>
                                    <Grid xs={5}>
                                        <Typography>Image settings:</Typography>
                                    </Grid>
                                    <Grid xs={7}>
                                        <Typography>{valueImageSettings}</Typography>
                                    </Grid>

                                    <Grid xs={5}>
                                        <Typography>Image type:</Typography>
                                    </Grid>
                                    <Grid xs={7}>
                                        <Typography>
                                            {solar4gCamera.Solar4gCaptureInfo?.ImageType !== null &&
                                                imageTypeMapping[solar4gCamera.Solar4gCaptureInfo?.ImageType ?? 0]}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Stack>
                        ) : (
                            <NoDataCameras sx={{ border: 'none', minHeight: 150 }} />
                        )}
                    </Box>

                    {/* using Info and ROI and Schedule */}
                    <Box id="trigger-capture" border={'1px solid #E4E7EC'} borderRadius={2}>
                        <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                            <Typography fontSize={16}>Trigger Capture</Typography>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Tooltip title="Edit trigger capture settings" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpEditTriggerCapture.setTrue();
                                        }}
                                    >
                                        <IcEdit style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>

                        <Divider
                            orientation="vertical"
                            sx={{
                                borderBottomWidth: 1,
                                borderColor: color.grey300,
                                mx: 2,
                            }}
                        />

                        {solar4gCamera.Solar4gAlarmROI! &&
                        solar4gCamera.Solar4gCaptureInfo &&
                        solar4gCamera.Solar4gSchedule ? (
                            <Stack paddingY={1} paddingX={2} spacing={2}>
                                <CheckData
                                    title="Al confidence"
                                    value={<Typography>{captureROI?.Threshold}</Typography>}
                                    edited={
                                        captureROIExpected?.Threshold != null
                                            ? captureROI?.Threshold !== captureROIExpected?.Threshold
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>{captureROIExpected?.Threshold}</Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureROI({
                                            Threshold: captureROI?.Threshold,
                                        })
                                    }
                                />
                                <CheckData
                                    title="Sensor sensitivity"
                                    value={<Typography>{captureInfo?.RadarSensitivity}</Typography>}
                                    edited={
                                        captureInfoExpected?.RadarSensitivity != null
                                            ? captureInfo?.RadarSensitivity !== captureInfoExpected?.RadarSensitivity
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>
                                            {captureInfoExpected?.RadarSensitivity}
                                        </Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureInfo({
                                            RadarSensitivity: captureInfo?.RadarSensitivity,
                                        })
                                    }
                                />

                                <CheckData
                                    title={
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography>Minimum detection speed</Typography>
                                            <Tooltip
                                                placement="top"
                                                title="Targets with a speed smaller than this set value will trigger the capture."
                                            >
                                                <InformationIcon />
                                            </Tooltip>
                                        </Stack>
                                    }
                                    value={
                                        captureInfo?.LowSpeedLimit !== null && (
                                            <Typography>{captureInfo?.LowSpeedLimit} km/h</Typography>
                                        )
                                    }
                                    edited={
                                        captureInfoExpected?.LowSpeedLimit != null
                                            ? captureInfo?.LowSpeedLimit !== captureInfoExpected?.LowSpeedLimit
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>
                                            {captureInfoExpected?.LowSpeedLimit} km/h
                                        </Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureInfo({
                                            LowSpeedLimit: captureInfo?.LowSpeedLimit,
                                        })
                                    }
                                />

                                <CheckData
                                    title={
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography>Maximum detection speed</Typography>
                                            <Tooltip
                                                placement="top"
                                                title="Targets with a speed greater than this set value will trigger the capture."
                                            >
                                                <InformationIcon />
                                            </Tooltip>
                                        </Stack>
                                    }
                                    value={
                                        captureInfo?.HighSpeedLimit !== null && (
                                            <Typography>{captureInfo?.HighSpeedLimit} km/h</Typography>
                                        )
                                    }
                                    edited={
                                        captureInfoExpected?.HighSpeedLimit != null
                                            ? captureInfo?.HighSpeedLimit !== captureInfoExpected?.HighSpeedLimit
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>
                                            {captureInfoExpected?.HighSpeedLimit} km/h
                                        </Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureInfo({
                                            HighSpeedLimit: captureInfo?.HighSpeedLimit,
                                        })
                                    }
                                />

                                <CheckData
                                    title={
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography>Sleep delay</Typography>
                                            <Tooltip
                                                placement="top"
                                                title="This value represents the duration of time after which the device enters sleep mode when no target is detected. To avoid missed detections at lower target speeds, it is recommended to configure a larger value for this setting."
                                            >
                                                <InformationIcon />
                                            </Tooltip>
                                        </Stack>
                                    }
                                    value={
                                        captureInfo?.CaptureTimeout !== null && (
                                            <Typography>{captureInfo?.CaptureTimeout}s</Typography>
                                        )
                                    }
                                    edited={
                                        captureInfoExpected?.CaptureTimeout != null
                                            ? captureInfo?.CaptureTimeout !== captureInfoExpected?.CaptureTimeout
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>
                                            {captureInfoExpected?.CaptureTimeout}s
                                        </Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureInfo({
                                            CaptureTimeout: captureInfo?.CaptureTimeout,
                                        })
                                    }
                                />

                                <CheckData
                                    title={
                                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                            <Typography>Capture interval</Typography>
                                            <Tooltip
                                                placement="top"
                                                title="In scenarios where the target moves slowly, setting a longer capture interval can prevent frequent triggering of captures for the same target."
                                            >
                                                <InformationIcon />
                                            </Tooltip>
                                        </Stack>
                                    }
                                    value={
                                        <Typography>
                                            {captureROI?.Interval !== null ? `${captureROI?.Interval} ms` : ''}
                                        </Typography>
                                    }
                                    edited={
                                        captureROIExpected?.Interval != null
                                            ? captureROI?.Interval !== captureROIExpected?.Interval
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>{captureROIExpected?.Interval} ms</Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureROI({
                                            Interval: captureROI?.Interval,
                                        })
                                    }
                                />

                                <CheckData
                                    title="Indicator light"
                                    value={<Typography>{indicatorMapping[captureInfo?.Indicator!] || ''}</Typography>}
                                    edited={
                                        captureInfoExpected?.Indicator != null
                                            ? captureInfo?.Indicator !== captureInfoExpected?.Indicator
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>
                                            {captureInfoExpected?.Indicator === CaptureInfoCaptureIndicator.OFF
                                                ? 'Off'
                                                : captureInfoExpected?.Indicator === CaptureInfoCaptureIndicator.ON
                                                ? 'On'
                                                : ''}
                                        </Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureInfo({
                                            Indicator: captureInfo?.Indicator,
                                        })
                                    }
                                />

                                {convertTrigger(captureSchedule?.Trigger ?? '')?.map((time, index) => {
                                    return (
                                        <CheckData
                                            title={
                                                <Typography>{index >= 1 ? `Schedule ${index}` : 'Schedule'}</Typography>
                                            }
                                            value={<Typography>{time}</Typography>}
                                            edited={false}
                                            valueEdited={''}
                                            Cancel={() =>
                                                handleUpdateCaptureSchedule({
                                                    Trigger: captureSchedule?.Trigger,
                                                })
                                            }
                                        />
                                    );
                                })}

                                {captureScheduleExpected?.Trigger != null &&
                                    captureSchedule?.Trigger !== captureScheduleExpected?.Trigger && (
                                        <CheckData
                                            title={<Typography color={color.grey600}>Schedule Expected</Typography>}
                                            value={''}
                                            edited={true}
                                            valueEdited={
                                                <Stack spacing={1}>
                                                    {convertTrigger(captureScheduleExpected?.Trigger ?? '').map(
                                                        (time) => {
                                                            return (
                                                                <Typography color={color.grey600}>{time}</Typography>
                                                            );
                                                        }
                                                    )}
                                                </Stack>
                                            }
                                            Cancel={() =>
                                                handleUpdateCaptureSchedule({
                                                    Trigger: captureSchedule?.Trigger,
                                                })
                                            }
                                        />
                                    )}
                            </Stack>
                        ) : (
                            <NoDataCameras sx={{ border: 'none', minHeight: 150 }} />
                        )}
                    </Box>
                    {/* using Info and ROI */}
                    <Box id="continuous-capture" border={'1px solid #E4E7EC'} borderRadius={2}>
                        <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                            <Typography fontSize={16}>Continuous capture</Typography>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Tooltip title="Edit continuous capture settings" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpEditContinuousCapture.setTrue();
                                        }}
                                    >
                                        <IcEdit style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>

                        <Divider
                            orientation="vertical"
                            sx={{
                                borderBottomWidth: 1,
                                borderColor: color.grey300,
                                mx: 2,
                            }}
                        />

                        {solar4gCamera.Solar4gAlarmROI && solar4gCamera.Solar4gCaptureInfo ? (
                            <Stack paddingY={1} paddingX={2} spacing={1}>
                                <CheckData
                                    title={<Typography>Enable</Typography>}
                                    value={
                                        captureInfo?.Continuous !== null && (
                                            <Typography>{captureInfo?.Continuous === 1 ? 'Yes' : 'No'}</Typography>
                                        )
                                    }
                                    edited={
                                        captureInfoExpected?.Continuous != null
                                            ? captureInfo?.Continuous !== captureInfoExpected?.Continuous
                                            : false
                                    }
                                    valueEdited={
                                        <Typography color={color.grey600}>
                                            {captureInfoExpected?.Continuous === 1 ? 'Yes' : 'No'}
                                        </Typography>
                                    }
                                    Cancel={() =>
                                        handleUpdateCaptureInfo({
                                            Continuous: captureInfo?.Continuous,
                                        })
                                    }
                                />
                                {captureInfo?.Continuous === 1 && (
                                    <CheckData
                                        title={<Typography>Interval</Typography>}
                                        value={
                                            <Typography>
                                                {captureInfo?.Interval}
                                                {captureInfo?.Interval === 1
                                                    ? captureInfo?.IntervalUnit === CaptureInfoIntervalUnit.HOUR
                                                        ? 'hour'
                                                        : captureInfo?.IntervalUnit === CaptureInfoIntervalUnit.MINUTE
                                                        ? 'minute'
                                                        : ''
                                                    : captureInfo?.IntervalUnit === CaptureInfoIntervalUnit.HOUR
                                                    ? 'hours'
                                                    : captureInfo?.IntervalUnit === CaptureInfoIntervalUnit.MINUTE
                                                    ? 'minutes'
                                                    : ''}
                                            </Typography>
                                        }
                                        edited={
                                            captureInfoExpected?.Interval != null
                                                ? captureInfo?.Interval !== captureInfoExpected?.Interval
                                                : false
                                        }
                                        valueEdited={
                                            <Typography color={color.grey600}>
                                                {captureInfoExpected?.Interval}
                                                {captureInfoExpected?.Interval === 1
                                                    ? captureInfoExpected?.IntervalUnit === CaptureInfoIntervalUnit.HOUR
                                                        ? 'hour'
                                                        : captureInfoExpected?.IntervalUnit ===
                                                          CaptureInfoIntervalUnit.MINUTE
                                                        ? 'minute'
                                                        : ''
                                                    : captureInfoExpected?.IntervalUnit === CaptureInfoIntervalUnit.HOUR
                                                    ? 'hours'
                                                    : captureInfoExpected?.IntervalUnit ===
                                                      CaptureInfoIntervalUnit.MINUTE
                                                    ? 'minutes'
                                                    : ''}
                                            </Typography>
                                        }
                                        Cancel={() =>
                                            handleUpdateCaptureInfo({
                                                Interval: captureInfo?.Interval,
                                            })
                                        }
                                    />
                                )}
                            </Stack>
                        ) : (
                            <NoDataCameras sx={{ border: 'none', minHeight: 150 }} />
                        )}
                    </Box>

                    {/* //////////////////////////////////////////////////////////////// */}
                    <Box id="photo-capture-config" border={'1px solid #E4E7EC'} borderRadius={2}>
                        <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                            <Typography fontSize={16}>Photo capture config</Typography>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Tooltip title="Edit photo capture config" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpEditPhotoCaptureConfig.setTrue();
                                        }}
                                    >
                                        <IcEdit style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>

                        <Divider
                            orientation="vertical"
                            sx={{
                                borderBottomWidth: 1,
                                borderColor: color.grey300,
                                mx: 2,
                            }}
                        />

                        <Stack paddingY={1} paddingX={2} spacing={2}>
                            <CheckData
                                title={<Typography>Capture enable</Typography>}
                                value={
                                    captureInfo?.PostCaptureEnable !== null && (
                                        <Typography>
                                            {
                                                CheckEnum(solar4gCamera?.Solar4gCaptureInfo?.PostCaptureEnable!)
                                                    .checkBoolean
                                            }
                                        </Typography>
                                    )
                                }
                                edited={
                                    captureInfoExpected?.PostCaptureEnable != null
                                        ? captureInfo?.PostCaptureEnable !== captureInfoExpected?.PostCaptureEnable
                                        : false
                                }
                                valueEdited={
                                    <Typography color={color.grey600}>
                                        {
                                            CheckEnum(solar4gCamera?.Solar4gCaptureInfoExpected?.PostCaptureEnable!)
                                                .checkBoolean
                                        }
                                    </Typography>
                                }
                                Cancel={() =>
                                    handleUpdatePhotoCaptureConfigInfo({
                                        PostCaptureEnable: captureInfo?.PostCaptureEnable,
                                    })
                                }
                            />
                            <Grid xs={12} container>
                                <Grid xs={5}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography>Capture interval</Typography>
                                        <Tooltip
                                            placement="top"
                                            title="Cannot modify the capture interval, as these parameters are determined by the grace period."
                                        >
                                            <InformationIcon />
                                        </Tooltip>
                                        :
                                    </Stack>
                                </Grid>
                                <Grid xs={7}>{solar4gCamera?.Solar4gCaptureInfo?.PostCaptureInterval ?? 'N/A'}</Grid>
                            </Grid>

                            <Grid xs={12} container>
                                <Grid xs={5}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography>Capture number</Typography>
                                        <Tooltip
                                            placement="top"
                                            title="Cannot modify the capture number, as these parameters are determined by the grace period."
                                        >
                                            <InformationIcon />
                                        </Tooltip>
                                        :
                                    </Stack>
                                </Grid>
                                <Grid xs={7}>{solar4gCamera?.Solar4gCaptureInfo?.PostCaptureNum ?? 'N/A'}</Grid>
                            </Grid>
                        </Stack>
                    </Box>

                    {/* using Info */}
                    <Box id="upload-setting" border={'1px solid #E4E7EC'} borderRadius={2}>
                        <Stack paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'}>
                            <Typography fontSize={16}>Upload setting</Typography>
                            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
                                <Tooltip title="Edit upload settings" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpEditUploadSetting.setTrue();
                                        }}
                                    >
                                        <IcEdit style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Stack>

                        <Divider
                            orientation="vertical"
                            sx={{
                                borderBottomWidth: 1,
                                borderColor: color.grey300,
                                mx: 2,
                            }}
                        />

                        {solar4gCamera.Solar4gCaptureInfo ? (
                            <Stack paddingY={1} paddingX={2} spacing={2}>
                                <Stack spacing={2} bgcolor={color.grey100} padding={2} borderRadius={1}>
                                    <Grid xs={12} container>
                                        <Grid xs={12} mb={1}>
                                            <Typography variant="body1" color={'#009D4F'}>
                                                Snapshots Upload Settings
                                            </Typography>
                                        </Grid>
                                        <Stack width={'100%'} paddingX={2} paddingY={1} bgcolor={color.white} gap={3}>
                                            <CheckData
                                                title={
                                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                        <Typography>Upload mode</Typography>
                                                        <Tooltip
                                                            placement="top"
                                                            title="The timing upload mode can only be used when the SD card is inserted."
                                                        >
                                                            <InformationIcon />
                                                        </Tooltip>
                                                    </Stack>
                                                }
                                                value={
                                                    <Typography>
                                                        {uploadModeMapping[captureInfo?.UploadMode!] || ''}
                                                    </Typography>
                                                }
                                                edited={
                                                    captureInfoExpected?.UploadMode != null
                                                        ? captureInfo?.UploadMode !== captureInfoExpected?.UploadMode
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.UploadMode ===
                                                        CaptureInfoUploadMode.TIMING
                                                            ? 'Timing'
                                                            : captureInfoExpected?.UploadMode ===
                                                              CaptureInfoUploadMode.IMMEDIACY
                                                            ? 'Immediacy'
                                                            : ''}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        UploadMode: captureInfo?.UploadMode,
                                                    })
                                                }
                                            />

                                            {captureInfo?.UploadMode === CaptureInfoUploadMode.TIMING && (
                                                <>
                                                    <CheckData
                                                        title={<Typography>Frequency</Typography>}
                                                        value={
                                                            <Typography>
                                                                {captureInfo?.Frequency ===
                                                                CaptureInfoFrequency.ONCE_A_DAY
                                                                    ? 'Once a Day'
                                                                    : captureInfo?.Frequency ===
                                                                      CaptureInfoFrequency.TWICE_A_DAY
                                                                    ? 'Twice a Day'
                                                                    : ''}
                                                            </Typography>
                                                        }
                                                        edited={
                                                            captureInfoExpected?.Frequency != null
                                                                ? captureInfo?.Frequency !==
                                                                  captureInfoExpected?.Frequency
                                                                : false
                                                        }
                                                        valueEdited={
                                                            <Typography color={color.grey600}>
                                                                {captureInfoExpected?.Frequency ===
                                                                CaptureInfoFrequency.ONCE_A_DAY
                                                                    ? 'Once a Day'
                                                                    : captureInfoExpected?.Frequency ===
                                                                      CaptureInfoFrequency.TWICE_A_DAY
                                                                    ? 'Twice a Day'
                                                                    : ''}
                                                            </Typography>
                                                        }
                                                        Cancel={() =>
                                                            handleUpdateCaptureInfo({
                                                                Frequency: captureInfo?.Frequency,
                                                            })
                                                        }
                                                    />

                                                    <CheckData
                                                        title={<Typography>Time 1</Typography>}
                                                        value={<Typography>{captureInfo?.Time1}</Typography>}
                                                        edited={
                                                            captureInfoExpected?.Time1 != null
                                                                ? captureInfo?.Time1 !== captureInfoExpected?.Time1
                                                                : false
                                                        }
                                                        valueEdited={
                                                            <Typography color={color.grey600}>
                                                                {captureInfoExpected?.Time1}
                                                            </Typography>
                                                        }
                                                        Cancel={() =>
                                                            handleUpdateCaptureInfo({
                                                                Time1: captureInfo?.Time1,
                                                            })
                                                        }
                                                    />

                                                    {(captureInfo?.Frequency === CaptureInfoFrequency.TWICE_A_DAY ||
                                                        captureInfoExpected?.Frequency ===
                                                            CaptureInfoFrequency.TWICE_A_DAY) && (
                                                        <CheckData
                                                            title={<Typography>Time 2</Typography>}
                                                            value={<Typography>{captureInfo?.Time2}</Typography>}
                                                            edited={
                                                                captureInfoExpected?.Time2 != null
                                                                    ? captureInfo?.Time2 !== captureInfoExpected?.Time2
                                                                    : false
                                                            }
                                                            valueEdited={
                                                                <Typography color={color.grey600}>
                                                                    {captureInfoExpected?.Time2}
                                                                </Typography>
                                                            }
                                                            Cancel={() =>
                                                                handleUpdateCaptureInfo({
                                                                    Time2: captureInfo?.Time2,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </Stack>
                                    </Grid>

                                    <Grid xs={12} container>
                                        <Grid xs={12} mb={1}>
                                            <Typography variant="body1" color={'#009D4F'}>
                                                Post type
                                            </Typography>
                                        </Grid>
                                        <Stack width={'100%'} paddingX={2} paddingY={1} bgcolor={color.white} gap={3}>
                                            <CheckData
                                                title="Enable post"
                                                value={
                                                    captureInfo?.PostEnable !== null && (
                                                        <Typography>
                                                            {captureInfo?.PostEnable === 1 ? 'Yes' : 'No'}
                                                        </Typography>
                                                    )
                                                }
                                                edited={
                                                    captureInfoExpected?.PostEnable != null
                                                        ? solar4gCamera.Solar4gCaptureInfo?.PostEnable !==
                                                          captureInfoExpected?.PostEnable
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.PostEnable == null
                                                            ? 'N/A'
                                                            : captureInfoExpected?.PostEnable
                                                            ? 'Yes'
                                                            : 'No'}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        PostEnable: solar4gCamera.Solar4gCaptureInfo?.PostEnable,
                                                    })
                                                }
                                            />
                                            <CheckData
                                                title="Post content"
                                                value={
                                                    <Typography>
                                                        {captureInfo?.SnapshotEnable == 1 ? 'Snapshot, ' : ''}
                                                        {captureInfo?.StatusEnable == 1 ? 'Status, ' : ''}
                                                        {captureInfo?.AlarmEnable == 1 ? 'Alarm' : ''}
                                                    </Typography>
                                                }
                                                edited={
                                                    captureInfoExpected?.SnapshotEnable != null &&
                                                    captureInfoExpected?.StatusEnable != null &&
                                                    captureInfoExpected?.AlarmEnable != null
                                                        ? captureInfo?.SnapshotEnable !==
                                                              captureInfoExpected?.SnapshotEnable ||
                                                          captureInfo?.StatusEnable !==
                                                              captureInfoExpected?.StatusEnable ||
                                                          captureInfo?.AlarmEnable !== captureInfoExpected?.AlarmEnable
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {`${
                                                            captureInfo?.SnapshotEnable !==
                                                            captureInfoExpected?.SnapshotEnable
                                                                ? `Snapshot(${
                                                                      CheckEnum(
                                                                          captureInfoExpected?.SnapshotEnable ?? -1
                                                                      ).checkBoolean
                                                                  }), `
                                                                : ''
                                                        }  
                                                            ${
                                                                captureInfo?.StatusEnable !==
                                                                captureInfoExpected?.StatusEnable
                                                                    ? `Status(${
                                                                          CheckEnum(
                                                                              captureInfoExpected?.StatusEnable ?? -1
                                                                          ).checkBoolean
                                                                      }), `
                                                                    : ''
                                                            }
                                                            ${
                                                                captureInfo?.AlarmEnable !==
                                                                captureInfoExpected?.AlarmEnable
                                                                    ? `Alarm(${
                                                                          CheckEnum(
                                                                              captureInfoExpected?.AlarmEnable ?? -1
                                                                          ).checkBoolean
                                                                      })`
                                                                    : ''
                                                            } 
                                                             `}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        SnapshotEnable: captureInfo?.SnapshotEnable,
                                                        StatusEnable: captureInfo?.StatusEnable,
                                                        AlarmEnable: captureInfo?.AlarmEnable,
                                                    })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Snapshot topic</Typography>}
                                                value={<Typography>{captureInfo?.SnapshotTopic}</Typography>}
                                                edited={
                                                    captureInfoExpected?.SnapshotTopic
                                                        ? captureInfo?.SnapshotTopic !==
                                                          captureInfoExpected?.SnapshotTopic
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.SnapshotTopic}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        SnapshotTopic: captureInfo?.SnapshotTopic,
                                                    })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Status topic</Typography>}
                                                value={<Typography>{captureInfo?.StatusTopic}</Typography>}
                                                edited={
                                                    captureInfoExpected?.StatusTopic
                                                        ? captureInfo?.StatusTopic !== captureInfoExpected?.StatusTopic
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.StatusTopic}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        StatusTopic: captureInfo?.StatusTopic,
                                                    })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Alarm topic</Typography>}
                                                value={<Typography>{captureInfo?.AlarmTopic}</Typography>}
                                                edited={
                                                    captureInfoExpected?.AlarmTopic
                                                        ? captureInfo?.AlarmTopic !== captureInfoExpected?.AlarmTopic
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.AlarmTopic}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        AlarmTopic: captureInfo?.AlarmTopic,
                                                    })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Configure request topic</Typography>}
                                                value={<Typography>{captureInfo?.ConfigTopic}</Typography>}
                                                edited={
                                                    captureInfoExpected?.ConfigTopic
                                                        ? captureInfo?.ConfigTopic !== captureInfoExpected?.ConfigTopic
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.ConfigTopic}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        ConfigTopic: captureInfo?.ConfigTopic,
                                                    })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Configure respond topic</Typography>}
                                                value={<Typography>{captureInfo?.ConfigRespTopic}</Typography>}
                                                edited={
                                                    captureInfoExpected?.ConfigRespTopic
                                                        ? captureInfo?.ConfigRespTopic !==
                                                          captureInfoExpected?.ConfigRespTopic
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.ConfigRespTopic}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({
                                                        ConfigRespTopic: captureInfo?.ConfigRespTopic,
                                                    })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Post type</Typography>}
                                                value={
                                                    captureInfo?.PostType !== null && (
                                                        <Typography>
                                                            {captureInfo?.PostType === CaptureInfoPostType.MQTT
                                                                ? 'MQTT'
                                                                : captureInfo?.PostType === CaptureInfoPostType.HTTP
                                                                ? 'HTTP'
                                                                : ''}
                                                        </Typography>
                                                    )
                                                }
                                                edited={
                                                    captureInfoExpected?.PostType
                                                        ? captureInfo?.PostType !== captureInfoExpected?.PostType
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.PostType === CaptureInfoPostType.MQTT
                                                            ? 'MQTT'
                                                            : captureInfoExpected?.PostType === CaptureInfoPostType.HTTP
                                                            ? 'HTTP'
                                                            : ''}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({ PostType: captureInfo?.PostType })
                                                }
                                            />

                                            {captureInfo?.PostType ? (
                                                <CheckData
                                                    title={<Typography>URL</Typography>}
                                                    value={<Typography>{captureInfo?.HttpUrl}</Typography>}
                                                    edited={
                                                        captureInfoExpected?.HttpUrl
                                                            ? captureInfo?.HttpUrl !== captureInfoExpected?.HttpUrl
                                                            : false
                                                    }
                                                    valueEdited={
                                                        <Typography color={color.grey600}>
                                                            {captureInfoExpected?.HttpUrl}
                                                        </Typography>
                                                    }
                                                    Cancel={() =>
                                                        handleUpdateCaptureInfo({ HttpUrl: captureInfo?.HttpUrl })
                                                    }
                                                />
                                            ) : (
                                                <>
                                                    <CheckData
                                                        title={<Typography>Host</Typography>}
                                                        value={<Typography>{captureInfo?.Host}</Typography>}
                                                        edited={
                                                            captureInfoExpected?.Host
                                                                ? captureInfo?.Host !== captureInfoExpected?.Host
                                                                : false
                                                        }
                                                        valueEdited={
                                                            <Typography color={color.grey600}>
                                                                {captureInfoExpected?.Host}
                                                            </Typography>
                                                        }
                                                        Cancel={() =>
                                                            handleUpdateCaptureInfo({ Host: captureInfo?.Host })
                                                        }
                                                    />

                                                    <CheckData
                                                        title={<Typography>Post</Typography>}
                                                        value={<Typography>{captureInfo?.Port}</Typography>}
                                                        edited={
                                                            captureInfoExpected?.Port
                                                                ? captureInfo?.Port !== captureInfoExpected?.Port
                                                                : false
                                                        }
                                                        valueEdited={
                                                            <Typography color={color.grey600}>
                                                                {captureInfoExpected?.Port}
                                                            </Typography>
                                                        }
                                                        Cancel={() =>
                                                            handleUpdateCaptureInfo({ Port: captureInfo?.Port })
                                                        }
                                                    />
                                                </>
                                            )}

                                            <CheckData
                                                title={<Typography>User name</Typography>}
                                                value={<Typography>{captureInfo?.UserName ?? ''}</Typography>}
                                                edited={
                                                    captureInfoExpected?.UserName
                                                        ? captureInfo?.UserName !== captureInfoExpected?.UserName
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.UserName}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({ UserName: captureInfo?.UserName })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Password</Typography>}
                                                value={
                                                    solar4gCamera.Solar4gCaptureInfo?.Password !== null && (
                                                        <Stack direction="row" spacing={5}>
                                                            <Typography>
                                                                {showPassword
                                                                    ? solar4gCamera.Solar4gCaptureInfo?.Password
                                                                    : solar4gCamera.Solar4gCaptureInfo?.Password?.replace(
                                                                          /./g,
                                                                          '*'
                                                                      )}
                                                            </Typography>
                                                            {showPassword ? (
                                                                <BiHide
                                                                    fontSize={20}
                                                                    color="#85858A"
                                                                    cursor="pointer"
                                                                    onClick={() => setShowPassword(false)}
                                                                />
                                                            ) : (
                                                                <BiShow
                                                                    fontSize={20}
                                                                    color="#85858A"
                                                                    cursor="pointer"
                                                                    onClick={() => setShowPassword(true)}
                                                                />
                                                            )}
                                                        </Stack>
                                                    )
                                                }
                                                edited={
                                                    captureInfoExpected?.Password
                                                        ? captureInfo?.Password !== captureInfoExpected?.Password
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.Password}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({ Password: captureInfo?.Password })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>SSL/TLS</Typography>}
                                                value={
                                                    solar4gCamera.Solar4gCaptureInfo?.TlsEnable !== null && (
                                                        <Typography>
                                                            {solar4gCamera.Solar4gCaptureInfo?.TlsEnable === 0
                                                                ? 'No'
                                                                : 'Yes'}
                                                        </Typography>
                                                    )
                                                }
                                                edited={
                                                    captureInfoExpected?.TlsEnable
                                                        ? captureInfo?.TlsEnable !== captureInfoExpected?.TlsEnable
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.TlsEnable === 0 ? 'No' : 'Yes'}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({ TlsEnable: captureInfo?.TlsEnable })
                                                }
                                            />

                                            <CheckData
                                                title={<Typography>Certificate type</Typography>}
                                                value={
                                                    solar4gCamera.Solar4gCaptureInfo?.TlsType !== null && (
                                                        <Typography>
                                                            {solar4gCamera.Solar4gCaptureInfo?.TlsType === 0
                                                                ? 'CA signed server'
                                                                : 'Self signed'}
                                                        </Typography>
                                                    )
                                                }
                                                edited={
                                                    captureInfoExpected?.TlsType
                                                        ? captureInfo?.TlsType !== captureInfoExpected?.TlsType
                                                        : false
                                                }
                                                valueEdited={
                                                    <Typography color={color.grey600}>
                                                        {captureInfoExpected?.TlsType === 0
                                                            ? 'CA signed server'
                                                            : 'Self signed'}
                                                    </Typography>
                                                }
                                                Cancel={() =>
                                                    handleUpdateCaptureInfo({ TlsType: captureInfo?.TlsType })
                                                }
                                            />
                                        </Stack>
                                    </Grid>
                                </Stack>
                            </Stack>
                        ) : (
                            <NoDataCameras sx={{ border: 'none', minHeight: 150 }} />
                        )}
                    </Box>

                    <PopUpEditImageSettings {...popUpEditImageSettings} />
                    <PopUpEditTriggerCapture
                        {...popUpEditTriggerCapture}
                        handleUpdateCapture={handleUpdateCaptureInfo}
                        handleUpdateCaptureROI={handleUpdateCaptureROI}
                        handleUpdateCaptureSchedule={handleUpdateCaptureSchedule}
                        updatedTimeFields={updatedTimeFields}
                        timeFields={timeFields}
                        setTimeFields={setTimeFields}
                        camera={camera}
                    />
                    <PopUpEditContinuousCapture
                        {...popUpEditContinuousCapture}
                        handleUpdateCapture={handleUpdateCaptureInfo}
                        handleUpdateCaptureROI={handleUpdateCaptureROI}
                    />
                    <PopUpEditPhotoCaptureConfig
                        {...popUpEditPhotoCaptureConfig}
                        handleUpdatePhotoCaptureConfigInfo={handleUpdatePhotoCaptureConfigInfo}
                    />
                    <PopUpEditUploadSetting {...popUpEditUploadSetting} handleUpdateCapture={handleUpdateCaptureInfo} />
                </Stack>
            )}
        </>
    );
}

function CheckData(props: {
    title: ReactNode;
    value: ReactNode;
    edited: boolean;
    valueEdited?: ReactNode;
    Cancel: () => Promise<void>;
}) {
    const { title, value, edited, valueEdited, Cancel } = props;
    const backdrop = useBackdrop();

    const popUpWarning = usePopUp({
        onConfirm() {
            backdrop.setTrue();
            Cancel().finally(() => {
                popUpWarning.onClose();
                backdrop.setFalse();
            });
        },
    });
    return (
        <Grid xs={12} container>
            <Grid xs={5}>
                <Stack direction={'row'}>
                    {title}
                    <Typography>:</Typography>
                </Stack>
            </Grid>
            <Grid xs={7}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack direction={'row'} spacing={1}>
                        {value}
                        {edited && valueEdited && (
                            <Stack direction={'row'} spacing={1}>
                                {value && <Typography>/</Typography>}
                                {valueEdited}
                                <Tooltip
                                    placement="top"
                                    title={
                                        <Typography align="center" fontSize={13} fontWeight={450} color={color.grey100}>
                                            The new data will be updated when the camera wakes up
                                        </Typography>
                                    }
                                >
                                    <InformationIcon />
                                </Tooltip>
                            </Stack>
                        )}
                    </Stack>
                    {edited && valueEdited && (
                        <Tooltip placement="top" title="Cancel">
                            <CancelIcon onClick={popUpWarning.setTrue} style={{ cursor: 'pointer' }} />
                        </Tooltip>
                    )}
                    <PopUpWarning {...popUpWarning} message={`Are you sure you want to cancel edit?`} />
                </Stack>
            </Grid>
        </Grid>
    );
}
