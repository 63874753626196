import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function InformationTabsIcon(props: Props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.16691 1.63484C7.63358 1.24151 8.38691 1.24151 8.84025 1.63484L9.89358 2.53484C10.0936 2.70151 10.4736 2.84151 10.7402 2.84151H11.8736C12.5802 2.84151 13.1602 3.42151 13.1602 4.12818V5.26151C13.1602 5.52818 13.3002 5.90151 13.4669 6.10151L14.3669 7.15484C14.7602 7.62151 14.7602 8.37484 14.3669 8.82818L13.4669 9.88151C13.3002 10.0815 13.1602 10.4548 13.1602 10.7215V11.8548C13.1602 12.5615 12.5802 13.1415 11.8736 13.1415H10.7402C10.4736 13.1415 10.1002 13.2815 9.90025 13.4482L8.84691 14.3482C8.38025 14.7415 7.62691 14.7415 7.17358 14.3482L6.12025 13.4482C5.92025 13.2815 5.54025 13.1415 5.28025 13.1415H4.11358C3.40691 13.1415 2.82691 12.5615 2.82691 11.8548V10.7148C2.82691 10.4548 2.69358 10.0748 2.52691 9.88151L1.62691 8.82151C1.24025 8.36151 1.24025 7.61484 1.62691 7.15484L2.52691 6.09484C2.69358 5.89484 2.82691 5.52151 2.82691 5.26151V4.13484C2.82691 3.42818 3.40691 2.84818 4.11358 2.84818H5.26691C5.53358 2.84818 5.90691 2.70818 6.10691 2.54151L7.16691 1.63484Z"
                stroke={props.color ?? '#85858A'}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8 5.42188V8.64188"
                stroke={props.color ?? '#85858A'}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.99609 10.668H8.00208"
                stroke={props.color ?? '#85858A'}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
