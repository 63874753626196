import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Fade, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import BaseTimePicker from '@pages/cameras/details/components/BaseTimePicker';
import color from '@theme/Colors';
import moment from 'moment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { formatMinutesToTime, getMinutesFromStartOfDay } from 'src/helpers';
import { useCameraConfig } from '../hook/CameraConfigProvider';

type Props = IPopUp & {};

export type FormValues = {
    from: Date;
    to: Date;
};

export default function PopUpArrangeUploadTimes(props: Props) {
    const { open, onClose } = props;

    const cameraConfigContext = useCameraConfig();
    const {
        isLoading,
        photoUploadSchedule,
        handlePhotoUploadTimes,
        filterByUploadModeAndStatus,
        cameraConfigMonitoring,
    } = cameraConfigContext;

    const valuesDefault: FormValues = {
        from: formatMinutesToTime(photoUploadSchedule.StartTimeInMinutes).toDate(),
        to: formatMinutesToTime(photoUploadSchedule.EndTimeInMinutes).toDate(),
    };

    const {
        control,
        reset,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        formState: { isDirty, isValid, errors },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: valuesDefault,
    });

    const disabled = !(isValid && isDirty);

    useEffect(() => {
        reset(valuesDefault);
    }, [open]);

    const handleCancel = () => {
        onClose?.();
        reset();
    };

    const fromValue = watch().from;
    const toValue = watch().to;

    useEffect(() => {
        if (moment(toValue).isBefore(moment(fromValue))) {
            setError('to', {
                type: 'manual',
                message: 'End time must be later than start time.',
            });
        } else {
            clearErrors('to');
        }
    }, [fromValue, toValue, setError, clearErrors]);

    const getStepMinutes = (start: number, end: number, camerasLength: number) => {
        const stepEstimate = (end - start) / camerasLength;
        let step = 60;

        if (stepEstimate <= 5) {
            step = 5;
        } else if (stepEstimate < 60) {
            step = Math.round(stepEstimate / 5) * 5;
        }

        return step;
    };

    const filterCameraUploadTiming = filterByUploadModeAndStatus(cameraConfigMonitoring);
    const minutesStart = getMinutesFromStartOfDay(fromValue);
    const minutesEnd = getMinutesFromStartOfDay(toValue);

    const minutes = getStepMinutes(minutesStart, minutesEnd, filterCameraUploadTiming.length);

    return (
        <PopUpBase
            title={
                <Stack direction={'column'} mb={1}>
                    <Typography variant="h4">Configure upload interval</Typography>
                </Stack>
            }
            fixOverflow
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        maxWidth: '625px',
                        borderRadius: '10px',
                    },
                    '& .MuiPaper-root': {
                        overflowY: 'visible',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            open={open}
            onClose={onClose}
            hideConfirm
            minWidthButton={130}
            desc={
                <Stack width="100%" mb={errors.to ? 2 : 0}>
                    <Typography textAlign={'right'} sx={{ color: color.priText }} mb={1}>
                        Total camera: {filterCameraUploadTiming.length || 0}
                    </Typography>
                    <Grid container alignItems={'center'} xs={12}>
                        <Grid xs={3}>
                            <Typography sx={{ lineHeight: '40px' }}>Upload schedule:</Typography>
                        </Grid>
                        <Grid
                            position={'relative'}
                            container
                            alignItems={'center'}
                            xs={9}
                            justifyContent={'space-between'}
                        >
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Typography>From</Typography>
                                    <Controller
                                        name="from"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'This field is required.',
                                            },
                                        }}
                                        render={({ field }) => (
                                            <BaseTimePicker
                                                zIndex={10}
                                                width={'100%'}
                                                timePickerProps={{
                                                    shouldDisableTime: (time) => false,
                                                }}
                                                value={moment(field.value, 'HH:mm') as any}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                </Stack>
                                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                                    <Typography>to</Typography>
                                    <Controller
                                        name="to"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'This field is required.',
                                            },
                                            validate: (value) => {
                                                return (
                                                    moment(value).isAfter(moment(fromValue)) ||
                                                    'End time must be later than start time'
                                                );
                                            },
                                        }}
                                        render={({ field }) => (
                                            <BaseTimePicker
                                                zIndex={10}
                                                width={'100%'}
                                                timePickerProps={{
                                                    minTime: moment(watch().from).add(1, 'minutes'),
                                                    shouldDisableTime: (time) => false,
                                                }}
                                                value={moment(field.value, 'HH:mm') as any}
                                                onChange={field.onChange}
                                            />
                                        )}
                                    />
                                </Stack>
                            </Stack>
                            {errors.to && (
                                <Fade in={!!errors.to}>
                                    <Box position={'absolute'} bottom={'-45px'}>
                                        <Typography my={2} color={color.danger}>
                                            {errors.to.message}
                                        </Typography>
                                    </Box>
                                </Fade>
                            )}
                        </Grid>
                    </Grid>

                    <Stack direction={'row'} spacing={1} alignItems={'center'} mt={errors.to ? 4 : 1}>
                        <Typography fontSize={13} color={color.grey600}>
                            The cameras will automatically upload at evenly spaced average intervals of time:
                        </Typography>
                        <Typography fontSize={13} color={color.danger}>
                            {minutes} mins
                        </Typography>
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        variant="cancel"
                        disabled={isLoading}
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        loading={isLoading}
                        loadingPosition="center"
                        variant="contained"
                        disabled={disabled || isLoading}
                        onClick={handleSubmit(async (data) => {
                            await handlePhotoUploadTimes(data);
                            onClose?.();
                        })}
                    >
                        Confirm
                    </LoadingButton>
                </Stack>
            }
        />
    );
}
