import { SVGProps, forwardRef } from 'react';

const InformationIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    const { children, ...restProps } = props;
    return (
        <svg
            ref={ref}
            {...restProps}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 8.16675C1 12.0327 4.13401 15.1667 8 15.1667C11.866 15.1667 15 12.0327 15 8.16675C15 4.30076 11.866 1.16675 8 1.16675C4.13401 1.16675 1 4.30076 1 8.16675ZM2 8.16675C2 4.85304 4.68629 2.16675 8 2.16675C11.3137 2.16675 14 4.85304 14 8.16675C14 11.4805 11.3137 14.1667 8 14.1667C4.68629 14.1667 2 11.4805 2 8.16675ZM8.5 11.0001V6.50008H6.5V7.50008H7.5V11.0001H6V12.0001H10V11.0001H8.5ZM7.25 4.25008C7.25 3.83587 7.58579 3.50008 8 3.50008C8.41421 3.50008 8.75 3.83587 8.75 4.25008C8.75 4.6643 8.41421 5.00008 8 5.00008C7.58579 5.00008 7.25 4.6643 7.25 4.25008Z"
                fill={props.color ?? '#85858A'}
            />
        </svg>
    );
});

export default InformationIcon;
