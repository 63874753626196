import {
    AlarmROIRegion,
    CaptureInfoIntervalUnit,
    Solar4gAlarmROI,
    Solar4gCamera,
    Solar4gCaptureInfo,
} from '@EcamModel/model';
import BaseCheckbox from '@components/BaseCheckbox';
import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Autocomplete, Button, Collapse, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import color from '@theme/Colors';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCameraDetailContext } from '../../hook/CameraDetailsProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {
    handleUpdateCapture: (data: Partial<Solar4gCamera>) => Promise<void>;
    handleUpdateCaptureROI: (data: Partial<Solar4gAlarmROI>) => Promise<void>;
};

export default function PopUpEditContinuousCapture(props: Props) {
    const { open, onClose } = props;

    const { Solar4gCaptureInfo } = useCameraDetailContext();

    const {
        control,
        clearErrors,
        watch,
        reset,
        setValue,
        handleSubmit,
        trigger,
        formState: { isDirty, isValid, errors },
    } = useForm<Partial<Solar4gCaptureInfo & AlarmROIRegion>>({
        mode: 'all',
        defaultValues: {},
    });
    const disabled = !(isValid && isDirty);

    const defaultValues: Partial<Solar4gCaptureInfo & AlarmROIRegion> = {
        Continuous: Solar4gCaptureInfo?.Continuous,
        Interval: Solar4gCaptureInfo?.Interval,
        IntervalUnit: Solar4gCaptureInfo?.IntervalUnit,
    };

    useEffect(() => {
        if (!open) return;
        reset(defaultValues);
    }, [open]);

    return (
        <>
            <PopUpBase
                open={open}
                onClose={onClose}
                onConfirm={() => {}}
                dialogProps={{
                    sx: {
                        '& .MuiDialog-paper': {
                            borderRadius: '10px',
                            maxWidth: '700px',
                        },
                        zIndex: (theme) => theme.zIndex.modal,
                    },
                }}
                title={
                    <Stack>
                        <Typography variant="h4">Continuous capture</Typography>
                    </Stack>
                }
                titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
                subTitleProps={{ sx: { color: 'gray' } }}
                minWidthButton={150}
                desc={
                    <Stack width="550px" sx={{ background: '#FAFAFA', p: 2 }} spacing={3}>
                        <Stack direction="row" spacing={2.4}>
                            <Typography sx={{ lineHeight: '40px' }}>Enable:</Typography>
                            <Stack direction="row" alignItems="center" mt={2}>
                                <Stack direction="row" alignItems="center">
                                    <Controller
                                        name="Continuous"
                                        control={control}
                                        render={({ field }) => {
                                            return (
                                                <FormControlLabel
                                                    control={
                                                        <BaseCheckbox
                                                            {...field}
                                                            onChange={(_, checked) => {
                                                                if (Number(checked) === 0) {
                                                                    setValue('Interval', Solar4gCaptureInfo?.Interval);
                                                                    setValue(
                                                                        'IntervalUnit',
                                                                        Solar4gCaptureInfo?.IntervalUnit
                                                                    );
                                                                }
                                                                return field.onChange(Number(checked));
                                                            }}
                                                            checked={!!field.value}
                                                            value={field.value}
                                                        />
                                                    }
                                                    label={<Typography ml={0.5} mt={0.3}></Typography>}
                                                    sx={{ ml: 0 }}
                                                />
                                            );
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        </Stack>

                        <Collapse
                            in={watch().Continuous === 1 ? true : false}
                            sx={{
                                '&.MuiCollapse-root': {
                                    marginTop: 0,
                                },
                            }}
                        >
                            {watch().Continuous === 1 && (
                                <Grid xs={12} container sx={{ background: '#FFFFFF', py: 1, px: 1 }} columnSpacing={1}>
                                    <Grid xs={1.5}>
                                        <Typography
                                            sx={{
                                                lineHeight: '40px',
                                                color: `${watch().Interval === 0 ? color.danger : 'inherit'}`,
                                            }}
                                        >
                                            Interval:
                                        </Typography>
                                    </Grid>
                                    <Grid xs={4.3}>
                                        <Controller
                                            name="Interval"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'This field is required.',
                                                },
                                                min: {
                                                    message: 'Value must be greater than or equal to 1.',
                                                    value: 1,
                                                },
                                                max: {
                                                    message: `Value must be less than or equal to ${
                                                        watch().IntervalUnit === 1 ? '24' : '1440'
                                                    }.`,
                                                    value: watch().IntervalUnit === 1 ? 24 : 1440,
                                                },
                                                pattern: {
                                                    value: /^[0-9]+$/,
                                                    message: 'Please enter valid Number.',
                                                },
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <BaseTextField
                                                    {...field}
                                                    value={field.value}
                                                    maxLength={10}
                                                    onChange={(e) => {
                                                        const value = Number(e.target.value);
                                                        if (!isNaN(value)) {
                                                            field.onChange(
                                                                isNaN(Number(value)) ? value : Number(value)
                                                            );
                                                        }
                                                    }}
                                                    placeholder={'Interval'}
                                                    error={!!error?.message}
                                                    helperText={error?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={4.5}>
                                        <Controller
                                            name="IntervalUnit"
                                            control={control}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    clearIcon={false}
                                                    options={[0, 1]}
                                                    getOptionLabel={(options) => {
                                                        const authTypeDisplayValue = {
                                                            [CaptureInfoIntervalUnit.MINUTE]: 'minute (s)',
                                                            [CaptureInfoIntervalUnit.HOUR]: 'hour (s)',
                                                        };
                                                        return authTypeDisplayValue[options];
                                                    }}
                                                    sx={{
                                                        '.MuiInputBase-root': {
                                                            height: '40px',
                                                        },
                                                        '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                                            padding: '0px',
                                                        },
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Hour or minute"
                                                            sx={{
                                                                '.MuiFormLabel-root': {
                                                                    marginTop: '-5px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                    value={field.value}
                                                    onChange={(event, newValue) => {
                                                        field.onChange(newValue);
                                                        if (newValue === null) {
                                                            setValue('IntervalUnit', Solar4gCaptureInfo!.IntervalUnit);
                                                            setValue('Interval', Solar4gCaptureInfo!.Interval);
                                                        }
                                                        clearErrors();
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={1.7}>
                                        <Typography
                                            sx={{
                                                lineHeight: '40px',
                                                textAlign: 'end',
                                                color: `${watch().Interval === 0 ? color.danger : 'inherit'}`,
                                            }}
                                        >
                                            {watch().IntervalUnit === 1 ? '(1 ~ 24)' : '(1 ~ 1440)'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Collapse>
                    </Stack>
                }
                customActions={
                    <Stack direction={'row'} width="100%" justifyContent="space-between">
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="cancel"
                            onClick={() => {
                                props.onClose?.();
                                clearErrors();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="contained"
                            disabled={disabled}
                            onClick={handleSubmit(async (data) => {
                                const _editTriggerCapture: Solar4gCamera = {} as Solar4gCamera;
                                const _prevData = { ...data };

                                const isIntervalChanged =
                                    _prevData.IntervalUnit !== defaultValues.IntervalUnit &&
                                    _prevData.Interval === defaultValues.Interval &&
                                    _prevData.Continuous === defaultValues.Continuous;

                                if (isIntervalChanged) {
                                    await props.handleUpdateCapture({
                                        Interval: data.Interval,
                                        IntervalUnit: data.IntervalUnit,
                                    } as Partial<Solar4gCamera>);
                                } else {
                                    for (const key in data) {
                                        if (data[key] !== defaultValues[key]) {
                                            _editTriggerCapture[key] = data[key];
                                        }
                                    }
                                    await props.handleUpdateCapture(_editTriggerCapture);
                                }
                                props.onClose?.();
                            })}
                        >
                            Save
                        </Button>
                    </Stack>
                }
            />
        </>
    );
}
