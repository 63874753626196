import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function EventLogIcon(props: Props) {
    return (
        <svg
            width={props.width ?? 17}
            height={props.height ?? 16}
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.83105 5.66797H8.16439"
                stroke={props.color ?? '#85858A'}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M4.49707 11H5.8304"
                stroke={props.color ?? '#85858A'}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M7.49707 11H10.1637"
                stroke={props.color ?? '#85858A'}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M15.1644 8.0187V10.7387C15.1644 13.0787 14.5711 13.6654 12.2044 13.6654H4.79105C2.42439 13.6654 1.83105 13.0787 1.83105 10.7387V5.2587C1.83105 2.9187 2.42439 2.33203 4.79105 2.33203H10.1644"
                stroke={props.color ?? '#85858A'}
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.2176 2.75289L10.7442 5.22622C10.6509 5.31955 10.5576 5.50622 10.5376 5.63955L10.4042 6.58622C10.3576 6.92622 10.5976 7.16622 10.9376 7.11955L11.8842 6.98622C12.0176 6.96622 12.2042 6.87289 12.2976 6.77955L14.7709 4.30622C15.1976 3.87955 15.3976 3.38622 14.7709 2.75955C14.1376 2.12622 13.6442 2.32622 13.2176 2.75289Z"
                stroke={props.color ?? '#85858A'}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.8643 3.10547C13.0776 3.8588 13.6643 4.44547 14.4109 4.65214"
                stroke={props.color ?? '#85858A'}
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
