import { Stack, Typography } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

import React from 'react';

type Props = {
    hasPermission: boolean;
    reportId: string;
    embedUrl: string;
    accessToken: string | undefined;
    errors?: React.ReactNode
};
export default function EmbedReport(props: Props) {
    const { hasPermission, reportId, embedUrl, accessToken } = props;
    return (
        <>
            {hasPermission ? (
                <Stack
                    height="100%"
                    sx={{
                        '& .report-container': {
                            height: '80vh',
                        },
                        '& .report-container iframe': {
                            height: '100%',
                            border: 'none',
                            overflowX: 'hidden',
                        },
                    }}
                >
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report', // Supported types: report, dashboard, tile, visual and qna
                            id: reportId,
                            embedUrl: embedUrl,
                            accessToken: accessToken, // Keep as empty string, null or undefined
                            tokenType: models.TokenType.Aad,
                            settings: {
                                filterPaneEnabled: false,
                                navContentPaneEnabled: false,
                                layoutType: models.LayoutType.Custom,
                                customLayout: {
                                    pagesLayout: {
                                        default: {
                                            defaultLayout: {
                                                // width: 1600,
                                                // height: 860,
                                                displayState: {
                                                    mode: models.VisualContainerDisplayMode.Hidden,
                                                },
                                            },
                                            visualsLayout: {},
                                        },
                                    },
                                    displayOption: models.DisplayOption.FitToWidth,
                                },
                            },
                        }}
                        cssClassName="report-container"
                    />
                </Stack>
            ) : (
                <Stack width="100%" minHeight={400} alignItems="center" justifyContent="center">
                   {props.errors ?? 
                   <>
                    <Typography variant="h2" fontWeight={400} color="primary.dark">
                        Sorry, you do not have permission to view this report.
                    </Typography>
                    <Typography variant="h6" fontWeight={400} align="center">
                        <>Please contact with us to get more information.</>
                    </Typography>
                    </>
                    }
                </Stack>
            )}
        </>
    );
}
