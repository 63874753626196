import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { ContraventionReasonTranslations, GeoZoneOperationConfig, GeoZones } from '../../model';
import { IGeoZoneHttpController, IGetGeoZoneProps } from '../IGeoZoneHttpController';
import { Paging } from '@Core';

export class GeoZoneHttpController extends BaseHttpController<GeoZones> implements IGeoZoneHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'geoZone', client);
    }

    async getContraventionReasonTranslations(): Promise<ContraventionReasonTranslations[]> {
        return await this.doGet({ path: `contravention-reason-translation` }).then((res) => res.data);
    }

    async listGeoZoneByCameraView(props: IGetGeoZoneProps): Promise<Paging<GeoZones>> {
        return await this.doPost({ path: `list-geo-zone-by-camera-view`, body: props }).then((res) => res.data);
    }

    async upsertGeoZoneOverview(props: GeoZones): Promise<GeoZones> {
        return await this.doPost({ path: `upsert-geo-zone-overview`, body: props }).then((res) => res.data);
    }

    async getGeoZoneOverviewById(geoZoneId: number): Promise<GeoZones> {
        return await this.doGet({ path: `get-geo-zone-overview/${geoZoneId}` }).then((res) => res.data);
    }

    async deleteGeoZoneOverview(geoZoneId: number) {
        return await this.doDelete({ path: `delete-geo-zone-overview/${geoZoneId}` }).then((res) => res.data);
    }
}
