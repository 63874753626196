import { SVGProps, forwardRef } from 'react';
const CancelIcon = forwardRef<SVGSVGElement, SVGProps<SVGSVGElement>>((props, ref) => {
    const { children, ...restProps } = props;
    return (
        <svg
            ref={ref}
            {...restProps}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.99998 14.6666C11.6666 14.6666 14.6666 11.6666 14.6666 7.99992C14.6666 4.33325 11.6666 1.33325 7.99998 1.33325C4.33331 1.33325 1.33331 4.33325 1.33331 7.99992C1.33331 11.6666 4.33331 14.6666 7.99998 14.6666Z"
                stroke="#E01B00"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.11334 9.88661L9.88668 6.11328"
                stroke="#E01B00"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.88668 9.88661L6.11334 6.11328"
                stroke="#E01B00"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});

export default CancelIcon;
