import { BaseModel } from '@Core';
import { ZoneSM } from './Zones';

export interface ServiceSM extends BaseModel {
    ZoneId: number;
    ZoneSM?: ZoneSM;

    ServiceType: ServiceSMType;
    Status: ServiceSMStatus;

    InstallDate: Date;
    GoLiveDate: Date;
    TerminationDate: Date;
}

export enum ServiceSMStatus {
    Install,
    Test,
    Production,
    Terminated,
}

export enum ServiceSMType {
    Anpr,
    WardenPatrol,
    SelfTicket,
    iTicket,
    ContraventionManagement,
    Other,
    iPark,
    eCam,
}
