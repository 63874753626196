import { BaseHttpController } from '@Core/controller/BaseHttpController';
import {
    CameraAverageDailyWorkingHours,
    CameraBatteryDetail,
    CameraDailyWorkedHour,
    CameraDistributeWorkingHour,
    CameraPingDetail,
    CameraPingHour,
    CamerasWithTime,
    ConsolidateCameraMonitoring,
} from '@EcamModel/model/CameraOverview';
import { AxiosInstance } from 'axios';
import {
    ICameraAverageDailyWorkingHour,
    ICameraDailyWorkedHour,
    ICameraOverviewHttpController,
    IGetCameraPingHour,
    ICameraPingHour,
    IGetConsolidateCameraMonitoring,
} from '../ICameraOverviewHttpController';

export class CameraOverviewHttpController extends BaseHttpController<{}> implements ICameraOverviewHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-overview', client);
    }

    async getConsolidateCameraMonitoring(
        props: IGetConsolidateCameraMonitoring
    ): Promise<ConsolidateCameraMonitoring[]> {
        return await this.doPost({ path: 'consolidate-camera-monitoring', body: props }).then((res) => res.data);
    }

    async cameraDailyWorkedHour(props: ICameraDailyWorkedHour): Promise<CameraDailyWorkedHour[]> {
        return await this.doPost({ path: 'daily-worked-hour', body: props }).then((res) => res.data);
    }

    async cameraAverageDailyWorkingHours(
        props: ICameraAverageDailyWorkingHour
    ): Promise<CameraAverageDailyWorkingHours> {
        return await this.doPost({ path: 'average-daily-working-hours', body: props }).then((res) => res.data);
    }

    async cameraPingDetail(props: IGetCameraPingHour): Promise<CameraPingDetail[]> {
        return await this.doPost({ path: 'camera-ping-hours', body: props }).then((res) => res.data);
    }

    async cameraBatteryDetail(props: ICameraDailyWorkedHour): Promise<CameraBatteryDetail[]> {
        return await this.doPost({ path: 'camera-battery-detail', body: props }).then((res) => res.data);
    }

    async cameraDistributeWorkingHours(props: ICameraDailyWorkedHour): Promise<CameraDistributeWorkingHour[]> {
        return await this.doPost({ path: 'camera-distribute-working-hours', body: props }).then((res) => res.data);
    }

    async cameraPingHours(props: ICameraPingHour): Promise<CameraPingHour[]> {
        return await this.doPost({ path: 'camera-ping-hours', body: props }).then((res) => res.data);
    }

    async camerasWithTime(): Promise<CamerasWithTime[]> {
        return await this.doPost({ path: 'camera-with-time' }).then((res) => res.data);
    }

    async cameraSetTimeInfo(props: IGetConsolidateCameraMonitoring): Promise<ConsolidateCameraMonitoring> {
        return await this.doPost({ path: 'camera-set-time-info', body: props }).then((res) => res.data);
    }
}
