import { Box, Stack, styled } from "@mui/material";

export const a = 2

export const BaseItem = styled(Box)({
    width: '100%',
    borderRadius: 8,
    padding: 16,
    border: "1px solid #DDDDDD"
});

export const StackRowSpace = styled(Stack)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
});
