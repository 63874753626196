import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import MultiProvider from '@components/MultiProvider';
import { msalConfig } from '@configs/authConfig';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import theme from '@theme/Theme';
import 'moment-timezone/builds/moment-timezone-with-data-10-year-range';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import './index.css';
import BackdropProvider from './providers/BackdropProvider';
import reportWebVitals from './reportWebVitals';
import i18n from './translations/config';
// import { LoadScript } from '@react-google-maps/api';
// import { appConfig } from './configs';

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
    <MultiProvider
        providers={[
            <QueryClientProvider client={queryClient} />,
            <ThemeProvider theme={theme} />,
            <LocalizationProvider dateAdapter={AdapterMoment} />,
            <MsalProvider instance={msalInstance} />,
            <I18nextProvider i18n={i18n} />,
            // <LoadScript googleMapsApiKey={appConfig.apiGoogle.googleMapApiKey} />,
            <BackdropProvider />,
        ]}
    >
        <CssBaseline />
        <App />
        <ToastContainer
            autoClose={3000}
            hideProgressBar
            closeButton={false}
            position={'bottom-left'}
            newestOnTop
            pauseOnFocusLoss={false}
        />
    </MultiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
