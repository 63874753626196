/* eslint-disable react-hooks/exhaustive-deps */
import { Paging } from '@Core';
import { ArrowDropDown } from '@mui/icons-material';
import {
    CircularProgress,
    ClickAwayListener,
    Fade,
    IconButton,
    Paper,
    Stack,
    SxProps,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import _ from 'lodash';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { VariableSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import BaseOption from './BaseOption';

export const activeStyles = (props: SxProps<Theme>, active?: boolean) => {
    return active ? props : undefined;
};

export type StyledAutocompleteProps<T> = {
    disabledAllOption?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    isSync?: boolean;
    label?: React.ReactNode;
    value?: T;
    placeholder?: string;
    onChange?(value?: T): any;
    isEqual?(option?: T, value?: T): boolean;
    getOptionLabel(option: T): string;
    renderOptionTooltip?(option: T): ReactNode;
    handleChangeSearch?(text: string): void;
    searchLoading: boolean;

    loading?: boolean;
    paging: Paging<T>;

    error?: boolean;
    helperText?: ReactNode;

    hasNextPage: boolean;
    isNextPageLoading: boolean;
    loadNextPage(searchText: string): void;
    wrapperWidth?: string | number;

    isRequired?: boolean;
};

const OPTION_HEIGHT = 37; // px

const getHeight = (width: number, content: string) => {
    const UL_PADDING = 16;
    const ITEM_PADDING = 16;
    const SCROLL_BAR_WIDTH = 4;
    const LINE_HEIGHT = 21;

    const para = document.createElement('p');
    para.innerText = content;
    para.style.width = `${width - UL_PADDING - ITEM_PADDING - SCROLL_BAR_WIDTH}px`;

    document.body.appendChild(para);
    const height = para.offsetHeight;
    document.body.removeChild(para);

    // console.log(`_height`, width, content, height);

    return height > LINE_HEIGHT ? OPTION_HEIGHT + LINE_HEIGHT : OPTION_HEIGHT;
};

export default function StyledAutocomplete<T>(props: StyledAutocompleteProps<T>) {
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);

    const { isRequired = false } = props;

    const handleChangeSearch = useCallback(
        _.debounce((text: string) => {
            props.handleChangeSearch?.(text);
        }, 300),
        [props.handleChangeSearch]
    );

    const toggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setInputValue(props.value ? props.getOptionLabel(props.value) : '');
    }, [props.value]);

    useEffect(() => {
        if (open) {
            // if (searchValue) {
            //     props.handleChangeSearch?.('');
            //     setSearchValue('');
            // }
        } else {
            setInputValue(props.value ? props.getOptionLabel(props.value) : '');
            // if (searchValue) {
            //     props.handleChangeSearch?.('');
            //     setSearchValue('');
            // }
        }
    }, [open]);

    useEffect(() => {
        setSearchLoading(props.searchLoading);
    }, [props.paging.rows, props.searchLoading]);

    const items = props.paging.rows;
    const { hasNextPage } = props;
    const itemCount = hasNextPage ? items.length + 1 : items.length;

    const loadMoreItems = () => (props.isNextPageLoading ? undefined : props.loadNextPage(searchValue));
    const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

    const handleChangeText = (e: any) => {
        setInputValue(e.target.value);
        if (open) {
            setSearchValue(e.target.value);
            handleChangeSearch(e.target.value);
            setSearchLoading(true);
        }
    };

    const refZ = useRef<any>(null);

    return (
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown" touchEvent="onTouchEnd">
            <div ref={refZ} style={{ width: props.wrapperWidth ?? 'auto' }}>
                <Stack
                    sx={{
                        // height: 40,
                        position: 'relative',
                    }}
                >
                    <TextField
                        required={isRequired}
                        label={props.label}
                        value={inputValue}
                        disabled={props.loading || props.disabled || props.readOnly}
                        fullWidth
                        error={props.error}
                        helperText={props.helperText}
                        onClick={(e) => {
                            if (props.loading || props.disabled || props.readOnly) return;
                            e.preventDefault();
                            e.stopPropagation();
                            setOpen(true);
                        }}
                        autoComplete="off"
                        onChange={handleChangeText}
                        placeholder={props.placeholder || 'All options'}
                        sx={{
                            '& input:not(:focus)': {
                                '&::placeholder': {
                                    color: 'initial',
                                    opacity: 0.5,
                                },
                            },
                        }}
                        InputProps={{
                            readOnly: props.readOnly,

                            endAdornment: (
                                <IconButton
                                    size="small"
                                    sx={{
                                        padding: '1px',
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        toggle();
                                    }}
                                    disabled={props.loading || props.disabled || props.readOnly}
                                >
                                    {props.loading ? (
                                        <CircularProgress size={'16px'} />
                                    ) : (
                                        <ArrowDropDown
                                            fontSize="small"
                                            style={{ transition: '0.3s' }}
                                            className={open ? 'rotate' : ''}
                                        />
                                    )}
                                </IconButton>
                            ),
                        }}
                    />

                    <Fade in={open} timeout={0} unmountOnExit={false}>
                        <Paper
                            sx={{
                                position: 'absolute',
                                top: 'calc(100% + 2px)',
                                zIndex: 2,
                                left: 0,
                                right: 0,
                                p: 1,
                            }}
                        >
                            {!props.disabledAllOption && (
                                <Stack
                                    sx={{
                                        ...activeStyles({ display: 'none' }, Boolean(searchLoading)),
                                    }}
                                >
                                    <Stack>
                                        <BaseOption
                                            option={undefined}
                                            select={!props.value}
                                            renderOption={() => (
                                                <Typography>{props.placeholder || 'All options'}</Typography>
                                            )}
                                            onClick={(option) => {
                                                handleClose();
                                                props.onChange?.(undefined);
                                                props.handleChangeSearch?.('');
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            )}

                            {!searchLoading && (
                                <InfiniteLoader
                                    isItemLoaded={isItemLoaded}
                                    itemCount={itemCount}
                                    loadMoreItems={loadMoreItems}
                                    threshold={5}
                                >
                                    {({ onItemsRendered, ref }) => {
                                        return (
                                            <VariableSizeList
                                                ref={ref}
                                                onItemsRendered={onItemsRendered}
                                                height={OPTION_HEIGHT * Math.min(6, itemCount)}
                                                itemCount={itemCount}
                                                itemSize={(index) =>
                                                    getHeight(
                                                        refZ.current?.offsetWidth,
                                                        items[index] ? props.getOptionLabel(items[index]) : ''
                                                    )
                                                }
                                                width={'100%'}
                                                className="styled-scroll"
                                            >
                                                {({ index, style }) => {
                                                    const option = items[index];

                                                    return !isItemLoaded(index) ? (
                                                        <Stack
                                                            style={style}
                                                            sx={{ height: OPTION_HEIGHT, pl: 1 }}
                                                            justifyContent="center"
                                                        >
                                                            <Typography>Loading...</Typography>
                                                        </Stack>
                                                    ) : (
                                                        <BaseOption
                                                            stackProps={{ style: style }}
                                                            option={option}
                                                            renderOption={(_option) => (
                                                                <Stack
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justifyContent="space-between"
                                                                >
                                                                    <Typography>
                                                                        {props.getOptionLabel(_option)}
                                                                    </Typography>
                                                                    {props.renderOptionTooltip?.(_option)}
                                                                </Stack>
                                                            )}
                                                            onClick={(option) => {
                                                                handleClose();
                                                                props.onChange?.(option);
                                                            }}
                                                            select={
                                                                props.isEqual?.(option, props.value) ??
                                                                _.isEqual(option, props.value)
                                                            }
                                                        />
                                                    );
                                                }}
                                            </VariableSizeList>
                                        );
                                    }}
                                </InfiniteLoader>
                            )}

                            <Stack
                                sx={{
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: '5px',
                                    ...activeStyles({ display: 'none' }, Boolean(!searchLoading)),
                                }}
                            >
                                <Typography color="grayText">Searching...</Typography>
                            </Stack>

                            <Stack
                                sx={{
                                    height: 30,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingLeft: '5px',
                                    ...activeStyles(
                                        { display: 'none' },
                                        Boolean(!!items.length || props.loading || searchLoading)
                                    ),
                                }}
                            >
                                <Typography color="grayText">No options</Typography>
                            </Stack>
                        </Paper>
                    </Fade>
                </Stack>
            </div>
        </ClickAwayListener>
    );
}
