import { Paging } from '@Core';
import { ICameraDailyWorkedHour, IGetCameraPingHour } from '@EcamModel/controllers/ICameraOverviewHttpController';
import {
    CameraServiceType,
    CaptureInfoPostType,
    CaptureInfoUploadMode,
    CellularInfoNetworkType,
    Location,
    Status,
    WifiStatus,
} from '@EcamModel/model';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import {
    CameraAverageDailyWorkingHours,
    CameraBatteryDetail,
    CameraDailyWorkedHour,
    CameraDistributeWorkingHour,
    CameraPingDetail,
    ConsolidateCameraMonitoring,
} from '@EcamModel/model/CameraOverview';
import { Zones } from '@EcamModel/model/Zones';
import { DrawerContext, DrawerPage } from '@components/drawer/useDrawer';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushError, pushSuccess, pushWarning } from '@components/toast';
import {
    cameraNoteHttpController,
    cameraOverviewHttpController,
    locationController,
    solar4gCamPhotoUploadScheduleHttpController,
} from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import { Typography } from '@mui/material';
import { indicatorMapping } from '@pages/cameras/details/components/CaptureManagementMilesightCameraSolar';
import { BaseSelect, cameraSortByStatus, Filter, purposes } from '@pages/cameras/list';
import { search } from '@pages/cameras/list/helpers';
import {
    sortByColumn,
    sortByColumnWithMathAbs,
    sortByDate,
    sortValueBoolean,
    sortValueNumberToText,
} from '@pages/camerasTime/hook/useSortColumnTable';
import color from '@theme/Colors';
import _, { cloneDeep } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    endOfYesterday,
    formatUKDate,
    formatUKTimeDate,
    getFirstAndLastDayOfCurrentWeek,
    getMinutesFromStartOfDay,
    startOfYesterday,
} from 'src/helpers';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { useDebounce } from 'usehooks-ts';
import { CameraOverviewPopup } from '../CameraOverviewScreen';
import { Chart } from '../charts/BarChartCameraStatus';
import { cameraNoteSortByType } from '@pages/cameras/details/components/CameraInformation';
import { SortByValue } from '@EcamModel/controllers/ICameraHttpController';
import moment from 'moment';
import { FormValues } from '../components/PopUpArrangeUploadTimes';
import { IAutoArrangePhotoUploadTime } from '@EcamModel/controllers/ISolar4gCamPhotoUploadScheduleHttpController';
import { Solar4gPhotoUploadSchedule } from '@EcamModel/model/Solar4gPhotoUploadSchedule';

export const statuses: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Active',
        Value: Status.Active,
    },
    {
        Id: 2,
        Name: 'Inactive',
        Value: Status.Inactive,
    },
    {
        Id: 3,
        Name: 'Not available',
        Value: Status.NotAvailable,
    },
    {
        Id: 4,
        Name: 'Deleted',
        Value: Status.Deleted,
    },
];

export const cameraVersions: BaseSelect[] = [
    {
        Id: 1,
        Name: '1',
        Value: 1,
    },
    {
        Id: 2,
        Name: '2',
        Value: 2,
    },
];

export const cameraDeleted: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Active',
        Value: 'Active',
    },
    {
        Id: 2,
        Name: 'Deleted',
        Value: 'Deleted',
    },
];

export const uploadMode: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Immediacy',
        Value: CaptureInfoUploadMode.IMMEDIACY,
    },
    {
        Id: 2,
        Name: 'Timing',
        Value: CaptureInfoUploadMode.TIMING,
    },
];

export const serviceType: BaseSelect[] = [
    {
        Id: 1,
        Name: 'eCam TechOps',
        Value: CameraServiceType.ECamTechOps,
    },
    {
        Id: 2,
        Name: 'ANPR TechOps',
        Value: CameraServiceType.ANPRTechOps,
    },
];

export interface TableRowContent {
    id: string;
    label: string;
    version?: number;
    type?: string;
    number?: number;
}

export const cameraStatusMapping = {
    [Status.Active]: 'Active',
    [Status.Inactive]: 'Inactive',
    [Status.NotAvailable]: 'Not available',
    [Status.Deleted]: 'Deleted',
};

export const netWorkingMapping = {
    [CellularInfoNetworkType.AUTO]: 'Auto',
    [CellularInfoNetworkType.ONLY_2G]: '2G only',
    [CellularInfoNetworkType.ONLY_3G]: '3G only',
    [CellularInfoNetworkType.ONLY_4G]: '4G only',
};

export const uploadModeMapping = {
    [CaptureInfoUploadMode.TIMING]: 'Timing',
    [CaptureInfoUploadMode.IMMEDIACY]: 'Immediacy',
};

export const wifiStatusMapping = {
    [WifiStatus.Connected]: 'Connected',
    [WifiStatus.DisConnected]: 'Disconnected',
};

export const postTypeMapping = {
    [CaptureInfoPostType.HTTP]: 'HTTP',
    [CaptureInfoPostType.MQTT]: 'MQTT',
};

export const cameraServiceMapping = {
    [CameraServiceType.ANPRTechOps]: 'ANPR',
    [CameraServiceType.ECamTechOps]: 'eCam',
};
export enum Mode {
    create,
    edit,
}

export default function useCameraConfigProvider() {
    const { fromCurrentWeek, toCurrentWeek } = getFirstAndLastDayOfCurrentWeek();
    console.log('🚀 ~ useCameraConfigProvider ~ fromCurrentWeek:', fromCurrentWeek);

    const [filter, setFilter] = useState<Partial<Filter>>({
        page: 1,
        from: startOfYesterday,
        to: endOfYesterday,
        status: {
            Id: 2,
            Name: 'Active',
            Value: 0,
        },
    });

    const backdrop = useBackdrop();

    const { setActiveItem } = useContext(DrawerContext);

    const containerRef = useRef<HTMLDivElement>(null);
    const [zones, setZones] = useState<Zones[]>([]);
    const [sorting, setSorting] = useState({});

    const [loading, setLoading] = useState<boolean>(true);
    const [orderBy, setOrderBy] = useState<string>('Avg. uptime (h)');
    const [sortOrder, setSortOrder] = useState(true);
    const [loadingDailyWorkedHours, setLoadingDailyWorkedHours] = useState<boolean>(true);
    const [cameraDailyWorkedHour, setCameraDailyWorkedHour] = useState<CameraDailyWorkedHour[]>([]);
    const [cameraDailyBatteryDetail, setCameraDailyBatteryDetail] = useState<CameraBatteryDetail[]>([]);
    const [cameraPingStatus, setCameraPingStatus] = useState<CameraPingDetail[]>([]);
    const [capturedPhotos, setCapturedPhotos] = useState<CameraDistributeWorkingHour[]>([]);
    const [cameraOverview, setCameraOverview] = useState<CameraOverviewPopup>({
        cameraName: '',
        cameraId: 1,
    });

    const [cameraDailyAverageWorkedHours, setCameraDailyAverageWorkedHours] = useState<CameraAverageDailyWorkingHours>(
        {} as CameraAverageDailyWorkingHours
    );

    const [searchCameraConfig, setSearchCameraConfig] = useState<string>('');
    const debouncedValue = useDebounce<string>(searchCameraConfig, 500);
    const [ascendingOrder, setAscendingOrder] = useState<boolean>(true);

    const [cameraConfigMonitoring, setCameraConfigMonitoring] = useState<ConsolidateCameraMonitoring[]>([]);

    const [photoUploadSchedule, setPhotoUploadSchedule] = useState<Solar4gPhotoUploadSchedule>(
        {} as Solar4gPhotoUploadSchedule
    );

    useEffect(() => {
        setActiveItem(DrawerPage.OverviewCameras);
        handleGetPhotoUploadTimeLatest();
    }, []);

    const CheckRowDataToShow = (props: { number: number; content: JSX.Element }) => {
        return !!props.number ? props.content : <></>;
    };

    const checkAndUpdateLocalStorage = (data: TableRowContent[]) => {
        const updatedData = data.some((item) => item.version === versionsRows);
        if (!updatedData && data.length) {
            localStorage.setItem('eCamConfigCustomFields', JSON.stringify(sortedNumberFieldCustom));
            return sortedNumberFieldCustom;
        } else {
            return data;
        }
    };

    const getDataFromLocalStorage = (): TableRowContent[] => {
        try {
            const jsonData = localStorage.getItem('eCamConfigCustomFields');

            if (jsonData) {
                const data = JSON.parse(jsonData);
                return checkAndUpdateLocalStorage(data);
            } else {
                localStorage.setItem('eCamConfigCustomFields', JSON.stringify(sortedNumberFieldCustom));
                return sortedNumberFieldCustom;
            }
        } catch (error) {
            console.log('🚀 ~ getDataFromLocalStorage ~ error:', error);
            return [];
        }
    };

    const middleFields: TableRowContent[] = getDataFromLocalStorage();

    const tableRow: TableRowContent[] = [
        {
            id: 'No.',
            label: 'No.',
        },
        {
            id: 'Camera',
            label: 'Camera',
        },
        ...middleFields,
        {
            id: 'Action',
            label: 'Action',
        },
    ];

    const toggleSortOrder = () => {
        setAscendingOrder(!ascendingOrder);
    };

    const handleSortChange = (columnLabel, currentSort) => {
        toggleSortOrder();
        const newSorting = {
            ...sorting,
            [columnLabel]: currentSort === 'ASC' ? 'DSC' : 'ASC',
        };
        setOrderBy(columnLabel);
        setSorting(newSorting);
        setSortOrder((pre) => !pre);

        const convertNull = (value) => {
            if (value === null) {
                return 'N/A';
            }
            return value;
        };

        const sortPingAble = cameraConfigMonitoring.sort((a, b) => {
            const pingA = a.Pingable !== null ? String(a.Pingable) : null;
            const pingB = b.Pingable !== null ? String(b.Pingable) : null;

            const convertedPingA = convertNull(pingA);
            const convertedPingB = convertNull(pingB);

            if (convertedPingA !== null && convertedPingB !== null) {
                if (convertedPingA < convertedPingB) {
                    return ascendingOrder ? -1 : 1;
                }
                if (convertedPingA > convertedPingB) {
                    return ascendingOrder ? 1 : -1;
                }
            }
            return 0;
        });

        switch (columnLabel) {
            case 'Avg. uptime (h)':
                setCameraConfigMonitoring(
                    sortByColumn('AvgDailyWorkedHours', newSorting['Avg. uptime (h)'], cameraConfigMonitoring)
                );
                break;
            case 'Deployment date':
                setCameraConfigMonitoring(
                    sortByDate('CameraDeployDate', newSorting['Deployment date'], cameraConfigMonitoring)
                );
                break;
            case 'Camera':
                setCameraConfigMonitoring(sortByColumn('CameraName', newSorting['Camera'], cameraConfigMonitoring));
                break;
            case 'Contraventions cancelled':
                setCameraConfigMonitoring(
                    sortByColumn(
                        'CancelledContraventions',
                        newSorting['Contraventions cancelled'],
                        cameraConfigMonitoring
                    )
                );
                break;
            case 'Contraventions approved':
                setCameraConfigMonitoring(
                    sortByColumn(
                        'ApprovedContraventions',
                        newSorting['Contraventions approved'],
                        cameraConfigMonitoring
                    )
                );
                break;
            case 'Potential contraventions':
                setCameraConfigMonitoring(
                    sortByColumn(
                        'PotentialContraventions',
                        newSorting['Potential contraventions'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Potential rate':
                setCameraConfigMonitoring(
                    sortByColumn('PotentialRate', newSorting['Potential rate'], cameraConfigMonitoring)
                );
                break;
            case 'Approval rate':
                setCameraConfigMonitoring(
                    sortByColumn('ApprovalRate', newSorting['Approval rate'], cameraConfigMonitoring)
                );
                break;
            case 'Cancellation rate':
                setCameraConfigMonitoring(
                    sortByColumn('CancellationRate', newSorting['Cancellation rate'], cameraConfigMonitoring)
                );
                break;

            case 'Camera version':
                setCameraConfigMonitoring(
                    sortByColumn('CameraVersion', newSorting['Camera version'], cameraConfigMonitoring)
                );
                break;
            case 'Dev Mac':
                setCameraConfigMonitoring(sortByColumn('DevMac', newSorting['Dev Mac'], cameraConfigMonitoring));
                break;
            case 'Operational status':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueNumberToText(
                            cameraConfigMonitoring,
                            ascendingOrder,
                            'CameraStatus',
                            cameraStatusMapping
                        ),
                        newSorting['Operational status'],
                        cameraConfigMonitoring
                    )
                );
                break;
            case 'Avg. photos':
                setCameraConfigMonitoring(
                    sortByColumn('AvgDailyPhotosTaken', newSorting['Avg. photos'], cameraConfigMonitoring)
                );
                break;
            case 'Contraventions in verification':
                setCameraConfigMonitoring(
                    sortByColumn(
                        'InprogressContraventions',
                        newSorting['Contraventions in verification'],
                        cameraConfigMonitoring
                    )
                );
                break;
            case 'Server time':
                setCameraConfigMonitoring(sortByDate('ServerTime', newSorting['Server time'], cameraConfigMonitoring));
                break;
            case 'Camera time':
                setCameraConfigMonitoring(sortByDate('CameraTime', newSorting['Camera time'], cameraConfigMonitoring));
                break;
            case 'Time difference':
                setCameraConfigMonitoring(
                    sortByColumnWithMathAbs('DifferentMinute', newSorting['Time difference'], cameraConfigMonitoring)
                );
                break;
            case 'Zone':
                setCameraConfigMonitoring(sortByColumn('ZoneName', newSorting['Zone'], cameraConfigMonitoring));
                break;
            case 'Firmware':
                setCameraConfigMonitoring(sortByColumn('Firmware', newSorting['Firmware'], cameraConfigMonitoring));
                break;
            case 'Auto time':
                setCameraConfigMonitoring(
                    sortByColumn('SetTimeAutomatically', newSorting['Auto time'], cameraConfigMonitoring)
                );
                break;
            case 'Capture interval':
                setCameraConfigMonitoring(
                    sortByColumn('CaptureInterval', newSorting['Capture interval'], cameraConfigMonitoring)
                );
                break;
            case 'Network mode 4G/3G/2G':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueNumberToText(cameraConfigMonitoring, ascendingOrder, 'NetworkType', netWorkingMapping),
                        newSorting['Network mode 4G/3G/2G'],
                        cameraConfigMonitoring
                    )
                );
                break;
            case 'Last contacted':
                setCameraConfigMonitoring(
                    sortByDate('LastContact', newSorting['Last contacted'], cameraConfigMonitoring)
                );
                break;
            case 'APN':
                setCameraConfigMonitoring(sortByColumn('Apn', newSorting['APN'], cameraConfigMonitoring));
                break;
            case 'ICCID':
                setCameraConfigMonitoring(sortByColumn('Iccid', newSorting['ICCID'], cameraConfigMonitoring));
                break;

            case 'Camera Deleted':
                setCameraConfigMonitoring(
                    sortByColumn('CameraDeleted', newSorting['Camera Deleted'], cameraConfigMonitoring)
                );
                break;

            case 'Upload mode':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueNumberToText(cameraConfigMonitoring, ascendingOrder, 'UploadMode', uploadModeMapping),
                        newSorting['Upload mode'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Sleep delay (s)':
                setCameraConfigMonitoring(
                    sortByColumn('CaptureTimeout', newSorting['Sleep delay (s)'], cameraConfigMonitoring)
                );
                break;

            case 'Sensor sensitivity':
                setCameraConfigMonitoring(
                    sortByColumn('RadarSensitivity', newSorting['Sensor sensitivity'], cameraConfigMonitoring)
                );
                break;

            case 'Minimum detection speed':
                setCameraConfigMonitoring(
                    sortByColumn('LowSpeedLimit', newSorting['Minimum detection speed'], cameraConfigMonitoring)
                );
                break;

            case 'Indicator light':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueNumberToText(cameraConfigMonitoring, ascendingOrder, 'Indicator', indicatorMapping),
                        newSorting['Indicator light'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Signal strength':
                setCameraConfigMonitoring(
                    sortByColumn('SignalStrength', newSorting['Signal strength'], cameraConfigMonitoring)
                );
                break;

            case 'Connect status':
                setCameraConfigMonitoring(
                    sortByColumn('ConnectStatus', newSorting['Connect status'], cameraConfigMonitoring)
                );
                break;

            case 'Lastest ping':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueBoolean(cameraConfigMonitoring, ascendingOrder, 'Pingable'),
                        newSorting['Lastest ping'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Sim status':
                setCameraConfigMonitoring(sortByColumn('SimStatus', newSorting['Sim status'], cameraConfigMonitoring));
                break;

            case 'Type service':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueNumberToText(
                            cameraConfigMonitoring,
                            ascendingOrder,
                            'CameraService',
                            cameraServiceMapping
                        ),
                        newSorting['Type service'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Gen PCNs':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueBoolean(cameraConfigMonitoring, ascendingOrder, 'IsVerificationsEnabled'),
                        newSorting['Gen PCNs'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Mechanism type':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueNumberToText(cameraConfigMonitoring, ascendingOrder, 'PostType', postTypeMapping),
                        newSorting['Mechanism type'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Images left in SD':
                setCameraConfigMonitoring(
                    sortByColumn('ImagesLeft', newSorting['Images left in SD'], cameraConfigMonitoring)
                );
                break;

            case 'Network status':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueNumberToText(
                            cameraConfigMonitoring,
                            ascendingOrder,
                            'LastWifiStatus',
                            wifiStatusMapping
                        ),
                        newSorting['Network status'],
                        cameraConfigMonitoring
                    )
                );
                break;
            case 'Lastest ping':
                setCameraConfigMonitoring(
                    sortByColumn(
                        sortValueBoolean(cameraConfigMonitoring, ascendingOrder, 'Pingable'),
                        newSorting['Lastest ping'],
                        cameraConfigMonitoring
                    )
                );
                break;

            case 'Latest photo':
                setCameraConfigMonitoring(
                    sortByDate('LatestPhotoTime', newSorting['Latest photo'], cameraConfigMonitoring)
                );
                break;

            default:
                break;
        }
    };

    const excludedColumns = ['No.', 'Action', 'Timezone', 'Note'];

    const setPartialFilter = (filter: Partial<Filter>) => {
        setFilter((prev) => ({ ...prev, ...filter }));
    };

    const locationAutocomplete = useStyledAutocomplete({
        async getList(props): Promise<Paging<Location>> {
            const res = await locationController.listHasCamera(props).finally(() => {});
            return res;
        },
        listProps: {
            search: { fields: ['name'] } as any,
        },
    });

    const onChangeLocation = (l?: Location) => {
        setPartialFilter({ location: l, zones: l ? l.Zones : undefined });
        setZones(l ? l.Zones! : []);
    };

    const statusAutocomplete = useStyledAutocomplete({
        list: {
            options: statuses,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const cameraVersionAutocomplete = useStyledAutocomplete({
        list: {
            options: cameraVersions,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const cameraDeletedAutocomplete = useStyledAutocomplete({
        list: {
            options: cameraDeleted,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const uploadModeAutocomplete = useStyledAutocomplete({
        list: {
            options: uploadMode,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const serviceTypeAutocomplete = useStyledAutocomplete({
        list: {
            options: serviceType,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const purposesAutocomplete = useStyledAutocomplete({
        list: {
            options: purposes,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const filteredData = _.filter(cameraConfigMonitoring, (f) => !_.isNull(f.Firmware));
    const groupedData = _.groupBy(filteredData, 'Firmware');

    const filteredAndSortedOptions = Object.keys(groupedData)
        .map((firmware, index) => ({
            Id: index,
            Name: firmware,
            Value: firmware,
        }))
        .sort((a, b) => b.Name.localeCompare(a.Name));

    const firmwareAutocomplete = useStyledAutocomplete({
        list: {
            options: filteredAndSortedOptions,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
        dependencyList: [cameraConfigMonitoring],
    });

    const textBackdrop = () => {
        const message = (
            <Typography fontSize={16} fontWeight={500} color={color.containedButtonString} bgcolor={'white'} p={1}>
                We are sending a request to change the time to the camera, please wait...
            </Typography>
        );
        backdrop.setTrue(message);
    };

    const setCameraTimeInfo = async (cameraId: number) => {
        textBackdrop();
        await cameraOverviewHttpController
            .cameraSetTimeInfo({
                startDate: filter.from ?? startOfYesterday,
                endDate: filter.to ?? endOfYesterday,
                cameraId: cameraId,
            })
            .then((res) => {
                const _prevState = cloneDeep(cameraConfigMonitoring);
                _prevState.map((item) => {
                    if (item.CameraId === res.CameraId) {
                        const _prevCameraTimeNumber = new Date(item.CameraTime).getTime();
                        const _currentCameraTimeNumber = new Date(res.CameraTime).getTime();

                        if (_prevCameraTimeNumber === _currentCameraTimeNumber) {
                            pushError("Cannot change the camera's time, please try later!");
                            return item;
                        } else {
                            pushSuccess('Change time camera successfully!');
                            setCameraConfigMonitoring((prev) =>
                                prev.map((item) => (item.CameraId === res.CameraId ? res : item))
                            );
                            return res;
                        }
                    }
                    return item;
                });
            })
            .catch(() => {
                pushError('Change time camera failed!');
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const handleMileSightCamerasCopy = (): void => {
        const container = containerRef.current!;

        const range = document.createRange();
        range.selectNode(container);

        const selection = window.getSelection() as Selection;
        selection.removeAllRanges();
        selection.addRange(range);

        if (document.queryCommandSupported('copy')) {
            document.execCommand('copy');
            selection.removeAllRanges();
            pushSuccess('Copy successfully!');
        } else {
            pushWarning('Your browser does not support the copy function');
        }
    };

    const getBatteryDetailByHourly = async (cameraId: number) => {
        let req: ICameraDailyWorkedHour = {
            CameraId: cameraId,
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        setLoadingDailyWorkedHours(true);
        cameraOverviewHttpController
            .cameraBatteryDetail(req)
            .then((res) => {
                setCameraDailyBatteryDetail(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    const getPingStatus = async (cameraId: number) => {
        let req: IGetCameraPingHour = {
            CameraId: cameraId,
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        setLoadingDailyWorkedHours(true);
        cameraOverviewHttpController
            .cameraPingDetail(req)
            .then((res) => {
                setCameraPingStatus(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    const getCapturedPhotosAvg = async (cameraId: number) => {
        let req: ICameraDailyWorkedHour = {
            CameraId: cameraId,
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        setLoadingDailyWorkedHours(true);
        cameraOverviewHttpController
            .cameraDistributeWorkingHours(req)
            .then((res) => {
                setCapturedPhotos(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    const getCameraConfigMonitoring = async (props: { startDate: Date; endDate: Date }) => {
        setLoading(true);
        const startAndEndDate = {
            startDate: props?.startDate ?? startOfYesterday,
            endDate: props?.endDate ?? endOfYesterday,
        };

        const result = await cameraOverviewHttpController
            .getConsolidateCameraMonitoring(startAndEndDate)
            .finally(() => setLoading(false));
        setCameraConfigMonitoring(result);
    };

    useEffect(() => {
        getCameraConfigMonitoring({
            startDate: filter?.from ?? startOfYesterday,
            endDate: filter?.to ?? endOfYesterday,
        });
    }, [filter?.from, filter?.to]);

    // ------------------------ PopUpAvgWorkedHours --------------------------------

    const cameraDailyWorkedHourPopup = cameraDailyWorkedHour.map((i) => {
        let chart: Chart = { label: formatUKDate(i.Created), value: i.DailyWorkedHours };
        return chart;
    });

    const cameraPhotoTakenPopup = cameraDailyWorkedHour.map((i) => {
        let chart: Chart = { label: formatUKDate(i.Created), value: i.DailyPhotosTaken };
        return chart;
    });

    const cameraBatteryDetailPopup = cameraDailyBatteryDetail.map((i) => {
        let chart: Chart = { label: formatUKTimeDate(i.Created), value: i.Battery };
        return chart;
    });

    // ------------------------ PopupDailyAverageWorkedHours --------------------------------

    const cameraAllDailyAverageWorkedHour = cameraDailyAverageWorkedHours.CameraAll?.map((i) => {
        let chart: Chart = { label: formatUKDate(i.Created), value: i.AvgDailyWorkedHours };
        return chart;
    });

    const totalHoursInAllCamera = cameraDailyAverageWorkedHours.CameraAll?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.AvgDailyWorkedHours;
    }, 0);

    const averageHoursInAllCamera = totalHoursInAllCamera / cameraDailyAverageWorkedHours.CameraAll?.length;

    const cameraV1DailyAverageWorkedHour = cameraDailyAverageWorkedHours.CameraV1?.map((i) => {
        let chart: Chart = { label: formatUKDate(i.Created), value: i.AvgDailyWorkedHours };
        return chart;
    });

    const totalHoursInCameraV1 = cameraDailyAverageWorkedHours.CameraV1?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.AvgDailyWorkedHours;
    }, 0);

    const averageHoursInCameraV1 = totalHoursInCameraV1 / cameraDailyAverageWorkedHours.CameraV1?.length;

    const cameraV2DailyAverageWorkedHour = cameraDailyAverageWorkedHours.CameraV2?.map((i) => {
        let chart: Chart = { label: formatUKDate(i.Created), value: i.AvgDailyWorkedHours };
        return chart;
    });

    const totalHoursInCameraV2 = cameraDailyAverageWorkedHours.CameraV2?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.AvgDailyWorkedHours;
    }, 0);

    const averageHoursInCameraV2 = totalHoursInCameraV2 / cameraDailyAverageWorkedHours.CameraV2?.length;

    const filteredServices = useMemo(() => {
        return cameraConfigMonitoring?.filter((item) => {
            // const isLocationMatch = !filter.location?.Id || item.LocationId === filter.location?.Id;
            const isCameraNameMatch = item.CameraName!.toLowerCase().includes(debouncedValue.toLowerCase());
            const isVersionMatch = !filter.camVersion || item.CameraVersion === filter.camVersion.Value;

            const isModeUploadMatch = !filter.uploadMode || item.UploadMode === filter.uploadMode.Value;
            const isStatusMatch = !filter.status || item.CameraStatus === filter.status.Value;
            const isFirmwareMatch =
                !filter.firmware || filter.firmware.Id === -1 || item.Firmware === filter.firmware.Value;
            const areZonesMatched = filter.zones?.length === 0 || filter.zones?.some((zone) => item.ZoneId === zone.Id);

            const isServiceTypeMatch = !filter.serviceType || item.CameraService === filter.serviceType.Value;

            const isPurposeTypeMatch = !filter.camPurposes || item.PurposeType === filter.camPurposes?.Value;

            if (filter.location) {
                return (
                    isCameraNameMatch &&
                    isVersionMatch &&
                    isStatusMatch &&
                    areZonesMatched &&
                    isFirmwareMatch &&
                    isModeUploadMatch &&
                    isServiceTypeMatch &&
                    isPurposeTypeMatch
                );
            } else {
                return (
                    // isLocationMatch &&
                    isCameraNameMatch &&
                    isVersionMatch &&
                    isStatusMatch &&
                    isFirmwareMatch &&
                    isModeUploadMatch &&
                    isServiceTypeMatch &&
                    isPurposeTypeMatch
                );
            }
        });
    }, [
        debouncedValue,
        cameraConfigMonitoring,
        filter.camVersion,
        filter.status,
        filter.location,
        filter.zones,
        filter.firmware,
        filter.uploadMode,
        filter.serviceType,
        filter.camPurposes,
    ]);

    const filterByUploadModeAndStatus = (services: ConsolidateCameraMonitoring[]) => {
        return (
            services.filter((item) => {
                const isModeUploadMatch = item.UploadMode === CaptureInfoUploadMode.TIMING;
                const isStatusMatch = item.CameraStatus === Status.Active;
                return isModeUploadMatch && isStatusMatch;
            }) || []
        );
    };

    const calculatePercentageColor = (
        value: number,
        minValue: number,
        maxValue: number,
        cameraService: CameraServiceType
    ): string => {
        if (cameraService === CameraServiceType.ANPRTechOps) {
            return '';
        }
        const range = maxValue - minValue;
        const binSize = range / 4;

        let colors;
        if (value <= minValue + binSize) {
            colors = color.danger;
        } else if (value <= minValue + binSize * 2) {
            colors = color.gold600;
        } else if (value <= minValue + binSize * 3) {
            colors = color.warning;
        } else {
            colors = color.success;
        }

        return `rgba(${parseInt(colors.substring(1, 3), 16)}, ${parseInt(colors.substring(3, 5), 16)}, ${parseInt(
            colors.substring(5, 7),
            16
        )}, 0.17)`;
    };

    const calculateMinMax = (key: string) => {
        const values = Object.values(filteredServices).map((item) => item[key]);
        const flatValues = [].concat.apply([], values);
        const minValue = Math.min(...flatValues);
        const maxValue = Math.max(...flatValues);
        return { minValue, maxValue };
    };

    const { minValue: minValueApproved, maxValue: maxValueApproved } = calculateMinMax('ApprovedContraventions');
    const { minValue: minValueVerification, maxValue: maxValueVerification } =
        calculateMinMax('InprogressContraventions');
    const { minValue: minValueCancelled, maxValue: maxValueCancelled } = calculateMinMax('CancelledContraventions');
    const { minValue: minValuePotential, maxValue: maxValuePotential } = calculateMinMax('PotentialContraventions');

    const { minValue: minValuePotentialRate, maxValue: maxValuePotentialRate } = calculateMinMax('PotentialRate');
    const { minValue: minValueApprovalRate, maxValue: maxValueApprovalRate } = calculateMinMax('ApprovalRate');
    const { minValue: minValueCancellationRate, maxValue: maxValueCancellationRate } =
        calculateMinMax('CancellationRate');

    const { minValue: minValueAvgDailyPhotosTaken, maxValue: maxValueAvgDailyPhotosTaken } =
        calculateMinMax('AvgDailyPhotosTaken');

    const sumOfFields = filteredServices.reduce(
        (accumulator, currentValue) => {
            accumulator.InprogressContraventions += currentValue.InprogressContraventions || 0;
            accumulator.ApprovedContraventions += currentValue.ApprovedContraventions || 0;
            accumulator.CancelledContraventions += currentValue.CancelledContraventions || 0;
            accumulator.PotentialContraventions += currentValue.PotentialContraventions || 0;
            accumulator.AvgDailyPhotosTaken += currentValue.AvgDailyPhotosTaken || 0;
            return accumulator;
        },
        {
            InprogressContraventions: 0,
            ApprovedContraventions: 0,
            CancelledContraventions: 0,
            PotentialContraventions: 0,
            AvgDailyPhotosTaken: 0,
        }
    );

    const avgPotentialRate = (sumOfFields.PotentialContraventions / sumOfFields.AvgDailyPhotosTaken) * 100 || 0;

    const avgApprovalRate =
        (sumOfFields.ApprovedContraventions /
            (sumOfFields.ApprovedContraventions + sumOfFields.CancelledContraventions)) *
            100 || 0;

    const avgCancellationRate =
        (sumOfFields.CancelledContraventions /
            (sumOfFields.ApprovedContraventions + sumOfFields.CancelledContraventions)) *
            100 || 0;

    // API note
    const [filterNote, setFilterNote] = useState<Partial<Filter>>({
        page: 1,
        from: undefined,
        to: undefined,
        sortedOrder: cameraSortByStatus[1],
        sort: cameraNoteSortByType[0],
    });
    const [searchValue, setSearchValue] = useState('');

    const debouncedValueSearchNote = useDebounce<string>(searchValue ?? '', 500);
    const [mode, setMode] = useState<Mode>(Mode.create);
    const [idCamera, setIdCamera] = useState<number>();
    const [idNote, setIdNote] = useState<number>(0);

    const [listNotes, setListNotes] = useState<Paging<CameraNotes>>({} as Paging<CameraNotes>);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const popUpNoteInCamera = usePopUp();
    const popUpAddOrUpdateNoteInCamera = usePopUp();
    const popUpConfirmDeleteNoteHistory = usePopUp();

    const sortingConditions =
        filterNote.sortedOrder?.Value === SortByValue.Asc ? `${filterNote.sort?.Value}` : `-${filterNote.sort?.Value}`;

    const handleGetListNote = async (cameraId: number) => {
        if (cameraId) {
            setIsLoading(true);
            await cameraNoteHttpController
                .list({
                    page: filterNote.page ?? 1,
                    CameraId: cameraId,
                    pageSize: 6,
                    sorts: [sortingConditions] as any,
                    search: {
                        content: searchValue ?? '',
                        fields: ['CreatedBy'],
                    },
                    From: filterNote.from ?? undefined,
                    To: filterNote.to ?? undefined,
                    filter: {
                        CameraId: cameraId,
                    },
                })
                .then((res) => {
                    setListNotes(res);
                })
                .catch((err) => {
                    pushError(err?.response?.data.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleAddOrUpdateNote = async (idCamera: number, note: string, idNote?: number) => {
        backdrop.setTrue();
        const data: CameraNotes = {
            CameraId: idCamera,
            Notes: note,
            Id: mode === Mode.edit ? idNote : undefined,
        };
        await cameraNoteHttpController
            .upsert(data)
            .then(async (res) => {
                if (idNote) {
                    pushSuccess('Updated successfully');
                } else {
                    pushSuccess('Add successfully');
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
                popUpNoteInCamera.setTrue();
            });
    };

    const handleDeleteNote = async (idNote: number) => {
        backdrop.setTrue();
        popUpNoteInCamera.onClose?.();
        await cameraNoteHttpController
            .delete(String(idNote))
            .then(() => {})
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
                popUpNoteInCamera.setTrue();
            });
    };

    const filteredNameCamera = cameraConfigMonitoring.find((n) => n.CameraId === idCamera);

    const handleGetPhotoUploadTimeLatest = () => {
        setIsLoading(true);
        solar4gCamPhotoUploadScheduleHttpController
            .getPhotoUploadTimeLatest()
            .then((res) => {
                setPhotoUploadSchedule(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handlePhotoUploadTimes = async (data: FormValues) => {
        setIsLoading(true);
        const startTime = getMinutesFromStartOfDay(moment(data.from).toDate());
        const toTime = getMinutesFromStartOfDay(moment(data.to).toDate());

        const _filter: IAutoArrangePhotoUploadTime = {
            StartTimeInMinutes: startTime ?? 0,
            EndTimeInMinutes: toTime ?? 0,
        };

        await solar4gCamPhotoUploadScheduleHttpController
            .autoArrangePhotoUploadTime(_filter)
            .then((res) => {
                pushSuccess('Saved successfully');
                setPhotoUploadSchedule(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return {
        filter,
        setFilter,
        sorting,
        cameraConfigMonitoring,
        loading,
        orderBy,
        sortOrder,
        loadingDailyWorkedHours,
        setLoadingDailyWorkedHours,
        cameraDailyWorkedHour,
        setCameraDailyWorkedHour,
        cameraPingStatus,
        capturedPhotos,
        cameraOverview,
        setCameraOverview,
        cameraDailyAverageWorkedHours,
        setCameraDailyAverageWorkedHours,
        containerRef,
        zones,
        CheckRowDataToShow,
        sortByColumn,
        sortByDate,
        handleSortChange,
        locationAutocomplete,
        excludedColumns,
        onChangeLocation,
        statusAutocomplete,
        cameraVersionAutocomplete,
        cameraDeletedAutocomplete,
        uploadModeAutocomplete,
        serviceTypeAutocomplete,
        purposesAutocomplete,
        setPartialFilter,
        tableRow,

        setCameraTimeInfo,
        handleMileSightCamerasCopy,
        getBatteryDetailByHourly,
        getCapturedPhotosAvg,
        getPingStatus,

        cameraDailyWorkedHourPopup,
        cameraPhotoTakenPopup,
        cameraBatteryDetailPopup,

        cameraAllDailyAverageWorkedHour,
        averageHoursInAllCamera,
        cameraV1DailyAverageWorkedHour,
        averageHoursInCameraV1,
        cameraV2DailyAverageWorkedHour,
        averageHoursInCameraV2,

        filteredServices,
        searchCameraConfig,
        setSearchCameraConfig,

        firmwareAutocomplete,

        activeMapping: cameraStatusMapping,
        netWorkingMapping,
        uploadModeMapping,
        wifiStatusMapping,
        postTypeMapping,
        cameraServiceMapping,

        getDataFromLocalStorage,

        versionsRows,

        calculatePercentageColor,
        minValueApproved,
        maxValueApproved,
        minValueVerification,
        maxValueVerification,
        minValueCancelled,
        maxValueCancelled,
        minValuePotential,
        maxValuePotential,
        minValuePotentialRate,
        maxValuePotentialRate,
        minValueApprovalRate,
        maxValueApprovalRate,
        minValueCancellationRate,
        maxValueCancellationRate,
        minValueAvgDailyPhotosTaken,
        maxValueAvgDailyPhotosTaken,

        sumOfFields,
        avgPotentialRate,
        avgApprovalRate,
        avgCancellationRate,

        debouncedValueSearchNote,
        handleGetListNote,
        handleAddOrUpdateNote,
        handleDeleteNote,
        listNotes,
        mode,
        setMode,
        isLoading,
        filterNote,
        setFilterNote,
        setIdCamera,
        idCamera,
        setIdNote,
        idNote,
        setIsLoading,
        setListNotes,
        fromCurrentWeek,
        toCurrentWeek,
        setSearchValue,
        searchValue,
        filteredNameCamera,

        popUpNoteInCamera,
        popUpAddOrUpdateNoteInCamera,
        popUpConfirmDeleteNoteHistory,

        handlePhotoUploadTimes,
        photoUploadSchedule,
        filterByUploadModeAndStatus,
    };
}

const versionsRows: number = 14;

export const DEFAULT_DATA_CHART_ROWS: TableRowContent[] = [
    {
        id: 'Deployment date',
        version: versionsRows,
        type: 'number',
        label: 'Deployment date',
    },
    {
        id: 'Avg. photos',
        version: versionsRows,
        type: 'datee',
        label: 'Photos',
    },
    {
        id: 'Potential contraventions',
        version: versionsRows,
        type: 'string',
        label: 'Potential',
    },
    {
        id: 'Contraventions in verification',
        version: versionsRows,
        type: 'datee',
        label: 'Verification',
    },
    {
        id: 'Contraventions approved',
        version: versionsRows,
        type: 'number',
        label: 'Approved',
    },
    {
        id: 'Contraventions cancelled',
        version: versionsRows,
        type: 'number',
        label: 'Cancelled',
    },
    {
        id: 'Potential rate',
        version: versionsRows,
        type: 'new',
        label: 'Potential rate',
    },
    {
        id: 'Approval rate',
        version: versionsRows,
        type: 'new',
        label: 'Approval rate',
    },
    {
        id: 'Cancellation rate',
        version: versionsRows,
        type: 'new',
        label: 'Cancellation rate',
    },
    {
        id: 'Camera version',
        version: versionsRows,
        type: 'date123',
        label: 'Camera version',
    },
    {
        id: 'Operational status',
        version: versionsRows,
        type: 'number',
        label: 'Status',
    },
    {
        id: 'Avg. uptime (h)',
        version: versionsRows,
        type: 'date',
        label: 'Uptime (h)',
    },
];

const sortedNumberFieldCustom = DEFAULT_DATA_CHART_ROWS.map((item, index) => ({
    ...item,
    number: index + 1,
}));

type CameraConfigContextType = ReturnType<typeof useCameraConfigProvider>;

export const CameraConfigContext = createContext<CameraConfigContextType>({} as CameraConfigContextType);

export const useCameraConfig = () => useContext(CameraConfigContext);
