import { useMsal } from '@azure/msal-react';
import { DrawerContext, DrawerPage } from '@components/drawer/useDrawer';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
type Props = {
    reportId: string;
    activeItem: DrawerPage;
};
export default function useEmbedReport(props: Props) {
    const { instance, accounts } = useMsal();
    const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
    const [embedUrl, setEmbedUrl] = useState('');
    const [hasPermission, setHasPermission] = useState(true);
    const { setActiveItem } = useContext(DrawerContext);

    useEffect(() => {
        const getTokenAndReport = async () => {
            setHasPermission(true);
            setActiveItem(props.activeItem);
            try {
                const accessTokenRequest = {
                    scopes: ['https://analysis.windows.net/powerbi/api/.default'],
                    account: accounts[0],
                };

                const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
                const _accessToken = accessTokenResponse.accessToken;
                setAccessToken(_accessToken);

                const reportResponse = await axios.get(`https://api.powerbi.com/v1.0/myorg/reports/${props.reportId}`, {
                    headers: {
                        Authorization: 'Bearer ' + _accessToken,
                    },
                });
                const _embedUrl = reportResponse.data.embedUrl;
                setEmbedUrl(_embedUrl);
            } catch (error: any) {
                console.error(`error`, error);
                setHasPermission(false);
                return Promise.reject(error);
            }
        };
        getTokenAndReport();
    }, []);

    return { accessToken, embedUrl, hasPermission };
}
