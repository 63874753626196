import IcEdit from '@assets/details-camera-icons/IcEdit';
import LoadingTextSkeleton from '@components/skeleton/LoadingTextSkeleton';
import { BaseHead } from '@components/tables';
import {
    FanWorkingMode,
    FocusMode,
    ManualSpeed,
    MinimumFocusDistance,
    OnvifPresetMode,
    PTZBasicEnable,
    PTZBasicStatus,
    PTZOsdStatus,
    ResumeTime,
} from '@EcamModel/model/PTZBasic';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Box,
    Collapse,
    Divider,
    Fade,
    IconButton,
    Stack,
    Table,
    TableCellProps,
    TableContainer,
    TableHead,
    Tooltip,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useCameraDetailContext } from '@pages/cameras/hook/CameraDetailsProvider';
import useMileSightPTZ, { SectionsCollapse } from '@pages/cameras/hook/useMilesightPTZ';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';
import color from '@theme/Colors';
import { ReactNode, useEffect } from 'react';

const operationStatusMapping: { [key in PTZOsdStatus]: string } = {
    [PTZOsdStatus.AlwaysClose]: 'Always Close',
    [PTZOsdStatus.AlwaysOpen]: 'Always Open',
    [PTZOsdStatus.TwoSeconds]: '2s',
    [PTZOsdStatus.FiveSeconds]: '5s',
    [PTZOsdStatus.TenSeconds]: '10s',
};

const binaryStatusMapping: { [key in PTZBasicStatus]: string } = {
    [PTZBasicStatus.AlwaysClose]: 'Always Close',
    [PTZBasicStatus.AlwaysOpen]: 'Always Open',
};

const presetFreezingMapping: { [key in PTZBasicEnable]: string } = {
    [PTZBasicEnable.No]: 'No',
    [PTZBasicEnable.Yes]: 'Yes',
};

const manualSpeedMapping: { [key in ManualSpeed]: string } = {
    [ManualSpeed.Low]: 'Low',
    [ManualSpeed.Medium]: 'Medium',
    [ManualSpeed.High]: 'High',
};

const focusModeMapping: { [key in FocusMode]: string } = {
    [FocusMode.Auto]: 'Auto',
    [FocusMode.SemiAuto]: 'Semi-Auto',
    [FocusMode.Manual]: 'Manual',
};

const focusDistanceMapping: { [key in MinimumFocusDistance]: string } = {
    [MinimumFocusDistance.OneMeter]: '1m',
    [MinimumFocusDistance.OnePointFiveMeter]: '1.5m',
    [MinimumFocusDistance.ThreeMeter]: '3m',
    [MinimumFocusDistance.SixMeter]: '6m',
    [MinimumFocusDistance.TenMeter]: '10m',
    [MinimumFocusDistance.TwentyMeter]: '20m',
};

const fanWorkingModeMapping: { [key in FanWorkingMode]: string } = {
    [FanWorkingMode.Genernal]: 'General',
    [FanWorkingMode.Enhancement]: 'Enhancement',
    [FanWorkingMode.Constant]: 'Constant',
    [FanWorkingMode.Off]: 'Off',
};

const onvifPresetModeMapping: { [key in OnvifPresetMode]: string } = {
    [OnvifPresetMode.ValidList]: 'Normal',
    [OnvifPresetMode.FullList]: 'Compatible',
};

const timeIntervalMapping: { [key in ResumeTime]: string } = {
    [ResumeTime.Disable]: 'Disable',
    [ResumeTime.ThirtySeconds]: '30 seconds',
    [ResumeTime.SixtySeconds]: '60 seconds',
    [ResumeTime.ThreeHundredSeconds]: '300 seconds',
    [ResumeTime.SixHundredSeconds]: '600 seconds',
};

type Props = {
    isOpenCollapse: { [key in SectionsCollapse]: boolean };
    handleToggleCollapse: (section: SectionsCollapse) => void;
};

export default function BasicInfoMilesightCameraPTZ(props: Props) {
    const handleEdit = () => {
        console.log('Edit button clicked');
    };
    const { camera, filteredCamera } = useCameraDetailContext();
    const { isOpenCollapse, handleToggleCollapse } = props;

    const { basicInfoMilesightsPTZ, isLoadingMilesights, getBasicInfoMilesightsPTZ } = useMileSightPTZ();

    useEffect(() => {
        if (camera?.VendorCameraId === null) {
            getBasicInfoMilesightsPTZ(filteredCamera!);
        }
    }, [filteredCamera]);

    const sectionsBasicInformation: SectionHasSubItemProps[] = [
        {
            id: 'preset',
            title: 'Preset',
            data: [
                {
                    label: 'Preset freezing:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={presetFreezingMapping[basicInfoMilesightsPTZ.PresetFreezing]}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
                {
                    label: 'Onvif Preset compatible settings:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={onvifPresetModeMapping[basicInfoMilesightsPTZ.OnvifPresetMode]}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
            ],
        },
        {
            id: 'speed',
            title: 'Speed',
            data: [
                {
                    label: 'Preset Speed:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={basicInfoMilesightsPTZ.PresetSpeed}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
                {
                    label: 'Manual Speed:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={manualSpeedMapping[basicInfoMilesightsPTZ.ManualSpeed]}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
            ],
        },
        {
            id: 'patrol',
            title: 'Patrol',
            data: [
                {
                    label: 'Patrol Recovering:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={basicInfoMilesightsPTZ.PatrolRecovering ? 'Yes' : 'No'}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
                {
                    label: 'Patrol Recovery Time:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={`${basicInfoMilesightsPTZ.PatrolRecoveryTime}s (5-720s)`}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
            ],
        },
        {
            id: 'focus',
            title: 'Focus',
            data: [
                {
                    label: 'Focus Mode:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={focusModeMapping[basicInfoMilesightsPTZ.FocusMode]}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
                {
                    label: 'Minimum Focus Distance:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={focusDistanceMapping[basicInfoMilesightsPTZ.FocusDistance]}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
            ],
        },
    ];

    const statusList_PTZOSD: DataSectionHasSubItem[] = [
        {
            label: 'Zoom Status:',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                    value={operationStatusMapping[basicInfoMilesightsPTZ.ZoomStatus]}
                    style={{
                        width: '50%',
                    }}
                />
            ),
        },
        {
            label: 'Pan & Tilt Status:',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                    value={operationStatusMapping[basicInfoMilesightsPTZ.TiltStatus]}
                    style={{
                        width: '50%',
                    }}
                />
            ),
        },
        {
            label: 'Preset Status:',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                    value={operationStatusMapping[basicInfoMilesightsPTZ.PresetStatus]}
                    style={{
                        width: '50%',
                    }}
                />
            ),
        },
        {
            label: 'Patrol Status:',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                    value={binaryStatusMapping[basicInfoMilesightsPTZ.PatrolStatus]}
                    style={{
                        width: '50%',
                    }}
                />
            ),
        },
        {
            label: 'Pattern Status:',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                    value={binaryStatusMapping[basicInfoMilesightsPTZ.PatternStatus]}
                    style={{
                        width: '50%',
                    }}
                />
            ),
        },
        {
            label: 'Auto Scan Status:',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                    value={binaryStatusMapping[basicInfoMilesightsPTZ.AutoscanStatus]}
                    style={{
                        width: '50%',
                    }}
                />
            ),
        },
    ];

    const sectionsOthers: SectionHasSubItemProps[] = [
        {
            id: 'power-off-memory',
            title: 'Power Off Memory',
            data: [
                {
                    label: 'Set Resume Time:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={timeIntervalMapping[basicInfoMilesightsPTZ.ResumeTime]}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
            ],
        },
        {
            id: 'dehumidifying',
            title: 'Dehumidifying',
            data: [
                {
                    label: 'Fan Working Mode:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.basicInfoMilesightsPTZ}
                            value={fanWorkingModeMapping[basicInfoMilesightsPTZ.FanWorkingMode]}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
            ],
        },
    ];
    console.log('🚀 ~ BasicInfoMilesightCameraPTZ ~ isOpenCollapse.PTZ_OSD:', isOpenCollapse.PTZ_OSD);

    return (
        <Stack id="basic" border={'1px solid #E4E7EC'} borderRadius={2} flex={1}>
            {/* Basic Information */}
            <SectionHasSubItemHeader id="" title="Basic Information" onEdit={handleEdit} />

            {/* Basic */}
            <BaseSectionCollapse
                isOpen={isOpenCollapse.basic}
                onToggle={() => {
                    handleToggleCollapse(SectionsCollapse.Basic);
                }}
                sections={sectionsBasicInformation}
                title="Basic"
                id="basic"
            />

            {/* PTZ OSD */}
            <SectionNotSubItem
                name="PTZ_OSD"
                header="PTZ OSD"
                statusList={statusList_PTZOSD}
                isOpen={isOpenCollapse.PTZ_OSD}
                onToggleCollapse={() => {
                    handleToggleCollapse(SectionsCollapse.PTZ_OSD);
                }}
            />

            {/* Others */}
            <BaseSectionCollapse
                isOpen={isOpenCollapse.others}
                onToggle={() => {
                    handleToggleCollapse(SectionsCollapse.Others);
                }}
                sections={sectionsOthers}
                title="Others"
                id="others"
            />
        </Stack>
    );
}

export interface TableHeaderStyle {
    label: string;
    align?: TableCellProps['align'];
}

export interface SectionNotSubItemProps<T = any> {
    name?: string;
    header?: string;
    statusList?: DataSectionHasSubItem[];
    tableHeaders?: TableHeaderStyle[];
    tableBody?: ReactNode;
    isOpen?: boolean;
    items?: T[];
    onToggleCollapse?: () => void;
}

export const SectionNotSubItemStatus: React.FC<DataSectionHasSubItem> = ({ label, value }) => (
    <Grid xs={12} container>
        <Grid xs={5}>
            <Typography>{label}</Typography>
        </Grid>
        <Grid xs={7}>{value}</Grid>
    </Grid>
);

export const SectionNotSubItem: React.FC<SectionNotSubItemProps> = ({
    name,
    header,
    statusList,
    tableHeaders,
    tableBody,
    isOpen = false,
    onToggleCollapse,
    items,
}) => {
    const hasTableBody = Boolean(tableBody);
    const hasItems = Boolean(items?.length! > 0);
    return (
        <Stack id={name} border={'1px solid #E4E7EC'} borderRadius={2} flex={1} margin={'0px 16px 16px 16px'}>
            <Stack
                paddingX={2}
                paddingY={1}
                direction="row"
                justifyContent={'space-between'}
                alignItems="center"
                onClick={onToggleCollapse}
                sx={{
                    cursor: 'pointer',
                }}
            >
                <Typography fontSize={16}>{header}</Typography>
                {isOpen ? <ExpandLess sx={{ width: 16 }} /> : <ExpandMore sx={{ width: 16 }} />}
            </Stack>

            <Collapse in={isOpen}>
                <Divider
                    orientation="horizontal"
                    sx={{
                        borderBottomWidth: 1,
                        borderColor: '#E4E7EC',
                        mx: 2,
                    }}
                />
                <Stack spacing={2} padding={1} borderRadius={1} margin={1}>
                    {hasTableBody ? (
                        hasItems ? (
                            <TableContainer component={Box}>
                                <Table>
                                    <TableHead>
                                        {tableHeaders?.map((t, i) => (
                                            <BaseHead key={i} align={t.align ?? 'left'} title={t.label} />
                                        ))}
                                    </TableHead>
                                    {tableBody}
                                </Table>
                            </TableContainer>
                        ) : (
                            <ListNoData />
                        )
                    ) : (
                        <>
                            {statusList?.map((status, index) => (
                                <SectionNotSubItemStatus key={index} label={status.label} value={status.value} />
                            ))}
                        </>
                    )}
                </Stack>
            </Collapse>
        </Stack>
    );
};

export interface SectionHasSubItemHeaderProps {
    id: string;
    title: string;
    onEdit: () => void;
}

export interface DataSectionHasSubItem {
    label: string;
    value: ReactNode;
}

export interface SectionHasSubItemProps {
    id?: string;
    title: string;
    data?: DataSectionHasSubItem[];
    tableBody?: React.ReactNode;
    tableHeaders?: string[];
    items?: any[];
}

export const SectionHasSubItemHeader: React.FC<SectionHasSubItemHeaderProps> = ({ title, onEdit, id }) => (
    <Stack id={id} paddingX={2} paddingY={1} direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontSize={16} variant="h5">
            {title}
        </Typography>
        {/* <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
            <Tooltip title={'Edit'} placement="top" arrow>
                <IconButton
                    sx={{
                        borderRadius: '3px',
                        width: '24px',
                        height: '24px',
                        color: '#FFFFFF',
                        backgroundColor: '#2E7D32',
                        ':hover': {
                            backgroundColor: '#256428',
                        },
                        padding: '4px',
                        '&.MuiIconButton-root.Mui-disabled': {
                            backgroundColor: '#eee',
                        },
                        cursor: 'pointer',
                    }}
                    onClick={onEdit}
                >
                    <IcEdit style={{ width: '14px' }} />
                </IconButton>
            </Tooltip>
        </Stack> */}
    </Stack>
);

export const SectionHasSubItem: React.FC<SectionHasSubItemProps> = ({
    id,
    title,
    data,
    tableBody,
    tableHeaders,
    items,
}) => {
    const hasTableBody = Boolean(tableBody);
    const hasItems = Boolean(items?.length! > 0);
    const hasData = Boolean(data?.length! > 0);

    return (
        <Grid id={id} xs={12} container>
            <Grid xs={12} mb={1}>
                <Typography variant="body1" color={'#009D4F'}>
                    {title}
                </Typography>
            </Grid>
            <Stack width="100%" paddingX={2} paddingY={1} bgcolor={color.white} gap={3}>
                {hasTableBody ? (
                    hasItems ? (
                        <TableContainer component={Box}>
                            <Table>
                                <TableHead>
                                    {tableHeaders?.map((t, i) => (
                                        <BaseHead key={i} align="left" title={t} />
                                    ))}
                                </TableHead>
                                {tableBody}
                            </Table>
                        </TableContainer>
                    ) : (
                        <ListNoData />
                    )
                ) : (
                    hasData &&
                    (data as DataSectionHasSubItem[]).map((item, index) => (
                        <SectionNotSubItemStatus key={index} label={item.label} value={item.value} />
                    ))
                )}
            </Stack>
        </Grid>
    );
};

type BaseSectionProps = {
    id?: string;
    title: string;
    isOpen: boolean;
    sections?: SectionHasSubItemProps[];
    onToggle: () => void;
    diffContentBody?: ReactNode;
};

export const BaseSectionCollapse: React.FC<BaseSectionProps> = ({
    id,
    title,
    isOpen,
    sections,
    diffContentBody,
    onToggle,
}) => {
    return (
        <Stack id={id} border={'1px solid #E4E7EC'} borderRadius={2} flex={1} margin={'0px 16px 16px 16px'}>
            <Stack
                paddingX={2}
                paddingY={1}
                direction="row"
                justifyContent={'space-between'}
                onClick={onToggle}
                sx={{
                    cursor: 'pointer',
                }}
            >
                <Typography fontSize={16}>{title}</Typography>
                {isOpen ? <ExpandLess sx={{ width: 16 }} /> : <ExpandMore sx={{ width: 16 }} />}
            </Stack>

            {isOpen && (
                <Fade in={isOpen}>
                    <Divider
                        orientation="vertical"
                        sx={{
                            borderBottomWidth: 1,
                            borderColor: '#E4E7EC',
                            mx: 2,
                        }}
                    />
                </Fade>
            )}

            <Collapse in={isOpen}>
                {diffContentBody ? (
                    <>{diffContentBody}</>
                ) : (
                    <>
                        {sections?.map((section) => {
                            return (
                                <Stack spacing={2} bgcolor={color.grey100} padding={2} borderRadius={1} margin={1}>
                                    <SectionHasSubItem
                                        key={section.id}
                                        id={section.id}
                                        title={section.title}
                                        data={section.data}
                                        tableBody={section.tableBody}
                                        tableHeaders={section.tableHeaders}
                                        items={section.items}
                                    />
                                </Stack>
                            );
                        })}
                    </>
                )}
            </Collapse>
        </Stack>
    );
};
