import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;
export default function DisconnectedIcon(props: Props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M3.53335 9.51675C3.33335 9.31675 3.23891 9.07786 3.25002 8.80008C3.26113 8.5223 3.37224 8.30564 3.58335 8.15008C4.19446 7.68342 4.87513 7.31964 5.62535 7.05875C6.37558 6.79786 7.16713 6.66719 8.00002 6.66675C8.84446 6.66675 9.6418 6.80008 10.392 7.06675C11.1422 7.33342 11.8227 7.70564 12.4334 8.18342C12.6445 8.33897 12.7529 8.55297 12.7587 8.82542C12.7645 9.09786 12.6671 9.33386 12.4667 9.53342C12.2778 9.7223 12.0471 9.81964 11.7747 9.82542C11.5022 9.83119 11.2496 9.75608 11.0167 9.60008C10.5834 9.30008 10.114 9.06942 9.60869 8.90808C9.10335 8.74675 8.56713 8.6663 8.00002 8.66675C7.43335 8.66675 6.89713 8.75008 6.39135 8.91675C5.88558 9.08342 5.4218 9.31119 5.00002 9.60008C4.75558 9.76675 4.49713 9.84186 4.22469 9.82542C3.95224 9.80897 3.7218 9.70608 3.53335 9.51675ZM0.70002 6.70008C0.511131 6.51119 0.416687 6.28053 0.416687 6.00808C0.416687 5.73564 0.516687 5.51075 0.716687 5.33342C1.70558 4.50008 2.81669 3.84719 4.05002 3.37475C5.28335 2.9023 6.60002 2.6663 8.00002 2.66675C9.40002 2.66675 10.7167 2.90297 11.95 3.37542C13.1834 3.84786 14.2945 4.50053 15.2834 5.33342C15.4834 5.51119 15.5862 5.73342 15.592 6.00008C15.5978 6.26675 15.5005 6.50008 15.3 6.70008C15.1111 6.88897 14.8807 6.98897 14.6087 7.00008C14.3367 7.01119 14.0894 6.92786 13.8667 6.75008C13.0556 6.09453 12.1527 5.58342 11.158 5.21675C10.1634 4.85008 9.11069 4.66675 8.00002 4.66675C6.88891 4.66675 5.83624 4.85008 4.84202 5.21675C3.8478 5.58342 2.94491 6.09453 2.13335 6.75008C1.91113 6.92786 1.6638 7.01119 1.39135 7.00008C1.11891 6.98897 0.888465 6.88897 0.70002 6.70008ZM8.00002 13.3334C7.62224 13.3334 7.30558 13.2056 7.05002 12.9501C6.79446 12.6945 6.66669 12.3779 6.66669 12.0001C6.66669 11.6223 6.79446 11.3056 7.05002 11.0501C7.30558 10.7945 7.62224 10.6667 8.00002 10.6667C8.3778 10.6667 8.69446 10.7945 8.95002 11.0501C9.20558 11.3056 9.33335 11.6223 9.33335 12.0001C9.33335 12.3779 9.20558 12.6945 8.95002 12.9501C8.69446 13.2056 8.3778 13.3334 8.00002 13.3334Z"
                fill="#E01B00"
            />
            <path d="M3.99994 2L11.9999 13" stroke="#E01B00" />
        </svg>
    );
}
