/* eslint-disable react-hooks/exhaustive-deps */
import { ICameraDailyWorkedHour } from '@EcamModel/controllers/ICameraOverviewHttpController';
import { CameraServiceType, Status, WifiStatus } from '@EcamModel/model';
import { ConsolidateCameraMonitoring } from '@EcamModel/model/CameraOverview';
import PopUpWarning from '@components/PopUpWarning';
import { pushError } from '@components/toast';
import { appConfig } from '@configs/index';
import { cameraOverviewHttpController } from '@controllers/index';
import { IPopUp } from '@hooks/usePopUp';
import { Autorenew } from '@mui/icons-material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
    Badge,
    Box,
    IconButton,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { checkCellularEnum } from '@pages/cameras/details/components/CellularMilesightCameraSolar';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';
import ConnectedIcon from '@pages/cameras/list/components/icons/ConnectedIcon';
import DisconnectedIcon from '@pages/cameras/list/components/icons/DisconnectedIcon';
import { getValueFromMapping } from '@pages/components';
import color from '@theme/Colors';
import { ReactNode } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Link } from 'react-router-dom';
import {
    endOfYesterday,
    formatDateByTimeZone,
    formatLocaleTime,
    formatMinutesToDurationString,
    formatNumber,
    formatTruncateDecimal,
    startOfYesterday,
} from 'src/helpers';
import { useCameraConfig } from '../hook/CameraConfigProvider';
import PopUpAddOrUpdateNoteInCamera from './PopUpAddOrUpdateNoteInCamera';
import PopUpNoteInCamera from './PopUpNoteInCamera';
import { IoEyeOutline } from 'react-icons/io5';

type Props = IPopUp & {
    filteredServices: ConsolidateCameraMonitoring[];
    popUpAvgWorkedHoursOfCamera: {
        setTrue: () => void;
        open: boolean;
        onClose: () => void;
        onConfirm: (() => void) | undefined;
    };
};

export default function ListCameraConfig(props: Props) {
    const { popUpAvgWorkedHoursOfCamera } = props;

    const cameraConfigContext = useCameraConfig();
    const {
        filter,
        sorting,
        sortOrder,
        containerRef,
        setCameraOverview,
        cameraConfigMonitoring,
        CheckRowDataToShow,
        handleSortChange,
        excludedColumns,
        tableRow,
        orderBy,
        setCameraTimeInfo,
        setLoadingDailyWorkedHours,
        setCameraDailyWorkedHour,

        activeMapping,
        netWorkingMapping,
        uploadModeMapping,
        wifiStatusMapping,
        postTypeMapping,
        cameraServiceMapping,

        calculatePercentageColor,
        minValueApproved,
        maxValueApproved,
        minValueVerification,
        maxValueVerification,
        minValueCancelled,
        maxValueCancelled,
        minValuePotential,
        maxValuePotential,
        minValuePotentialRate,
        maxValuePotentialRate,
        minValueApprovalRate,
        maxValueApprovalRate,
        minValueCancellationRate,
        maxValueCancellationRate,
        minValueAvgDailyPhotosTaken,
        maxValueAvgDailyPhotosTaken,

        filteredServices,
        sumOfFields,
        avgPotentialRate,
        avgApprovalRate,
        avgCancellationRate,
        setIdNote,
        idNote,
        idCamera,
        setIdCamera,
        handleDeleteNote,

        popUpNoteInCamera,
        popUpAddOrUpdateNoteInCamera,
        popUpConfirmDeleteNoteHistory,
    } = cameraConfigContext;

    console.log('tableRow', tableRow);

    const getCameraDailyWorkedHour = async (cameraId: number) => {
        let req: ICameraDailyWorkedHour = {
            CameraId: cameraId,
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        setLoadingDailyWorkedHours(true);
        await cameraOverviewHttpController
            .cameraDailyWorkedHour(req)
            .then((res) => {
                setCameraDailyWorkedHour(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    const PercentageRate = (props: {
        valueRate: number;
        min: number;
        max: number;
        CameraService: CameraServiceType;
        isCheckRate?: boolean;
    }) => {
        return (
            <Tooltip
                title={props.isCheckRate ? `${Number(formatTruncateDecimal(props.valueRate, 2))}%` : ''}
                placement="top"
            >
                <Box
                    sx={{
                        padding: '4px 6px !important',
                        border: 'none !important',
                        alignItems: 'center',
                        borderRadius: '4px',
                        width: '100%',
                        display: 'inline-block',
                        backgroundColor: calculatePercentageColor(
                            props.valueRate,
                            props.min,
                            props.max,
                            props.CameraService
                        ),
                    }}
                >
                    <Typography
                        component={'span'}
                        sx={{
                            fontSize: 13,
                        }}
                    >
                        {props.isCheckRate
                            ? Number(formatTruncateDecimal(props.valueRate, 2)) ?? 'N/A'
                            : formatNumber(Number(formatTruncateDecimal(props.valueRate, 2))) ?? 'N/A'}
                    </Typography>
                </Box>
            </Tooltip>
        );
    };

    const BackgroundColorAbsolute = (props: {
        value: ReactNode;
        conditionBgColor: string;
        isCheckOnClick?: boolean;
        CameraId?: number;
        CameraName?: string;
    }) => {
        return (
            <Box
                sx={{
                    position: 'relative',
                    maxWidth: '100%',
                    padding: '4px 6px !important',
                    border: 'none !important',
                    alignItems: 'center',
                    borderRadius: '4px',
                    width: '100%',
                    cursor: props.isCheckOnClick ? 'pointer' : 'default',
                    display: 'inline-block',
                }}
            >
                <Box
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: '17%',
                        backgroundColor: props.conditionBgColor,
                        borderRadius: '4px',
                    }}
                ></Box>
                <Typography
                    onClick={() => {
                        if (props.isCheckOnClick) {
                            getCameraDailyWorkedHour(props.CameraId || 0);
                            setCameraOverview({
                                cameraName: props.CameraName!,
                                cameraId: props.CameraId!,
                            });
                            popUpAvgWorkedHoursOfCamera.setTrue();
                        }
                    }}
                    sx={{
                        fontSize: 13,
                        position: 'relative',
                        zIndex: 1,
                    }}
                >
                    {props.value}
                </Typography>
            </Box>
        );
    };
    const getBgColorFirmware = (firmware: string, setTimeAutomatically: number) => {
        return appConfig.apps.firmTesting === firmware
            ? color.success
            : appConfig.apps.firmVersion !== firmware
            ? color.danger
            : setTimeAutomatically === 1
            ? ''
            : '';
    };

    const renderColumnData = (
        columnId: string,
        rowData: ConsolidateCameraMonitoring,
        index: number
    ): React.ReactNode => {
        console.log(`rowData`, rowData);
        switch (columnId) {
            case 'No.':
                return (
                    <Box
                        key={columnId}
                        sx={{
                            padding: '0px 3px !important',
                            backgroundColor: '#FAFAFA',
                            maxWidth: '100%',
                            position: 'sticky',
                            zIndex: 2,
                            left: 0,
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                            }}
                        >
                            {index + 1}
                        </Typography>
                    </Box>
                );
            case 'Camera':
                return (
                    <Box
                        key={columnId}
                        sx={{
                            padding: '0px 3px !important',
                            backgroundColor: '#FAFAFA',
                            maxWidth: '100%',
                            position: 'sticky',
                            zIndex: 2,
                            left: 0,
                            alignItems: 'center',
                        }}
                    >
                        <Link
                            to={`/locations/${rowData.LocationId}/cameras/${rowData.CameraId}`}
                            style={{
                                textDecoration: 'none',
                            }}
                            target="_blank"
                        >
                            <Typography sx={styledRowTypo}>{rowData.CameraName}</Typography>
                        </Link>
                    </Box>
                );

            case 'Deployment date':
                return (
                    <Box
                        key={columnId}
                        sx={{
                            maxWidth: '100%',
                            padding: '4px 6px !important',
                            border: 'none !important',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 13,
                            }}
                        >
                            {rowData.CameraDeployDate.toString()}
                        </Typography>
                    </Box>
                );
            case 'Contraventions in verification':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValueVerification}
                        max={maxValueVerification}
                        valueRate={rowData.InprogressContraventions}
                    />
                );
            case 'Contraventions approved':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValueApproved}
                        max={maxValueApproved}
                        valueRate={rowData.ApprovedContraventions}
                    />
                );
            case 'Camera version':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            padding: '4px 6px !important',
                            border: 'none !important',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                letterSpacing: 1,
                                fontSize: 13,
                            }}
                        >
                            {rowData.CameraVersion}
                        </Typography>
                    </Box>
                );
            case 'Avg. photos':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValueAvgDailyPhotosTaken}
                        max={maxValueAvgDailyPhotosTaken}
                        valueRate={rowData.AvgDailyPhotosTaken}
                    />
                );
            case 'Avg. uptime (h)':
                return (
                    <BackgroundColorAbsolute
                        conditionBgColor={
                            rowData.AvgDailyWorkedHours <= 8
                                ? color.danger
                                : rowData.AvgDailyWorkedHours <= 15
                                ? color.gold600
                                : color.success
                        }
                        value={rowData.AvgDailyWorkedHours ?? 'N/A'}
                        isCheckOnClick
                        CameraId={rowData.CameraId}
                        CameraName={rowData.CameraName}
                    />
                );
            case 'Camera time':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component={'span'}>
                            {rowData.CameraTime !== null ? formatLocaleTime(rowData.CameraTime) : 'N/A'}
                        </Typography>
                    </Box>
                );
            case 'Auto time':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            component={'span'}
                            sx={{
                                backgroundColor:
                                    rowData.SetTimeAutomatically === 0
                                        ? color.danger
                                        : rowData.SetTimeAutomatically === 1
                                        ? ''
                                        : '',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                maxWidth: '115px',
                                width: '100%',
                                display: 'inline-block',
                                color:
                                    rowData.SetTimeAutomatically === 1
                                        ? color.textPrimary
                                        : rowData.SetTimeAutomatically == 0
                                        ? color.white
                                        : color.textPrimary,
                                cursor: 'pointer',
                            }}
                        >
                            {rowData.SetTimeAutomatically == 1
                                ? 'true'
                                : rowData.SetTimeAutomatically == 0
                                ? 'false'
                                : 'N/A'}
                        </Typography>
                    </Box>
                );
            case 'Contraventions cancelled':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValueCancelled}
                        max={maxValueCancelled}
                        valueRate={rowData.CancelledContraventions}
                    />
                );
            case 'Potential contraventions':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValuePotential}
                        max={maxValuePotential}
                        valueRate={rowData.PotentialContraventions}
                    />
                );
            case 'Dev Mac':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            padding: '4px 6px !important',
                            border: 'none !important',
                            alignItems: 'center',
                        }}
                    >
                        <Typography>{rowData.DevMac ?? 'N/A'}</Typography>
                    </Box>
                );
            case 'Operational status':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            padding: '4px 6px !important',
                            border: 'none !important',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component={'span'} fontSize={13} sx={{ letterSpacing: 1 }}>
                            {getValueFromMapping(rowData.CameraStatus, activeMapping)}
                        </Typography>
                    </Box>
                );
            case 'Server time':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>
                            {rowData.ServerTime !== null ? formatLocaleTime(rowData.ServerTime) : 'N/A'}
                        </Typography>
                    </Box>
                );
            case 'Time difference':
                return (
                    <BackgroundColorAbsolute
                        conditionBgColor={Math.abs(rowData.DifferentMinute) > 4 ? color.danger : ''}
                        value={
                            rowData.DifferentMinute !== null
                                ? formatMinutesToDurationString(rowData.DifferentMinute)
                                : 'N/A'
                        }
                    />
                );
            case 'Zone':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography
                            component={'span'}
                            sx={{
                                backgroundColor:
                                    rowData.ZoneName == 'USA' ? color.danger : rowData.ZoneName == 'GRB' ? '' : '',
                                padding: '2px 4px',
                                borderRadius: '4px',
                                maxWidth: '115px',
                                width: '100%',
                                display: 'inline-block',
                                color:
                                    rowData.ZoneName === 'GBR'
                                        ? color.textPrimary
                                        : rowData.ZoneName === 'USA'
                                        ? color.white
                                        : '',
                                cursor: 'pointer',
                            }}
                        >
                            {rowData.ZoneName || 'N/A'}
                        </Typography>
                    </Box>
                );
            case 'Capture interval':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>{rowData.CaptureInterval ?? 'N/A'}</Typography>
                    </Box>
                );
            case 'Firmware':
                return (
                    <BackgroundColorAbsolute
                        conditionBgColor={getBgColorFirmware(rowData.Firmware, rowData.SetTimeAutomatically)}
                        value={rowData.Firmware || 'N/A'}
                    />
                );
            case 'Network mode 4G/3G/2G':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>
                            {getValueFromMapping(rowData.NetworkType, netWorkingMapping)}
                        </Typography>
                    </Box>
                );
            case 'Last contacted':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>
                            {rowData.LastContact !== null ? formatLocaleTime(rowData.LastContact) : 'N/A'}
                        </Typography>
                    </Box>
                );
            case 'APN':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>{rowData.Apn ?? 'N/A'}</Typography>
                    </Box>
                );
            case 'ICCID':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography>{rowData.Iccid ?? 'N/A'}</Typography>
                    </Box>
                );
            case 'Upload mode':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography>{getValueFromMapping(rowData.UploadMode, uploadModeMapping)}</Typography>
                    </Box>
                );
            case 'Signal strength':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>
                            {checkCellularEnum({ signalStrength: rowData.SignalStrength }).checkSignalStrength}
                        </Typography>
                    </Box>
                );
            case 'Potential rate':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValuePotentialRate}
                        max={maxValuePotentialRate}
                        valueRate={rowData.PotentialRate}
                        isCheckRate
                    />
                );
            case 'Approval rate':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValueApprovalRate}
                        max={maxValueApprovalRate}
                        valueRate={rowData.ApprovalRate}
                        isCheckRate
                    />
                );
            case 'Cancellation rate':
                return (
                    <PercentageRate
                        CameraService={rowData.CameraService}
                        min={minValueCancellationRate}
                        max={maxValueCancellationRate}
                        valueRate={rowData.CancellationRate}
                        isCheckRate
                    />
                );
            case 'Sim status':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>{rowData.SimStatus ?? 'N/A'}</Typography>
                    </Box>
                );
            case 'Connect status':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>{rowData.ConnectStatus ?? 'N/A'}</Typography>
                    </Box>
                );
            case 'Network status':
                return (
                    <Tooltip
                        placement="top"
                        title={rowData.LastWifiStatus === WifiStatus.Connected ? 'Connected' : 'Not available'}
                    >
                        <Box
                            sx={{
                                maxWidth: '100%',
                                border: 'none !important',
                                cursor: 'pointer',
                            }}
                        >
                            {rowData.LastWifiStatus === WifiStatus.Connected ? (
                                <ConnectedIcon width={21} height={21} />
                            ) : (
                                <DisconnectedIcon width={21} height={21} />
                            )}
                        </Box>
                    </Tooltip>
                );
            case 'Lastest ping':
                return (
                    <>
                        {rowData.Pingable !== null ? (
                            <BackgroundColorAbsolute
                                conditionBgColor={!rowData.Pingable ? color.danger : color.success}
                                value={rowData.Pingable ? 'Can ping' : "Can't ping"}
                            />
                        ) : (
                            'N/A'
                        )}
                    </>
                );
            case 'Type service':
                return (
                    <BackgroundColorAbsolute
                        conditionBgColor={rowData.CameraService === CameraServiceType.ECamTechOps ? color.success : ''}
                        value={getValueFromMapping(rowData.CameraService, cameraServiceMapping)}
                    />
                );
            case 'Gen PCNs':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        {rowData.IsVerificationsEnabled !== null ? (
                            <Typography
                                component={'span'}
                                sx={{
                                    fontSize: 13,
                                }}
                            >
                                {rowData.IsVerificationsEnabled ? 'On' : 'Off'}
                            </Typography>
                        ) : (
                            'N/A'
                        )}
                    </Box>
                );
            case 'Mechanism type':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography
                            component={'span'}
                            sx={{
                                fontSize: 13,
                            }}
                        >
                            <Typography>{getValueFromMapping(rowData.PostType, postTypeMapping)}</Typography>
                        </Typography>
                    </Box>
                );
            case 'Images left in SD':
                return (
                    <Tooltip
                        title={
                            rowData.ImagesLeft === null
                                ? 'The camera is currently asleep. Please wake up the camera'
                                : ''
                        }
                        placement="top"
                    >
                        <Box
                            sx={{
                                maxWidth: '100%',
                                border: 'none !important',
                                cursor: 'pointer',
                            }}
                        >
                            <Typography component={'span'}>{rowData.ImagesLeft ?? 'Not active yet'}</Typography>
                        </Box>
                    </Tooltip>
                );

            case 'Latest photo':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Typography component={'span'}>
                            {rowData.LatestPhotoTime !== null
                                ? formatDateByTimeZone(rowData.LatestPhotoTime, rowData.TimeZone ?? undefined, true)
                                : 'N/A'}
                        </Typography>
                    </Box>
                );

            case 'Note':
                return (
                    <Box
                        sx={{
                            maxWidth: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Tooltip title="View note" placement="top">
                            <Badge
                                badgeContent={rowData.CameraNoteCount}
                                sx={{
                                    '& .MuiBadge-badge': {
                                        right: 10,
                                        top: 8,
                                        border: `2px solid #FFFFFF`,
                                        padding: '0 4px',
                                        fontSize: 9,
                                        background: color.gold600
                                    },
                                }}
                            >
                                <IconButton
                                    sx={{
                                        backgroundColor: color.grey200,
                                        border: `6px solid ${color.grey100}`,
                                        width: '40px',
                                        height: '40px',
                                    }}
                                    onClick={() => {
                                        setIdCamera(rowData.CameraId ?? 0);
                                        popUpNoteInCamera.setTrue();
                                    }}
                                >
                                    <IoEyeOutline style={{ width: '14px', height: '14px' }} />
                                </IconButton>
                            </Badge>
                        </Tooltip>
                    </Box>
                );

            case 'Action':
                return (
                    <Box
                        sx={{
                            width: '100%',
                            border: 'none !important',
                        }}
                    >
                        <Tooltip
                            title={
                                rowData.CameraStatus === Status.Deleted ? 'Camera deleted' : 'Sync time automatically'
                            }
                            placement="top"
                            arrow
                        >
                            <span>
                                <IconButton
                                    disabled={rowData.CameraStatus === Status.Deleted}
                                    sx={{
                                        borderRadius: '3px',
                                        width: '24px',
                                        height: '24px',
                                        color: 'white',
                                        backgroundColor: color.success,
                                        ':hover': {
                                            backgroundColor: '#1C7947',
                                        },
                                        '&.MuiIconButton-root.Mui-disabled': {
                                            backgroundColor: '#eee',
                                        },
                                        '&:hover .rotate-icon': {
                                            transform: 'rotate(90deg) scale(1.1)',
                                        },
                                    }}
                                    onClick={() => setCameraTimeInfo(rowData.CameraId)}
                                >
                                    <Autorenew
                                        sx={{
                                            fontSize: 17,
                                            transition: 'transform 0.3s ease-in-out',
                                        }}
                                        className="rotate-icon"
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                );
            default:
                return null;
        }
    };

    return (
        <>
            {cameraConfigMonitoring?.length > 0 ? (
                <Box
                    ref={containerRef}
                    sx={{
                        '& .chart-scroll-bar': {
                            '&::-webkit-scrollbar': {
                                height: '8px',
                            },

                            '&::-webkit-scrollbar-track': {
                                WebkitBoxShadow: 'inset 0 0 0px rgba(0, 0, 0, 0)',
                            },

                            '&::-webkit-scrollbar-thumb': {
                                height: '5px',
                                backgroundColor: color.grey400,
                                borderRadius: '3px',
                            },

                            '&::-webkit-scrollbar-thumb:hover': {
                                cursor: 'pointer',
                                backgroundColor: color.grey600,
                            },
                            '&::-webkit-scrollbar:vertical': {
                                display: 'none',
                            },
                        },
                    }}
                >
                    <TableContainer component={Box}>
                        <ScrollContainer
                            className="container chart-scroll-bar"
                            style={{
                                height: '100%',
                                width: '100%',
                                paddingBottom: '5px',
                                position: 'relative',
                                maxHeight: 700,
                                overflowY: 'auto',
                                overflowX: 'auto',
                            }}
                            hideScrollbars={false}
                        >
                            <Table aria-label="simple table">
                                {filteredServices.length > 0 ? (
                                    <>
                                        <TableHead
                                            sx={{
                                                position: 'sticky',
                                                top: 0,
                                                zIndex: 5,
                                            }}
                                        >
                                            <TableRow>
                                                {tableRow.map((headCell, index) => {
                                                    const isNoOrActionOrCameraColumn =
                                                        headCell.id === 'No.' ||
                                                        headCell.id === 'Action' ||
                                                        headCell.id === 'Camera';
                                                    const isNoColumn = headCell.id === 'No.';
                                                    const isActionColumn = headCell.id === 'Action';
                                                    const isCameraColumn = headCell.id === 'Camera';
                                                    const isZoneNameColumn = headCell.id === 'Zone';
                                                    const isCameraVersionColumn = headCell.id === 'Camera version';

                                                    const isTittleHaveValueNumber = [
                                                        'Time automatically',
                                                        'Capture interval',
                                                        'Contraventions in verification',
                                                        'Contraventions approved',
                                                        'Contraventions cancelled',
                                                        'Potential contraventions',
                                                        'Camera version',
                                                        'Operational status',
                                                        'Avg. photos',
                                                        'Potential rate',
                                                        'Approval rate',
                                                        'Cancellation rate',
                                                    ].includes(headCell.id.toString());

                                                    const isTotalColumn = [
                                                        'Contraventions in verification',
                                                        'Contraventions approved',
                                                        'Contraventions cancelled',
                                                        'Potential contraventions',
                                                        'Avg. photos',
                                                    ].includes(headCell.id.toString());

                                                    const isTotalColumnRate = [
                                                        'Potential rate',
                                                        'Approval rate',
                                                        'Cancellation rate',
                                                    ].includes(headCell.id.toString());

                                                    const totalValue = () => {
                                                        switch (headCell.id) {
                                                            case 'Contraventions in verification':
                                                                return sumOfFields.InprogressContraventions;
                                                            case 'Contraventions approved':
                                                                return sumOfFields.ApprovedContraventions;
                                                            case 'Contraventions cancelled':
                                                                return sumOfFields.CancelledContraventions;
                                                            case 'Potential contraventions':
                                                                return sumOfFields.PotentialContraventions;
                                                            case 'Avg. photos':
                                                                return sumOfFields.AvgDailyPhotosTaken;
                                                            default:
                                                                return 0;
                                                        }
                                                    };

                                                    const totalValueRate = () => {
                                                        switch (headCell.id) {
                                                            case 'Potential rate':
                                                                return avgPotentialRate || 0;
                                                            case 'Approval rate':
                                                                return avgApprovalRate || 0;
                                                            case 'Cancellation rate':
                                                                return avgCancellationRate || 0;
                                                            default:
                                                                return 0;
                                                        }
                                                    };

                                                    return (
                                                        <TableCell
                                                            key={index}
                                                            align={isCameraColumn ? 'left' : 'center'}
                                                            sx={{
                                                                padding: '4px 14px !important',
                                                                backgroundColor: color.grey200,
                                                                minWidth: isZoneNameColumn ? '110px' : 'max-content',
                                                                ...(isTittleHaveValueNumber && {
                                                                    width: 'max-content',
                                                                    position: 'sticky',
                                                                }),
                                                                ...(isNoOrActionOrCameraColumn && {
                                                                    minWidth: isCameraColumn ? '180px' : '30px',
                                                                    position: 'sticky',
                                                                    zIndex: isNoColumn ? 2 : isCameraColumn ? 3 : 1,
                                                                    left: isNoColumn
                                                                        ? 0
                                                                        : isCameraColumn
                                                                        ? '50px'
                                                                        : 'unset',
                                                                    right: isActionColumn
                                                                        ? 0
                                                                        : isCameraColumn
                                                                        ? 'unset'
                                                                        : 'unset',
                                                                }),
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={`${headCell.id} ${
                                                                    isTotalColumn
                                                                        ? `(${formatNumber(totalValue())})`
                                                                        : `${
                                                                              isTotalColumnRate
                                                                                  ? `(${formatTruncateDecimal(
                                                                                        totalValueRate(),
                                                                                        2
                                                                                    )}%)`
                                                                                  : ''
                                                                          }`
                                                                }`}
                                                                placement="top"
                                                                onClick={() => {
                                                                    handleSortChange(
                                                                        headCell.id,
                                                                        sorting[headCell.id] || 'ASC'
                                                                    );
                                                                }}
                                                            >
                                                                <div>
                                                                    {excludedColumns.includes(headCell.label) ? (
                                                                        <Typography fontSize={14} fontWeight={'medium'}>
                                                                            {headCell.label}
                                                                        </Typography>
                                                                    ) : (
                                                                        <TableSortLabel
                                                                            active={orderBy === headCell.id}
                                                                            IconComponent={ArrowDownwardIcon}
                                                                            style={{
                                                                                userSelect: 'text',
                                                                                position: 'relative',
                                                                            }}
                                                                            sx={{
                                                                                '& .MuiSvgIcon-root': {
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    left: '97%',
                                                                                    fontSize: 16,
                                                                                    marginLeft: '2px',
                                                                                    transform: `translateY(-50%) rotate(${
                                                                                        sortOrder ? 180 : 0
                                                                                    }deg)`,
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                fontSize={14}
                                                                                fontWeight={'medium'}
                                                                                width={
                                                                                    isCameraVersionColumn
                                                                                        ? '55px'
                                                                                        : 'max-content'
                                                                                }
                                                                                display={'flex'}
                                                                            >
                                                                                {headCell.label}
                                                                                {(isTotalColumn ||
                                                                                    isTotalColumnRate) && (
                                                                                    <Typography ml={'4px'}>
                                                                                        (
                                                                                        {isTotalColumnRate
                                                                                            ? `${formatTruncateDecimal(
                                                                                                  totalValueRate(),
                                                                                                  2
                                                                                              )}%`
                                                                                            : formatNumber(
                                                                                                  totalValue()
                                                                                              )}
                                                                                        )
                                                                                    </Typography>
                                                                                )}
                                                                            </Typography>
                                                                        </TableSortLabel>
                                                                    )}
                                                                </div>
                                                            </Tooltip>
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredServices.map((rowData, index) => {
                                                return (
                                                    <TableRow key={index} sx={{ position: 'relative' }}>
                                                        {tableRow.map((columnId, columnIndex) => {
                                                            const isNoOrActionOrCameraColumn = [
                                                                'No.',
                                                                'Action.',
                                                                'Camera',
                                                            ].includes(columnId.id.toString());

                                                            const isNoColumn = columnId.id === 'No.';
                                                            const isActionColumn = columnId.id === 'Action';
                                                            const isCameraColumn = columnId.id === 'Camera';

                                                            return (
                                                                <TableCell
                                                                    key={columnIndex}
                                                                    align={isCameraColumn ? 'left' : 'center'}
                                                                    sx={{
                                                                        padding: '4px 8px !important',
                                                                        backgroundColor: '#FAFAFA',
                                                                        minWidth: isNoOrActionOrCameraColumn
                                                                            ? '50px'
                                                                            : 'unset',
                                                                        position: 'sticky',
                                                                        zIndex: isNoColumn ? 2 : isCameraColumn ? 3 : 1,
                                                                        left: isNoColumn
                                                                            ? 0
                                                                            : isCameraColumn
                                                                            ? '50px'
                                                                            : 'unset',
                                                                        right: isActionColumn
                                                                            ? 0
                                                                            : isCameraColumn
                                                                            ? 'unset'
                                                                            : 'unset',
                                                                    }}
                                                                >
                                                                    {renderColumnData(
                                                                        String(columnId.id),
                                                                        rowData,
                                                                        index
                                                                    )}
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </>
                                ) : (
                                    <ListNoData />
                                )}
                            </Table>
                        </ScrollContainer>
                    </TableContainer>
                </Box>
            ) : (
                <ListNoData />
            )}

            <PopUpNoteInCamera {...popUpNoteInCamera} />

            <PopUpAddOrUpdateNoteInCamera {...popUpAddOrUpdateNoteInCamera} />
            <PopUpWarning
                {...popUpConfirmDeleteNoteHistory}
                title="Confirm"
                message="Are you sure you want to delete this note?"
                onClose={() => {
                    setIdNote(0);
                    popUpConfirmDeleteNoteHistory.onClose();
                }}
                onConfirm={() => {
                    if (idNote) {
                        handleDeleteNote(idNote);
                        popUpConfirmDeleteNoteHistory.onClose();
                    } else {
                        pushError('Id note does not exist');
                    }
                }}
            />
        </>
    );
}
const styledRowTypo: SxProps<Theme> = {
    color: color.secondary,
    cursor: 'pointer',
    fontSize: 13,
};
