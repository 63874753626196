import { CameraPingDetail } from '@EcamModel/model/CameraOverview';
import color from '@theme/Colors';
import { ChartData, Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { formatUKTimeDate } from 'src/helpers';
ChartJS.register(...registerables);

type Props = {
    chart: CameraPingDetail[];
};

export default function BarStackedChart({ chart }: Props) {
    const data: ChartData<'bar', number[], string> = {
        labels: chart.map((item) => formatUKTimeDate(item.Created)),
        datasets: [
            {
                label: 'Number of successful pings',
                data: chart.map((item) => item.PingableCount),
                backgroundColor: color.success,
                borderColor: color.success,
                maxBarThickness: 20,
            },
            {
                label: 'Number of failed pings',
                data: chart.map((item) => item.NotPingableCount),
                backgroundColor: color.error,
                borderColor: color.error,
                maxBarThickness: 20,
            },
        ],
    };

    const maxTotalValue = chart.reduce((maxTotal, currentItem) => {
        const totalValue = currentItem.PingableCount + currentItem.NotPingableCount;
        return Math.max(maxTotal, totalValue);
    }, 0);

    const options: ChartOptions<'bar'> = {
        responsive: true,
        // set height not crash
        maintainAspectRatio: false,
        plugins: {
            //note
            legend: {
                display: false,
                position: 'bottom' as const,
                align: 'center',
                //set note label font
                labels: {
                    font: {
                        size: 16,
                    },
                    padding: 20,
                    boxWidth: 5,
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },

            // Top Label
            datalabels: {
                display: false,
                color: 'white',
                anchor: 'center',
                align: 'center',
                font: {
                    size: 11,
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label(tooltipItems: any) {
                        return `${tooltipItems.dataset.label}: ${tooltipItems.formattedValue}`;
                    },
                    title: function (tooltipItems) {
                        const from = parseInt(tooltipItems[0].label, 10);
                        const to = (from + 1) % 24;
                        return `${from}:00 - ${to}:00`;
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        //setting grid
        scales: {
            x: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    //y step
                    stepSize: 100000,
                },

                //y label start at 0
                beginAtZero: true,
                min: 0,
                max: maxTotalValue,
                stacked: true,
            },
        },
    };

    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}
