import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function England(props: Props) {
    return (
        <svg {...props} width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_332_22914)">
                <path
                    d="M0 3C0 1.34315 1.34315 0 3 0H30.6C32.2569 0 33.6 1.34315 33.6 3V21C33.6 22.6569 32.2569 24 30.6 24H3C1.34315 24 0 22.6569 0 21V3Z"
                    fill="#1A47B8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.57436 0H0V4L30.0062 24L33.6 24V20L3.57436 0Z"
                    fill="white"
                />
                <path d="M1.19216 0L33.6 21.6567V24H32.4358L0 2.32089V0H1.19216Z" fill="#F93939" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.4001 0H33.6001V4C33.6001 4 12.8158 17.3249 3.20009 24H9.15527e-05V20L30.4001 0Z"
                    fill="white"
                />
                <path d="M33.6 0H32.5148L0 21.6753V24H1.19216L33.6 2.33842V0Z" fill="#F93939" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.2193 0H21.4086V7.40291H33.6V16.5922H21.4086V24H12.2193V16.5922H0V7.40291H12.2193V0Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.1474 0H19.4526V9.23077H33.6V14.7692H19.4526V24H14.1474V14.7692H0V9.23077H14.1474V0Z"
                    fill="#F93939"
                />
            </g>
            <defs>
                <clipPath id="clip0_332_22914">
                    <path
                        d="M0 3C0 1.34315 1.34315 0 3 0H30.6C32.2569 0 33.6 1.34315 33.6 3V21C33.6 22.6569 32.2569 24 30.6 24H3C1.34315 24 0 22.6569 0 21V3Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
