import { CellularInfoAuthType, CellularInfoNetworkType, Solar4gCellularInfo } from '@EcamModel/model';
import BaseCheckbox from '@components/BaseCheckbox';
import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import { pushError, pushSuccess } from '@components/toast';
import { IPopUp } from '@hooks/usePopUp';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Box, Button, FormControlLabel, IconButton, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import color from '@theme/Colors';
import { ReactNode, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useBackdrop } from 'src/providers/BackdropProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {
    cellularSettings: Solar4gCellularInfo;
    onSave: (data: Partial<Solar4gCellularInfo>) => Promise<void>;
};

const listAuthtype = [0, 1, 2, 3, 4];
const listNetwordtype = [0, 1, 2, 3];

export default function PopUpCellularSettings(props: Props) {
    const { open, onClose, onSave, cellularSettings } = props;
    const backdrop = useBackdrop();

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const {
        control,
        formState: { isValid, isDirty },
        reset,
        handleSubmit,
        setValue,
    } = useForm<Partial<Solar4gCellularInfo>>({ mode: 'all' });
    const disabled = !(isValid && isDirty);
    const defaultValues = {
        Apn: cellularSettings?.Apn,
        UserName: cellularSettings?.UserName,
        Password: cellularSettings?.Password,
        PinCode: cellularSettings?.PinCode,
        AuthType: cellularSettings?.AuthType,
        NetworkType: cellularSettings?.NetworkType,
        PingEnable: cellularSettings?.PingEnable,
        PingAddr: cellularSettings?.PingAddr,
        Mtu: cellularSettings?.Mtu,
        BillDay: cellularSettings?.BillDay,
    };

    useEffect(() => {
        if (!open) return;
        reset(defaultValues);
    }, [open, cellularSettings]);

    return (
        <PopUpBase
            open={open}
            title={<Typography variant="h4">Cellular settings</Typography>}
            desc={
                <Box minWidth={500} bgcolor={color.grey100} paddingX={2} py={1.5}>
                    <Stack direction={'column'} spacing={1.5}>
                        <FormLayout
                            title="APN:"
                            body={
                                <Controller
                                    control={control}
                                    name="Apn"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Please enter a APN.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            value={field.value}
                                            placeholder={'APN'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                            sx={{
                                                '& .MuiOutlinedInput-input': {
                                                    color: color.secondary,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="User Name:"
                            body={
                                <Controller
                                    name="UserName"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Please enter a username.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            value={field.value}
                                            placeholder={'User Name'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="Password:"
                            body={
                                <Controller
                                    control={control}
                                    name="Password"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Please enter a password.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            placeholder={'Password'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                            maxLength={30}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                        size="small"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff sx={{ fontSize: 20 }} />
                                                        ) : (
                                                            <Visibility sx={{ fontSize: 20 }} />
                                                        )}
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="PIN code:"
                            body={
                                <Controller
                                    control={control}
                                    name="PinCode"
                                    rules={{
                                        maxLength: {
                                            message: 'The PIN code must be numeric with 4 - 8 digits',
                                            value: 8,
                                        },
                                        minLength: {
                                            message: 'The PIN code must be numeric with 4 - 8 digits',
                                            value: 4,
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Please enter a valid PIN code.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            placeholder={'PIN code'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                            maxLength={30}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="Authentication:"
                            body={
                                <Controller
                                    control={control}
                                    name="AuthType"
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={listAuthtype}
                                            {...field}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    padding: '9.95px 14px !important',
                                                },
                                                '& .MuiButtonBase-root': {
                                                    marginRight: '5px !important',
                                                },
                                                '& .MuiAutocomplete-inputRoot': {
                                                    '& 	.MuiAutocomplete-input': {
                                                        padding: '0px 0px',
                                                    },
                                                },
                                            }}
                                            value={field.value}
                                            onChange={(_, newValue) => {
                                                field.onChange(newValue);
                                                if (newValue == null) {
                                                    setValue('AuthType', cellularSettings.AuthType);
                                                }
                                            }}
                                            getOptionLabel={(options) => {
                                                const authTypeLabel = {
                                                    [CellularInfoAuthType.Auto]: 'Auto',
                                                    [CellularInfoAuthType.PAP]: 'PAP',
                                                    [CellularInfoAuthType.CHAP]: 'CHAP',
                                                    [CellularInfoAuthType.MS_CHAP]: 'MS-CHAP',
                                                    [CellularInfoAuthType.MS_CHAP_V2]: 'MS-CHAPv2',
                                                };

                                                return authTypeLabel[options];
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            clearIcon={true}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="Network type:"
                            body={
                                <Controller
                                    control={control}
                                    name="NetworkType"
                                    render={({ field }) => (
                                        <Autocomplete
                                            options={listNetwordtype}
                                            {...field}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    padding: '9.95px 14px !important',
                                                },
                                                '& .MuiButtonBase-root': {
                                                    marginRight: '5px !important',
                                                },
                                                '& .MuiAutocomplete-inputRoot': {
                                                    '& 	.MuiAutocomplete-input': {
                                                        padding: '0px 0px',
                                                    },
                                                },
                                            }}
                                            onChange={(_, newValue) => {
                                                field.onChange(newValue);
                                                if (newValue == null) {
                                                    setValue('NetworkType', cellularSettings.NetworkType);
                                                }
                                            }}
                                            value={field.value}
                                            getOptionLabel={(options) => {
                                                const networkTypeLabel = {
                                                    [CellularInfoNetworkType.AUTO]: 'Auto',
                                                    [CellularInfoNetworkType.ONLY_2G]: '2G only',
                                                    [CellularInfoNetworkType.ONLY_3G]: '3G only',
                                                    [CellularInfoNetworkType.ONLY_4G]: '4G only',
                                                };

                                                return networkTypeLabel[options];
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            clearIcon={true}
                                            disabledItemsFocusable
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="Ping detection:"
                            body={
                                <Controller
                                    control={control}
                                    name="PingEnable"
                                    render={({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <BaseCheckbox
                                                    {...field}
                                                    value={field.value}
                                                    onChange={(e, checked) => {
                                                        field.onChange(Number(checked));
                                                    }}
                                                    checked={!!field.value}
                                                />
                                            }
                                            label={<></>}
                                            sx={{ ml: 0 }}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="IP address:"
                            body={
                                <Controller
                                    control={control}
                                    name="PingAddr"
                                    rules={{
                                        required: {
                                            value: true,
                                            message: 'Please enter a IP address.',
                                        },
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <BaseTextField
                                            {...field}
                                            value={field.value}
                                            placeholder={'IP address'}
                                            error={!!error?.message}
                                            helperText={error?.message}
                                        />
                                    )}
                                />
                            }
                        />

                        <FormLayout
                            title="MTU:"
                            body={
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Controller
                                        control={control}
                                        name="Mtu"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Please enter a MTU.',
                                            },
                                            max: {
                                                value: 1500,
                                                message: 'Bytes is valid',
                                            },
                                            min: {
                                                value: 1200,
                                                message: 'Bytes is valid',
                                            },
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Please enter a valid Bytes.',
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <BaseTextField
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        isNaN(Number(e.target.value))
                                                            ? e.target.value
                                                            : Number(e.target.value)
                                                    );
                                                }}
                                                placeholder={'MTU'}
                                                error={!!error?.message}
                                                helperText={error?.message}
                                            />
                                        )}
                                    />
                                    <Box width={205}>
                                        <Typography>Bytes (1200~1500)</Typography>
                                    </Box>
                                </Stack>
                            }
                        />

                        <FormLayout
                            title="Billing day:"
                            body={
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <Typography>Day</Typography>
                                    <Controller
                                        control={control}
                                        name="BillDay"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Please enter a Billing day.',
                                            },
                                            max: {
                                                value: 28,
                                                message: 'Day is valid',
                                            },
                                            min: {
                                                value: 1,
                                                message: 'Day is valid',
                                            },
                                            pattern: {
                                                value: /^[0-9]+$/,
                                                message: 'Please enter a valid Billing day.',
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <BaseTextField
                                                {...field}
                                                value={field.value}
                                                onChange={(e) => {
                                                    field.onChange(
                                                        isNaN(Number(e.target.value))
                                                            ? e.target.value
                                                            : Number(e.target.value)
                                                    );
                                                }}
                                                placeholder={'Billing day'}
                                                error={!!error?.message}
                                                helperText={error?.message}
                                            />
                                        )}
                                    />
                                    <Box width={140}>
                                        <Typography>Of the month</Typography>
                                    </Box>
                                </Stack>
                            }
                        />
                    </Stack>
                </Box>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            onClose?.();
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        sx={{ minWidth: 130 }}
                        variant={'contained'}
                        disabled={disabled}
                        onClick={handleSubmit((data) => {
                            backdrop.setTrue();

                            const _cellularSettings: Partial<Solar4gCellularInfo> = {} as Partial<Solar4gCellularInfo>;
                            for (const key in data) {
                                if (data[key] !== defaultValues[key]) {
                                    _cellularSettings[key] = data[key];
                                }
                            }

                            onSave(_cellularSettings)
                                .then(() => {
                                    pushSuccess('Saved successfully');
                                })
                                .catch((res) => {
                                    pushError(res);
                                })
                                .finally(() => {
                                    onClose?.();
                                    backdrop.setFalse();
                                });
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
            minWidthButton={150}
        />
    );
}

function FormLayout(props: { title: string; body: ReactNode }) {
    const { title, body } = props;

    return (
        <Grid container xs={12} alignItems={'center'}>
            <Grid xs={3}>
                <Typography>{title}</Typography>
            </Grid>
            <Grid xs={9}>{body}</Grid>
        </Grid>
    );
}
