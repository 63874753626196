import { BaseModel } from '@Core';
import { TypeInfo } from './Solar4gCamera';

export interface Solar4gCaptureInfo extends BaseModel {
    DevMac: string;

    DevName: string;

    ImageType: CaptureInfoImageType;

    Width: number;

    Height: number;

    Indicator: CaptureInfoCaptureIndicator;

    RadarSensitivity: number; // Sensor Sensitivity: 1~10

    LowSpeedLimit: number;

    HighSpeedLimit: number;

    CaptureTimeout: number; // Sleep Delay: 1~10，default: 3s

    Continuous: CaptureInfoContinuous;

    IntervalUnit: CaptureInfoIntervalUnit;

    Interval: number;

    UploadMode: CaptureInfoUploadMode;

    Frequency: CaptureInfoFrequency;

    Time1: string;

    Time2: string;

    PostEnable: number;

    PostType: CaptureInfoPostType;

    PostDelete: number;

    Host: string;

    Port: string;

    UserName: string;

    Password: string;

    TlsEnable: number;

    TlsType: number;

    SnapshotEnable: number;

    AlarmEnable: number;

    StatusEnable: number;

    SnapshotTopic: string;

    AlarmTopic: string;

    StatusTopic: string;

    ConfigTopic: string;

    ConfigRespTopic: string;

    HttpUrl: string;

    HttpPort: number;

    HttpUserName: string;

    HttpPassword: string;

    CropX: number;

    CropY: number;

    CropW: number;

    CropH: number;

    CaExist: number;

    CertExist: number;

    KeyExist: number;

    PostCaptureEnable: number; // 0: disable - 1: enable
    PostCaptureNum: number;
    PostCaptureInterval: number; // 1 ~ 30 seconds

    InfoType: TypeInfo;
}

export enum CaptureInfoImageType {
    FULL_IMAGE,
    ROI_CROPPING,
}

export enum CaptureInfoCaptureIndicator {
    OFF,
    ON,
}

export enum CaptureInfoContinuous {
    OFF,
    ON,
}

export enum CaptureInfoIntervalUnit {
    MINUTE,
    HOUR,
}

export enum CaptureInfoUploadMode {
    TIMING,
    IMMEDIACY,
}

export enum CaptureInfoFrequency {
    ONCE_A_DAY = 1,
    TWICE_A_DAY = 2,
}

export enum CaptureInfoPostType {
    MQTT,
    HTTP,
}
