import {
    CameraWithInfo,
    ContraventionReasonTranslations,
    GeoZoneCoordinates,
    GeoZoneValidationType,
    GeoZones,
    Status,
} from '@EcamModel/model';
import { GeoZoneOperationConfigDay } from '@EcamModel/model/GeoZoneOperationConfigDay';
import { GeoZoneOperationConfigGracePeriodWithDay } from '@EcamModel/model/GeoZoneOperationConfigGracePeriod';
import IcMenu from '@assets/details-camera-icons/IcMenu';
import IcSetting from '@assets/details-camera-icons/IcSetting';
import FramePolygonSized from '@components/FramePolygonSized';
import IOSSwitch from '@components/IOSSwitch';
import BreadCrumbs, { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushSuccess } from '@components/toast';
import { cameraController, geoZoneController } from '@controllers/index';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Stack,
    SxProps,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { validateDatePickerValue } from '@pages/cameras/add-new';
import { groupBy } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { BsQuestion } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { getGracePeriods } from '../utils';
import GracePeriods from './GracePeriods';
import OperationalHours from './OperationalHours';
import BaseCheckbox from '@components/BaseCheckbox';

export type DetectMode = {
    Label: string;
    Value: GeoZoneValidationType;
    Note?: string;
};
export type GeoZoneGracePeriod = {
    GracePeriod: string;
    _fakeId: string;
} & Omit<GeoZoneOperationConfigGracePeriodWithDay, 'GracePeriod'>;

export type GeoZoneHourConfigTimeDate = {
    From?: Date;
    To?: Date;
    _fakeId: string;
} & Omit<GeoZoneOperationConfigDay, 'From' | 'To'>;

export type GeoZoneHourConfigWithTimeRanges = {
    Weekday: number;
    TimeRanges: GeoZoneHourConfigTimeDate[];
    AllDay: boolean;
    Status: boolean;
};

export type FormValues = {
    contraventionReasonTranslations: ContraventionReasonTranslations;
    detectMode: DetectMode;
    isActive: boolean;
    geoZonePoints: GeoZoneCoordinates[];
    operationHours: GeoZoneHourConfigWithTimeRanges[];
    gracePeriods: GeoZoneGracePeriod[];
    isExemptVRNs: boolean;
};

export const schema: any = yup.object().shape({
    contraventionReasonTranslations: yup.object().required(),
    gracePeriods: yup.array().of(
        yup.object().shape({
            Name: yup.string().required('Name is required'),
            GracePeriod: yup
                .string()
                .required('Please enter grace periods.')
                .test('validFormat', 'Use the format: x(h) x(m) x(s).', function (value) {
                    const timeRegex =
                        /^(?:[0-9]\d*h(\s[0-9]\d*m)?(\s[0-9]\d*s)?|[0-9]\d*m(\s[0-9]\d*s)?|[0-9]\d*s|[0-9]\d*)$/;
                    if (!timeRegex.test(value)) {
                        return false;
                    }
                    const totalSeconds = getGracePeriods(value);
                    if (totalSeconds < 10) {
                        throw new yup.ValidationError('Minium total seconds is 10.', value, this.path);
                    }
                    return true;
                }),
        })
    ),
});
export const detectModes: DetectMode[] = [
    {
        Label: 'Covered',
        Value: GeoZoneValidationType.Covered,
        Note: 'The whole plate number must be within the geo-zone.',
    },
    {
        Label: 'Center',
        Value: GeoZoneValidationType.Center,
        Note: 'The center point of the plate number must be within the geo-zone.',
    },
    {
        Label: 'Contained',
        Value: GeoZoneValidationType.Contained,
        Note: 'Only one/some of 4 coordinates of the plate number with in the geo zone we can understand the vehicle within the geo-zone.',
    },
];
export const defaultGracePeriod = { _fakeId: 'df-1', Name: 'Default', GracePeriod: '60' };
export const defaultOperationHours = [
    {
        Weekday: 1,
        TimeRanges: [{ From: undefined, To: undefined, _fakeId: 'df-1' }],
        AllDay: false,
        Status: false,
    },
    {
        Weekday: 2,
        TimeRanges: [{ From: undefined, To: undefined, _fakeId: 'df-1' }],
        AllDay: false,
        Status: false,
    },
    {
        Weekday: 3,
        TimeRanges: [{ From: undefined, To: undefined, _fakeId: 'df-1' }],
        AllDay: false,
        Status: false,
    },
    {
        Weekday: 4,
        TimeRanges: [{ From: undefined, To: undefined, _fakeId: 'df-1' }],
        AllDay: false,
        Status: false,
    },
    {
        Weekday: 5,
        TimeRanges: [{ From: undefined, To: undefined, _fakeId: 'df-1' }],
        AllDay: false,
        Status: false,
    },
    {
        Weekday: 6,
        TimeRanges: [{ From: undefined, To: undefined, _fakeId: 'df-1' }],
        AllDay: false,
        Status: false,
    },
    {
        Weekday: 7,
        TimeRanges: [{ From: undefined, To: undefined, _fakeId: 'df-1' }],
        AllDay: false,
        Status: false,
    },
];
export const handleConvertOperationHourConfig = (operationHours: GeoZoneHourConfigWithTimeRanges[]) => {
    const convertedOperationHourConfig: any[] = [];
    operationHours.forEach((item) => {
        if (item.TimeRanges.length > 0) {
            item.TimeRanges.forEach((timeRange) => {
                convertedOperationHourConfig.push({
                    Id: timeRange.Id,
                    Weekday: item.Weekday,
                    From: !!timeRange.From
                        ? moment(timeRange.From).hours() * 60 * 60 + moment(timeRange.From).minutes() * 60
                        : null,
                    To: !!timeRange.To
                        ? moment(timeRange.To).hours() * 60 * 60 + moment(timeRange.To).minutes() * 60
                        : null,
                    AllDay: item.AllDay,
                    Status: item.Status,
                    _fakeId: timeRange._fakeId ?? defaultGracePeriod._fakeId,
                    GeoZoneOperationConfigGracePeriodId: timeRange.GeoZoneOperationConfigGracePeriodId,
                } as any);
            });
        }
    });
    return convertedOperationHourConfig;
};
export const hasInvalidGracePeriod = (
    operationHours: GeoZoneHourConfigWithTimeRanges[],
    gracePeriods: GeoZoneGracePeriod[]
) => {
    return operationHours.every((operationHour) =>
        operationHour.TimeRanges.every((timeRange) =>
            gracePeriods.some((gracePeriod) => gracePeriod._fakeId === timeRange._fakeId)
        )
    );
};
export default function AddGeoZones() {
    const navigate = useNavigate();
    const location = useLocation();

    const params = useParams<{ id: string }>();
    const { selectedGeoZone } = location.state || {};

    const [camera, setCamera] = useState<CameraWithInfo>({} as CameraWithInfo);
    const [contraventionReasons, setContraventionReasons] = useState<ContraventionReasonTranslations[]>([]);
    const [startDate, setStartDate] = useState(moment());
    const [isOverlap, setIsOverlap] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingSave, setLoadingSave] = useState(false);

    const navigatePath = `/locations/${camera.Zone?.LocationId}/cameras/${camera.Id}`;

    const handleNavigateGeoZonesTab = () => {
        navigate(`${navigatePath}`, { state: { tabValue: 1 } });
    };

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Cameras', href: `/locations/${camera.Zone?.LocationId}/cameras` },
        { title: `${camera.Name}`, href: `${navigatePath}` },
        { title: `Add geo-zones`, isColorBlackText: true },
    ];

    const {
        watch,
        reset,
        control,
        setValue,
        handleSubmit,
        setError,
        formState: { isDirty, isValid, errors, ...formState },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
        mode: 'all',
        defaultValues: {
            isExemptVRNs: true,
            contraventionReasonTranslations: undefined,
            detectMode: detectModes[0],
            isActive: true,
            geoZonePoints: [],
            operationHours: defaultOperationHours,
            gracePeriods: [defaultGracePeriod],
        },
    });

    const {
        fields: operationHours,
        update: updateOperationHours,
        append: appendOperationHours,
        remove: removeOperationHours,
    } = useFieldArray({ control, name: 'operationHours' });

    const gracePeriodForm = useFieldArray({ control, name: 'gracePeriods' });
    const isInvalidOperationHours = watch().operationHours.some(
        (item) => !!item.Status && item.TimeRanges.some((t) => !t.From || !t.To)
    );
    const hasOperationHours = watch().operationHours.every((item) => !item.Status);

    const isHasInValidGracePeriod = hasInvalidGracePeriod(watch().operationHours, watch().gracePeriods);
    const isDisabled =
        !(isDirty && isValid) ||
        isOverlap ||
        Boolean(watch().geoZonePoints.length < 5) ||
        isInvalidOperationHours ||
        hasOperationHours ||
        !isHasInValidGracePeriod ||
        loadingSave;

    const blobName = camera.CameraPhotos?.[0]?.BlobName;

    const prevGeoZonePoints: GeoZoneCoordinates[][] = camera.GeoZones?.map((gz) => {
        return gz.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(gz.GeoZoneCoordinates[0]);
    }) as GeoZoneCoordinates[][];

    const filterPreGeoZones = prevGeoZonePoints?.map((item) =>
        JSON.stringify(item) !==
        JSON.stringify(selectedGeoZone.GeoZoneCoordinates?.concat(selectedGeoZone.GeoZoneCoordinates[0]))
            ? item
            : [
                  { Index: 1, XPoint: undefined, YPoint: undefined, GeoZoneId: selectedGeoZone.Id },
                  { Index: 2, XPoint: undefined, YPoint: undefined, GeoZoneId: selectedGeoZone.Id },
                  { Index: 3, XPoint: undefined, YPoint: undefined, GeoZoneId: selectedGeoZone.Id },
                  { Index: 4, XPoint: undefined, YPoint: undefined, GeoZoneId: selectedGeoZone.Id },
              ]
    );

    const contraventionAutocomplete = useStyledAutocomplete({
        list: {
            options: contraventionReasons,
            isFiltered(option, searchText) {
                return option.Summary.toLowerCase().includes(searchText.toLowerCase());
            },
        },
        dependencyList: [contraventionReasons],
    });
    const detectModeAutocomplete = useStyledAutocomplete({
        list: {
            options: detectModes,
            isFiltered(option, searchText) {
                return option.Label.toLowerCase().includes(searchText.toLowerCase());
            },
        },
    });
    const handleAddGeoZone = (data: FormValues) => {
        setLoadingSave(true);
        const groupedOperationHours = groupBy(handleConvertOperationHourConfig(data.operationHours), '_fakeId');
        const _geoZones: GeoZones = {
            CameraId: camera.Id!,
            ContraventionReasonId: Number(data.contraventionReasonTranslations.Id!),
            Status: !!data.isActive ? Status.Active : Status.Inactive,
            ValidationType: data.detectMode.Value,
            ContraventionType: data.contraventionReasonTranslations.Summary ?? '',
            GeoZoneCoordinates: data.geoZonePoints.splice(0, 4).map((item) => ({
                GeoZoneId: item.GeoZoneId,
                Index: item.Index,
                XPoint: item.XPoint,
                YPoint: item.YPoint,
            })),
            GZOConfigs: [
                {
                    EffectiveFrom: moment(startDate).startOf('date').toDate(),
                    GZOConfigGracePeriods: data.gracePeriods.map(
                        (item) =>
                            ({
                                ...item,
                                GracePeriod: getGracePeriods(item.GracePeriod),
                                GZOConfigDays: groupedOperationHours[item._fakeId],
                            } as GeoZoneOperationConfigGracePeriodWithDay)
                    ),
                    Status: false,
                },
            ],
            PermitExemptionsEnabled: data.isExemptVRNs,
        };
        geoZoneController
            .upsert(_geoZones)
            .then((res) => {
                handleNavigateGeoZonesTab();
            })
            .finally(() => {
                pushSuccess('Add geo-zones successfully');
                setLoadingSave(false);
            });
    };

    useEffect(() => {
        geoZoneController.getContraventionReasonTranslations().then((res) => {
            setContraventionReasons(res);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        cameraController
            .get(params.id!)
            .then((res) => setCamera(res))
            .catch((err) => {
                // navigate('/cameras');
                navigate(`/locations/${camera.Zone?.LocationId}/cameras`);
            })
            .finally(() => setLoading(false));
    }, [params.id]);

    return (
        <Box>
            {loading ? (
                <Stack direction="row" justifyContent="center" alignItems="center" minHeight={500}>
                    <CircularProgress />
                </Stack>
            ) : (
                <Stack mt={1}>
                    <Stack mb={3}>
                        <Typography variant="h3">{'Add geo-zones'}</Typography>
                        <BreadCrumbs breadcrumbs={breadcrumbs} />
                    </Stack>

                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Controller
                            name="contraventionReasonTranslations"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <StyledAutocomplete
                                    {...field}
                                    {...contraventionAutocomplete}
                                    isRequired
                                    getOptionLabel={(o) => o.Summary}
                                    label="Contravention reason"
                                    value={field.value}
                                    placeholder="Select reason"
                                    disabledAllOption
                                    onChange={(c) => {
                                        setValue('contraventionReasonTranslations', c!, {
                                            shouldValidate: true,
                                            shouldTouch: true,
                                            shouldDirty: true,
                                        });
                                    }}
                                    wrapperWidth="100%"
                                />
                            )}
                        />
                        <Controller
                            name="detectMode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <StyledAutocomplete
                                    {...field}
                                    {...detectModeAutocomplete}
                                    isRequired
                                    getOptionLabel={(o) => o.Label}
                                    renderOptionTooltip={(o) => (
                                        <Tooltip title={o.Note} arrow placement="top">
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    p: 0.1,
                                                    background: (theme) => theme.palette.info.main,
                                                    borderRadius: '100%',
                                                }}
                                            >
                                                <BsQuestion color="#fff" fontSize={16} />
                                            </Stack>
                                        </Tooltip>
                                    )}
                                    label="Detect mode"
                                    value={field.value}
                                    disabledAllOption
                                    onChange={(c) => {
                                        field.onChange(c);
                                    }}
                                    wrapperWidth="100%"
                                />
                            )}
                        />
                        <div>
                            <Controller
                                name="isActive"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <IOSSwitch
                                        {...field}
                                        label={
                                            <Typography sx={{ minWidth: '55px' }}>
                                                {!!field.value ? 'Active' : 'Inactive'}
                                            </Typography>
                                        }
                                        iosSwitchProps={{
                                            checked: field.value,
                                            onChange(event, checked) {
                                                field.onChange(event);
                                            },
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </Stack>

                    {/* checkbox ExemptVRNs */}
                    <Stack direction="row" alignItems="center" justifyContent={'space-between'} width="100%" mt={2}>
                        <Stack direction="row" alignItems="center">
                            <Controller
                                name="isExemptVRNs"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControlLabel
                                        control={
                                            <BaseCheckbox
                                                checked={field.value}
                                                onChange={(_, checked) => {
                                                    field.onChange(checked);
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography ml={0.5} mt={0.3}>
                                                Exempt VRNs on Permit lists
                                            </Typography>
                                        }
                                        sx={{ ml: 0 }}
                                    />
                                )}
                            />
                        </Stack>
                    </Stack>
                    {/* end checkbox ExemptVRNs  */}

                    {/* Draw angle box */}
                    <HeadingSection icon={<IcMenu />} heading="Zone marking" />
                    <div style={{ marginBottom: '51px' }} />
                    <Controller
                        name="geoZonePoints"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                            <FramePolygonSized
                                {...field}
                                prevGeoZone={filterPreGeoZones as GeoZoneCoordinates[][]}
                                selectedGeoZone={{} as GeoZones}
                                onChange={(points) => {
                                    setValue('geoZonePoints', points, {
                                        shouldValidate: true,
                                        shouldTouch: true,
                                        shouldDirty: true,
                                    });
                                }}
                                blobName={blobName}
                                mode={'create'}
                            />
                        )}
                    />

                    {/* Operational periods */}
                    <HeadingSection icon={<IcSetting />} heading="Time setting" />
                    <Stack
                        p={1.7}
                        sx={{
                            border: '1px solid #DDDDDD',
                            borderWidth: '0px 1px 1px 1px',
                            borderRadius: '0px 0px 4px 4px',
                        }}
                        spacing={2}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{ borderBottom: '1px solid #DDDDDD', pb: 1.7 }}
                        >
                            <Typography>
                                Effective from<span style={{ color: 'red' }}>*</span>:
                            </Typography>
                            <DesktopDatePicker
                                value={startDate}
                                format="DD/MM/YYYY"
                                shouldDisableDate={(date) => validateDatePickerValue(moment(date).toDate()) !== null}
                                onChange={(e) => setStartDate(e!)}
                                slotProps={{
                                    textField: {
                                        inputProps: {
                                            readOnly: true,
                                        },
                                        sx: {
                                            width: 250,
                                        },
                                    },
                                }}
                            />
                        </Stack>
                        <Stack sx={{ borderBottom: '1px solid #DDDDDD', pb: 2 }} spacing={2}>
                            <Typography>
                                Grace period setting<span style={{ color: 'red' }}>*</span>:
                            </Typography>
                            <GracePeriods
                                gracePeriods={gracePeriodForm}
                                control={control}
                                errors={errors}
                                watch={watch}
                            />
                        </Stack>

                        {operationHours.map((item, index, arr) => {
                            return (
                                <OperationalHours
                                    geoZoneHourConfigWithTimeRanges={item as any}
                                    geoZoneGracePeriod={watch().gracePeriods}
                                    onChange={(item, error) => {
                                        const operationHours = {
                                            Weekday: item.Weekday,
                                            TimeRanges: item.TimeRanges,
                                            AllDay: item.AllDay,
                                            Status: item.Status,
                                        };
                                        updateOperationHours(index, operationHours);

                                        setIsOverlap(error);
                                    }}
                                />
                            );
                        })}
                    </Stack>
                    <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                        <Button sx={{ minWidth: 130 }} variant="cancel" onClick={handleNavigateGeoZonesTab}>
                            Cancel
                        </Button>
                        <Button
                            sx={{ minWidth: 130 }}
                            variant="contained"
                            disabled={isDisabled}
                            onClick={handleSubmit(handleAddGeoZone)}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            )}
        </Box>
    );
}
export const HeadingSection = ({
    icon,
    heading,
    style,
}: {
    icon: React.ReactNode;
    heading: string;
    style?: SxProps<Theme>;
}) => {
    return (
        <Box
            sx={{
                p: 2,
                background: '#FAFAFA',
                borderBottom: '1px solid #DDDDDD',
                borderRadius: '4px 4px 0px 0px',
                mt: 4,
                userSelect: 'none',
                ...style,
            }}
        >
            <Stack direction="row" alignItems="center" spacing={1}>
                {icon}
                <Typography variant="h5">{heading}</Typography>
            </Stack>
        </Box>
    );
};
