import BaseCheckbox from '@components/BaseCheckbox';
import IOSSwitch from '@components/IOSSwitch';
import { Button, Collapse, FormControl, FormControlLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { MdOutlineArrowDropUp } from 'react-icons/md';
import { GeoZoneGracePeriod, GeoZoneHourConfigWithTimeRanges } from './AddGeoZones';
import BaseTimePicker from './BaseTimePicker';
type Props = {
    geoZoneHourConfigWithTimeRanges: GeoZoneHourConfigWithTimeRanges;
    geoZoneGracePeriod: GeoZoneGracePeriod[];
    onChange?(geoZoneHourConfigWithTimeRanges: GeoZoneHourConfigWithTimeRanges, error: boolean): void;
};
export default function OperationalHours(props: Props) {
    const [open, setOpen] = useState(false);
    const [isAllDay, setIsAllDay] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [gracePeriod, setGracePeriod] = useState(props.geoZoneGracePeriod?.[0]._fakeId);

    const [state, setState] = useState<GeoZoneHourConfigWithTimeRanges>(props.geoZoneHourConfigWithTimeRanges);
    const [flag, setFlag] = useState(false);

    const handleAddShifts = () => {
        setState((prev) => ({
            ...prev,
            TimeRanges: prev.TimeRanges.concat({ _fakeId: 'df-1', From: undefined, To: undefined } as any),
        }));
        setFlag(false);
    };

    const handleRemoveShifts = (index: number) => {
        setState((prev) => ({ ...prev, TimeRanges: prev.TimeRanges.filter((item, i) => i !== index) }));
        setFlag(false);
    };

    const convertShifts: [number, number][] = state.TimeRanges.map((item) => {
        return [
            moment(item.From).hours() * 60 * 60 + moment(item.From).minutes() * 60,
            moment(item.To).hours() * 60 * 60 + moment(item.To).minutes() * 60,
        ];
    });

    function checkOverlap(timeRanges: [number, number][]): boolean {
        for (let i = 0; i < timeRanges.length - 1; i++) {
            for (let j = i + 1; j < timeRanges.length; j++) {
                const arr1 = timeRanges[i];
                const arr2 = timeRanges[j];
                if (arr1[1] > arr2[0] && arr2[1] > arr1[0]) {
                    return true;
                }
            }
        }

        return false;
    }
    const isOverlap = checkOverlap(convertShifts);

    useEffect(() => {
        setState(props.geoZoneHourConfigWithTimeRanges);
        setIsEnabled(props.geoZoneHourConfigWithTimeRanges.Status);
        setIsAllDay(props.geoZoneHourConfigWithTimeRanges.AllDay);
    }, [props.geoZoneHourConfigWithTimeRanges]);

    useEffect(() => {
        if (!flag) {
            props.onChange?.(state, isOverlap);
            setFlag(true);
        }
    }, [flag, props, state, isOverlap]);

    return (
        <Stack
            direction="column"
            gap={1.5}
            width={'100%'}
            sx={{
                border: `1px solid ${!!open ? '#256428' : '#DDDDDD'}`,
                borderRadius: 1,
                padding: 2,
                pb: !open ? 0 : 2,
                cursor: 'pointer',
            }}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={'space-between'}
                width="100%"
                onClick={() => {
                    setOpen((prev) => !prev);
                }}
            >
                <Stack direction="row" alignItems="center">
                    <IOSSwitch
                        iosSwitchProps={{
                            checked: isEnabled,
                            onChange(event, checked) {
                                setIsEnabled(checked);
                                setState((prev) => ({ ...prev, Status: checked }));
                                setFlag(false);
                            },
                            onClick(event) {
                                event.stopPropagation();
                            },
                        }}
                    />
                    <Typography>
                        {moment().isoWeekday(props.geoZoneHourConfigWithTimeRanges.Weekday).format('dddd')}
                    </Typography>
                </Stack>
                <Stack direction="row" alignItems="center">
                    <FormControlLabel
                        control={
                            <BaseCheckbox
                                checked={isAllDay}
                                onChange={(_, checked) => {
                                    setIsAllDay(checked);
                                    setState(
                                        (prev) =>
                                            ({
                                                ...prev,
                                                TimeRanges: [
                                                    {
                                                        From: !!checked ? moment().startOf('date') : undefined,
                                                        To: !!checked ? moment().endOf('date') : undefined,
                                                        _fakeId: gracePeriod,
                                                    },
                                                ],
                                                AllDay: checked,
                                            } as any)
                                    );
                                    setFlag(false);
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                            />
                        }
                        label={
                            <Typography ml={0.5} mt={0.3}>
                                All day
                            </Typography>
                        }
                        sx={{ ml: 0 }}
                    />
                    <Stack justifyContent="center" sx={{ transform: `rotate(${!!open ? 0 : 180}deg)` }}>
                        <MdOutlineArrowDropUp fontSize={18} color="#85858A" />
                    </Stack>
                </Stack>
            </Stack>
            <Collapse in={!!open}>
                <Stack direction="row" spacing={1} width="100%">
                    <Stack spacing={1.3} width="100%">
                        {state.TimeRanges?.map((item, index, arr) => {
                            return (
                                <Stack direction="row" alignItems="center" spacing={1} key={`${index}+shift`}>
                                    <Stack direction="row" alignItems="center" spacing={1} width="100%">
                                        <Typography>From</Typography>
                                        <BaseTimePicker
                                            marginTop={-255}
                                            width="100%"
                                            value={item.From!}
                                            timePickerProps={{
                                                shouldDisableTime: (time) => false,
                                            }}
                                            onChange={(value) => {
                                                setState((prev) => {
                                                    const clonePrev = cloneDeep(prev);
                                                    clonePrev.TimeRanges[index].From = value;
                                                    clonePrev.TimeRanges[index].To = undefined;
                                                    clonePrev.AllDay = false;
                                                    return clonePrev;
                                                });
                                                setFlag(false);
                                                setIsAllDay(false);
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1} width="100%">
                                        <Typography>to</Typography>
                                        <BaseTimePicker
                                            marginTop={-255}
                                            width="100%"
                                            value={item.To!}
                                            timePickerProps={{
                                                minTime: moment(item.From).add(1, 'minutes'),
                                                shouldDisableTime: (time) => false,
                                            }}
                                            onChange={(value) => {
                                                setState((prev) => {
                                                    const clonePrev = cloneDeep(prev);
                                                    clonePrev.TimeRanges[index].To = value;
                                                    clonePrev.AllDay = false;
                                                    return clonePrev;
                                                });
                                                setFlag(false);
                                                setIsAllDay(false);
                                            }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center" spacing={1} width="100%">
                                        <Typography minWidth={90}>Grace period</Typography>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                value={item._fakeId}
                                                onChange={(e) => {
                                                    const value = e.target.value;
                                                    setGracePeriod(value as any);
                                                    setState((prev) => {
                                                        const clonePrev = cloneDeep(prev);
                                                        clonePrev.TimeRanges[index]._fakeId = value as any;
                                                        return clonePrev;
                                                    });
                                                    setFlag(false);
                                                }}
                                                fullWidth
                                            >
                                                {props.geoZoneGracePeriod
                                                    ?.filter((item) => !!item.Name && !!item.GracePeriod)
                                                    .map((item) => {
                                                        return <MenuItem value={item._fakeId}>{item.Name}</MenuItem>;
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    {index === 0 ? (
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                minWidth: 30,
                                                minHeight: 30,
                                                p: '6px !important',
                                                borderRadius: '8px',
                                                '&:hover': {
                                                    color: (theme) => theme.palette.primary.light,
                                                },
                                            }}
                                            disabled={
                                                state.TimeRanges.length === 3 ||
                                                state.TimeRanges.some((item) => !item.From || !item.To) ||
                                                !!isAllDay ||
                                                !!isOverlap
                                            }
                                            onClick={() => {
                                                handleAddShifts();
                                            }}
                                        >
                                            <AiOutlinePlus />
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="cancelOutlined"
                                            sx={{
                                                minWidth: 30,
                                                minHeight: 30,
                                                p: '6px !important',
                                                borderRadius: '8px',
                                                '&:hover': {
                                                    color: (theme) => theme.palette.grey[700],
                                                },
                                            }}
                                            onClick={() => {
                                                handleRemoveShifts(index);
                                            }}
                                        >
                                            <AiOutlineMinus />
                                        </Button>
                                    )}
                                </Stack>
                            );
                        })}
                        {isOverlap && (
                            <p style={{ color: '#E01B00', marginLeft: 42, fontSize: 12 }}>
                                {'There is at least one overlapping shift, please check and adjust.'}
                            </p>
                        )}
                    </Stack>
                </Stack>
            </Collapse>
        </Stack>
    );
}
