import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;
export default function BatteryMedium(props: Props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.6705 12.3333H2.71053C2.35691 12.3333 2.01777 12.1929 1.76772 11.9428C1.51767 11.6928 1.3772 11.3536 1.3772 11V5.00001C1.3772 4.64638 1.51767 4.30724 1.76772 4.0572C2.01777 3.80715 2.35691 3.66667 2.71053 3.66667H11.6705C12.0242 3.66667 12.3633 3.80715 12.6133 4.0572C12.8634 4.30724 13.0039 4.64638 13.0039 5.00001V5.66667H13.6239C13.8891 5.66667 14.1434 5.77203 14.331 5.95956C14.5185 6.1471 14.6239 6.40146 14.6239 6.66667V9.33334C14.6239 9.59856 14.5185 9.85291 14.331 10.0404C14.1434 10.228 13.8891 10.3333 13.6239 10.3333H13.0039V11C13.0039 11.3536 12.8634 11.6928 12.6133 11.9428C12.3633 12.1929 12.0242 12.3333 11.6705 12.3333ZM2.71053 4.33334C2.53372 4.33334 2.36415 4.40358 2.23913 4.5286C2.1141 4.65362 2.04386 4.82319 2.04386 5.00001V11C2.04386 11.1768 2.1141 11.3464 2.23913 11.4714C2.36415 11.5964 2.53372 11.6667 2.71053 11.6667H11.6705C11.8473 11.6667 12.0169 11.5964 12.1419 11.4714C12.267 11.3464 12.3372 11.1768 12.3372 11V10.1667C12.3374 10.0341 12.3901 9.90704 12.4838 9.81331C12.5776 9.71958 12.7046 9.66685 12.8372 9.66667H13.6239C13.7123 9.66667 13.7971 9.63155 13.8596 9.56904C13.9221 9.50653 13.9572 9.42174 13.9572 9.33334V6.66667C13.9572 6.57827 13.9221 6.49348 13.8596 6.43097C13.7971 6.36846 13.7123 6.33334 13.6239 6.33334H12.8372C12.7046 6.33316 12.5776 6.28043 12.4838 6.1867C12.3901 6.09297 12.3374 5.96589 12.3372 5.83334V5.00001C12.3372 4.82319 12.267 4.65362 12.1419 4.5286C12.0169 4.40358 11.8473 4.33334 11.6705 4.33334H2.71053Z"
                fill="#FAAD14"
            />
            <path
                d="M7.62789 5H3.37211C3.1666 5 3 5.29854 3 5.66681V10.3332C3 10.7015 3.1666 11 3.37211 11H7.62789C7.8334 11 8 10.7015 8 10.3332V5.66681C8 5.29854 7.8334 5 7.62789 5Z"
                fill="#FAAD14"
            />
        </svg>
    );
}
