import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import color from '@theme/Colors';
import React, { useContext } from 'react';

type Props = {
    open: boolean;
    isActive: boolean;
    onClick: () => void;
    icon: JSX.Element;
    label: string;
    isSmallDrawer?: boolean;
};

export default function ItemDrawer({ open, isActive, onClick, icon, label, isSmallDrawer }: Props) {
    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <Tooltip title={label} placement="right">
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        justifyContent: isSmallDrawer || open ? 'initial' : 'center',
                        px: 2.5,
                        borderRadius: '7px',

                        '&:hover': {
                            background: isActive ? color.lightPrimary : color.grey200,
                            outline: 'none',
                        },
                        background: isActive ? color.lightPrimary : '',
                        '& .MuiTypography-root': {
                            color: isActive ? color.priText : '',
                        },
                        '& .MuiListItemIcon-root': {
                            color: isActive ? color.priText : 'rgba(0, 0, 0, 0.54)',
                        },
                        '& .icon-svg-fill': {
                            fill: isActive ? color.priText : '',
                        },
                        '& .icon-svg-stroke': {
                            stroke: isActive ? color.priText : '',
                        },
                        mb: 1,
                    }}
                    onClick={() => onClick()}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: isSmallDrawer || open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>

                    {open && (
                        <ListItemText
                            primary={label}
                            sx={{
                                opacity: isSmallDrawer || open ? 1 : 0,
                                // textTransform: 'capitalize',

                                '& span': {
                                    fontWeight: 400,
                                },
                            }}
                        />
                    )}
                </ListItemButton>
            </Tooltip>
        </ListItem>
    );
}
