import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';

import { CameraWithInfo } from '@EcamModel/model';
import ArrowBackIosIcon from '@assets/jsx-icon/ArrowBackIosIcon';
import ArrowForwardIosIcon from '@assets/jsx-icon/ArrowForwardIosIcon';
import NextSlidesBottom from '@assets/jsx-icon/NextSlidesBottom';
import PrevSlidesBottom from '@assets/jsx-icon/PrevSlidesBottom';
import { appConfig } from '@configs/index';
import { Box, Dialog, IconButton } from '@mui/material';
import color from '@theme/Colors';
import { Swiper as SwiperType } from 'swiper';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

interface Props {
    camera: CameraWithInfo;
}

export default function ImageCarousel(props: Props) {
    const cameraInstalledPhotoBlobURL = appConfig.gateway.cameraInstalledPhotoBlobURL;
    const CameraInstalledPhotos = props.camera.CameraInstalledPhotos || [];
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null | any>(null);
    const [selectedItem, setSelectedItem] = useState<string | undefined>();
    const prevSlidesMainRef = useRef<HTMLButtonElement | null>(null);
    const nextSlidesMainRef = useRef<HTMLButtonElement | null>(null);
    const prevSlidesPreviewRef = useRef<HTMLButtonElement | null>(null);
    const nextSlidesPreviewRef = useRef<HTMLButtonElement | null>(null);

    const slidesPerView = Math.min(CameraInstalledPhotos?.length, 5);

    useEffect(() => {
        if (thumbsSwiper) {
            thumbsSwiper.params.navigation.prevEl = prevSlidesPreviewRef.current;
            thumbsSwiper.params.navigation.nextEl = nextSlidesPreviewRef.current;
            thumbsSwiper.navigation.update();
        }
    }, [thumbsSwiper]);

    useEffect(() => {
        const mainSwiperEl = prevSlidesMainRef.current?.closest('.swiper-container') as any;
        if (mainSwiperEl) {
            const mainSwiper = mainSwiperEl.swiper;
            mainSwiper.params.navigation.prevEl = prevSlidesMainRef.current;
            mainSwiper.params.navigation.nextEl = nextSlidesMainRef.current;
            mainSwiper.navigation.update();
        }
    }, []);

    return (
        <>
            <Box sx={{ position: 'relative', height: '390px' }} mx={2} bgcolor={color.white}>
                <IconButton
                    ref={prevSlidesMainRef}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: 5,
                        transform: 'translateY(-50%)',
                        display: CameraInstalledPhotos.length > 1 ? 'block' : 'none',
                        zIndex: 10,
                        color: '#fff',
                    }}
                >
                    <ArrowForwardIosIcon style={{ fontSize: '24px' }} />
                </IconButton>
                <IconButton
                    ref={nextSlidesMainRef}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: 5,
                        display: CameraInstalledPhotos.length > 1 ? 'block' : 'none',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        color: '#fff',
                    }}
                >
                    <ArrowBackIosIcon style={{ fontSize: '24px' }} />
                </IconButton>
                <Swiper
                    style={{
                        height: '390px',
                    }}
                    loop={true}
                    navigation={{
                        prevEl: prevSlidesMainRef.current,
                        nextEl: nextSlidesMainRef.current,
                    }}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    onSlideChange={(swiper) => setSelectedIndex(swiper.realIndex)}
                >
                    {CameraInstalledPhotos?.map((e, index) => (
                        <SwiperSlide key={index}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    aspectRatio: '16/9',
                                    background: `url(${cameraInstalledPhotoBlobURL}${e.BlobName}) no-repeat`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setSelectedItem(`${cameraInstalledPhotoBlobURL}${e.BlobName}`);
                                }}
                            />
                        </SwiperSlide>
                    ))}

                    {selectedItem && (
                        <Dialog
                            open={true}
                            onClose={() => {
                                setSelectedItem(undefined);
                            }}
                            PaperProps={{
                                sx: {
                                    minWidth: 1280,
                                    minHeight: 720,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    aspectRatio: '16/9',
                                    background: `url(${selectedItem}) no-repeat`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                }}
                            />
                        </Dialog>
                    )}
                </Swiper>
            </Box>

            {CameraInstalledPhotos.length > 1 && (
                <Box
                    margin={'16px 16px'}
                    sx={{
                        padding: '16px 40px',
                        backgroundColor: 'grey.200',
                        borderRadius: '8px',
                        position: 'relative',
                    }}
                >
                    <IconButton
                        ref={prevSlidesPreviewRef}
                        sx={{
                            visibility: CameraInstalledPhotos?.length <= 5 ? 'hidden' : '',
                            position: 'absolute',
                            top: '50%',
                            left: 5,
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            padding: '2px',
                        }}
                    >
                        <PrevSlidesBottom />
                    </IconButton>
                    <IconButton
                        ref={nextSlidesPreviewRef}
                        sx={{
                            visibility: CameraInstalledPhotos?.length <= 5 ? 'hidden' : '',
                            position: 'absolute',
                            top: '50%',
                            right: 5,
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            padding: '2px',
                        }}
                    >
                        <NextSlidesBottom />
                    </IconButton>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={5}
                        slidesPerView={slidesPerView}
                        navigation={{
                            nextEl: nextSlidesPreviewRef.current,
                            prevEl: prevSlidesPreviewRef.current,
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                    >
                        {CameraInstalledPhotos?.map((e, index) => (
                            <SwiperSlide
                                key={index}
                                className={index === selectedIndex ? 'selected' : ''}
                                style={{
                                    cursor: 'pointer',
                                    width: 'calc(20% - 5px)',
                                    boxSizing: 'border-box',
                                    height: '105px',
                                    borderRadius: '8px',
                                    border: index === selectedIndex ? `3px solid ${color.success}` : 'none',
                                    overflow: 'hidden',
                                    objectFit: 'cover',
                                }}
                            >
                                <img
                                    src={`${cameraInstalledPhotoBlobURL}${e.BlobName}`}
                                    alt={`thumb-${index}`}
                                    style={{ width: '100%', height: '105px', objectFit: 'cover' }}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            )}
        </>
    );
}
