import NoDataIcon from '@assets/jsx-icon/NoDataIcon';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import color from '@theme/Colors';
import React from 'react';

interface Props {
    sx?: SxProps<Theme>;
    changeText?: string;
}

export default function ListNoData(props: Props) {
    return (
        <Stack
            width={'100%'}
            direction={'column'}
            justifyContent={'center'}
            minHeight={props.sx ? `${props.sx}` : '200px'}
            sx={{ border: '1px solid #DDDDDD', borderRadius: '4px', ...props.sx }}
        >
            <Stack direction={'column'} alignItems={'center'}>
                <NoDataIcon />
                <Typography sx={{ color: color.grey600 }} textAlign={'center'}>
                    {props.changeText ? props.changeText : 'No data here'}
                </Typography>
            </Stack>
        </Stack>
    );
}
