import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ExemptionPeriods } from '@EcamModel/model/ExemptionPeriods';
import { AxiosInstance } from 'axios';
import { IExemptionPeriodHttpController } from '../IExemptionPeriodHttpController';

export class ExemptionPeriodController
    extends BaseHttpController<ExemptionPeriods>
    implements IExemptionPeriodHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'exemption-period', client);
    }
}
