import FilterIcon from '@assets/jsx-icon/FilterIcon';
import { Box, Button, Grid, IconButton, styled, SxProps, Theme, Tooltip, Typography } from '@mui/material';
import color from '@theme/Colors';
import React, { ReactNode } from 'react';

type BaseFilterProps = {
    rightTitleContent?: React.ReactNode;
    handleResetFilter: () => void;
    filterChild?: React.ReactNode;
    baseStyle?: SxProps<Theme>;
    refreshFilter?: ReactNode;
};

export default function FilterRoot(props: BaseFilterProps) {
    return (
        <BoxRadiusGrey sx={{ ...props.baseStyle }}>
            <Grid container gap={2}>
                <GFlexRow item xs={12} justifyContent={props.rightTitleContent ? 'space-between' : 'left'}>
                    <GFlexRowCenter item gap={2}>
                        <FilterIcon />
                        <Typography>Filter</Typography>
                        <Button variant="cancelSmall" onClick={props.handleResetFilter}>
                            Reset filter
                        </Button>
                        {props.refreshFilter && <>{props.refreshFilter}</>}
                    </GFlexRowCenter>
                    {props.rightTitleContent}
                </GFlexRow>

                {props.filterChild}
            </Grid>
        </BoxRadiusGrey>
    );
}

export const BoxRadiusGrey = styled(Box)({
    width: '100%',
    background: color.grey100,
    borderRadius: 10,
    padding: 16,
});

export const GFlexRow = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const GFlexRowCenter = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});
