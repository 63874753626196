import LoadingTextSkeleton from '@components/skeleton/LoadingTextSkeleton';
import { Divider, Stack, Typography } from '@mui/material';
import { useCameraDetailContext } from '@pages/cameras/hook/CameraDetailsProvider';
import color from '@theme/Colors';
import { ReactNode, useEffect } from 'react';
import { transformScheduleData } from 'src/helpers';
import {
    BaseSectionCollapse,
    DataSectionHasSubItem,
    SectionHasSubItemHeader,
    SectionHasSubItemProps,
} from './BasicInfoMilesightCameraPTZ';
import useMileSightPTZ, { SectionsCollapse } from '@pages/cameras/hook/useMilesightPTZ';

enum Task {
    Close,
    AutoScan,
    Preset,
    Patrol,
    Pattern,
    Check,
    TiltScan,
    PanoramaScan,
}

export const taskTypeMapping: { [key: number]: string } = {
    [Task.Close]: 'Close',
    [Task.AutoScan]: 'Auto Scan',
    [Task.Preset]: 'Preset',
    [Task.Patrol]: 'Patrol',
    [Task.Pattern]: 'Pattern',
    [Task.Check]: 'Check',
    [Task.TiltScan]: 'Tilt Scan',
    [Task.PanoramaScan]: 'Panorama Scan',
};

type Props = {
    isOpenCollapse: { [key in SectionsCollapse]: boolean };
    handleToggleCollapse: (section: SectionsCollapse) => void;
};

export default function InitialPositionMilesightsCameraPTZ(props: Props) {
    const handleEdit = () => {
        console.log('Edit button clicked');
    };

    const { camera, filteredCamera } = useCameraDetailContext();
    const { isOpenCollapse, handleToggleCollapse } = props;
    const { PTZScheduledTask, isLoadingMilesights, getScheduledTaskMilesightsPTZ } = useMileSightPTZ();

    useEffect(() => {
        if (camera?.VendorCameraId === null) {
            getScheduledTaskMilesightsPTZ(filteredCamera!);
        }
    }, [filteredCamera]);

    const dataSectionHasSubItems: DataSectionHasSubItem[] = transformScheduleData(
        PTZScheduledTask.ScheduleList,
        (dayName, schedule) => ({
            label: `${dayName}: `,
            value: schedule,
        })
    );

    const sectionHasSubItemProps: SectionHasSubItemProps = {
        title: 'Schedule settings',
        data: dataSectionHasSubItems,
    };

    const sectionsInitialPosition: SectionHasSubItemProps[] = [
        {
            title: sectionHasSubItemProps.title,
            data: sectionHasSubItemProps.data,
        },
        {
            title: 'Tasks interference settings',
            data: [
                {
                    label: 'Latency time:',
                    value: (
                        <LoadingTextSkeleton
                            conditions={isLoadingMilesights.PTZScheduledTask}
                            value={`${PTZScheduledTask.RecoveryTime}s (5-720s)`}
                            style={{
                                width: '50%',
                            }}
                        />
                    ),
                },
            ],
        },
    ];

    return (
        <Stack id="initialPosition" border={'1px solid #E4E7EC'} borderRadius={2} flex={1}>
            {/* Basic Information */}
            <SectionHasSubItemHeader id="" title="Initial position" onEdit={handleEdit} />

            {/* Scheduled tasks */}
            <BaseSectionCollapse
                id="scheduledTasks"
                title="Scheduled tasks"
                isOpen={isOpenCollapse.scheduledTasks}
                onToggle={() => {
                    handleToggleCollapse(SectionsCollapse.ScheduledTasks);
                }}
                sections={sectionsInitialPosition}
            />
        </Stack>
    );
}

export const formatTaskSchedule = (schedule: string): ReactNode => {
    if (schedule?.trim() === '') {
        return (
            <Stack direction="row" width={'100%'}>
                <Typography variant="body2" width={'100%'}>
                    &#8212;
                </Typography>
            </Stack>
        );
    }

    const tasks = schedule?.split(';').filter((task) => task.trim() !== '');

    const formattedTasks = tasks?.map((task, index) => {
        const parts = task?.split(':');

        if (parts?.length === 4) {
            const startTime = `${parts[0]}:${parts[1]}`;
            const stopTime = `${parts[2]}:${parts[3]}`;

            return `${startTime} - ${stopTime}`;
        } else if (parts?.length === 6) {
            const startTime = `${parts[0]}:${parts[1]}`;
            const stopTime = `${parts[2]}:${parts[3]}`;
            const type = taskTypeMapping[parseInt(parts[4])] || 'Unknown Type';
            const id = parts[5];

            return (
                <Stack key={index} direction="row" width={'100%'} mb={'10px'}>
                    <Typography variant="body2" color={color.grey600} width={'27%'} mt={'2px'}>
                        {startTime} - {stopTime}
                    </Typography>

                    <Divider
                        orientation="horizontal"
                        sx={{
                            borderRightWidth: 1,
                            borderColor: '#E4E7EC',
                            marginLeft: '4px',
                            marginRight: '16px',
                        }}
                    />

                    <Typography variant="body1" color={color.textPrimary} width={'max-content'}>
                        {type}
                    </Typography>
                </Stack>
            );
        } else {
            return (
                <Stack key={index} direction="row" width={'100%'}>
                    <Typography variant="body1" color={color.textPrimary} width={'100%'}>
                        Invalid Format
                    </Typography>
                </Stack>
            );
        }
    });

    return (
        <>
            <Typography variant="body1">
                {formattedTasks?.filter((task) => typeof task === 'string').join(', ')}
            </Typography>
            {formattedTasks?.filter((task) => typeof task !== 'string')}
        </>
    );
};
