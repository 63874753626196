import { DrawerPage } from '@components/drawer/useDrawer';
import { appConfig } from '@configs/index';
import EmbedReport from './components/EmbedReport';
import useEmbedReport from './hooks/useEmbedReport';
import { Typography } from '@mui/material';

export default function InsightHeatMap() {
    const { accessToken, embedUrl, hasPermission } = useEmbedReport({
        reportId: appConfig.report.powerBIReportHeatMapId,
        activeItem: DrawerPage.ReportHeatMap,
    });

    return (
        <EmbedReport
            reportId={appConfig.report.powerBIReportHeatMapId}
            embedUrl={embedUrl}
            accessToken={accessToken}
            hasPermission={hasPermission}
            errors={
                <>
                    <Typography variant="h2" fontWeight={400} color="primary.dark">
                        Sorry, you do not have permission to view this report.
                    </Typography>
                    <Typography
                        variant="h6"
                        fontWeight={400}
                        align="center"
                        sx={{
                            '& > a': { color: (theme) => theme.palette.secondary.main },
                            '& > a:hover': {
                                color: (theme) => theme.palette.secondary.dark,
                                transition: '0.2s ease-in-out',
                            },
                        }}
                    >
                      Please visit this <a href='https://app.powerbi.com/reportEmbed?reportId=70002043-4285-4f84-942e-51b6866578d3&autoAuth=true&ctid=0babbab9-2561-48f0-a5de-0b4ac6473951' target='_blank' rel="noreferrer">link</a> to gain first-time access, then come back and try again.
                    </Typography>
                </>
            }
        />
    );
}
