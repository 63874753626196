import color from '@theme/Colors';
import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

function VerificationIcon(props: Props) {
    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            className="icon-svg-stroke"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
            stroke={color.grey600}
        >
            <path
                strokeLinecap="round"
                strokeWidth="1.5"
                d="M15 10l-2.021 2.509C12.178 13.503 11.777 14 11.25 14c-.527 0-.927-.497-1.728-1.491L9 11.862M18.89 16c1.6-2.92 2.012-6.25 2.093-8.363.046-1.21-.953-2.137-2.165-2.137h-.589a1.75 1.75 0 01-1.75-1.75A1.75 1.75 0 0014.729 2H9.563a2.042 2.042 0 00-2.042 2.042A2.042 2.042 0 015.48 6.083h-.31c-1.207 0-2.206.92-2.145 2.126.099 1.95.532 4.96 2.086 7.791.423.77.997 1.543 1.634 2.28 1.98 2.287 2.97 3.43 5.255 3.43 2.285 0 3.275-1.143 5.255-3.43.637-.737 1.212-1.51 1.634-2.28z"
            ></path>
        </svg>
    );
}

export default VerificationIcon;
