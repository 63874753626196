import { IconButton, Tooltip } from '@mui/material';
import color from '@theme/Colors';
import React, { ReactNode } from 'react';

export type ToolTipProps = {
    icon: ReactNode;
    tooltipTitle?: ReactNode;
    onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export default function TooltipIconButton(props: ToolTipProps) {
    return (
        <Tooltip title={props.tooltipTitle ?? 'label'} placement="top" onClick={props.onClick}>
            <IconButton
                sx={{
                    '&.MuiIconButton-root': {
                        background: color.grey200,
                        boxShadow: `0 0 0 4px ${color.grey100}`,
                    },
                    width: 30,
                    height: 30,
                }}
                size="small"
            >
                {props.icon}
            </IconButton>
        </Tooltip>
    );
}
