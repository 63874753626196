import { Menu, MenuProps, styled } from '@mui/material';
import { getCenterFrame } from '@pages/cameras/details/utils';
import color from '@theme/Colors';
import React, { FC } from 'react';
import { Circle, Line, Text } from 'react-konva';

export interface GeoZoneProps {
    frame: {
        x: number;
        y: number;
        geoZoneId: number | undefined;
    }[];
    index: number;
    hoveredFrame?: number | null;
}

const GeoZonesHistoriesCameraSolar: FC<GeoZoneProps> = (props) => {
    const { frame, index } = props;
    const center = getCenterFrame(frame);

    return (
        <React.Fragment key={`frame-${index}`}>
            <Line points={frame.flatMap((point) => [point.x, point.y])} stroke={'blue'} strokeWidth={3} />
            <Circle x={center.x} y={center.y} radius={15} fill={'#FFFFFF'} />
            <Text
                x={center.x}
                y={center.y}
                text={String(`${index + 1}`)}
                fontSize={14}
                fill={color.textPrimary}
                align={'center'}
                verticalAlign={'middle'}
                offsetX={4}
                offsetY={6}
            />
        </React.Fragment>
    );
};

export default GeoZonesHistoriesCameraSolar;

export const StyledMenuList = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 250,
        color: theme.palette.grey[300],
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            // '&:active': {
            //     backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
            // },
        },
    },
}));
