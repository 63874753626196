import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraView, PhotoSnapShotByPTZ, ViewPTZ, ViewPreset } from '@EcamModel/model/CameraView';
import { AxiosInstance } from 'axios';
import { ICameraViewHttpController, IGetPhotoSnapShotByPTZ } from '../ICameraViewHttpController';
import { ISendActionGet } from '../IPTZCamConfigureRequestHttpController';

export class CameraViewHttpController extends BaseHttpController<CameraView> implements ICameraViewHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-view', client);
    }

    async getListPreset(props: ISendActionGet): Promise<ViewPreset[]> {
        return this.doPost({ path: 'get-list-preset', body: props }).then((res) => res.data);
    }

    async getPhotoSnapShotByPTZ(props: IGetPhotoSnapShotByPTZ): Promise<PhotoSnapShotByPTZ> {
        return this.doPost({ path: 'get-photo-snapshot-by-ptz', body: props }).then((res) => res.data);
    }
}
