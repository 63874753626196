import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import color from '@theme/Colors';
import React from 'react';

interface TabItem {
    index: number;
    label: string;
    icon?: React.ReactNode;
}

interface TabItemsProps {
    tabs: TabItem[];
    value: number;
    onChange: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void;
    style?: SxProps<Theme>;
}

export default function BaseCustomTabs(props: TabItemsProps) {
    const { onChange, tabs, value, style } = props;
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            {tabs.map((item) => {
                const conditionSelector = value === item.index;
                return (
                    <Button
                        key={item.index}
                        sx={{
                            py: 1,
                            px: 2,
                            backgroundColor: 'transparent',
                            borderRadius: '8px 8px 0px 0px',
                            borderBottom: `2px solid ${conditionSelector ? color.success : color.white}`,
                            cursor: 'pointer',
                            transition: '.2s ease-in-out',
                            ':hover': {
                                backgroundColor: conditionSelector ? '#E8F5E9' : color.grey100,
                                borderBottom: `2px solid ${conditionSelector ? color.success : color.grey100}`,
                            },
                            ...style,
                        }}
                        onClick={(e) => {
                            if (!conditionSelector) {
                                onChange(e, item.index);
                            }
                        }}
                    >
                        {item.icon && (
                            <Box width={16} height={16} color={conditionSelector ? color.success : '#85858A'}>
                                {item.icon}
                            </Box>
                        )}
                        <Typography color={conditionSelector ? color.success : '#85858A'}>{item.label}</Typography>
                    </Button>
                );
            })}
        </Stack>
    );
}
