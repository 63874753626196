import { DrawerPage } from '@components/drawer/useDrawer';
import { appConfig } from '@configs/index';
import EmbedReport from './components/EmbedReport';
import useEmbedReport from './hooks/useEmbedReport';
export default function ReportBI() {
    const { accessToken, embedUrl, hasPermission } = useEmbedReport({
        reportId: appConfig.report.powerBIReportId,
        activeItem: DrawerPage.Report,
    });

    return (
        <EmbedReport
            reportId={appConfig.report.powerBIReportId}
            embedUrl={embedUrl}
            accessToken={accessToken}
            hasPermission={hasPermission}
        />
    );
}
