import { Fade, Skeleton, SxProps, Typography } from '@mui/material';
import color from '@theme/Colors';
import { Fragment, ReactNode } from 'react';

type BasePropsTextSkeleton = {
    conditions: boolean;
    style?: SxProps;
    value: ReactNode;
};
export default function LoadingTextSkeleton(props: BasePropsTextSkeleton) {
    const { conditions, style, value } = props;
    return (
        <Typography>
            {conditions ? (
                <Skeleton
                    animation="wave"
                    sx={{
                        borderRadius: '4px',
                        backgroundColor: color.grey200,
                        ...style,
                    }}
                />
            ) : (
                <Fragment>{value}</Fragment>
            )}
        </Typography>
    );
}
