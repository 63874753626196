import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import {
    IAutoArrangePhotoUploadTime,
    ISolar4gCamPhotoUploadScheduleHttpController,
} from '../ISolar4gCamPhotoUploadScheduleHttpController';
import { Solar4gPhotoUploadSchedule } from '@EcamModel/model/Solar4gPhotoUploadSchedule';

export class Solar4gCamPhotoUploadScheduleHttpController
    extends BaseHttpController<{}>
    implements ISolar4gCamPhotoUploadScheduleHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'solar4g-photo-upload-schedule', client);
    }

    async autoArrangePhotoUploadTime(props: IAutoArrangePhotoUploadTime): Promise<Solar4gPhotoUploadSchedule> {
        return await this.doPost({ path: `auto-arrange-photo-upload-time`, body: props }).then((res) => res.data);
    }

    async getPhotoUploadTimeLatest(): Promise<Solar4gPhotoUploadSchedule> {
        return await this.doGet({ path: `get-photo-upload-time-latest` }).then((res) => res.data);
    }
}
