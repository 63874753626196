import color from '@theme/Colors';
import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

function DashboardIcon(props: Props) {
    return (
        <svg
            {...props}
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke={color.grey600}
            className="icon-svg-stroke"
        >
            <path d="M6.88 18.15V16.08" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M12 18.15V14.01" strokeWidth="1.5" strokeLinecap="round" />
            <path d="M17.12 18.15V11.93" strokeWidth="1.5" strokeLinecap="round" />
            <path
                d="M17.12 5.85L16.66 6.39C14.11 9.37 10.69 11.48 6.88 12.43"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path d="M14.1899 5.85H17.1199V8.77" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default DashboardIcon;
