import IcEdit from '@assets/details-camera-icons/IcEdit';
import ICDelete from '@assets/jsx-icon/ICDelete';
import BaseListNote, { tableHeaders } from '@components/BaseListNote';
import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import StyledDateRangePicker from '@components/date-range-select/StyledDateRangePicker';
import FilterRoot from '@components/filter/FilterRoot';
import { CellOfRow } from '@components/tables';
import { IPopUp } from '@hooks/usePopUp';
import { Box, Button, Divider, Grid, IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DangerTooltip } from '@pages/cameras/list/components/TooltipDangerIconButton';
import color from '@theme/Colors';
import { useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { formatDateTime } from 'src/helpers';
import { Mode, useCameraConfig } from '../hook/CameraConfigProvider';
import { cameraSortByStatus } from '@pages/cameras/list';
import { cameraNoteSortByType } from '@pages/cameras/details/components/CameraInformation';
import SearchTypeSelect from '@pages/cameras/list/components/SearchTypeSelect';

type Props = IPopUp & {};

export default function PopUpNoteInCamera(props: Props) {
    const { open, onClose } = props;
    const cameraConfigContext = useCameraConfig();
    const {
        listNotes,
        setMode,
        isLoading,
        filterNote,
        setFilterNote,
        setIdNote,
        idCamera,
        debouncedValueSearchNote,
        handleGetListNote,
        setSearchValue,
        searchValue,
        filteredNameCamera,
        popUpAddOrUpdateNoteInCamera,
        popUpConfirmDeleteNoteHistory,
        filter,
    } = cameraConfigContext;

    useEffect(() => {
        if (!open) return;
        setFilterNote({
            page: 1,
            from: undefined,
            to: undefined,
            sortedOrder: cameraSortByStatus[1],
            sort: cameraNoteSortByType[0],
        });
    }, [open]);

    useEffect(() => {
        if (idCamera) {
            handleGetListNote(idCamera);
        }
    }, [
        filterNote.page,
        filterNote.from,
        filterNote.to,
        filterNote.sort,
        filterNote.sortedOrder,
        debouncedValueSearchNote,
        idCamera,
    ]);

    return (
        <PopUpBase
            title={<Typography variant="h4">Camera note: {filteredNameCamera?.CameraName}</Typography>}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'lg',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                },
            }}
            open={open}
            onClose={onClose}
            hideConfirm
            desc={
                <Box>
                    <Stack
                        paddingX={2}
                        paddingY={1}
                        bgcolor={color.grey100}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Typography fontSize={16}>Note</Typography>
                        <Tooltip title="Add note" placement="top" arrow>
                            <IconButton
                                sx={{
                                    borderRadius: '3px',
                                    width: '24px',
                                    height: '24px',
                                    color: 'white',
                                    backgroundColor: '#2E7D32',
                                    ':hover': {
                                        backgroundColor: '#256428',
                                    },
                                    padding: '4px',
                                    '&.MuiIconButton-root.Mui-disabled': {
                                        backgroundColor: '#eee',
                                    },
                                }}
                                onClick={() => {
                                    setMode(Mode.create);
                                    popUpAddOrUpdateNoteInCamera.setTrue();
                                    props.onClose?.();
                                }}
                            >
                                <MdAdd style={{ width: '16px', height: '16px' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                    <Stack border={'1px solid #E4E7EC'} paddingY={1} paddingX={2} spacing={2}>
                        <Stack mt={2}>
                            <FilterRoot
                                handleResetFilter={() => {
                                    setFilterNote({
                                        page: 1,
                                        from: undefined,
                                        to: undefined,
                                        sortedOrder: cameraSortByStatus[1],
                                        sort: cameraNoteSortByType[0],
                                    });
                                    setSearchValue('');
                                }}
                                rightTitleContent={
                                    <Grid container xs={9} spacing={1} justifyContent={'flex-end'}>
                                        <Grid xs={5}>
                                            <SearchTypeSelect
                                                startAdornment={
                                                    <Stack direction={'row'}>
                                                        <SearchTypeSelect
                                                            listData={cameraSortByStatus}
                                                            value={filterNote?.sortedOrder!}
                                                            keyEqual="Id"
                                                            keyLabel="Name"
                                                            handleChange={(e) => {
                                                                setFilterNote((prev) => ({
                                                                    ...prev,
                                                                    sortedOrder: e,
                                                                    page: 1,
                                                                }));
                                                            }}
                                                            label="Sort by"
                                                            minWidth={60}
                                                        />
                                                        <Divider
                                                            orientation="vertical"
                                                            flexItem
                                                            sx={{
                                                                mx: '5px',
                                                                borderRightWidth: 1,
                                                                borderColor: color.grey600,
                                                            }}
                                                            variant="middle"
                                                        />
                                                    </Stack>
                                                }
                                                listData={cameraNoteSortByType}
                                                value={filterNote?.sort!}
                                                keyEqual="Id"
                                                keyLabel="Name"
                                                handleChange={(e) => {
                                                    setFilterNote((prev) => ({ ...prev, sort: e, page: 1 }));
                                                }}
                                                label="Sort by"
                                                minWidth={60}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                                filterChild={
                                    <Grid2 container xs={12} spacing={1} flex={1}>
                                        <Grid2 xs={6}>
                                            <BaseTextField
                                                placeholder={'Search by email'}
                                                value={searchValue}
                                                onChange={(e) => {
                                                    setSearchValue(e.target.value);
                                                    setFilterNote({
                                                        page: 1,
                                                        from: filterNote.from,
                                                        to: filterNote.to,
                                                        sortedOrder: filterNote.sortedOrder,
                                                        sort: filterNote.sort,
                                                    });
                                                }}
                                                maxLength={50}
                                                InputProps={{
                                                    endAdornment: (
                                                        <FiSearch
                                                            color="disabled"
                                                            size={16}
                                                            style={{ color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }}
                                                        />
                                                    ),
                                                }}
                                            />
                                        </Grid2>
                                        <Grid2 xs={6}>
                                            <StyledDateRangePicker
                                                value={{
                                                    startDate: filterNote?.from || undefined,
                                                    endDate: filterNote?.to || undefined,
                                                }}
                                                onChange={(value) => {
                                                    setFilterNote((prev) => ({
                                                        ...prev,
                                                        page: 1,
                                                        from: value.startDate,
                                                        to: value.endDate,
                                                    }));
                                                }}
                                                dateReset={{ startDate: undefined, endDate: undefined }}
                                                label={'Select date'}
                                            />
                                        </Grid2>
                                    </Grid2>
                                }
                            />
                        </Stack>

                        <BaseListNote
                            isCheckedScroll
                            paging={listNotes}
                            isLoading={isLoading}
                            tableHeaders={[...tableHeaders, '']}
                            styleContainer={{ mt: 2, mb: 5 }}
                            setPage={(page) => {
                                setFilterNote((prev) => ({ ...prev, page }));
                            }}
                            renderedItem={(item) => {
                                return (
                                    <>
                                        <CellOfRow value={formatDateTime(item.Created!)} align="left" />
                                        <CellOfRow value={item.CreatedBy ?? ''} align="left" />
                                        <CellOfRow value={formatDateTime(item.Updated!)} align="left" />
                                        <TableCell
                                            align="left"
                                            sx={{
                                                wordWrap: 'break-word',
                                                whiteSpace: 'pre-wrap',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            <Typography>{item.Notes.trim() ?? ''}</Typography>
                                        </TableCell>
                                        <CellOfRow
                                            value={
                                                <>
                                                    <Stack direction={'row'} spacing={0.7} alignItems={'center'}>
                                                        <Tooltip placement="top" title="Edit">
                                                            <Box
                                                                sx={{
                                                                    color: '#85858A',
                                                                    transition: '.2s ease-in-out',
                                                                    cursor: 'pointer',
                                                                    '&:hover': {
                                                                        color: (theme) => theme.palette.secondary.main,
                                                                    },
                                                                }}
                                                                onClick={() => {
                                                                    setMode(Mode.edit);
                                                                    setIdNote(item.Id ?? 0);
                                                                    popUpAddOrUpdateNoteInCamera.setTrue();
                                                                    props.onClose?.();
                                                                }}
                                                            >
                                                                <IcEdit />
                                                            </Box>
                                                        </Tooltip>
                                                        <DangerTooltip placement="top" title="Delete">
                                                            <Box
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setIdNote(item.Id ?? 0);
                                                                    popUpConfirmDeleteNoteHistory.setTrue();
                                                                }}
                                                            >
                                                                <ICDelete fontSize={18} />
                                                            </Box>
                                                        </DangerTooltip>
                                                    </Stack>
                                                </>
                                            }
                                            align="left"
                                        />
                                    </>
                                );
                            }}
                        />
                    </Stack>
                </Box>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Close
                    </Button>
                </Stack>
            }
        />
    );
}
