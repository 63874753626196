import { BaseModel } from '@Core';
import { TypeInfo } from './Solar4gCamera';

export interface Solar4gCellularInfo extends BaseModel {
    DevMac: string;

    DevName: string;

    Enable: number;

    Apn: string;

    UserName: string;

    Password: string;

    PinCode: string;

    AccessNum: string;

    AuthType: CellularInfoAuthType;

    NetworkType: CellularInfoNetworkType;

    Mtu: number;

    BillDay: number;

    RemoteWake: number;

    CallWake: number;

    PingEnable: number;

    PingAddr: string;

    Redial: number;

    Reset: number;

    LastBill: number;

    Traffic: number;

    IpAddr: string;

    Gateway: string;

    Dns: string;

    InfoType: TypeInfo;
}

export enum CellularInfoAuthType {
    Auto,
    PAP,
    CHAP,
    MS_CHAP,
    MS_CHAP_V2,
}

export enum CellularInfoNetworkType {
    AUTO,
    ONLY_4G,
    ONLY_3G,
    ONLY_2G,
}
