import { omit } from 'lodash';
import { DependencyList, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { StyledAutocompleteProps } from '.';
import { ListProps, Paging } from '@Core';

export default function useStyledAutocomplete<T, P extends ListProps<T>>(
    props: UseAutocomplete<T, P>
): TypeReturn<T, P> {
    const { listProps } = props;

    const [state, setState] = useState<State<T>>({
        initLoading: !props.disabled,
        searchLoading: false,
        hasNextPage: false,
        isNextPageLoading: false,
        paging: {
            page: 1,
            pageSize: props.listProps?.pageSize || 20,
            rows: [],
            total: 0,
            totalPages: 1,
        },
    });

    const getList = async (_props?: Partial<P>, reset?: boolean) => {
        if (props.list) {
            setState((prev) => {
                const options = props.list?.options ?? [];
                const rows = options.filter((o) =>
                    props.list?.isFiltered ? props.list.isFiltered(o, _props?.search?.content || '') : false
                );
                const total = options.length;

                const _state: State<T> = {
                    hasNextPage: false,
                    isNextPageLoading: false,
                    paging: {
                        page: 1,
                        pageSize: 100000,
                        rows: rows,
                        total: total,
                        totalPages: 1,
                    },
                    searchLoading: false,
                    initLoading: false,
                };

                return _state;
            });
        } else {
            setState((prev) => ({ ...prev, isNextPageLoading: true }));

            try {
                const _filter: P = {
                    page: 1,
                    pageSize: 20,
                    filter: {
                        ...listProps?.filter,
                        ..._props?.filter,
                    },
                    ...omit(listProps, 'filter'),
                    ...omit(_props, 'filter'),
                    search: { fields: listProps?.search?.fields ?? [], content: _props?.search?.content ?? '' },
                } as P;
                if (reset) {
                    _filter.filter = {};
                    if (_filter.search) _filter.search.content = '';
                    _filter.page = 1;
                }

                const res = await props.getList!(_filter);

                setState((prev) => ({
                    initLoading: false,
                    searchLoading: false,
                    isNextPageLoading: false,
                    paging: {
                        ...res,
                        rows: _props?.page === 1 ? res.rows : prev.paging.rows.concat(res.rows),
                    },
                    hasNextPage: res.page < res.totalPages,
                }));
            } catch (error) {}
        }
    };

    const reset = () => {
        setState((prev) => ({ ...prev, initLoading: true }));
        return getList(undefined, true);
    };

    const getListFn = (_props?: Partial<P>) => {
        setState((prev) => ({ ...prev, initLoading: true }));
        return getList(_props);
    };

    const fetchMore = (searchValue: string) => {
        getList({
            page: state.paging.page + 1,
            search: { content: searchValue },
        } as P);
    };
    const handleChangeSearch = (text: string) => {
        setState((prev) => ({ ...prev, searchLoading: true }));
        getList({ page: 1, search: { content: text } } as P);
    };

    useEffect(() => {
        if (!props.disabled) {
            setState((prev) => ({ ...prev, initLoading: true }));
            getList({ page: 1 } as any);
        }
    }, [...(props.dependencyList || [])]);

    const toggleLoading = () => {
        setState((prev) => ({ ...prev, initLoading: !prev.initLoading }));
    };

    return {
        ...props,
        loadNextPage: fetchMore,
        handleChangeSearch,
        paging: state.paging,
        hasNextPage: state.hasNextPage,
        isNextPageLoading: state.isNextPageLoading,
        searchLoading: state.searchLoading,
        loading: props.loading || state.initLoading,
        getList: getListFn,
        reset,
        toggleLoading,
    };
}

export type UseAutocomplete<T, P extends ListProps<T>> = {
    readOnly?: boolean;
    loading?: boolean;
    disabled?: boolean;
    list?: {
        options: T[];
        isFiltered(option: T, searchText: string): boolean;
    };
    getList?(props: P): Promise<Paging<T>>;
    listProps?: Partial<P>;
    dependencyList?: DependencyList;
};

type State<T> = {
    initLoading?: boolean;
    searchLoading: boolean;
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    paging: Paging<T>;
};

export type TypeReturn<T, P> = Pick<
    StyledAutocompleteProps<T>,
    | 'loadNextPage'
    | 'handleChangeSearch'
    | 'paging'
    | 'hasNextPage'
    | 'isNextPageLoading'
    | 'searchLoading'
    | 'loading'
    | 'value'
    | 'onChange'
    | 'isSync'
    | 'readOnly'
> & { getList(props?: P): Promise<void>; reset(): void; toggleLoading: VoidFunction };
