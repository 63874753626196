import { Container } from "@mui/material";
import React, { CSSProperties } from "react";
import { SyncLoader } from "react-spinners";
import color from "@theme/Colors";

export default function LoadingScreen({ isLoading }: { isLoading: boolean }) {
	return (
		<Container
			sx={{
				width: "100vw",
				height: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<SyncLoader
				color={color.primary}
				loading={isLoading}
				cssOverride={override}
				size={20}
				aria-label="Loading Spinner"
				data-testid="loader"
			/>
		</Container>
	);
}

const override: CSSProperties = {
	display: "block",
	margin: "0 auto",
	borderColor: "red",
};
