import { BaseModel } from '@Core';
import { CameraExemptionPeriodAuditEvent } from './CameraExemptionPeriodAudit';
import { CameraPhotos } from './CameraPhotos';
import { ExemptionPeriods } from './ExemptionPeriods';
import { GeoZones } from './GeoZones';
import { Solar4gCamera } from './Solar4gCamera';
import { Vendor } from './Vendor';
import { Zones } from './Zones';
import { CameraInstalledPhotos } from './CameraInstalledPhotos';
import { PTZCamera } from './PTZCamera';
import { CameraView } from './CameraView';

export interface CameraWithInfo extends Camera {
    ZoneName?: string;
    LocationName?: string;
    Mac?: string;
    LatestPhoto?: Date;
    IsAbleChangeZone?: boolean;
}

export interface StatisticTotal {
    TotalStepOne: number;
    TotalSteTwo: number;
    TotalStepWip: number;
}

export interface Camera extends BaseModel {
    ZoneId: number;
    Zone?: Zones;

    Name: string;

    GMT?: number;

    Status: Status;

    DeployedDate: Date;

    VendorId: number;
    Vendor?: Vendor;

    VendorCameraId?: number;
    Solar4gCamera?: Solar4gCamera;

    PTZCameraId?: number;
    PTZCamera?: PTZCamera;

    TimeZone?: string;

    IP: string;

    UserName: string;

    PassWord: string;

    LensCapacity?: string;

    ServiceType: CameraServiceType;
    ANPRCameraId: number | null;

    GeoZones?: GeoZones[];

    CameraPhotos?: CameraPhotos[];

    Notes?: string;

    Asset?: string;

    LastPhotoId?: number;

    PurposeType?: PurposeType;

    PcnByCamera?: number;
    ExemptionPeriods?: ExemptionPeriods[];
    ExemptionPeriodAuditEvents?: CameraExemptionPeriodAuditEvent[];
    IsVerificationsEnabled?: boolean;
    EnablePcnVerificationAt?: Date;

    CameraInstalledPhotos?: CameraInstalledPhotos[];

    SolarPanelPosition?: SolarPanelPositionType;

    CameraViews?: CameraView[];
}

export enum Status {
    Active,
    Inactive,
    NotAvailable,
    Deleted,
}

export enum PurposeType {
    Prospective,
    Testing,
    Live,
    OnHold,
    Decommissioned,
    Deleted,
}

export enum BatteryStatus {
    Normal,
    Low,
    Critical,
}

export enum WifiStatus {
    DisConnected,
    Connected,
}

export enum CameraServiceType {
    ECamTechOps,
    ANPRTechOps,
    ANPRTechOpsV2,
    ECamTechOps_V2,
}

export enum SolarPanelPositionType {
    NNE, // (North-North-East)

    ENE, // (East-North-East)

    ESE, // (East-South-East)

    SSE, // (South-South-East)

    SSW, // (South-South-West)

    WSW, // (West-South-West)

    WNW, // (West-North-West)

    NNW, // (North-North-west)
}
