import { Skeleton, Stack } from '@mui/material';
import color from '@theme/Colors';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function LoadingBreadCrumbs() {
    return (
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Skeleton
                animation="wave"
                sx={{
                    borderRadius: '4px',
                    backgroundColor: color.grey200,
                    width: '10%',
                }}
            />
            <NavigateNextIcon fontSize="small" sx={{ color: color.textPrimary }} />
            <Skeleton
                animation="wave"
                sx={{
                    borderRadius: '4px',
                    backgroundColor: color.grey200,
                    width: '10%',
                }}
            />
        </Stack>
    );
}
