import { CameraServiceType, CameraWithInfo } from '@EcamModel/model';
import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Collapse, Stack, Typography } from '@mui/material';
import { RequiredTitle, ServiceTypeLabel, camServiceTypes } from '@pages/cameras/add-new';
import { search } from '@pages/cameras/list/helpers';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { validateTypingInput } from '../utils';
import { cameraController } from '@controllers/index';
import { pushSuccess } from '@components/toast';

type Props = Omit<IPopUp, 'onConfirm'> & {
    camera: CameraWithInfo;
    onConfirm?(camera: CameraWithInfo): void;
};

type FormValues = {
    serviceType: ServiceTypeLabel;
    anprId: string;
};

export default function PopUpEditService(props: Props) {
    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        setError,
        clearErrors,
        formState: { isDirty, isValid, errors },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            serviceType: camServiceTypes[CameraServiceType.ECamTechOps],
            anprId: '',
        },
    });
    const disabled = !(isValid && isDirty);

    console.log({ isDirty, isValid, errors });

    useEffect(() => {
        if (!props.open) return;
        reset({
            serviceType: camServiceTypes[props.camera.ServiceType],
            anprId: props.camera.ANPRCameraId?.toString() || '',
        });
    }, [props.open, props.camera]);

    const serviceTypeAutocomplete = useStyledAutocomplete({
        // async getList(props: IGetLocationProps): Promise<Paging<Location>> {
        //     const res = await locationController.list(props).finally(() => {});
        //     return res;
        // },
        list: {
            options: Object.values(camServiceTypes),
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
        // listProps: {
        //     search: { fields: ['name'] } as any,
        // },
    });

    const isANPR = watch('serviceType.key') === CameraServiceType.ANPRTechOps;

    const handleSave = async (data: FormValues) => {
        if (data.serviceType.key === CameraServiceType.ANPRTechOps) {
            const isExist = await cameraController.checkANPRCameraExisted(parseInt(data.anprId));
            if (isExist) {
                setError('anprId', { message: 'This ID has already been used, please check again' });
                return;
            }
        }

        try {
            props.onClose?.();
            const updatedCamera = await cameraController.upsert({
                ...props.camera,
                ServiceType: data.serviceType.key,
                ANPRCameraId: data.anprId ? Number(data.anprId) : null,
            });

            props.onConfirm?.({ ...props.camera, ...updatedCamera });

            pushSuccess('Update successfully');
        } catch (error) {}
    };

    return (
        <PopUpBase
            open={props.open}
            fixOverflow
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Stack>
                    <Typography variant="h4">Edit service</Typography>
                    {/* <Typography>Are you sure you want to edit Milesights ?</Typography> */}
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="100%" sx={{ background: '#FAFAFA', p: 2 }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <RequiredTitle title="Service type" required sx={{ width: 130 }} />
                        <Controller
                            name="serviceType"
                            control={control}
                            // rules={{
                            //     required: {
                            //         value: true,
                            //         message: 'Please select a location.',
                            //     },
                            // }}
                            render={({ field, fieldState: { error } }) => (
                                <StyledAutocomplete
                                    {...serviceTypeAutocomplete}
                                    {...field}
                                    getOptionLabel={(o) => o.label}
                                    // label={
                                    //     <span>
                                    //         Location<span style={{ color: 'red' }}>{`*`}</span>
                                    //     </span>
                                    // }
                                    // placeholder="Select location"
                                    disabledAllOption
                                    value={field.value}
                                    onChange={(s) => {
                                        setValue('serviceType', s!, {
                                            shouldValidate: true,
                                            shouldTouch: true,
                                            shouldDirty: true,
                                        });
                                        if (s?.key === CameraServiceType.ECamTechOps) {
                                            setValue('anprId', '');
                                            clearErrors('anprId');
                                        }
                                    }}
                                    wrapperWidth={'100%'}
                                />
                            )}
                        />
                    </Stack>

                    <Collapse in={isANPR}>
                        <Stack direction="row" spacing={2}>
                            <RequiredTitle title="ANPR ID" required sx={{ width: 130 }} />

                            <Controller
                                name="anprId"
                                control={control}
                                rules={{
                                    required: {
                                        value: isANPR,
                                        message: 'This field is required.',
                                    },
                                    validate: (value) => {
                                        if (isANPR) {
                                            if (!value || isNaN(Number(value))) {
                                                return 'Please enter a valid ANPR ID.';
                                            }
                                        }
                                        return true;
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'ANPR ID'}
                                        error={!!error?.message}
                                        maxLength={30}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Stack>
                    </Collapse>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={disabled}
                        onClick={handleSubmit(handleSave)}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
