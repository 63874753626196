import {
    Solar4gCamera,
    Solar4gCellularInfo,
    Solar4gCellularStatus,
    Solar4gTimeInfo,
    TimeInfoDaylightSavingMode,
    TimeInfoSyncMode,
} from '@EcamModel/model';
import { pushError, pushSuccess } from '@components/toast';
import { solar4gCameraHttpController } from '@controllers/index';
import { useState } from 'react';
import { useBackdrop } from 'src/providers/BackdropProvider';

export default function useMileSightSolar() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const backdrop = useBackdrop();

    const [solar4gCamera, setSolar4gCamera] = useState<Solar4gCamera>({
        Solar4gCaptureInfo: {
            Width: 1920,
            Height: 1080,
        },
        Solar4gSchedule: {
            Trigger: '',
        },
    } as Solar4gCamera);

    const [systemTime, setSystemTime] = useState<Solar4gTimeInfo>({} as Solar4gTimeInfo);

    const Solar4gCaptureInfo = solar4gCamera.Solar4gCaptureInfo;
    const Solar4gSchedule = solar4gCamera.Solar4gSchedule;
    const Solar4gAlarmROI = solar4gCamera.Solar4gAlarmROI;

    const partsTimeTrigger = Solar4gSchedule?.Trigger?.split(';');

    const enableValueInContinuousCapture = Solar4gAlarmROI?.Region[0]?.enable;

    const valueImageSettings: string =
        Solar4gCaptureInfo?.Width !== null || Solar4gCaptureInfo?.Height !== null
            ? `${Solar4gCaptureInfo?.Width} x ${Solar4gCaptureInfo?.Height}`
            : '';

    const cellularStatus = solar4gCamera.Solar4gCellularStatus;
    const cellularSettings = solar4gCamera.Solar4gCellularInfo;
    const systemTimeMilesight = solar4gCamera.Solar4gTimeInfo;

    const [status, setStatus] = useState<Solar4gCellularStatus>({} as Solar4gCellularStatus);
    const settings = cellularSettings ?? ({} as Solar4gCellularInfo);
    const [settingsExpected, setSettingsExpected] = useState<Solar4gCellularInfo>(
        solar4gCamera.Solar4gCellularInfoExpected ?? ({} as Solar4gCellularInfo)
    );

    const getSolar4gCameraInfo = async (cameraId: number) => {
        setIsLoading(true);
        await solar4gCameraHttpController
            .getLatestInfoByCameraId(cameraId)
            .then((res) => {
                setSolar4gCamera(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const updateCellularInfo = async (data: Partial<Solar4gCellularInfo>) => {
        await solar4gCameraHttpController.updateCellularInfo(settings?.DevMac, data).then((res) => {
            setSettingsExpected(res);
            setSolar4gCamera((prev) => ({ ...prev, Solar4gCellularInfoExpected: res }));
        });
    };

    const refreshStatus = async () => {
        backdrop.setTrue();
        await solar4gCameraHttpController
            .refreshCellularStatus(status.DevMac)
            .then((res) => {
                setStatus(res);
                setSolar4gCamera((prev) => ({ ...prev, Solar4gCellularStatus: res }));
                pushSuccess('Refresh successfully');
            })
            .catch((error) => {
                pushError(error);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const checkCellularStatus = async () => {
        backdrop.setTrue();
        await solar4gCameraHttpController
            .checkCellularStatus(status.DevMac)
            .then((res) => {
                setStatus(res);
                setSolar4gCamera((prev) => ({ ...prev, Solar4gCellularStatus: res }));
                pushSuccess('Check successfully');
            })
            .catch((error) => {
                pushError(error);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const checkTimeInfo = () => {
        const syncMode = {
            [TimeInfoSyncMode.GPS]: 'Auto',
            [TimeInfoSyncMode.MANUAL]: 'Manual',
        };

        const daylightSaving = {
            [TimeInfoDaylightSavingMode.Disable]: 'Disable',
            [TimeInfoDaylightSavingMode.Automatic]: 'Automatic',
        };

        return {
            syncMode: syncMode[systemTime?.SyncMode],
            daylightSaving: daylightSaving[systemTime?.DaylightSaving],
        };
    };

    const updateTimeInfo = async (data: Partial<Solar4gTimeInfo>) => {
        await solar4gCameraHttpController.updateTimeInfo(systemTime?.DevMac, data).then((res) => {
            setSystemTime(res);
            setSolar4gCamera((prev) => ({ ...prev, Solar4gTimeInfo: res }));
        });
    };

    return {
        solar4gCamera,
        setSolar4gCamera,
        //Milesight Solar
        valueImageSettings,
        isLoading,
        setIsLoading,
        partsTimeTrigger,
        Solar4gCaptureInfo,
        Solar4gSchedule,
        Solar4gAlarmROI,
        // Cellular status
        status,
        settings,
        settingsExpected,
        //Set the system time
        systemTime, // Cellular status
        refreshStatus,
        checkCellularStatus,
        updateCellularInfo,
        //Set the system time
        checkTimeInfo,
        updateTimeInfo,
        cellularStatus,
        systemTimeMilesight,
        setStatus,
        setSystemTime,
        getSolar4gCameraInfo,
    };
}
