import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;
export default function BatteryLow(props: Props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M11.67 12.3333H2.71C2.35638 12.3333 2.01724 12.1928 1.76719 11.9428C1.51714 11.6927 1.37667 11.3536 1.37667 11V4.99996C1.37667 4.64634 1.51714 4.3072 1.76719 4.05715C2.01724 3.8071 2.35638 3.66663 2.71 3.66663H11.67C12.0236 3.66663 12.3628 3.8071 12.6128 4.05715C12.8629 4.3072 13.0033 4.64634 13.0033 4.99996V5.66663H13.6233C13.8885 5.66663 14.1429 5.77198 14.3304 5.95952C14.518 6.14706 14.6233 6.40141 14.6233 6.66663V9.33329C14.6233 9.59851 14.518 9.85286 14.3304 10.0404C14.1429 10.2279 13.8885 10.3333 13.6233 10.3333H13.0033V11C13.0033 11.3536 12.8629 11.6927 12.6128 11.9428C12.3628 12.1928 12.0236 12.3333 11.67 12.3333ZM2.71 4.33329C2.53319 4.33329 2.36362 4.40353 2.2386 4.52855C2.11357 4.65358 2.04333 4.82315 2.04333 4.99996V11C2.04333 11.1768 2.11357 11.3463 2.2386 11.4714C2.36362 11.5964 2.53319 11.6666 2.71 11.6666H11.67C11.8468 11.6666 12.0164 11.5964 12.1414 11.4714C12.2664 11.3463 12.3367 11.1768 12.3367 11V10.1666C12.3368 10.0341 12.3896 9.907 12.4833 9.81327C12.577 9.71954 12.7041 9.6668 12.8367 9.66663H13.6233C13.7117 9.66663 13.7965 9.63151 13.859 9.569C13.9215 9.50648 13.9567 9.4217 13.9567 9.33329V6.66663C13.9567 6.57822 13.9215 6.49344 13.859 6.43092C13.7965 6.36841 13.7117 6.33329 13.6233 6.33329H12.8367C12.7041 6.33312 12.577 6.28038 12.4833 6.18665C12.3896 6.09292 12.3368 5.96585 12.3367 5.83329V4.99996C12.3367 4.82315 12.2664 4.65358 12.1414 4.52855C12.0164 4.40353 11.8468 4.33329 11.67 4.33329H2.71Z"
                fill="#E01B00"
            />
            <path
                d="M3.92558 5H3.07442C3.03332 5 3 5.29854 3 5.66681V10.3332C3 10.7015 3.03332 11 3.07442 11H3.92558C3.96668 11 4 10.7015 4 10.3332V5.66681C4 5.29854 3.96668 5 3.92558 5Z"
                fill="#E01B00"
            />
        </svg>
    );
}
