import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import color from '@theme/Colors';

export const GridWithGray100 = styled(Grid)({
    background: color.grey100,
    padding: '24px 16px',
    borderRadius: '8px',
});

export const GridBorderRadius = styled(Grid)({
    background: color.white,
    padding: '16px',
    borderRadius: '8px',
    border: `1px solid ${color.grey200}`,
});
