import { BaseModel } from '@Core';
import { Camera, Status } from './Camera';
import { CameraPhotoVehicles } from './CameraPhotoVehicles';
import { ContraventionReasons } from './ContraventionReasons';
import { GeoZoneCoordinates } from './GeoZoneCoordinates';
import { GeoZoneOperationConfigWithGracePeriod } from './GeoZoneOperationConfig';
import { GeoZoneOperations } from './GeoZoneOperations';
import { CameraView } from './CameraView';

export interface GeoZones extends BaseModel {
    CameraId: number;
    Camera?: Camera;

    ContraventionReasonId?: number;
    ContraventionReason?: ContraventionReasons;

    GeoZoneCoordinates?: GeoZoneCoordinates[];

    GeoZoneOperations?: GeoZoneOperations[];

    CameraPhotoVehicles?: CameraPhotoVehicles[];

    GracePeriod?: number;

    Status?: Status;

    CameraViewId?: number;
    CameraViewZoomId?: number;
    CameraViewDetail?: CameraView;
    GeoZoneType?: GeoZoneType;

    ValidationType?: GeoZoneValidationType;

    PermitExemptionsEnabled?: boolean;

    GZOConfigs?: GeoZoneOperationConfigWithGracePeriod[];

    GeoZoneName?: string;

    GeoZoneDetailCount?: number;

    ContraventionType?: string;
}

export enum GeoZoneValidationType {
    Covered,
    Center,
    Contained,
}

export enum GeoZoneType {
    Detail,
    Overview,
}
