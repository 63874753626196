import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;
export default function ICDelete(props: Props) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.6028 6.35742C12.6028 6.35742 12.2506 10.7262 12.0462 12.5665C11.9489 13.4455 11.406 13.9605 10.5167 13.9768C8.82427 14.0072 7.12993 14.0092 5.43819 13.9735C4.58259 13.956 4.04873 13.4345 3.95338 12.5711C3.74775 10.7146 3.39746 6.35742 3.39746 6.35742"
                stroke="#E01B00"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M13.5001 4.26318H2.49988" stroke="#E01B00" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M11.3805 4.26323C10.8713 4.26323 10.4328 3.90321 10.3329 3.40438L10.1753 2.61559C10.078 2.25169 9.74846 2 9.37287 2H6.62703C6.25145 2 5.92192 2.25169 5.82462 2.61559L5.66699 3.40438C5.5671 3.90321 5.12859 4.26323 4.61938 4.26323"
                stroke="#E01B00"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
