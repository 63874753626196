import { CaptureInfoFrequency, CaptureInfoPostType, Solar4gCaptureInfo } from '@EcamModel/model';
import BaseCheckbox from '@components/BaseCheckbox';
import { BaseTextField } from '@components/BaseTextField';
import {
    Autocomplete,
    Collapse,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { RequiredTitle } from '@pages/cameras/add-new';
import moment from 'moment';
import { Control, Controller, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';
import BaseTimePicker from './BaseTimePicker';
import InformationIcon from './icon/InformationIcon';
import { useCameraDetailContext } from '../../hook/CameraDetailsProvider';
interface Props {
    control: Control<Partial<Solar4gCaptureInfo>, any>;
    setValue: UseFormSetValue<Partial<Solar4gCaptureInfo>>;
    clearErrors: UseFormClearErrors<Partial<Solar4gCaptureInfo>>;
    watch: Partial<Solar4gCaptureInfo>;
}
export default function FormPopUpEditUploadSettings(props: Props) {
    const { control, setValue, clearErrors, watch } = props;

    const { Solar4gCaptureInfo } = useCameraDetailContext();

    return (
        <Stack width="100%" sx={{ background: '#FAFAFA', p: 2 }} spacing={3}>
            <Stack>
                <Typography fontSize={14} fontWeight={500}>
                    Snapshots Upload Settings
                </Typography>

                <Grid
                    xs={12}
                    container
                    sx={{
                        background: '#FFFFFF',
                        py: 1,
                        px: 1,
                        '&.MuiGrid2-root': {
                            marginTop: 0,
                        },
                    }}
                    columnSpacing={1}
                    alignItems={'center'}
                >
                    <Grid xs={3}>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Typography sx={{ lineHeight: '40px' }}>Upload mode</Typography>
                            <Tooltip
                                placement="top"
                                title="The timing upload mode can only be used when the SD card is inserted."
                            >
                                <InformationIcon />
                            </Tooltip>
                            :
                        </Stack>
                    </Grid>
                    <Grid xs={9}>
                        <Controller
                            name="UploadMode"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <Grid container xs={12}>
                                            <Grid>
                                                <FormControlLabel
                                                    {...field}
                                                    sx={{
                                                        '.MuiFormControlLabel-label': {
                                                            mt: '3px !important',
                                                        },
                                                    }}
                                                    control={
                                                        <Radio
                                                            {...field}
                                                            onChange={(_) => {
                                                                field.onChange(Number(_.target.value));
                                                            }}
                                                            checked={field.value?.toString() === '0'}
                                                            value={0}
                                                        />
                                                    }
                                                    label="Timing"
                                                />
                                                <FormControlLabel
                                                    {...field}
                                                    sx={{
                                                        '.MuiFormControlLabel-label': {
                                                            mt: '3px !important',
                                                        },
                                                    }}
                                                    control={
                                                        <Radio
                                                            {...field}
                                                            onChange={(_, checked) => {
                                                                if (Number(checked) === 1) {
                                                                    setValue(
                                                                        'Frequency',
                                                                        Solar4gCaptureInfo?.Frequency
                                                                    );
                                                                    setValue('Time1', Solar4gCaptureInfo?.Time1);
                                                                    setValue('Time2', Solar4gCaptureInfo?.Time2);
                                                                }
                                                                return field.onChange(Number(_.target.value));
                                                            }}
                                                            checked={field.value?.toString() === '1'}
                                                            value={1}
                                                        />
                                                    }
                                                    label="Immediacy"
                                                />
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            )}
                        />
                    </Grid>
                </Grid>
                <Collapse
                    in={watch.UploadMode === 0}
                    sx={{
                        '&.MuiCollapse-root': {
                            marginTop: 0,
                        },
                    }}
                >
                    {watch.UploadMode === 0 && (
                        <>
                            <Grid
                                xs={12}
                                container
                                sx={{
                                    background: '#FFFFFF',
                                    py: 1,
                                    px: 1,
                                    '&.MuiGrid2-root': {
                                        marginTop: 0,
                                    },
                                }}
                                columnSpacing={1}
                                alignItems={'center'}
                            >
                                <Grid xs={3}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography sx={{ lineHeight: '40px' }}>Frequency</Typography>
                                    </Stack>
                                </Grid>
                                <Grid xs={9}>
                                    <Controller
                                        name="Frequency"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <Autocomplete
                                                {...field}
                                                options={[
                                                    CaptureInfoFrequency.ONCE_A_DAY,
                                                    CaptureInfoFrequency.TWICE_A_DAY,
                                                ]}
                                                getOptionLabel={(options) => {
                                                    const authTypeDisplayValue = {
                                                        [CaptureInfoFrequency.ONCE_A_DAY]: 'Once a Day',
                                                        [CaptureInfoFrequency.TWICE_A_DAY]: 'Twice a Day',
                                                    };
                                                    return authTypeDisplayValue[options];
                                                }}
                                                sx={{
                                                    '.MuiInputBase-root': {
                                                        height: '40px',
                                                    },
                                                    '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                                        padding: '0px 5px',
                                                    },
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Frequency"
                                                        sx={{
                                                            '.MuiFormLabel-root': {
                                                                marginTop: '-5px',
                                                            },
                                                        }}
                                                    />
                                                )}
                                                value={field.value}
                                                onChange={(_, newValue) => {
                                                    if (Number(newValue) === CaptureInfoFrequency.TWICE_A_DAY) {
                                                        setValue('Time2', Solar4gCaptureInfo?.Time2);
                                                    }
                                                    return field.onChange(newValue);
                                                }}
                                                clearIcon={true}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                xs={12}
                                container
                                sx={{
                                    background: '#FFFFFF',
                                    py: 1,
                                    px: 1,
                                    '&.MuiGrid2-root': {
                                        marginTop: 0,
                                    },
                                }}
                                columnSpacing={1}
                                alignItems={'center'}
                            >
                                <Grid xs={3}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography sx={{ lineHeight: '40px' }}>Time 1:</Typography>
                                    </Stack>
                                </Grid>
                                <Grid xs={9}>
                                    <Controller
                                        name="Time1"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            return (
                                                <BaseTimePicker
                                                    marginTop={44}
                                                    zIndex={10}
                                                    width={'100%'}
                                                    value={moment(field.value, 'HH:mm') as any}
                                                    onChange={(newValue) => {
                                                        field.onChange(newValue);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    <Collapse in={watch.Frequency === CaptureInfoFrequency.TWICE_A_DAY ? true : false}>
                        {watch.Frequency === CaptureInfoFrequency.TWICE_A_DAY && (
                            <Grid
                                xs={12}
                                container
                                sx={{
                                    background: '#FFFFFF',
                                    py: 1,
                                    px: 1,
                                    '&.MuiGrid2-root': {
                                        marginTop: 0,
                                    },
                                }}
                                columnSpacing={1}
                                alignItems={'center'}
                            >
                                <Grid xs={3}>
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography sx={{ lineHeight: '40px' }}>Time 2: </Typography>
                                    </Stack>
                                </Grid>
                                <Grid xs={9}>
                                    <Controller
                                        name="Time2"
                                        control={control}
                                        render={({ field, fieldState: { error } }) => {
                                            return (
                                                <BaseTimePicker
                                                    marginTop={44}
                                                    zIndex={9}
                                                    width={'100%'}
                                                    value={moment(field.value, 'HH:mm') as any}
                                                    onChange={(newValue) => {
                                                        field.onChange(newValue);
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Collapse>
                </Collapse>
            </Stack>
            <Stack>
                <Typography fontSize={14} fontWeight={500}>
                    Post type
                </Typography>
                <Stack
                    sx={{
                        background: '#FFFFFF',
                        '&.MuiGrid2-root': {
                            paddingBottom: 0,
                            marginTop: 0,
                        },
                    }}
                >
                    {/* Enable post */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <Typography sx={{ lineHeight: '40px' }}>Enable post:</Typography>
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="PostEnable"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        {...field}
                                        control={
                                            <BaseCheckbox
                                                {...field}
                                                onChange={(_, checked) => {
                                                    field.onChange(Number(checked));
                                                }}
                                                checked={!!field.value}
                                                value={field.value}
                                            />
                                        }
                                        label={<Typography ml={0.5} mt={0.3}></Typography>}
                                        sx={{ ml: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Post content */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <Typography sx={{ lineHeight: '40px' }}>Post content:</Typography>
                        </Grid>
                        <Grid container xs={3}>
                            <Controller
                                name="SnapshotEnable"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        {...field}
                                        control={
                                            <BaseCheckbox
                                                {...field}
                                                onChange={(_, checked) => {
                                                    field.onChange(Number(checked));
                                                }}
                                                checked={!!field.value}
                                                value={field.value}
                                            />
                                        }
                                        label={
                                            <Typography ml={0.5} mt={0.3}>
                                                Snapshot
                                            </Typography>
                                        }
                                        sx={{ ml: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container xs={3}>
                            <Controller
                                name="StatusEnable"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        {...field}
                                        control={
                                            <BaseCheckbox
                                                {...field}
                                                onChange={(_, checked) => {
                                                    field.onChange(Number(checked));
                                                }}
                                                checked={!!field.value}
                                                value={field.value}
                                            />
                                        }
                                        label={
                                            <Typography ml={0.5} mt={0.3}>
                                                Status
                                            </Typography>
                                        }
                                        sx={{ ml: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid container xs={3}>
                            <Controller
                                name="AlarmEnable"
                                control={control}
                                render={({ field }) => (
                                    <FormControlLabel
                                        {...field}
                                        control={
                                            <BaseCheckbox
                                                {...field}
                                                onChange={(_, checked) => {
                                                    field.onChange(Number(checked));
                                                }}
                                                checked={!!field.value}
                                                value={field.value}
                                            />
                                        }
                                        label={
                                            <Typography ml={0.5} mt={0.3}>
                                                Alarm
                                            </Typography>
                                        }
                                        sx={{ ml: 0 }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Snapshot topic */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="Snapshot topic" required />
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="SnapshotTopic"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Snapshot topic'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Status topic */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="Status topic" required />
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="StatusTopic"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Status topic'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Alarm topic */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="Alarm topic" required />
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="AlarmTopic"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Alarm topic'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Configure request topic */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                    >
                        <Grid xs={3}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="Request topic" required />
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="ConfigTopic"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Configure request topic'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Configure respond topic */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                    >
                        <Grid xs={3}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="Respond topic" required />
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="ConfigRespTopic"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Configure respond topic'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Post type */}
                    <Grid
                        container
                        alignItems={'center'}
                        xs={12}
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                    >
                        <Grid xs={2.9}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="Post type" required />
                        </Grid>
                        <Grid xs={9}>
                            <Controller
                                name="PostType"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field}
                                        options={[CaptureInfoPostType.MQTT, CaptureInfoPostType.HTTP]}
                                        getOptionLabel={(options) => {
                                            const authTypeDisplayValue = {
                                                [CaptureInfoPostType.MQTT]: 'MQTT',
                                                [CaptureInfoPostType.HTTP]: 'HTTP',
                                            };
                                            return authTypeDisplayValue[options];
                                        }}
                                        sx={{
                                            '.MuiInputBase-root': {
                                                height: '40px',
                                            },
                                            '.MuiOutlinedInput-root .MuiAutocomplete-input': {
                                                padding: '0px 5px',
                                            },
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Post type"
                                                sx={{
                                                    '.MuiFormLabel-root': {
                                                        marginTop: '-5px',
                                                    },
                                                }}
                                            />
                                        )}
                                        value={field.value}
                                        onChange={(_, newValue) => {
                                            field.onChange(newValue);
                                            clearErrors();
                                        }}
                                        clearIcon={true}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Collapse in={watch.PostType ? true : false}>
                        {watch.PostType && (
                            <Grid
                                xs={12}
                                container
                                sx={{
                                    py: 1,
                                    px: 1,
                                }}
                                columnSpacing={1}
                                alignItems={'center'}
                            >
                                <Grid xs={3}>
                                    <RequiredTitle sx={{ width: 'max-content' }} title="URL" required />
                                </Grid>
                                <Grid container xs={9}>
                                    <Controller
                                        name="HttpUrl"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'This field is required.',
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <BaseTextField
                                                {...field}
                                                placeholder={'URL'}
                                                error={!!error?.message}
                                                helperText={error?.message}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Collapse>

                    <Collapse in={!watch.PostType ? true : false}>
                        {!watch.PostType && (
                            <>
                                {/* Host */}
                                <Grid
                                    xs={12}
                                    container
                                    sx={{
                                        py: 1,
                                        px: 1,
                                    }}
                                    columnSpacing={1}
                                    alignItems={'center'}
                                >
                                    <Grid xs={3}>
                                        <RequiredTitle sx={{ width: 'max-content' }} title="Host" required />
                                    </Grid>
                                    <Grid container xs={9}>
                                        <Controller
                                            name="Host"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'This field is required.',
                                                },
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <BaseTextField
                                                    {...field}
                                                    placeholder={'Host'}
                                                    error={!!error?.message}
                                                    helperText={error?.message}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>

                                {/* Port */}
                                <Grid
                                    xs={12}
                                    container
                                    sx={{
                                        py: 1,
                                        px: 1,
                                    }}
                                    columnSpacing={1}
                                    alignItems={'center'}
                                >
                                    <Grid xs={3}>
                                        <RequiredTitle sx={{ width: 'max-content' }} title="Port" required />
                                    </Grid>
                                    <Grid container xs={9}>
                                        <Controller
                                            name="Port"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: 'This field is required.',
                                                },
                                            }}
                                            render={({ field, fieldState: { error } }) => (
                                                <BaseTextField
                                                    {...field}
                                                    placeholder={'Port'}
                                                    error={!!error?.message}
                                                    helperText={error?.message}
                                                    maxLength={10}
                                                    onChange={(e) => {
                                                        const value = Number(e.target.value);
                                                        if (!isNaN(value)) {
                                                            field.onChange(
                                                                isNaN(Number(value)) ? value : Number(value)
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Collapse>

                    {/* User name */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="User name" required />
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="UserName"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Username'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {/* Password */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <RequiredTitle sx={{ width: 'max-content' }} title="Password" required />
                        </Grid>
                        <Grid container xs={9}>
                            <Controller
                                name="Password"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'This field is required.',
                                    },
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        placeholder={'Password'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    {Solar4gCaptureInfo?.PostType == CaptureInfoPostType.MQTT ? (
                        <>
                            {/* SSL/TLS */}
                            <Grid
                                xs={12}
                                container
                                sx={{
                                    py: 1,
                                    px: 1,
                                }}
                                columnSpacing={1}
                                alignItems={'center'}
                            >
                                <Grid xs={3}>
                                    <Typography sx={{ lineHeight: '40px' }}>SSL/TLS:</Typography>
                                </Grid>
                                <Grid container xs={9}>
                                    <Controller
                                        name="TlsEnable"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                {...field}
                                                control={
                                                    <BaseCheckbox
                                                        {...field}
                                                        onChange={(_, checked) => {
                                                            field.onChange(Number(checked));
                                                        }}
                                                        checked={!!field.value}
                                                        value={field.value}
                                                    />
                                                }
                                                label={<Typography ml={0.5} mt={0.3}></Typography>}
                                                sx={{ ml: 0 }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        <></>
                    )}

                    {/* Certificate type */}
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={3}>
                            <Typography sx={{ lineHeight: '40px' }}>Certificate type:</Typography>
                        </Grid>
                        <Grid xs={9}>
                            <Controller
                                name="TlsType"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl>
                                        <RadioGroup
                                            {...field}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <Grid container xs={12}>
                                                <Grid>
                                                    <FormControlLabel
                                                        {...field}
                                                        sx={{
                                                            '.MuiFormControlLabel-label': {
                                                                mt: '3px !important',
                                                            },
                                                        }}
                                                        control={
                                                            <Radio
                                                                {...field}
                                                                onChange={(_) => {
                                                                    field.onChange(Number(_.target.value));
                                                                }}
                                                                checked={field.value?.toString() === '0'}
                                                                value={0}
                                                            />
                                                        }
                                                        label="CA signed server"
                                                    />
                                                    <FormControlLabel
                                                        {...field}
                                                        sx={{
                                                            '.MuiFormControlLabel-label': {
                                                                mt: '3px !important',
                                                            },
                                                        }}
                                                        control={
                                                            <Radio
                                                                {...field}
                                                                onChange={(_) => {
                                                                    field.onChange(Number(_.target.value));
                                                                }}
                                                                checked={field.value?.toString() === '1'}
                                                                value={1}
                                                            />
                                                        }
                                                        label="Self singed"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                )}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Stack>
    );
}
