import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { VerificationNote } from '@EcamModel/model/VerificationNote';
import { AxiosInstance } from 'axios';
import { IVerificationNoteHttpController } from '../IVerificationNoteHttpController';

export class VerificationNoteHttpController
    extends BaseHttpController<VerificationNote>
    implements IVerificationNoteHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'verification-note', client);
    }
}
