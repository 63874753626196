import { CameraWithInfo } from '@EcamModel/model';
import PopUpBase from '@components/PopUpBase';
import { DesktopDatePicker } from '@mui/x-date-pickers';

import { pushSuccess } from '@components/toast';
import { cameraController } from '@controllers/index';
import { IPopUp } from '@hooks/usePopUp';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { RequiredTitle } from '@pages/cameras/add-new';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Props = Omit<IPopUp, 'onConfirm'> & {
    camera: CameraWithInfo;
    onConfirm?(camera: CameraWithInfo): void;
};


type FormValues = {
    deployedDate: Moment;
};

export default function PopUpEditDeployDate(props: Props) {
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        setError,
        clearErrors,
        formState: { isDirty, isValid, errors },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            deployedDate: moment(props.camera.DeployedDate),
        },
    });
    const disabled = !(isValid && (!props.camera?.DeployedDate || isDirty));


    const handleSave = async (data: FormValues) => {
        setIsLoading(true);
        try {
            const updatedCamera = await cameraController.upsert({
                ...props.camera,
                 DeployedDate: data.deployedDate.toDate(),
            });

            props.onConfirm?.({ ...props.camera, ...updatedCamera });
            props.onClose?.();
            setIsLoading(false);
            pushSuccess('Update successfully');
        } catch (error) {}
    };

    useEffect(() => {
        if (!props.open) return;
        reset({
            deployedDate: moment( props.camera.DeployedDate),
        });
    }, [props.open, props.camera]);

    return (
        <PopUpBase
            open={props.open}
            fixOverflow
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
                PaperProps: {
                    sx: { overflowY: 'unset' },
                },
            }}
            onClose={props.onClose}
            title={<Typography variant="h4">Edit Production</Typography>}
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack sx={{ p: 2 }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <RequiredTitle title="Production" required />
                        <Controller
                            name="deployedDate"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please choose a date.',
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <DesktopDatePicker
                                    {...field}
                                    value={field.value}
                                    format="DD/MM/YYYY"
                                    slotProps={{
                                        textField: {
                                            inputProps: {
                                                readOnly: true,
                                            },
                                            fullWidth:true
                                        },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={disabled || isLoading}
                        onClick={handleSubmit(handleSave)}
                        endIcon={isLoading && <CircularProgress color="inherit" size="14px" />}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
