import MenuIcon from '@mui/icons-material/Menu';
import { Avatar, Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { CSSObject, styled, Theme } from '@mui/material/styles';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// import ConfirmDialog from '../../../components/ConfirmDialog';
// import useModal from '../../../components/modal/useModal';
import LogoutIcon from '@assets/drawer-icon/LogoutIcon';
import { useMsal } from '@azure/msal-react';
import LanguageSelect from '@components/LanguageSelect';
import PopUpWarning from '@components/PopUpWarning';
import { appConfig } from '@configs/index';
import usePopUp from '@hooks/usePopUp';
import { ChevronLeftRounded } from '@mui/icons-material';
import color from '@theme/Colors';
import i18n from 'src/translations/config';
import ItemDrawer from './DrawerItem';
import { DrawerContext } from './useDrawer';

const drawerWidth = 200;

type Props = {
    open: (open: boolean) => void;
};

export default function MyDrawer(props: Props) {
    const { instance, accounts } = useMsal();
    let navigate = useNavigate();
    const { t } = i18n;
    const {
        ListDrawItem,
        open,
        handleDrawerClose,
        // logout
        activeItem,
        setActiveItem,
        handleDrawerOpen,
    } = useContext(DrawerContext);
    // const popUpConfirm = useModal();
    const isShowMultiLanguage = appConfig.flagFeatures.multiLanguage;

    const popUpWarning = usePopUp({
        onConfirm() {
            const currentAccount = instance.getAccountByHomeId(accounts[0].homeAccountId);
            console.log(currentAccount?.homeAccountId);
            const logoutRequest = {
                account: currentAccount,
                // mainWindowRedirectUri: '/',
                postLogoutRedirectUri: '/',
            };
            instance.logoutRedirect(logoutRequest);
        },
    });
    return (
        <Box
            sx={{
                display: 'flex',
                pt: 10,
            }}
        >
            <PopUpWarning {...popUpWarning} message="Are you sure you want to log out?" />
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    pt: 10,
                    '& .MuiPaper-root': {
                        // border: 'none',
                        borderRight: open ? `1px solid ${color.grey100}` : '',
                        background: color.grey100,
                        width: open ? '245px' : '70px',
                        pt: 12,
                        px: 1,
                        overflow: 'visible',
                    },
                }}
            >
                <DrawerHeader sx={{ padding: 0, justifyContent: 'center' }} className="drawer-header">
                    <Stack direction={'row'}>
                        {isShowMultiLanguage && (
                            <Stack sx={{ display: open ? 'block' : 'none', transition: 'all 1s' }}>
                                <LanguageSelect isShowLabel={true} />
                            </Stack>
                        )}

                        <Avatar
                            sx={[
                                {
                                    background: color.grey100,
                                    color: color.grey600,
                                },
                                open
                                    ? {
                                          display: 'flex',
                                          width: '30px',
                                          height: '30px',
                                          position: 'absolute',
                                          boxShadow: '4px 2px 8px 2px rgb(0 0 0 / 9%)',
                                          zIndex: 9,
                                          right: '-15px',
                                          top: 110,
                                      }
                                    : {},
                            ]}
                        >
                            {open ? (
                                <ChevronLeftRounded
                                    sx={{
                                        opacity: 1,
                                        cursor: 'pointer',
                                        fontSize: 22,
                                    }}
                                    onClick={() => {
                                        handleDrawerClose();
                                        props.open(open);
                                    }}
                                />
                            ) : (
                                <Box
                                    onClick={() => {
                                        handleDrawerOpen();
                                        props.open(open);
                                    }}
                                >
                                    <MenuIcon style={{ width: '24px', height: '24px', cursor: 'pointer' }} />
                                </Box>
                            )}
                        </Avatar>
                    </Stack>
                </DrawerHeader>

                <List
                    sx={{
                        height: '100%',
                    }}
                >
                    <Grid container height={'100%'} justifyContent={'space-between'}>
                        <Grid item xs={12}>
                            {ListDrawItem.map((item, index) => {
                                const isActive = item.id === activeItem;

                                return (
                                    <ItemDrawer
                                        key={index}
                                        icon={item.icon}
                                        isActive={isActive}
                                        label={item.label}
                                        open={open}
                                        onClick={() => {
                                            setActiveItem(item.id);
                                            navigate(item.path);
                                        }}
                                    />
                                );
                            })}
                        </Grid>
                        <Grid item xs={12} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
                            <Box></Box>
                            <ItemDrawer
                                key={99}
                                icon={<LogoutIcon className="svg-item-Menu" />}
                                isActive={false}
                                label={t('drawer.logout')}
                                open={open}
                                onClick={popUpWarning.setTrue}
                            />
                        </Grid>
                    </Grid>
                </List>
            </Drawer>
            {/* <ConfirmDialog
                open={popUpConfirm.open}
                title={t('dashboard:title.logOut')}
                content={t('dashboard:text.areYouSureWantToLogOut')}
                onClose={() => popUpConfirm.setOpen(false)}
                onConfirm={logout}
            /> */}
        </Box>
    );
}

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));
