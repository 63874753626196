import { Solar4gCamera, Solar4gCaptureInfo } from '@EcamModel/model';
import BaseCheckbox from '@components/BaseCheckbox';
import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Button, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InformationIcon from './icon/InformationIcon';
import { useCameraDetailContext } from '../../hook/CameraDetailsProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {
    handleUpdatePhotoCaptureConfigInfo: (data: Partial<Solar4gCamera>) => Promise<void>;
};

export default function PopUpEditPhotoCaptureConfig(props: Props) {
    const { open, onClose } = props;
    const { Solar4gCaptureInfo } = useCameraDetailContext();

    const {
        control,
        clearErrors,
        reset,
        handleSubmit,
        formState: { isDirty, isValid, errors },
    } = useForm<Partial<Solar4gCaptureInfo>>({
        mode: 'all',
        defaultValues: {},
    });
    const disabled = !(isValid && isDirty);

    const defaultValues: Partial<Solar4gCaptureInfo> = {
        PostCaptureEnable: Solar4gCaptureInfo?.PostCaptureEnable,
        PostCaptureInterval: Solar4gCaptureInfo?.PostCaptureInterval,
        PostCaptureNum: Solar4gCaptureInfo?.PostCaptureNum,
    };

    useEffect(() => {
        if (!open) return;
        reset(defaultValues);
    }, [open]);

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            onConfirm={() => {}}
            dialogProps={{
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                        maxWidth: '700px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            title={
                <Stack>
                    <Typography variant="h4">Photo capture config</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="550px" sx={{ background: '#FAFAFA' }} spacing={1}>
                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={4}>
                            <Typography sx={{ lineHeight: '40px' }}>Capture enable:</Typography>
                        </Grid>
                        <Grid container xs={8}>
                            <Controller
                                name="PostCaptureEnable"
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <BaseCheckbox
                                                    {...field}
                                                    onChange={(_, checked) => {
                                                        return field.onChange(Number(checked));
                                                    }}
                                                    checked={!!field.value}
                                                    value={field.value}
                                                />
                                            }
                                            label={<Typography ml={0.5} mt={0.3}></Typography>}
                                            sx={{ ml: 0 }}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={4}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography
                                    sx={{
                                        lineHeight: '40px',
                                    }}
                                >
                                    Capture interval
                                </Typography>
                                <Tooltip
                                    placement="top"
                                    title={
                                        'Cannot modify the capture interval, as these parameters are determined by the grace period.'
                                    }
                                >
                                    <InformationIcon />
                                </Tooltip>
                                :
                            </Stack>
                        </Grid>
                        <Grid container xs={8}>
                            <Controller
                                name="PostCaptureInterval"
                                control={control}
                                // rules={{
                                //     max: {
                                //         value: 15,
                                //         message: 'Value must be less than or equal to 15.',
                                //     },
                                //     required: {
                                //         value: true,
                                //         message: 'This field is required.',
                                //     },
                                // }}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        value={field.value}
                                        disabled
                                        maxLength={10}
                                        onChange={(e) => {
                                            const value = Number(e.target.value);
                                            if (!isNaN(value)) {
                                                field.onChange(isNaN(Number(value)) ? value : Number(value));
                                            }
                                        }}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        xs={12}
                        container
                        sx={{
                            py: 1,
                            px: 1,
                        }}
                        columnSpacing={1}
                        alignItems={'center'}
                    >
                        <Grid xs={4}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <Typography
                                    sx={{
                                        lineHeight: '40px',
                                    }}
                                >
                                    Capture number:
                                </Typography>
                                <Tooltip
                                    placement="top"
                                    title={
                                        'Cannot modify the capture number, as these parameters are determined by the grace period.'
                                    }
                                >
                                    <InformationIcon />
                                </Tooltip>
                                :
                            </Stack>
                        </Grid>
                        <Grid container xs={8}>
                            <Controller
                                name="PostCaptureNum"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <BaseTextField
                                        {...field}
                                        value={field.value}
                                        disabled
                                        maxLength={10}
                                        onChange={(e) => {
                                            const value = Number(e.target.value);
                                            if (!isNaN(value)) {
                                                field.onChange(isNaN(Number(value)) ? value : Number(value));
                                            }
                                        }}
                                        placeholder={'Interval'}
                                        error={!!error?.message}
                                        helperText={error?.message}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            clearErrors();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={disabled}
                        onClick={handleSubmit(async (data) => {
                            const _editUpload: Solar4gCamera = {} as Solar4gCamera;

                            for (const key in data) {
                                if (data[key] !== defaultValues[key]) {
                                    _editUpload[key] = data[key];
                                }
                            }

                            await props.handleUpdatePhotoCaptureConfigInfo(_editUpload);
                            props.onClose?.();
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
