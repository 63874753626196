import { Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material';
import { omit } from 'lodash';

export const STextField = styled(TextField)({});

type Props = {
    maxLength?: number;
    customWidth?: string | number;
    isVariantOutlined?: boolean;
};

export const BaseTextField = (props: TextFieldProps & Props) => {
    return (
        <Stack width={props.customWidth ? props.customWidth : '100%'}>
            <Stack direction={'row'} justifyContent="space-between" alignItems={'center'}>
                <Typography
                    variant="subtitle1"
                    fontSize={13}
                    fontWeight={500}
                    sx={{
                        color: props.error ? (theme) => theme.palette.error.main : 'inherit',
                        transition:
                            'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
                    }}
                >
                    {!props.isVariantOutlined && (
                        <>
                            {props.label}
                            {props.required && <span style={{ color: 'red' }}>{'*'}</span>}
                        </>
                    )}
                </Typography>

                {/* {props.maxLength && (
                    <Typography fontWeight={500} fontSize={12}>{`${((props.value ?? '') as string).length}/${
                        props.maxLength
                    }`}</Typography>
                )} */}
            </Stack>

            {props.isVariantOutlined ? (
                <STextField
                    variant="outlined"
                    label={
                        <span>
                            {props.label}
                            {props.required && <span style={{ color: 'red' }}>*</span>}
                        </span>
                    }
                    inputProps={{ maxLength: props.maxLength }}
                    {...omit(props, 'required', 'label')}
                    autoComplete={'off'}
                />
            ) : (
                <STextField
                    inputProps={{ maxLength: props.maxLength }}
                    {...omit(props, 'required', 'label')}
                    autoComplete={'off'}
                />
            )}
        </Stack>
    );
};
