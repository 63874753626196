import { BaseModel } from '@Core';
import { Camera } from './Camera';
import { ExemptionPeriods } from './ExemptionPeriods';

export interface CameraExemptionPeriodAuditEvent extends BaseModel {
    CameraId: number;
    Camera?: Camera;

    ExemptionPeriodId: number;
    ExemptionPeriod?: ExemptionPeriods;

    OldValues: Partial<ExemptionPeriods>;
    NewValues: Partial<ExemptionPeriods>;

    AuditEventType: AuditEventType;
    Notes?: string;
}

export enum AuditEventType {
    CREATE,
    UPDATE,
    DELETE,
}
