import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { CameraPhotoVehicles, CameraPhotos } from '../../model';
import { ICameraPhotoHttpController, IGetCameraPhotoProps } from '../ICameraPhotoHttpController';

export class CameraPhotoHttpController extends BaseHttpController<CameraPhotos> implements ICameraPhotoHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'cameraPhoto', client);
    }

    async getLivePhotos(params: IGetCameraPhotoProps): Promise<Paging<CameraPhotos>> {
        return await this.doPost({ path: 'live-photos', body: params }).then((res) => res.data);
    }

    async getCameraPhotoVehiclesByPlate(props: IGetCameraPhotoProps): Promise<Paging<CameraPhotoVehicles>> {
        return await this.doPost({ path: 'get-camera-photo-vehicles-by-plate', body: props }).then((res) => res.data);
    }
}
