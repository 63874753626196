import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { VerificationAudit } from '@EcamModel/model/VerificationAudit';
import { AxiosInstance } from 'axios';
import {
    IAuditAndApprovePotentialContravention,
    IAuditAndSkipPotentialContravention,
    IVerificationAuditHttpController,
} from '../IVerificationAuditHttpController';

export class VerificationAuditHttpController
    extends BaseHttpController<VerificationAudit>
    implements IVerificationAuditHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'verification-audit', client);
    }

    async getAuditorEmails(): Promise<string[]> {
        return await this.doGet({ path: 'get-auditor-emails' }).then((res) => res.data);
    }

    async getVerifierEmails(): Promise<string[]> {
        return await this.doGet({ path: 'get-verifier-emails' }).then((res) => res.data);
    }

    async auditAndSkip(props: IAuditAndSkipPotentialContravention): Promise<boolean> {
        return await this.doPost({ path: 'audit-and-skip', body: props }).then((res) => res.data);
    }

    async auditAndApprove(props: IAuditAndApprovePotentialContravention): Promise<boolean> {
        return await this.doPost({ path: 'audit-and-approve', body: props }).then((res) => res.data);
    }
}
