export const timezoneMapping: { [key: string]: string } = {
    'UTC-12:00': '(UTC-12:00) International Date Line West',
    'UTC-11:00': '(UTC-11:00) Coordinated Universal Time-11',
    'UTC-10:00': '(UTC-10:00) Hawaii (Honolulu)',
    'UTC-09:30': '(UTC-09:30) Marquesas Islands',
    'UTC-09:00': '(UTC-09:00) Alaska (Anchorage)',
    'UTC-08:00': '(UTC-08:00) Pacific Time (US & Canada) (Los Angeles)',
    'UTC-07:00': '(UTC-07:00) Mountain Time (US & Canada) (Denver)',
    'UTC-06:00': '(UTC-06:00) Central Time (US & Canada) (Chicago)',
    'UTC-05:00': '(UTC-05:00) Eastern Time (US & Canada) (New York)',
    'UTC-04:00': '(UTC-04:00) Atlantic Time (Canada) (Halifax)',
    'UTC-03:30': "(UTC-03:30) Newfoundland (St. John's)",
    'UTC-03:00': '(UTC-03:00) Argentina (Buenos Aires)',
    'UTC-02:00': '(UTC-02:00) Brazil (no DST)',
    'UTC-01:00': '(UTC-01:00) Azores',
    UTC0: '(UTC+00:00) United Kingdom (London)',
    'UTC+00:00': '(UTC+00:00) Coordinated Universal Time',
    'UTC+01:00': '(UTC+01:00) Central European Time (Berlin)',
    'UTC+02:00': '(UTC+02:00) Eastern European Time (Athens)',
    'UTC+03:00': '(UTC+03:00) Moscow Standard Time (Moscow)',
    'UTC+03:30': '(UTC+03:30) Iran Standard Time (Tehran)',
    'UTC+04:00': '(UTC+04:00) Gulf Standard Time (Dubai)',
    'UTC+04:30': '(UTC+04:30) Afghanistan (Kabul)',
    'UTC+05:00': '(UTC+05:00) Pakistan (Karachi)',
    'UTC+05:30': '(UTC+05:30) India (Delhi)',
    'UTC+05:45': '(UTC+05:45) Nepal (Kathmandu)',
    'UTC+06:00': '(UTC+06:00) Bangladesh (Dhaka)',
    'UTC+06:30': '(UTC+06:30) Myanmar (Yangon)',
    'UTC+07:00': '(UTC+07:00) Indochina Time (Bangkok)',
    'UTC+08:00': '(UTC+08:00) China (Beijing)',
    'UTC+08:45': '(UTC+08:45) Western Australia (Eucla)',
    'UTC+09:00': '(UTC+09:00) Japan (Tokyo)',
    'UTC+09:30': '(UTC+09:30) Central Australia (Adelaide)',
    'UTC+10:00': '(UTC+10:00) Australia (Sydney)',
    'UTC+10:30': '(UTC+10:30) Lord Howe Island',
    'UTC+11:00': '(UTC+11:00) Solomon Islands (Honiara)',
    'UTC+12:00': '(UTC+12:00) New Zealand (Auckland)',
    'UTC+12:45': '(UTC+12:45) Chatham Islands',
    'UTC+13:00': '(UTC+13:00) Tonga',
    'UTC+14:00': '(UTC+14:00) Line Islands (Kiritimati)',
};
