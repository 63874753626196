import { IconButton, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import color from '@theme/Colors';
import React, { ReactNode } from 'react';
import { ToolTipProps } from './TooltipIconButton';

export default function TooltipDangerIconButton(props: ToolTipProps) {
    return (
        <DangerTooltip title={props.tooltipTitle ?? 'label'} placement="top" onClick={props.onClick}>
            <IconButton
                sx={{
                    '&.MuiIconButton-root': {
                        background: color.grey200,
                        boxShadow: `0 0 0 4px ${color.grey100}`,
                    },
                    width: 30,
                    height: 30,
                }}
                size="small"
            >
                {props.icon}
            </IconButton>
        </DangerTooltip>
    );
}

export const DangerTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: color.danger,
        color: color.white,
    },
    [`& .${tooltipClasses.arrow}`]: {
        '&:before': {
            // border: `1px solid ${color.danger}`,
            color: color.danger,
        },
        color,
    },
}));
