import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import {
    CancellationStatistic,
    DynamicCancellationStatistic,
    MileSightCameraStatistic,
    PotentialContraventionStatistic,
    StepStatistic,
    StepStatisticDetail,
    VerifiCationStatistic,
} from '@EcamModel/model/PotentialContraventionsStatistic';
import { AxiosInstance } from 'axios';
import { IDashBoardHttpController, IDashBoardProp } from '../IDashBoardHttpController';

export class DashBoardHttpController extends BaseHttpController<{}> implements IDashBoardHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'dashBoard', client);
    }

    async stepStatistic(props: IDashBoardProp): Promise<StepStatistic> {
        return await this.doPost({ path: 'step-statistic', body: props }).then((res) => res.data);
    }

    async pcnStatistic(props: IDashBoardProp): Promise<PotentialContraventionStatistic> {
        return await this.doPost({ path: 'pcn-statistic', body: props }).then((res) => res.data);
    }

    async stepStatisticDetail(props: IDashBoardProp): Promise<Paging<StepStatisticDetail>> {
        return await this.doPost({ path: 'step-statistic-detail', body: props }).then((res) => res.data);
    }

    async cancellationStatistic(props: IDashBoardProp): Promise<CancellationStatistic> {
        return await this.doPost({ path: 'cancellation-statistic', body: props }).then((res) => res.data);
    }

    async dynamicCancellationStatistic(props: IDashBoardProp): Promise<DynamicCancellationStatistic[]> {
        return await this.doPost({ path: 'dynamic-cancellation-statistic', body: props }).then((res) => res.data);
    }

    async verificationStatistic(props: IDashBoardProp): Promise<Paging<VerifiCationStatistic>> {
        return await this.doPost({ path: 'verification-statistic', body: props }).then((res) => res.data);
    }

    async cameraStatistic(props: { startDate: Date; endDate: Date }): Promise<MileSightCameraStatistic[]> {
        return await this.doPost({ path: 'mileSightCamera-statistic', body: props }).then((res) => res.data);
    }
}
