export default function UploadImageIcon() {
    return (
        <svg
            style={{ cursor: 'pointer' }}
            width="51"
            height="48"
            viewBox="0 0 51 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="25.5004" cy="24" rx="24.5531" ry="24" fill="#F9FAFB" />
            <path
                d="M21.4078 28.0906L25.5 23.9984M25.5 23.9984L29.5922 28.0906M25.5 23.9984V33.2059M33.6844 28.8506C34.934 27.8185 35.7305 26.2573 35.7305 24.51C35.7305 21.4024 33.2113 18.8832 30.1037 18.8832C29.8802 18.8832 29.671 18.7666 29.5575 18.574C28.2234 16.3101 25.7603 14.791 22.9424 14.791C18.7048 14.791 15.2695 18.2263 15.2695 22.4639C15.2695 24.5776 16.1242 26.4917 17.5069 27.8794"
                stroke="#475367"
                strokeWidth="2.30186"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
