import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcDraw(props: SvgIconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.9233 0C13.3859 0 12.8433 0.22429 12.4363 0.646835L4.27916 8.99095L3.87798 9.38746C3.39581 9.48091 2.93613 9.72656 2.57161 10.1384C2.03671 10.7459 1.93128 11.4888 1.72812 12.2865C1.52432 13.0849 1.26587 13.974 0.642902 14.9567L0 16H1.18551C3.70183 16 5.3618 14.7511 6.2085 13.8719L6.22779 13.8519C6.61482 13.4394 6.83083 12.9207 6.9112 12.3913L7.23265 12.0783L15.3898 3.73416V3.71346C15.7806 3.30632 16 2.75495 16 2.18015C16 1.60535 15.7806 1.05398 15.3898 0.646835C14.9828 0.22429 14.4608 0 13.9233 0ZM13.9233 1.31436C14.1239 1.31436 14.3149 1.40782 14.4859 1.58538C14.8298 1.94251 14.8298 2.41712 14.4859 2.77492L8.67918 8.74062L7.51424 7.53039L13.3402 1.58538C13.5112 1.40849 13.7221 1.31436 13.9233 1.31436ZM6.5891 8.46961L7.75468 9.67917L6.79033 10.6598L5.62475 9.44954L6.5891 8.46961ZM4.38073 10.6798C4.70476 10.6905 5.05578 10.8206 5.32516 11.0763C5.82469 11.5503 5.83691 12.38 5.30458 12.9327C4.74719 13.5115 3.75776 14.2577 2.31123 14.5394C2.61983 13.8379 2.83584 13.183 2.97406 12.641C3.18751 11.8039 3.34566 11.2298 3.51667 11.0343C3.7404 10.7819 4.05671 10.6691 4.38073 10.6798Z"
                fill="currentColor"
            />
        </svg>
    );
}
