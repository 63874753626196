import { getValueFromMapping } from '@pages/components';

export const withColumnSort = (Component, columnLabel) => {
    return ({ sort, onChangeSort, ...props }) => {
        const handleSortChange = () => {
            onChangeSort(columnLabel, sort);
        };

        return (
            <Component
                {...props}
                sort={sort && columnLabel === props.title ? sort : undefined}
                onChangeSort={handleSortChange}
            />
        );
    };
};

export const sortByColumn = (columnName, sortOrder, stateName) => {
    return stateName.slice().sort((a, b) => {
        const columnValueA = a[columnName];
        const columnValueB = b[columnName];

        if (columnValueA != null && columnValueB != null) {
            if (typeof columnValueA === 'number' && typeof columnValueB === 'number') {
                return sortOrder === 'ASC' ? columnValueA - columnValueB : columnValueB - columnValueA;
            } else if (typeof columnValueA === 'string' && typeof columnValueB === 'string') {
                return sortOrder === 'ASC'
                    ? columnValueA.localeCompare(columnValueB)
                    : columnValueB.localeCompare(columnValueA);
            }
        } else if (columnValueA == null && columnValueB != null) {
            return sortOrder === 'ASC' ? -1 : 1;
        } else if (columnValueA != null && columnValueB == null) {
            return sortOrder === 'ASC' ? 1 : -1;
        }
        return 0;
    });
};

export const sortByColumnWithMathAbs = (columnName, sortOrder, stateName) => {
    return stateName.slice().sort((a, b) => {
        const getValue = (obj) => obj[columnName];

        const columnValueA = getValue(a);
        const columnValueB = getValue(b);

        const convertedValueA = columnValueA != null ? Math.abs(columnValueA) : -Infinity;
        const convertedValueB = columnValueB != null ? Math.abs(columnValueB) : -Infinity;

        if (convertedValueA < convertedValueB) {
            return sortOrder === 'ASC' ? -1 : 1;
        } else if (convertedValueA > convertedValueB) {
            return sortOrder === 'ASC' ? 1 : -1;
        }
        return 0;
    });
};

export const sortByDate = (columnName, sortOrder, stateName) => {
    // Define a function to convert null values to the special date representing the end of the list
    const convertNullToDate = (value) => {
        if (value === null) {
            return new Date('1970-01-01 ').getTime(); // Choose a special date representing the end
        }
        return new Date(value).getTime();
    };

    return stateName.slice().sort((a, b) => {
        const getValue = (obj) => obj[columnName];
        const columnValueA = getValue(a);
        const columnValueB = getValue(b);

        // Convert null values to the special date representing the end of the list
        const convertedDateA = convertNullToDate(columnValueA);
        const convertedDateB = convertNullToDate(columnValueB);

        const compare = sortOrder === 'ASC' ? -1 : 1;

        return (convertedDateA - convertedDateB) * compare;
    });
};

const convertNull = (value) => {
    if (value === null) {
        return 'N/A';
    }
    return value;
};

export const sortValueNumberToText = (cameraConfigMonitoring, ascendingOrder, field, mapping) => {
    return cameraConfigMonitoring.sort((a, b) => {
        const valueA = a[field] !== null ? getValueFromMapping(a[field], mapping) : null;
        const valueB = b[field] !== null ? getValueFromMapping(b[field], mapping) : null;

        const convertedValueA = convertNull(valueA);
        const convertedValueB = convertNull(valueB);

        if (convertedValueA !== null && convertedValueB !== null) {
            if (convertedValueA < convertedValueB) {
                return ascendingOrder ? -1 : 1;
            }
            if (convertedValueA > convertedValueB) {
                return ascendingOrder ? 1 : -1;
            }
        }
        return 0;
    });
};

export const sortValueBoolean = (cameraConfigMonitoring, ascendingOrder, sortBy) => {
    return cameraConfigMonitoring.sort((a, b) => {
        const pingA = a[sortBy] !== null ? String(a[sortBy]) : null;
        const pingB = b[sortBy] !== null ? String(b[sortBy]) : null;

        const convertedPingA = convertNull(pingA);
        const convertedPingB = convertNull(pingB);

        if (convertedPingA !== null && convertedPingB !== null) {
            if (convertedPingA < convertedPingB) {
                return ascendingOrder ? -1 : 1;
            }
            if (convertedPingA > convertedPingB) {
                return ascendingOrder ? 1 : -1;
            }
        }
        return 0;
    });
};
