import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { AxiosInstance } from 'axios';
import { GeoZoneOperationConfig, GeoZoneOperationConfigWithGracePeriod } from '../../model';
import { IGeoZoneOperationConfigHttpController } from '../IGeoZoneOperationConfigHttpController';

export class GeoZoneOperationConfigHttpController
    extends BaseHttpController<GeoZoneOperationConfig>
    implements IGeoZoneOperationConfigHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'geoZoneOperationConfig', client);
    }

    async upsertGzoConfig(
        t: Partial<GeoZoneOperationConfigWithGracePeriod>
    ): Promise<GeoZoneOperationConfigWithGracePeriod[]> {
        return await this.doPost({ path: '', body: t }).then((res) => res.data);
    }

    async getPastOrFuturePeriods(geoZoneId: number): Promise<GeoZoneOperationConfigWithGracePeriod[]> {
        return await this.doGet({ path: `get-past-and-future-periods/${geoZoneId}` }).then((res) => res.data);
    }

    async deleteGzoConfig(gzoConfigId: number): Promise<GeoZoneOperationConfigWithGracePeriod[]> {
        return await this.doGet({ path: `delete/${gzoConfigId}` }).then((res) => res.data);
    }
}
