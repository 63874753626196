import { ISendActionGet } from '@EcamModel/controllers/IPTZCamConfigureRequestHttpController';
import {
    CameraWithInfo,
    ContraventionReasonTranslations,
    GeoZoneCoordinates,
    GeoZones,
    GeoZoneType,
    Status,
} from '@EcamModel/model';
import { GeoZoneOperationConfigGracePeriodWithDay } from '@EcamModel/model/GeoZoneOperationConfigGracePeriod';
import { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushError, pushSuccess } from '@components/toast';
import { cameraController, cameraViewController, geoZoneController } from '@controllers/index';
import { yupResolver } from '@hookform/resolvers/yup';
import { getGracePeriods } from '@pages/cameras/details/utils';
import { groupBy } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    defaultGracePeriod,
    defaultOperationHours,
    detectModes,
    FormValues,
    handleConvertOperationHourConfig,
    hasInvalidGracePeriod,
    schema,
} from '../../../../AddGeoZones';
import { base64ToHTMLImageElement } from '../../components/PopupConfigViewCameraPTZ';
import { IGetPhotoSnapShotByPTZ } from '@EcamModel/controllers/ICameraViewHttpController';
import { initPaging } from '@pages/cameras/list';
import { Paging } from '@Core';
import { IGetGeoZoneProps } from '@EcamModel/controllers/IGeoZoneHttpController';
import useScrollToTop from '@hooks/useScrollToTop';

export default function useAddGeoZones() {
    const { locationId, id: cameraId, geoZoneId } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const state = location?.state;
    const geoZonesItem = state?.geoZonesItem as GeoZones;
    console.log('🚀 ~ useAddGeoZones ~ geoZonesItem:', geoZonesItem);
    const cameraViewZoomId = geoZonesItem?.CameraViewZoomId;

    useScrollToTop();
    const [selectedCameraType, setSelectedCameraType] = useState<number>(cameraViewZoomId !== undefined ? 1 : 0);

    const isTypeOverview = selectedCameraType === 0;
    const isTypeDetails = selectedCameraType === 1;

    const isHasCameraViewZoomId = cameraViewZoomId;
    const typeDetailsIncludesCameraViewZoomId = Boolean(isTypeDetails || isHasCameraViewZoomId);

    const [camera, setCamera] = useState<CameraWithInfo>({} as CameraWithInfo);
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
    const [isOverlap, setIsOverlap] = useState<boolean>(false);

    const [contraventionReasons, setContraventionReasons] = useState<ContraventionReasonTranslations[]>([]);
    const [startDate, setStartDate] = useState<Moment>(moment());
    const [blobBase64, setBlobBase64] = useState<string>('');
    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
    const [listGeoZonesByView, setListGeoZonesByView] = useState<Paging<GeoZones>>({ ...initPaging });
    const [filteredListGeoZones, setFilteredListGeoZones] = useState<IGetGeoZoneProps>({} as IGetGeoZoneProps);

    const frameIndexItem = state?.frameIndex;
    const pathNameBreadCrumbs = `/locations/${locationId}/cameras`;

    const breadcrumbs: IBreadCrumbs[] = [
        { id: 1, title: 'Cameras', href: `${pathNameBreadCrumbs}` },
        { id: 2, title: `${camera.Name}`, href: `${pathNameBreadCrumbs}/${cameraId}` },
        {
            id: 3,
            title: `Geo-zones overview ${frameIndexItem + 1}`,
            onBack: true,
        },
        { id: 4, title: `Add geo-zones`, isColorBlackText: true },
    ];

    const conditionBreadcrumbs =
        frameIndexItem !== undefined ? breadcrumbs : breadcrumbs.filter((item) => item.id !== 3);

    const methods = useForm<FormValues>({
        resolver: isTypeOverview ? undefined : yupResolver(schema),
        mode: 'all',
        defaultValues: {
            isExemptVRNs: true,
            contraventionReasonTranslations: undefined,
            detectMode: detectModes[0],
            isActive: true,
            geoZonePoints: [],
            operationHours: defaultOperationHours,
            gracePeriods: [defaultGracePeriod],
        },
    });

    const {
        watch,
        reset,
        control,
        setValue,
        handleSubmit,
        setError,
        formState: { isDirty, isValid, errors, ...formState },
    } = methods;

    const geoZonePoints = useWatch({
        control,
        name: 'geoZonePoints',
    });

    const contraventionReasonTranslations = useWatch({
        control,
        name: 'contraventionReasonTranslations',
    });

    const detectMode = useWatch({
        control,
        name: 'detectMode',
    });

    const hasOperationHours = watch().operationHours?.every((item) => !item.Status);
    const isHasInValidGracePeriod = hasInvalidGracePeriod(watch().operationHours!, watch().gracePeriods!);
    const isInvalidOperationHours = watch().operationHours?.some(
        (item) => !!item.Status && item.TimeRanges.some((t) => !t.From || !t.To)
    );

    const cameraTypeConditions = {
        0: () => geoZonePoints.length < 4,
        1: () =>
            !(
                isDirty &&
                isValid &&
                contraventionReasonTranslations &&
                detectMode &&
                geoZonePoints.length > 4 &&
                operationHours.length > 0 &&
                !isOverlap &&
                !hasOperationHours &&
                isHasInValidGracePeriod &&
                !isInvalidOperationHours
            ),
    };

    const shouldDisableButton = () => cameraTypeConditions[selectedCameraType]?.() ?? true;

    const handleRadioChangeCameraType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedCameraType(Number(event.target.value));
    };

    const handleUpsertGeoZoneOverview = async (_filter: GeoZones) => {
        setIsLoadingSave(true);
        await geoZoneController
            .upsertGeoZoneOverview(_filter)
            .then((_) => {
                navigate(`${pathNameBreadCrumbs}/${cameraId}`, { state: { tabValue: 1 } });
                pushSuccess('Geo-zone saved successfully');
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
            });
    };

    const navigateGeoZonesTab = isHasCameraViewZoomId
        ? `${pathNameBreadCrumbs}/${cameraId}/overview/${geoZoneId}`
        : `${pathNameBreadCrumbs}/${cameraId}`;

    const handleNavigateButton = () => {
        navigate(navigateGeoZonesTab, { state: { tabValue: 1, frameIndex: frameIndexItem } });
    };

    const handleUpsertGeoZoneDetail = async (_filter: GeoZones) => {
        setIsLoadingSave(true);
        await geoZoneController
            .upsert(_filter)
            .then((_) => {
                handleNavigateButton();
                pushSuccess('Geo-zone saved successfully');
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
            });
    };

    const getPhotoSnapShotByView = async (_filteredCamera: IGetPhotoSnapShotByPTZ) => {
        await cameraViewController
            .getPhotoSnapShotByPTZ(_filteredCamera)
            .then((res) => {
                setBlobBase64(res.Base64PhotoSnapShot);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            });
    };

    useEffect(() => {
        const convertBase64ToImage = async () => {
            try {
                const imgElement = await base64ToHTMLImageElement(blobBase64);
                setImageElement(imgElement);
            } catch (error) {
                console.error('Failed to convert base64 to image', error);
            }
        };
        convertBase64ToImage();
    }, [blobBase64]);

    const {
        fields: operationHours,
        update: updateOperationHours,
        append: appendOperationHours,
        remove: removeOperationHours,
    } = useFieldArray({ control, name: 'operationHours' });

    const gracePeriodForm = useFieldArray({ control, name: 'gracePeriods' });

    const handleGetListGeoZoneByView = async (_filter: IGetGeoZoneProps) => {
        await geoZoneController
            .listGeoZoneByCameraView(_filter)
            .then((res) => {
                setListGeoZonesByView(res);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {});
    };

    useEffect(() => {
        const isLastCall = filteredListGeoZones && Object.keys(filteredListGeoZones).length > 0;

        if (isLastCall && !cameraViewZoomId) {
            handleGetListGeoZoneByView(filteredListGeoZones);
        } else if (cameraViewZoomId) {
            handleGetGeoZonesById(Number(geoZoneId));
        }
    }, [filteredListGeoZones, cameraViewZoomId]);

    const handleGetGeoZonesById = async (geoZonesId: number) => {
        await geoZoneController
            .getGeoZoneOverviewById(geoZonesId)
            .then((res) => {
                getPhotoSnapShotByView({
                    PTZ: {
                        PtzPanPos: res.CameraViewDetail?.PtzPanPos!,
                        PtzZoomPos: res.CameraViewDetail?.PtzZoomPos!,
                        PtzTiltPos: res.CameraViewDetail?.PtzTiltPos!,
                    },
                    PTZCommand: {
                        authenticate: {
                            password: camera.PassWord,
                            username: camera.UserName,
                        },
                        ip: camera.IP,
                    },
                });
                handleGetListGeoZoneByView({
                    pageSize: 10000,
                    filter: {
                        CameraId: Number(cameraId),
                        CameraViewId: res.CameraViewZoomId,
                    },
                });
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {});
    };

    const prevGeoZonePointsOverviewPage: GeoZoneCoordinates[][] =
        listGeoZonesByView.rows?.map((gz) => {
            const coordinatesWithGeoZone = gz.GeoZoneCoordinates?.map((coord) => ({
                ...coord,
                GeoZone: gz as GeoZones,
            }));

            return coordinatesWithGeoZone
                ?.sort((a, b) => a.Index - b.Index)
                .concat(coordinatesWithGeoZone[0]) as GeoZoneCoordinates[];
        }) ?? [];

    const prevGeoZonePointsDetailPage: GeoZoneCoordinates[][] =
        (listGeoZonesByView.rows?.map((gz) => {
            return gz.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(gz.GeoZoneCoordinates[0]);
        }) as GeoZoneCoordinates[][]) ?? [];

    const contraventionAutocomplete = useStyledAutocomplete({
        list: {
            options: contraventionReasons,
            isFiltered(option, searchText) {
                return option.Summary.toLowerCase().includes(searchText.toLowerCase());
            },
        },
        dependencyList: [contraventionReasons],
    });

    const detectModeAutocomplete = useStyledAutocomplete({
        list: {
            options: detectModes,
            isFiltered(option, searchText) {
                return option.Label.toLowerCase().includes(searchText.toLowerCase());
            },
        },
    });

    useEffect(() => {
        setLoading(true);
        cameraController
            .get(cameraId!)
            .then(async (res) => {
                const filteredCamera: ISendActionGet = {
                    ip: res.IP,
                    authenticate: {
                        password: res.PassWord,
                        username: res.UserName,
                    },
                };

                if (res.CameraViews?.length && res.CameraViews?.length > 0) {
                    const filteredListGeoZones: IGetGeoZoneProps = {
                        pageSize: 10000,
                        filter: {
                            CameraId: res.Id!,
                            CameraViewId: res.CameraViews?.[0].Id,
                        },
                    };

                    setFilteredListGeoZones(filteredListGeoZones);
                }
                setCamera(res);
                setLoading(false);

                if (!cameraViewZoomId) {
                    await getPhotoSnapShotByView({
                        PTZ: {
                            PtzPanPos: res.CameraViews?.[0].PtzPanPos!,
                            PtzZoomPos: res.CameraViews?.[0].PtzZoomPos!,
                            PtzTiltPos: res.CameraViews?.[0].PtzTiltPos!,
                        },
                        PTZCommand: filteredCamera,
                    });
                }
            })
            .catch((err) => {
                pushError(err.response.data.message);
                navigate(`/locations/${camera.Zone?.LocationId}/cameras`);
            });
    }, [cameraId]);

    useEffect(() => {
        geoZoneController.getContraventionReasonTranslations().then((res) => {
            setContraventionReasons(res);
        });
    }, []);

    const handleAddGeoZoneOverview = handleSubmit((data) => {
        if (isTypeOverview && !isHasCameraViewZoomId) {
            const filteredData: GeoZones = {
                CameraId: camera.Id!,
                CameraViewId: camera.CameraViews?.[0].Id,
                GeoZoneType: GeoZoneType.Overview,
                Status: !!data.isActive ? Status.Active : Status.Inactive,
                GeoZoneCoordinates: data.geoZonePoints.splice(0, 4).map((item) => ({
                    GeoZoneId: item.GeoZoneId,
                    Index: item.Index,
                    XPoint: item.XPoint,
                    YPoint: item.YPoint,
                })),
            };
            handleUpsertGeoZoneOverview(filteredData);
        } else if (isTypeDetails || isHasCameraViewZoomId) {
            const groupedOperationHours = groupBy(handleConvertOperationHourConfig(data.operationHours), '_fakeId');
            const filteredData: GeoZones = {
                CameraId: camera.Id!,
                CameraViewId: isHasCameraViewZoomId ? isHasCameraViewZoomId : camera.CameraViews?.[0].Id,
                GeoZoneType: GeoZoneType.Detail,
                ContraventionReasonId: Number(data.contraventionReasonTranslations.Id!),
                ContraventionType: data.contraventionReasonTranslations.Summary ?? '',
                ValidationType: data.detectMode.Value,
                Status: !!data.isActive ? Status.Active : Status.Inactive,
                PermitExemptionsEnabled: data.isExemptVRNs,
                GeoZoneCoordinates: data.geoZonePoints.splice(0, 4).map((item) => ({
                    GeoZoneId: item.GeoZoneId,
                    Index: item.Index,
                    XPoint: item.XPoint,
                    YPoint: item.YPoint,
                })),
                GZOConfigs: [
                    {
                        EffectiveFrom: moment(startDate).startOf('date').toDate(),
                        GZOConfigGracePeriods: data.gracePeriods.map(
                            (item) =>
                                ({
                                    ...item,
                                    GracePeriod: getGracePeriods(item.GracePeriod),
                                    GZOConfigDays: groupedOperationHours[item._fakeId],
                                } as GeoZoneOperationConfigGracePeriodWithDay)
                        ),
                        Status: false,
                    },
                ],
            };
            handleUpsertGeoZoneDetail(filteredData);
        }
    });

    return {
        loading,
        errors,
        methods,
        conditionBreadcrumbs,
        prevGeoZonePointsOverviewPage,
        prevGeoZonePointsDetailPage,
        selectedCameraType,
        gracePeriodForm,
        operationHours,
        contraventionAutocomplete,
        detectModeAutocomplete,
        isLoadingSave,
        imageElement,
        startDate,
        state,
        isHasCameraViewZoomId,
        typeDetailsIncludesCameraViewZoomId,
        isTypeOverview,
        isTypeDetails,
        pathNameBreadCrumbs,
        setStartDate,
        updateOperationHours,
        setIsOverlap,
        navigate,
        shouldDisableButton,
        handleRadioChangeCameraType,
        handleAddGeoZoneOverview,
        handleNavigateButton,
    };
}
