import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography, useTheme } from '@mui/material';
import color from '@theme/Colors';
import { Link, useNavigate } from 'react-router-dom';

export type IBreadCrumbs = {
    id?: number;
    title: string;
    href?: string;
    onBack?: boolean;
    isColorBlackText?: boolean;
};
type Props = {
    breadcrumbs: IBreadCrumbs[];
    isConditional?: boolean;
};
export default function BreadCrumbs({ breadcrumbs, isConditional = true }: Props) {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <>
            {!isConditional ? (
                <Typography component={'span'} color={color.grey600}>
                    Loading...
                </Typography>
            ) : (
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" sx={{ color: color.secondary }} />}
                    aria-label="breadcrumb"
                    color={theme.palette.secondary.dark}
                >
                    {breadcrumbs.map((bc, index) => {
                        return bc.onBack ? (
                            <Typography
                                key={index}
                                style={{ color: color.secondary, textDecoration: 'none', cursor: 'pointer' }}
                                onClick={() => {
                                    navigate(-1);
                                }}
                            >
                                {bc.title}
                            </Typography>
                        ) : bc.href ? (
                            <Link
                                key={index}
                                style={{
                                    color: color.secondary,
                                    textDecoration: 'none',
                                }}
                                to={bc.href}
                            >
                                {bc.title}
                            </Link>
                        ) : (
                            <Typography
                                key={index}
                                style={{
                                    color: bc.isColorBlackText ? color.textPrimary : color.secondary,
                                    textDecoration: 'none',
                                }}
                            >
                                {bc.title}
                            </Typography>
                        );
                    })}
                </Breadcrumbs>
            )}
        </>
    );
}
