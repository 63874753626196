import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { PotentialContraventionPhotos, PotentialContraventions } from '@EcamModel/model';
import { AxiosInstance } from 'axios';
import {
    IPotentialContraventionPhotoHttpController,
    IUpdatePotentialContraventionPhoto,
    IUpdatePotentialContraventionPhotoVehicle,
} from '../IPotentialContraventionPhotoHttpController';

export class PotentialContraventionPhotoController
    extends BaseHttpController<PotentialContraventions>
    implements IPotentialContraventionPhotoHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'potentialContraventionPhoto', client);
    }

    async updatePhotoVehicle(props: IUpdatePotentialContraventionPhotoVehicle): Promise<PotentialContraventionPhotos> {
        return await this.doPost({ path: `update-photo-vehicle`, body: props }).then((res) => res.data);
    }

    async updatePhoto(props: IUpdatePotentialContraventionPhoto): Promise<PotentialContraventionPhotos> {
        return await this.doPost({ path: `update-photo`, body: props }).then((res) => res.data);
    }
}
