import CheckedConfigCam from '@assets/jsx-icon/CheckedConfigCam';
import BaseCheckbox from '@components/BaseCheckbox';
import PopUpBase from '@components/PopUpBase';
import { BoxRadiusGrey } from '@components/filter/FilterRoot';
import { IPopUp } from '@hooks/usePopUp';
import { Button, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import color from '@theme/Colors';
import { useEffect, useState } from 'react';
import { TableRowContent, useCameraConfig } from '../hook/CameraConfigProvider';

type Props = IPopUp & {};

const dataNameTable = [{ label: 'No.' }, { label: 'Camera' }, { label: 'Action' }];

export default function PopUpCustomizable(props: Props) {
    const cameraConfigContext = useCameraConfig();
    const { getDataFromLocalStorage, versionsRows } = cameraConfigContext;

    const fieldCustom: TableRowContent[] = [
        {
            id: 'Deployment date',
            version: versionsRows,
            type: 'number',
            label: 'Deployment date',
        },
        {
            id: 'Avg. photos',
            version: versionsRows,
            type: 'datee',
            label: 'Photos',
        },
        {
            id: 'Potential contraventions',
            version: versionsRows,
            type: 'string',
            label: 'Potential',
        },
        {
            id: 'Contraventions in verification',
            version: versionsRows,
            type: 'datee',
            label: 'Verification',
        },
        {
            id: 'Contraventions approved',
            version: versionsRows,
            type: 'number',
            label: 'Approved',
        },
        {
            id: 'Contraventions cancelled',
            version: versionsRows,
            type: 'number',
            label: 'Cancelled',
        },
        {
            id: 'Potential rate',
            version: versionsRows,
            type: 'new',
            label: 'Potential rate',
        },
        {
            id: 'Approval rate',
            version: versionsRows,
            type: 'new',
            label: 'Approval rate',
        },
        {
            id: 'Cancellation rate',
            version: versionsRows,
            type: 'new',
            label: 'Cancellation rate',
        },
        {
            id: 'Camera version',
            version: versionsRows,
            type: 'date123',
            label: 'Camera version',
        },
        {
            id: 'Operational status',
            version: versionsRows,
            type: 'number',
            label: 'Status',
        },

        {
            id: 'Avg. uptime (h)',
            version: versionsRows,
            type: 'date',
            label: 'Uptime (h)',
        },
        {
            id: 'Server time',
            version: versionsRows,
            type: 'date',
            label: 'Server time',
        },
        {
            id: 'Camera time',
            version: versionsRows,
            type: 'date',
            label: 'Camera time',
        },
        {
            id: 'Time difference',
            version: versionsRows,
            type: 'date',
            label: 'Time difference',
        },
        {
            id: 'Zone',
            version: versionsRows,
            type: 'date',
            label: 'Zone',
        },
        {
            id: 'Auto time',
            version: versionsRows,
            type: 'date',
            label: 'Auto time',
        },
        {
            id: 'Capture interval',
            version: versionsRows,
            type: 'date123',
            label: 'Capture interval',
        },
        {
            id: 'Firmware',
            version: versionsRows,
            type: 'date123',
            label: 'Firmware',
        },
        {
            id: 'Dev Mac',
            version: versionsRows,
            type: 'date123',
            label: 'Mac',
        },
        {
            id: 'Network mode 4G/3G/2G',
            version: versionsRows,
            type: 'date123',
            label: '4G/3G/2G',
        },
        {
            id: 'Last contacted',
            version: versionsRows,
            type: 'date1',
            label: 'Last contacted',
        },
        {
            id: 'APN',
            version: versionsRows,
            type: 'date2',
            label: 'APN',
        },
        {
            id: 'ICCID',
            version: versionsRows,
            type: 'string',
            label: 'ICCID',
        },
        {
            id: 'Upload mode',
            version: versionsRows,
            type: 'date3',
            label: 'Upload mode',
        },
        {
            id: 'Lastest ping',
            version: versionsRows,
            type: 'date4',
            label: 'Pingable',
        },
        {
            id: 'Connect status',
            version: versionsRows,
            type: 'new',
            label: 'Connect status',
        },
        {
            id: 'Network status',
            version: versionsRows,
            type: 'new',
            label: 'Network status',
        },
        {
            id: 'Signal strength',
            version: versionsRows,
            type: 'new',
            label: 'Signal',
        },
        {
            id: 'Sim status',
            version: versionsRows,
            type: 'new',
            label: 'Sim status',
        },

        {
            id: 'Type service',
            version: versionsRows,
            type: 'new',
            label: 'Service',
        },
        {
            id: 'Gen PCNs',
            version: versionsRows,
            type: 'new',
            label: 'PCNs',
        },
        {
            id: 'Mechanism type',
            version: versionsRows,
            type: 'new',
            label: 'Mechanism type',
        },

        {
            id: 'Images left in SD',
            version: versionsRows,
            type: 'new',
            label: 'Images left in SD',
        },

        {
            id: 'Latest photo',
            version: versionsRows,
            type: 'new',
            label: 'Latest photo',
        },

        {
            id: 'Note',
            version: versionsRows,
            type: 'new',
            label: 'Note',
        },

        // {
        //     id: 'Wifi status',
        //     version: versionsRows,
        //     type: 'new',
        //     label: 'Wifi',
        // },
        // {
        //     id: 'Sleep delay (s)',
        //     version: versionsRows,
        //     type: 'new',
        //     label: 'Sleep delay',
        // },
        // {
        //     id: 'Sensor sensitivity',
        //     version: versionsRows,
        //     type: 'new',
        //     label: 'Sensitivity',
        // },
        // {
        //     id: 'Minimum detection speed',
        //     version: versionsRows,
        //     type: 'new',
        //     label: 'Low speed',
        // },
        // {
        //     id: 'Indicator light',
        //     version: versionsRows,
        //     type: 'new',
        //     label: 'Indicator',
        // },
    ];

    const sortedNumberFieldCustom = fieldCustom.map((item, index) => ({
        ...item,
        number: index + 1,
    }));

    const initialCheckboxStates = sortedNumberFieldCustom.map(() => false);
    const [checkboxStates, setCheckboxStates] = useState(initialCheckboxStates);
    const [selectedItems, setSelectedItems] = useState<TableRowContent[]>(sortedNumberFieldCustom);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const handleSelectAllChange = () => {
        const newCheckboxStates = checkboxStates.map(() => !selectAllChecked);
        setCheckboxStates(newCheckboxStates);
        setSelectAllChecked(!selectAllChecked);

        if (!selectAllChecked) {
            setSelectedItems(sortedNumberFieldCustom);
        } else {
            setSelectedItems([]);
        }
    };

    const handleCheckboxChange = (index: number) => {
        const newCheckboxStates = [...checkboxStates];
        newCheckboxStates[index] = !newCheckboxStates[index];
        setCheckboxStates(newCheckboxStates);

        const selected = sortedNumberFieldCustom.filter((_, i) => newCheckboxStates[i]);
        setSelectedItems(selected);

        const areAllChecked = newCheckboxStates.every(Boolean);
        const areAnyUnchecked = newCheckboxStates.some((state) => !state);

        if (areAllChecked) {
            setSelectAllChecked(true);
        } else if (areAnyUnchecked) {
            setSelectAllChecked(false);
        }
    };

    useEffect(() => {
        if (!props.open) return;

        const savedData = getDataFromLocalStorage();
        if (savedData) {
            const newCheckboxStates = sortedNumberFieldCustom.map((item) =>
                savedData.some((savedItem) => savedItem.id === item.id)
            );
            setCheckboxStates(newCheckboxStates);

            const selected = sortedNumberFieldCustom.filter((_, i) => newCheckboxStates[i]);
            setSelectedItems(selected);
            setSelectAllChecked(selected.length === sortedNumberFieldCustom.length);
        }
    }, [props.open]);

    const handleCancel = () => {
        setCheckboxStates(initialCheckboxStates);
        props.onClose?.();
    };

    const handleSave = (data: TableRowContent[]) => {
        try {
            const jsonData = JSON.stringify(data);
            localStorage.setItem('eCamConfigCustomFields', jsonData);
            props.onClose?.();
        } catch (error) {
            console.log('🚀 ~ handleSave ~ error:', error);
        }
    };

    function createGrid(dataStatistic: TableRowContent[], columns: number) {
        const gridItems: JSX.Element[] = [];
        const itemsInFirstColumn = 13;
        const itemsInRemainingColumns = 12;
        let remainingItems = dataStatistic.length;

        for (let i = 0; i < columns; i++) {
            const columnItems: JSX.Element[] = [];
            let numItems = i === 0 ? itemsInFirstColumn : itemsInRemainingColumns;
            numItems = Math.min(numItems, remainingItems);

            for (let j = 0; j < numItems; j++) {
                const index = i === 0 ? j : itemsInFirstColumn + (i - 1) * itemsInRemainingColumns + j;
                const item = dataStatistic[index];
                if (item) {
                    columnItems.push(
                        <Stack direction="row" width={'100%'} alignItems="center" padding={1} key={item.id}>
                            <FormGroup
                                sx={{
                                    paddingLeft: '10px',
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <BaseCheckbox
                                            checked={checkboxStates[index]}
                                            onChange={() => handleCheckboxChange(index)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                        />
                                    }
                                    label={<Typography ml={1}>{item.id}</Typography>}
                                />
                            </FormGroup>
                        </Stack>
                    );
                }
            }
            gridItems.push(
                <Grid item width={'100%'} xs={dataStatistic.length === 1 ? 12 : 6} key={i}>
                    {columnItems}
                </Grid>
            );

            remainingItems -= numItems;
            if (remainingItems <= 0) break;
        }

        return gridItems;
    }

    return (
        <PopUpBase
            title={<Typography variant="h4">Customise</Typography>}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'lg',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                },
            }}
            open={props.open}
            onClose={props.onClose}
            hideConfirm
            desc={
                <>
                    <BoxRadiusGrey>
                        <Stack>
                            <Typography>Default field</Typography>
                            <Typography color={color.grey600}>
                                These default fields will always be visible on the data table and you cannot customize
                                them.
                            </Typography>
                            <Stack
                                mt={1}
                                direction={'row'}
                                justifyContent={'space-between'}
                                p={2}
                                bgcolor={color.white}
                            >
                                {dataNameTable.map((item, index) => (
                                    <Stack key={index} direction={'row'} spacing={1}>
                                        <CheckedConfigCam />
                                        <Typography mt={1}>{item.label}</Typography>
                                    </Stack>
                                ))}
                            </Stack>
                        </Stack>

                        <Stack mt={2}>
                            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'end'}>
                                <Stack direction={'column'}>
                                    <Typography>Custom field</Typography>
                                    <Typography color={color.grey600}>
                                        You can customize any fields to appear on the data table.
                                    </Typography>
                                </Stack>

                                <FormGroup
                                    sx={{
                                        alignItems: 'end',
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <BaseCheckbox checked={selectAllChecked} onChange={handleSelectAllChange} />
                                        }
                                        label={
                                            <Typography ml={1} mt={'2px'}>
                                                Select All
                                            </Typography>
                                        }
                                    />
                                </FormGroup>
                            </Stack>
                            <Stack
                                mt={1}
                                direction={'row'}
                                justifyContent={'space-between'}
                                p={2}
                                bgcolor={color.white}
                            >
                                <Stack
                                    direction={'row'}
                                    spacing={5}
                                    width={'100%'}
                                    sx={{ maxHeight: 600, overflowY: 'auto', overflowX: 'auto' }}
                                >
                                    {createGrid(sortedNumberFieldCustom, 3)}
                                </Stack>
                                <Stack direction={'row'}></Stack>
                            </Stack>
                        </Stack>
                    </BoxRadiusGrey>
                </>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            handleCancel();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button sx={{ minWidth: 130 }} variant="contained" onClick={() => handleSave(selectedItems)}>
                        Apply
                    </Button>
                </Stack>
            }
        />
    );
}
