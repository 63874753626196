import { ISendActionGet } from '@EcamModel/controllers/IPTZCamConfigureRequestHttpController';
import { PTZAutoTracking } from '@EcamModel/model/PTZAutoTracking';
import { PTZBasic } from '@EcamModel/model/PTZBasic';
import { PTZCameraDayNight } from '@EcamModel/model/PTZCameraDayNight';
import { PTZCameraSetting } from '@EcamModel/model/PTZCameraSetting';
import { PTZEventMotionDetection } from '@EcamModel/model/PTZEventMotionDetection';
import { PTZLimit } from '@EcamModel/model/PTZLimit';
import { PTZRecordSetting } from '@EcamModel/model/PTZRecordSetting';
import { PTZScheduledTask } from '@EcamModel/model/PTZScheduledTask';
import { PTZSnapShotSetting } from '@EcamModel/model/PTZSnapshotSetting';
import { PTZStorageSetting } from '@EcamModel/model/PTZStorageSetting';
import { PTZSysDateTime } from '@EcamModel/model/PTZSysDateTime';
import { PTZVideoGeneral } from '@EcamModel/model/PTZVideoGeneral';
import { pushError } from '@components/toast';
import { cameraPTZConfigureRequestHttpController } from '@controllers/index';
import { useState } from 'react';

export enum SectionsCollapse {
    Basic = 'basic',
    Others = 'others',
    PTZ_OSD = 'PTZ_OSD',

    InitialPosition = 'initialPosition',
    ScheduledTasks = 'scheduledTasks',

    AutoTracking_BasicSettings = 'autoTracking_BasicSettings',
    AutoTracking_ScheduleSettings = 'autoTracking_ScheduleSettings',

    SetTheSystemTime = 'setTheSystemTime',
    CurrentSystemTime = 'currentSystemTime',

    ImageAdjustment = 'imageAdjustment',
    ImageEnhancement = 'imageEnhancement',
    DayNightSwitch = 'day_nightSwitch',
    DayNightParameters = 'day_nightParameters',
    Exposure = 'exposure',
    Backlight = 'backlight',
    WhiteBalance = 'whiteBalance',
    Display = 'display',

    MotionDetection_BasicSettings = 'motionDetection_BasicSettings',
    MotionDetection_ScheduleSettings = 'motionDetection_ScheduleSettings',

    StorageManagement = 'storageManagement',
    RecordSettings = 'recordSettings',
    SnapshotSettings = 'snapshotSettings',

    PrimaryStream = 'primaryStream',
    SecondaryStream = 'secondaryStream',
    TertiaryStream = 'tertiaryStream',
}

export interface LoadingState {
    basicInfoMilesightsPTZ: boolean;
    PTZLimit: boolean;
    PTZAutoTracking: boolean;
    PTZMotionDetection: boolean;
    PTZRecordSetting: boolean;
    PTZScheduledTask: boolean;
    PTZSnapShotSetting: boolean;
    PTZStorageSetting: boolean;
    PTZVideoGeneral: boolean;
    PTZSysDateTime: boolean;
    PTZCameraDayNight: boolean;
    PTZCameraSetting: boolean;
}

export default function useMileSightPTZ() {
    const [basicInfoMilesightsPTZ, setBasicInfoMilesightsPTZ] = useState<PTZBasic>({} as PTZBasic);
    const [PTZLimit, setPTZLimit] = useState<PTZLimit>({} as PTZLimit);
    const [PTZAutoTracking, setPTZAutoTracking] = useState<PTZAutoTracking>({} as PTZAutoTracking);
    const [PTZMotionDetection, setPTZMotionDetection] = useState<PTZEventMotionDetection>(
        {} as PTZEventMotionDetection
    );
    const [PTZRecordSetting, setPTZRecordSetting] = useState<PTZRecordSetting>({} as PTZRecordSetting);
    const [PTZScheduledTask, setPTZScheduledTask] = useState<PTZScheduledTask>({} as PTZScheduledTask);
    const [PTZSnapShotSetting, setPTZSnapShotSetting] = useState<PTZSnapShotSetting>({} as PTZSnapShotSetting);
    const [PTZStorageSetting, setPTZStorageSetting] = useState<PTZStorageSetting>({} as PTZStorageSetting);
    const [PTZVideoGeneral, setPTZVideoGeneral] = useState<PTZVideoGeneral>({} as PTZVideoGeneral);
    const [PTZSysDateTime, setPTZSysDateTime] = useState<PTZSysDateTime>({} as PTZSysDateTime);
    const [PTZCameraDayNight, setPTZCameraDayNight] = useState<PTZCameraDayNight>({} as PTZCameraDayNight);
    const [PTZCameraSetting, setPTZCameraSetting] = useState<PTZCameraSetting>({} as PTZCameraSetting);

    const [isLoadingMilesights, setIsLoadingMilesights] = useState<LoadingState>({
        basicInfoMilesightsPTZ: true,
        PTZLimit: true,
        PTZAutoTracking: true,
        PTZMotionDetection: true,
        PTZRecordSetting: true,
        PTZScheduledTask: true,
        PTZSnapShotSetting: true,
        PTZStorageSetting: true,
        PTZVideoGeneral: true,
        PTZSysDateTime: true,
        PTZCameraDayNight: true,
        PTZCameraSetting: true,
    });

    const setPartialLoading = (loadings: Partial<LoadingState>) => {
        setIsLoadingMilesights((prev) => ({ ...prev, ...loadings }));
    };

    const [isOpenCollapse, setIsOpenCollapse] = useState<{ [key in SectionsCollapse]: boolean }>({
        basic: true,
        PTZ_OSD: false,
        others: false,
        initialPosition: false,
        scheduledTasks: false,
        day_nightSwitch: false,
        storageManagement: false,
        recordSettings: false,
        snapshotSettings: false,
        autoTracking_BasicSettings: false,
        autoTracking_ScheduleSettings: false,
        backlight: false,
        currentSystemTime: false,
        day_nightParameters: false,
        display: false,
        exposure: false,
        imageAdjustment: false,
        imageEnhancement: false,
        motionDetection_BasicSettings: false,
        motionDetection_ScheduleSettings: false,
        primaryStream: false,
        secondaryStream: false,
        setTheSystemTime: false,
        tertiaryStream: false,
        whiteBalance: false,
    });

    const getBasicInfoMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZBasic(props)
            .then((res) => {
                setBasicInfoMilesightsPTZ(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ basicInfoMilesightsPTZ: false });
            });
    };

    const getLimitMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZLimit(props)
            .then((res) => {
                setPTZLimit(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZLimit: false });
            });
    };

    const getAutoTrackingMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZAutoTracking(props)
            .then((res) => {
                setPTZAutoTracking(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZAutoTracking: false });
            });
    };

    const getMotionDetectionMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZEventMotionDetection(props)
            .then((res) => {
                setPTZMotionDetection(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZMotionDetection: false });
            });
    };

    const getRecordSettingMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZRecordSetting(props)
            .then((res) => {
                setPTZRecordSetting(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZRecordSetting: false });
            });
    };

    const getScheduledTaskMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZScheduledTask(props)
            .then((res) => {
                setPTZScheduledTask(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZScheduledTask: false });
            });
    };

    const getSnapShotSettingMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZSnapShotSetting(props)
            .then((res) => {
                setPTZSnapShotSetting(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZSnapShotSetting: false });
            });
    };

    const getStorageSettingMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZStorageSetting(props)
            .then((res) => {
                setPTZStorageSetting(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZStorageSetting: false });
            });
    };

    const getVideoGeneralMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZVideoGeneral(props)
            .then((res) => {
                setPTZVideoGeneral(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZVideoGeneral: false });
            });
    };

    const getSysDateTimeMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZSysDateTime(props)
            .then((res) => {
                setPTZSysDateTime(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZSysDateTime: false });
            });
    };

    const getCameraDayNightMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZCameraDayNight(props)
            .then((res) => {
                setPTZCameraDayNight(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZCameraDayNight: false });
            });
    };

    const getCameraSettingMilesightsPTZ = (props: ISendActionGet) => {
        cameraPTZConfigureRequestHttpController
            .getPTZCameraSetting(props)
            .then((res) => {
                setPTZCameraSetting(res);
            })
            .catch((err) => {
                pushError(err);
            })
            .finally(() => {
                setPartialLoading({ PTZCameraSetting: false });
            });
    };

    return {
        isLoadingMilesights,
        setPartialLoading,
        isOpenCollapse,
        setIsOpenCollapse,

        basicInfoMilesightsPTZ,
        getBasicInfoMilesightsPTZ,

        PTZLimit,
        getLimitMilesightsPTZ,

        PTZAutoTracking,
        getAutoTrackingMilesightsPTZ,

        PTZMotionDetection,
        getMotionDetectionMilesightsPTZ,

        PTZRecordSetting,
        getRecordSettingMilesightsPTZ,

        PTZScheduledTask,
        getScheduledTaskMilesightsPTZ,

        PTZSnapShotSetting,
        getSnapShotSettingMilesightsPTZ,

        PTZStorageSetting,
        getStorageSettingMilesightsPTZ,

        PTZVideoGeneral,
        getVideoGeneralMilesightsPTZ,

        PTZSysDateTime,
        getSysDateTimeMilesightsPTZ,

        PTZCameraDayNight,
        getCameraDayNightMilesightsPTZ,

        PTZCameraSetting,
        getCameraSettingMilesightsPTZ,
    };
}
