import { BaseModel } from '@Core';
import { CameraPhotoVehicles } from './CameraPhotoVehicles';
import { PotentialContraventions } from './PotentialContraventions';

export interface PotentialContraventionPhotos extends BaseModel {
    CameraPhotoVehicleId: number;
    CameraPhotoVehicle?: CameraPhotoVehicles;

    PotentialContraventionId: number;
    PotentialContravention?: PotentialContraventions;

    PhotoType: PhotoType;

    VehicleBlobName?: string;
    VehicleBrightness?: number;
    VehicleContrast?: number;
}

export enum PhotoType {
    Entry,
    Validation,
    Overview,
    Exit,
}
