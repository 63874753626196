import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import IMultiSelectWithCheckBox from '@components/multi-select/IMultiSelectWithCheckBox';
import StyledAutocomplete from '@components/styled-autocomplete';

import { useCameraConfig } from '../hook/CameraConfigProvider';

export default function FilterChildCameraOverview() {
    const cameraConfigContext = useCameraConfig();

    const {
        filter,
        setFilter,
        zones,
        locationAutocomplete,
        onChangeLocation,
        statusAutocomplete,
        cameraVersionAutocomplete,
        setPartialFilter,
        firmwareAutocomplete,
        uploadModeAutocomplete,
        serviceTypeAutocomplete,
        purposesAutocomplete,
    } = cameraConfigContext;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid2 container spacing={2}>
                {/* Select Location */}
                <Grid2 xs={6} md={4} zIndex={12}>
                    <StyledAutocomplete
                        {...locationAutocomplete}
                        getOptionLabel={(o) => o.Name}
                        label="Location"
                        value={filter.location}
                        placeholder="All locations"
                        onChange={onChangeLocation}
                    />
                </Grid2>

                {/* Select Zone */}
                <Grid2 xs={6} md={4} zIndex={12}>
                    <IMultiSelectWithCheckBox
                        limitTags={3}
                        label={'Zone'}
                        keyId={'Id'}
                        keyLabel={'PublicName'}
                        listData={zones}
                        isDisable={!zones.length || filter.location === undefined}
                        value={filter.zones ?? []}
                        setFilter={(option) => {
                            setPartialFilter({ zones: option.length ? option : undefined });
                        }}
                        chipSize={150}
                    />
                </Grid2>

                {/* Select Status */}
                <Grid2 xs={6} md={4} zIndex={12}>
                    <StyledAutocomplete
                        {...statusAutocomplete}
                        getOptionLabel={(o) => {
                            return o.Name === 'Inactive' ? 'Inactive' : o.Name;
                        }}
                        label="Status"
                        value={filter.status}
                        placeholder="All status"
                        onChange={(o) => {
                            setFilter((prev) => ({ ...prev, status: o }));
                        }}
                    />
                </Grid2>

                {/* Select Ping */}
                {/* <Grid2
                    xs={4}
                    sx={{
                        zIndex: 11,
                    }}
                >
                    <StyledAutocomplete
                        {...statusAutocomplete}
                        getOptionLabel={(o) => o.Name}
                        label="Ping"
                        value={filter.ping}
                        placeholder="All ping"
                        onChange={(o) => {
                            setFilter((prev) => ({ ...prev, ping: o }));
                            handleChangeParams({ ping: o?.Id });
                        }}
                    />
                </Grid2> */}

                {/* Select Camera Version */}
                <Grid2 xs={6} md={4} zIndex={11}>
                    <StyledAutocomplete
                        {...cameraVersionAutocomplete}
                        getOptionLabel={(o) => o.Name}
                        label="Camera version"
                        value={filter.camVersion}
                        placeholder="All versions"
                        onChange={(o) => {
                            setFilter((prev) => ({ ...prev, camVersion: o }));
                        }}
                    />
                </Grid2>

                {/* Select firmware */}
                <Grid2 xs={6} md={4} zIndex={11}>
                    <StyledAutocomplete
                        {...firmwareAutocomplete}
                        getOptionLabel={(o) => o.Name}
                        label="Firmware"
                        value={filter.firmware}
                        placeholder="All firmware"
                        onChange={(o) => {
                            setFilter((prev) => ({ ...prev, firmware: o }));
                        }}
                    />
                </Grid2>

                {/* Select uploadMode */}
                <Grid2 xs={6} md={4} zIndex={11}>
                    <StyledAutocomplete
                        {...uploadModeAutocomplete}
                        getOptionLabel={(o) => o.Name}
                        label="Upload mode"
                        value={filter.uploadMode}
                        placeholder="All mode"
                        onChange={(o) => {
                            setFilter((prev) => ({ ...prev, uploadMode: o }));
                        }}
                    />
                </Grid2>

                {/* Select service type */}
                <Grid2 xs={6} zIndex={10}>
                    <StyledAutocomplete
                        {...serviceTypeAutocomplete}
                        getOptionLabel={(o) => o.Name}
                        label="Service type"
                        value={filter.serviceType}
                        placeholder="All service types"
                        onChange={(o) => {
                            setFilter((prev) => ({ ...prev, serviceType: o }));
                        }}
                    />
                </Grid2>
                <Grid2 xs={6} zIndex={10}>
                    <StyledAutocomplete
                        {...purposesAutocomplete}
                        getOptionLabel={(o) => o.Name}
                        label="Purposes"
                        value={filter.camPurposes}
                        placeholder="All purposes"
                        onChange={(o) => {
                            setFilter((prev) => ({ ...prev, camPurposes: o }));
                        }}
                    />
                </Grid2>
            </Grid2>
        </Box>
    );
}
