import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function ArrowBackIosIcon(props: Props) {
    return (
        <svg width="18" height="29" viewBox="0 0 18 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0 28.2412L17.25 14.121L0 0.000807762V5.6492L10.3496 14.121L0 22.5928V28.2412Z"
                fill="white"
            />
        </svg>
    );
}
