import PopUpBase from '@components/PopUpBase';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Stack, TextField, Typography } from '@mui/material';
import color from '@theme/Colors';
import { useEffect, useState } from 'react';
import { Mode, useCameraConfig } from '../hook/CameraConfigProvider';

type Props = Omit<IPopUp, 'onConfirm'> & {};
export default function PopUpAddOrUpdateNoteInCamera(props: Props) {
    const { open, onClose } = props;
    const [note, setNote] = useState<string>('');
    const cameraConfigContext = useCameraConfig();
    const { listNotes, mode, idCamera, idNote, handleAddOrUpdateNote, filteredNameCamera, popUpNoteInCamera } =
        cameraConfigContext;
    const filteredNote = listNotes?.rows?.find((n) => n.Id === idNote);

    useEffect(() => {
        if (!open) return;

        if (mode === Mode.edit) {
            setNote(filteredNote?.Notes ?? '');
        } else {
            setNote('');
        }
    }, [open]);

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                maxWidth: 'lg',
                PaperProps: {
                    style: { minWidth: 700 },
                },
            }}
            title={
                <Typography variant="h4">
                    {mode === Mode.create
                        ? `Add note: ${filteredNameCamera?.CameraName}`
                        : `Edit note: ${filteredNameCamera?.CameraName}`}
                </Typography>
            }
            desc={
                <>
                    <Stack direction={'column'} spacing={1} mt={1}>
                        <TextField
                            autoFocus
                            required
                            label="Note"
                            error={note.length > 255}
                            multiline
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: 0,
                                    borderRadius: 0,
                                },
                            }}
                            rows={8}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="Enter note"
                        />
                        <Typography textAlign={'end'} color={note.length > 255 ? color.danger : color.textPrimary}>
                            {note.length} / 255
                        </Typography>
                    </Stack>
                </>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                            popUpNoteInCamera.setTrue();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={note.length > 255 || note === '' || note === filteredNote?.Notes}
                        onClick={() => {
                            const data: CameraNotes = {
                                CameraId: idCamera!,
                                Notes: note,
                                Id: mode === Mode.edit ? idNote : undefined,
                            };
                            handleAddOrUpdateNote(data.CameraId, data.Notes, data.Id);
                            props.onClose?.();
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            }
            minWidthButton={150}
        />
    );
}
