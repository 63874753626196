import { CameraWithInfo, PurposeType } from '@EcamModel/model';
import PopUpBase from '@components/PopUpBase';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { pushSuccess } from '@components/toast';
import { cameraController } from '@controllers/index';
import { IPopUp } from '@hooks/usePopUp';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { RequiredTitle } from '@pages/cameras/add-new';
import { search } from '@pages/cameras/list/helpers';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

type Props = Omit<IPopUp, 'onConfirm'> & {
    camera: CameraWithInfo;
    onConfirm?(camera: CameraWithInfo): void;
};

type PurposesLabelType = {
    key: PurposeType;
    label: string;
};

const cameraPurposeType: Record<PurposeType, PurposesLabelType> = {
    [PurposeType.Decommissioned]: {
        key: PurposeType.Decommissioned,
        label: 'Decommissioned',
    },
    [PurposeType.Deleted]: {
        key: PurposeType.Deleted,
        label: 'Deleted',
    },
    [PurposeType.Live]: {
        key: PurposeType.Live,
        label: 'Live',
    },
    [PurposeType.OnHold]: {
        key: PurposeType.OnHold,
        label: 'On Hold',
    },
    [PurposeType.Prospective]: {
        key: PurposeType.Prospective,
        label: 'Prospective',
    },
    [PurposeType.Testing]: {
        key: PurposeType.Testing,
        label: 'Testing',
    },
};

type FormValues = {
    purposeType: PurposesLabelType;
};

export default function PopUpEditPurposes(props: Props) {
    const [isLoading, setIsLoading] = useState(false);

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        setError,
        clearErrors,
        formState: { isDirty, isValid, errors },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            purposeType: cameraPurposeType[PurposeType.Live],
        },
    });
    const disabled = !(isValid && (!props.camera?.PurposeType || isDirty));

    const purposesTypeAutocomplete = useStyledAutocomplete({
        list: {
            options: Object.values(cameraPurposeType),
            isFiltered(option, searchText) {
                return search(option.label, searchText);
            },
        },
    });

    const handleSave = async (data: FormValues) => {
        setIsLoading(true);
        try {
            const updatedCamera = await cameraController.upsert({
                ...props.camera,
                PurposeType: data.purposeType.key,
            });

            props.onConfirm?.({ ...props.camera, ...updatedCamera });
            props.onClose?.();
            setIsLoading(false);
            pushSuccess('Update successfully');
        } catch (error) {}
    };

    useEffect(() => {
        if (!props.open) return;
        reset({
            purposeType: cameraPurposeType[props.camera?.PurposeType ?? PurposeType.Live],
        });
    }, [props.open, props.camera]);

    return (
        <PopUpBase
            open={props.open}
            fixOverflow
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
                PaperProps: {
                    sx: { overflowY: 'unset' },
                },
            }}
            onClose={props.onClose}
            title={<Typography variant="h4">Edit purposes</Typography>}
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="100%" sx={{ background: '#FAFAFA', p: 2 }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <RequiredTitle title="Purpose type" required sx={{ width: 130 }} />
                        <Controller
                            name="purposeType"
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <StyledAutocomplete
                                    {...purposesTypeAutocomplete}
                                    {...field}
                                    getOptionLabel={(o) => o.label}
                                    disabledAllOption
                                    value={field.value}
                                    onChange={(s) => {
                                        field.onChange(s);
                                    }}
                                    wrapperWidth={'100%'}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={disabled || isLoading}
                        onClick={handleSubmit(handleSave)}
                        endIcon={isLoading && <CircularProgress color="inherit" size="14px" />}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
