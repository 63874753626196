import { Stack, SxProps, TableCell, TableCellProps, Theme, Typography, useThemeProps } from '@mui/material';
import color from '@theme/Colors';
import { omit } from 'lodash';
import { BiDownArrowAlt } from 'react-icons/bi';
import { HiOutlineArrowLongDown, HiOutlineArrowLongUp } from 'react-icons/hi2';

const CellOfRow = ({
    value,
    color,
    align,
    sx,
}: {
    value: JSX.Element | string | number;
    color?: string;
    align?: TableCellProps['align'];
    sx?: SxProps<Theme>;
}) => {
    return (
        <TableCell sx={{ ...sx, textAlign: align ?? 'center' }}>
            {['number', 'string'].includes(typeof value) ? (
                <Typography
                    variant="body1"
                    color={color ?? 'inherit'}
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                >
                    {value}
                </Typography>
            ) : (
                value
            )}
        </TableCell>
    );
};

export type Sort = 'ASC' | 'DSC';

type BaseHeadProps = {
    title: string | React.ReactNode;
    sx?: SxProps<Theme>;
    background?: string;
    align?: TableCellProps['align'];
    sort?: Sort;
    onChangeSort?(sort: Sort): void;
    isLeftLabel?: boolean;
    pagingLength?: number;
};

const BaseHead = (props: BaseHeadProps) => {
    const { title, sx, align, background, sort, pagingLength } = props;
    const shouldShowSortIcon = pagingLength !== 1;

    return (
        <TableCell sx={{ background: background ?? '#FAFAFA', ...omit(sx, 'background') }} align={align ?? 'right'}>
            {!!sort && shouldShowSortIcon ? (
                <Stack
                    direction={'row'}
                    justifyContent={props.isLeftLabel ? 'flex-start' : 'center'}
                    sx={{ cursor: 'pointer', userSelect: 'none', mr: sort ? '-8px' : undefined }}
                    onClick={() => props.onChangeSort?.(sort === 'ASC' ? 'DSC' : 'ASC')}
                >
                    {typeof title === 'string' ? (
                        <Typography fontSize={16} fontWeight={500}>
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}
                    {sort && (
                        <Stack sx={{ display: 'grid', placeItems: 'center' }} ml={'2px'}>
                            <BiDownArrowAlt
                                size={16}
                                style={{
                                    color: color.grey600,
                                    transition: 'all 0.3s',
                                    transform: `rotate(${sort === 'ASC' ? 0 : -180}deg)`,
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
            ) : (
                <>
                    {typeof title === 'string' ? (
                        <Typography fontSize={16} fontWeight={500}>
                            {title}
                        </Typography>
                    ) : (
                        title
                    )}
                </>
            )}
        </TableCell>
    );
};
export { CellOfRow, BaseHead };
