import { useEffect, useRef, useState } from 'react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';

import ArrowBackIosIcon from '@assets/jsx-icon/ArrowBackIosIcon';
import ArrowForwardIosIcon from '@assets/jsx-icon/ArrowForwardIosIcon';
import NextSlidesBottom from '@assets/jsx-icon/NextSlidesBottom';
import PrevSlidesBottom from '@assets/jsx-icon/PrevSlidesBottom';
import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton } from '@mui/material';
import color from '@theme/Colors';
import { Swiper as SwiperType } from 'swiper';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

interface Props {
    images: { file: File | null; url: string }[];
    handleRemoveCameraInstalledPhoto: (indexImage: number) => void;
}

export default function EditCameraInstalledPhoto(props: Props) {
    const { handleRemoveCameraInstalledPhoto } = props;
    const CameraInstalledPhotos = props.images || [];
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperType | null | any>(null);
    const [selectedItem, setSelectedItem] = useState<string | undefined>();
    const prevSlidesMainRef = useRef<HTMLButtonElement | null>(null);
    const nextSlidesMainRef = useRef<HTMLButtonElement | null>(null);
    const prevSlidesPreviewRef = useRef<HTMLButtonElement | null>(null);
    const nextSlidesPreviewRef = useRef<HTMLButtonElement | null>(null);

    const slidesPerView = Math.min(CameraInstalledPhotos?.length, 5);

    useEffect(() => {
        if (thumbsSwiper) {
            thumbsSwiper.params.navigation.prevEl = prevSlidesPreviewRef.current;
            thumbsSwiper.params.navigation.nextEl = nextSlidesPreviewRef.current;
            thumbsSwiper.navigation.update();
        }
    }, [thumbsSwiper]);

    useEffect(() => {
        const mainSwiperEl = prevSlidesMainRef.current?.closest('.swiper-container') as any;
        if (mainSwiperEl) {
            const mainSwiper = mainSwiperEl.swiper;
            mainSwiper.params.navigation.prevEl = prevSlidesMainRef.current;
            mainSwiper.params.navigation.nextEl = nextSlidesMainRef.current;
            mainSwiper.navigation.update();
        }
    }, []);

    return (
        <>
            <Box sx={{ position: 'relative', height: '260px', my: 2 }}>
                <IconButton
                    ref={prevSlidesMainRef}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: 5,
                        display: CameraInstalledPhotos.length > 1 ? 'block' : 'none',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        color: '#fff',
                    }}
                >
                    <ArrowForwardIosIcon style={{ fontSize: '24px' }} />
                </IconButton>
                <IconButton
                    ref={nextSlidesMainRef}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: 5,
                        display: CameraInstalledPhotos.length > 1 ? 'block' : 'none',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        color: '#fff',
                    }}
                >
                    <ArrowBackIosIcon style={{ fontSize: '24px' }} />
                </IconButton>
                <Swiper
                    style={{
                        height: '260px',
                    }}
                    loop={true}
                    navigation={{
                        prevEl: prevSlidesMainRef.current,
                        nextEl: nextSlidesMainRef.current,
                    }}
                    thumbs={{ swiper: thumbsSwiper }}
                    modules={[FreeMode, Navigation, Thumbs]}
                    onSlideChange={(swiper) => setSelectedIndex(swiper.realIndex)}
                >
                    {CameraInstalledPhotos?.map((e, index) => (
                        <SwiperSlide key={index}>
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    aspectRatio: '16/9',
                                    background: `url(${e.url}) no-repeat`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setSelectedItem(e.url);
                                }}
                            />
                        </SwiperSlide>
                    ))}

                    {selectedItem && (
                        <Dialog
                            open={true}
                            onClose={() => {
                                setSelectedItem(undefined);
                            }}
                            PaperProps={{
                                sx: {
                                    minWidth: 1280,
                                    minHeight: 720,
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    aspectRatio: '16/9',
                                    background: `url(${selectedItem}) no-repeat`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: 1,
                                    cursor: 'pointer',
                                }}
                            />
                        </Dialog>
                    )}
                </Swiper>
            </Box>

            {CameraInstalledPhotos.length > 1 && (
                <Box
                    sx={{
                        padding: '16px 32px',
                        backgroundColor: color.grey200,
                        borderRadius: '8px',
                        position: 'relative',
                    }}
                >
                    <IconButton
                        ref={prevSlidesPreviewRef}
                        sx={{
                            visibility: CameraInstalledPhotos?.length <= 5 ? 'hidden' : '',
                            position: 'absolute',
                            top: '50%',
                            left: 5,
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            padding: '2px',
                        }}
                    >
                        <PrevSlidesBottom />
                    </IconButton>
                    <IconButton
                        ref={nextSlidesPreviewRef}
                        sx={{
                            visibility: CameraInstalledPhotos?.length <= 5 ? 'hidden' : '',
                            position: 'absolute',
                            top: '50%',
                            right: 5,
                            transform: 'translateY(-50%)',
                            zIndex: 10,
                            padding: '2px',
                        }}
                    >
                        <NextSlidesBottom />
                    </IconButton>
                    <Swiper
                        onSwiper={setThumbsSwiper}
                        loop={true}
                        spaceBetween={5}
                        slidesPerView={slidesPerView}
                        navigation={{
                            nextEl: nextSlidesPreviewRef.current,
                            prevEl: prevSlidesPreviewRef.current,
                        }}
                        style={{
                            width: '100%',
                            justifyContent: 'space-between',
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                    >
                        {CameraInstalledPhotos?.map((e, index) => (
                            <SwiperSlide
                                key={index}
                                className={index === selectedIndex ? 'selected' : ''}
                                style={{
                                    cursor: 'pointer',
                                    width: 'calc(20% - 5px)',
                                    boxSizing: 'border-box',
                                    height: '105px',
                                    borderRadius: '8px',
                                    border: index === selectedIndex ? `3px solid ${color.success}` : 'none',
                                    overflow: 'hidden',
                                    objectFit: 'cover',
                                    position: 'relative',
                                    marginRight: 0,
                                }}
                            >
                                <>
                                    <img
                                        src={`${e.url}`}
                                        alt={`thumb-${index}`}
                                        style={{ width: '100%', height: '105px', objectFit: 'cover' }}
                                    />
                                    {handleRemoveCameraInstalledPhoto && (
                                        <IconButton
                                            onClick={() => handleRemoveCameraInstalledPhoto(index)}
                                            size="small"
                                            sx={{
                                                position: 'absolute',
                                                top: 4,
                                                right: 4,
                                                backgroundColor: color.white,
                                                '&:hover': {
                                                    backgroundColor: color.grey400,
                                                },
                                            }}
                                        >
                                            <Close sx={{ fontSize: 12 }} />
                                        </IconButton>
                                    )}
                                </>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            )}
        </>
    );
}
