import { appConfig } from '@configs/index';
import { Backdrop, Box, CircularProgress, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Image as KonvaImgage, Layer, Stage } from 'react-konva';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { useScreen } from 'usehooks-ts';

type Props = {
    blob?: string;
    onClose?(): void;
};

export const useImageDimensions = (blob = '') => {
    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [width, setWidth] = useState<number | null>(null);
    const [height, setHeight] = useState<number | null>(null);
    const [image, setImage] = useState<HTMLImageElement>();

    useEffect(() => {
        if (!blob) {
            setLoading(false);
            return;
        }
        const img = new Image();
        img.src = appConfig.gateway.blobURL + blob;

        img.onload = () => {
            const width = img.width;
            const height = img.height;
            setWidth(width);
            setHeight(height);

            setImage(img);
            setLoading(false);
        };

        img.onerror = () => {
            setLoading(false);
            setIsError(true);
        };
    }, [blob]);

    return {
        image,
        loading,
        isError,
    };
};

const PreviewImage = (props: Props) => {
    const { image } = useImageDimensions(props.blob);
    console.log(`image:`, image?.width);
    const screen = useScreen();

    if (!image?.width || !image?.height || !screen)
        return (
            <Backdrop open sx={{ zIndex: (theme) => theme.zIndex.appBar + 1 }}>
                <CircularProgress />
            </Backdrop>
        );

    const defaultHeight = screen.availHeight * 0.75;
    const scale = image.height < defaultHeight ? 1 : defaultHeight / image.height;
    const displayWidth = image.width * scale;
    const displayHeight = image.height * scale;

    return (
        <Dialog
            open={!!props.blob}
            onClose={props.onClose}
            PaperProps={{
                sx: {
                    minWidth: displayWidth,
                    minHeight: displayHeight,
                },
            }}
        >
            <div>
                {/* <img src={props.image} alt="" style={{ objectFit: 'contain', width: '80vw' }} /> */}
                <Stage width={displayWidth} height={displayHeight}>
                    <Layer>
                        <KonvaImgage image={image} scaleX={scale} scaleY={scale} onClick={(e) => console.log(e)} />
                        {/* {!!prevGeoZones?.length &&
                            prevGeoZones?.map((frame, frameIndex) => {
                                const center = getCenterFrame(frame);
                                return (
                                    <React.Fragment key={`frame-${frameIndex}`}>
                                        <Line points={frame.flatMap((point) => [point.x, point.y])} stroke="blue" />
                                        <Circle x={center.x} y={center.y} radius={15} fill="#fff" />
                                        <Text
                                            x={center.x}
                                            y={center.y}
                                            text={String(`${frameIndex + 1}`)}
                                            fontSize={14}
                                            fill={'black'}
                                            align={'center'}
                                            verticalAlign={'middle'}
                                            offsetX={4}
                                            offsetY={6}
                                        />
                                    </React.Fragment>
                                );
                            })} */}
                    </Layer>
                </Stage>
            </div>
        </Dialog>
    );
};

export default React.memo(PreviewImage);
