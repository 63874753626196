import { Box, Theme, Typography } from '@mui/material';
import React from 'react';
import { logoHeader } from '@configs/ImageFromEnv';

export default function LogoIconWithName() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 'none',
                order: '0',
            }}
        >
            <img src={logoHeader} alt="logo" width={'300px'} />
            {/* <Typography
                variant="h5"
                component={'p'}
                sx={{
                    color: color.white,
                    borderLeft: `2px solid ${color.primary}`,
                    lineHeight: '24px',
                    fontSize: '20px',
                    pl: 1,
                    ml: '5px',
                }}
            >
                <span style={{ color: color.primary }}>i</span>Hub Insights
            </Typography> */}
        </Box>
    );
}
