import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcSetting(props: SvgIconProps) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4837 2.39048C13.7146 2.76813 14.1292 2.99975 14.5769 2.99975C15.3551 2.99975 16.5861 2.99975 17.3706 2.99975C17.826 2.99975 18.2469 3.24018 18.4753 3.62915C18.8554 4.27996 19.446 5.28827 19.8287 5.94411C20.0609 6.34063 20.057 6.83031 19.8172 7.2218C19.4473 7.82981 18.8924 8.73993 18.5199 9.35045C18.2763 9.75076 18.2763 10.2492 18.5199 10.6495C18.8924 11.2601 19.4473 12.1702 19.8172 12.7782C20.057 13.1697 20.0609 13.6594 19.8287 14.0559C19.446 14.7117 18.8554 15.72 18.4753 16.3708C18.2469 16.7598 17.826 17.0003 17.3706 17.0003C16.5861 17.0003 15.3551 17.0003 14.5769 17.0003C14.1292 17.0003 13.7146 17.2319 13.4837 17.6095C13.1674 18.1294 12.7145 18.8708 12.3982 19.3895C12.1673 19.7684 11.7527 20 11.305 20C10.5689 20 9.43107 20 8.69503 20C8.24729 20 7.83271 19.7684 7.60182 19.3895C7.28547 18.8708 6.83262 18.1294 6.51626 17.6095C6.28538 17.2319 5.8708 17.0003 5.42305 17.0003C4.64492 17.0003 3.41394 17.0003 2.62943 17.0003C2.17403 17.0003 1.75308 16.7598 1.52474 16.3708C1.1446 15.72 0.553987 14.7117 0.171299 14.0559C-0.0608646 13.6594 -0.0570377 13.1697 0.18278 12.7782C0.552712 12.1702 1.10761 11.2601 1.48009 10.6495C1.72374 10.2492 1.72374 9.75076 1.48009 9.35045C1.10761 8.73993 0.552712 7.82981 0.18278 7.2218C-0.0570377 6.83031 -0.0608646 6.34063 0.171299 5.94411C0.553987 5.28827 1.1446 4.27996 1.52474 3.62915C1.75308 3.24018 2.17403 2.99975 2.62943 2.99975C3.41394 2.99975 4.64492 2.99975 5.42305 2.99975C5.8708 2.99975 6.28538 2.76813 6.51626 2.39048C6.83262 1.87059 7.28547 1.12915 7.60182 0.610524C7.83271 0.231621 8.24729 0 8.69503 0C9.43107 0 10.5689 0 11.305 0C11.7527 0 12.1673 0.231621 12.3982 0.610524C12.7145 1.12915 13.1674 1.87059 13.4837 2.39048ZM9.99998 6.22347C12.1124 6.22347 13.8269 7.91531 13.8269 9.9999C13.8269 12.0845 12.1124 13.7763 9.99998 13.7763C7.88754 13.7763 6.1731 12.0845 6.1731 9.9999C6.1731 7.91531 7.88754 6.22347 9.99998 6.22347Z"
                fill="#85858A"
            />
        </svg>
    );
}
