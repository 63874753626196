import { formatUKDate } from 'src/helpers';

export type MappingType = { [key: number]: string };
export type DateFormatter = (date: Date) => string;

export const getValueFromMapping = (key: number | null | undefined, valueMapping: MappingType): string => {
    if (key === null || key === undefined) {
        return 'N/A';
    }
    return valueMapping.hasOwnProperty(key) ? valueMapping[key] : 'N/A';
};

export const getValueRenderDate = (date: Date | null, formatFunction: DateFormatter) => {
    return date ? formatFunction(date) : 'N/A';
};

export const getValueRenderBoolean = (value: boolean | null, valueTrue?: string, valueFalse?: string) =>
    value !== null ? (value ? valueTrue ?? 'True' : valueFalse ?? 'False') : 'N/A';
