import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function NextSlidesBottom(props: Props) {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8.91016 20.4201L15.4302 13.9001C16.2002 13.1301 16.2002 11.8701 15.4302 11.1001L8.91016 4.58008"
                stroke="#292D32"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
