import { CameraWithInfo } from '@EcamModel/model';
import { BaseTextField } from '@components/BaseTextField';
import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { validateTypingIPAddress, validateTypingInput } from '../utils';

type Props = Omit<IPopUp, 'onConfirm'> & {
    camera: CameraWithInfo;
    onConfirm?(camera: CameraWithInfo): void;
};

type FormValues = {
    ip: string;
    userName: string;
    passWord: string;
};

export default function PopUpEditMilesightAccount(props: Props) {
    const [showPassword, setShowPassword] = useState(false);

    const {
        handleSubmit,
        watch,
        reset,
        control,
        setValue,
        setError,
        formState: { isDirty, isValid, errors },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            ip: '',
            userName: '',
            passWord: '',
        },
    });
    const disabled = !(isValid && isDirty);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    useEffect(() => {
        if (!props.open) return;
        reset({
            ip: props.camera.IP,
            userName: props.camera.UserName,
            passWord: props.camera.PassWord,
        });
    }, [props.open, props.camera]);

    return (
        <PopUpBase
            open={props.open}
            dialogProps={{
                fullWidth: true,
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                    zIndex: (theme) => theme.zIndex.modal,
                },
            }}
            onClose={props.onClose}
            onConfirm={() => {}}
            title={
                <Stack>
                    <Typography variant="h4">Edit milesights</Typography>
                    <Typography>Are you sure you want to edit Milesights?</Typography>
                </Stack>
            }
            titleProps={{ '&.MuiDialogTitle-root': { paddingTop: '24px' } }}
            subTitleProps={{ sx: { color: 'gray' } }}
            minWidthButton={150}
            desc={
                <Stack width="100%" sx={{ background: '#FAFAFA', p: 2 }} spacing={2}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ mt: !!errors.ip ? -2.8 : 0, width: 100 }}>IP:</Typography>
                        <Controller
                            name="ip"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter an IP address.',
                                },
                                validate: validateTypingIPAddress,
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    placeholder={'IP address'}
                                    maxLength={30}
                                    error={!!error?.message}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ mt: !!errors.userName ? -2.8 : 0, width: 100 }}>Username:</Typography>
                        <Controller
                            name="userName"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter a username.',
                                },
                                validate: validateTypingInput,
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    placeholder={'Username'}
                                    error={!!error?.message}
                                    maxLength={30}
                                    helperText={error?.message}
                                />
                            )}
                        />
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography sx={{ mt: !!errors.passWord ? -2.8 : 0, width: 100 }}>Password:</Typography>
                        <Controller
                            name="passWord"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Please enter a password.',
                                },
                                validate: validateTypingInput,
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <BaseTextField
                                    {...field}
                                    placeholder={'Password'}
                                    error={!!error?.message}
                                    maxLength={30}
                                    helperText={error?.message}
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                size="small"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff sx={{ fontSize: 20 }} />
                                                ) : (
                                                    <Visibility sx={{ fontSize: 20 }} />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Stack>
            }
            customActions={
                <Stack mt={3} direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={disabled}
                        onClick={handleSubmit((data) => {
                            const _camera: CameraWithInfo = {
                                ...props.camera,
                                IP: data.ip,
                                UserName: data.userName,
                                PassWord: data.passWord,
                            };
                            props.onConfirm?.(_camera);
                        })}
                    >
                        Save
                    </Button>
                </Stack>
            }
        />
    );
}
