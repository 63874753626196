import { Paging } from '@Core';
import { IUpsertCameraProps } from '@EcamModel/controllers/ICameraHttpController';
import { IGetPhotoSnapShotByPTZ } from '@EcamModel/controllers/ICameraViewHttpController';
import { IGetGeoZoneProps } from '@EcamModel/controllers/IGeoZoneHttpController';
import { ISendActionGet } from '@EcamModel/controllers/IPTZCamConfigureRequestHttpController';
import {
    CameraWithInfo,
    ExemptionPeriods,
    GeoZoneCoordinates,
    GeoZones,
    Solar4gCamera,
    Solar4gCellularInfo,
    Solar4gCellularStatus,
    Solar4gTimeInfo,
    Status,
    TimeInfoDaylightSavingMode,
    TimeInfoSyncMode,
} from '@EcamModel/model';
import { CameraView, PhotoSnapShotByPTZ } from '@EcamModel/model/CameraView';
import { PTZSysInfo } from '@EcamModel/model/PTZSysInfo';
import EventLogIcon from '@assets/jsx-icon/EventLogIcon';
import ICNote from '@assets/jsx-icon/ICNote';
import InformationTabsIcon from '@assets/jsx-icon/InformationTabsIcon';
import { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import { DrawerContext, DrawerPage } from '@components/drawer/useDrawer';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import { TabConfig } from '@components/tabs/BaseEnhancedTabs';
import { pushError, pushSuccess, pushWarning } from '@components/toast';
import {
    cameraController,
    cameraPTZConfigureRequestHttpController,
    cameraViewController,
    exemptionController,
    geoZoneController,
    solar4gCameraHttpController,
} from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import useScrollToTop from '@hooks/useScrollToTop';
import { Filter, initPaging } from '@pages/cameras/list';
import { search } from '@pages/cameras/list/helpers';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import { a11yProps } from '@pages/overview/tabs/useTabsData';
import color from '@theme/Colors';
import { createContext, useContext, useEffect, useState } from 'react';
import { PiCubeFocusThin } from 'react-icons/pi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBackdrop } from 'src/providers/BackdropProvider';
import useMileSightSolar from './useMilesightSolar';
import useMileSightPTZ from './useMilesightPTZ';

export const getGeoZone = (_scale: number, prevGeoZonePoints: GeoZoneCoordinates[][]) => {
    return prevGeoZonePoints?.map((polygon) =>
        polygon?.map((point) => ({
            x: point?.XPoint * _scale,
            y: point?.YPoint * _scale,
            geoZoneId: point?.GeoZoneId,
        }))
    );
};

export default function useCameraDetailProvider() {
    const params = useParams<{ id: string }>();
    const { locationId } = useParams();
    const location = useLocation();
    const state = location?.state;

    const navigate = useNavigate();
    const { setActiveItem } = useContext(DrawerContext);

    useScrollToTop();
    useEffect(() => {
        setActiveItem(DrawerPage.Cameras);
    }, []);

    const backdrop = useBackdrop();

    const [camera, setCamera] = useState<CameraWithInfo>({} as CameraWithInfo);
    const [loading, setLoading] = useState<boolean>(true);

    const blobName = camera.CameraPhotos?.[0]?.BlobName;
    const notAvailable = camera.Status === Status.NotAvailable;
    const captureAt = camera.CameraPhotos?.[0]?.CaptureAt;
    const timeZone = camera.TimeZone;

    const [wakeUpLoading, setWakeUpLoading] = useState(false);
    const [value, setValue] = useState<number>(state?.tabValue ?? 0);
    const [openIMGPreview, setOpenIMGPreview] = useState<boolean>(false);
    const [photoSnapShotByPTZ, setPhotoSnapShotByPTZ] = useState<PhotoSnapShotByPTZ>({} as PhotoSnapShotByPTZ);

    const { image, loading: loadingImage, isError } = useImageDimensions(blobName);
    const widthImage = 1030;
    const scaleNotFullCameraSolar = widthImage / (image?.width ?? 1920);
    const scaleFullCameraSolar = 1280 / (image?.width ?? 1920);

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Cameras', href: `/locations/${camera.Zone?.LocationId}/cameras` },
        { title: `${camera.Name}`, onBack: false, isColorBlackText: true },
    ];

    const [cameraDetailsPTZ, setCameraDetailsPTZ] = useState<PTZSysInfo>({} as PTZSysInfo);
    const [isLoadingDetailsCameraPTZ, setIsLoadingDetailsCameraPTZ] = useState<boolean>(true);

    const [filteredCamera, setFilteredCamera] = useState<ISendActionGet>({} as ISendActionGet);
    const [filteredListGeoZones, setFilteredListGeoZones] = useState<IGetGeoZoneProps>({} as IGetGeoZoneProps);
    const [listGeoZonesByView, setListGeoZonesByView] = useState<Paging<GeoZones>>({ ...initPaging });

    const [hoveredFrame, setHoveredFrame] = useState<number | null>(null);

    const [anchorElMenuLists, setAnchorElMenuLists] = useState<null | { mouseX: number; mouseY: number }>(null);
    const openMenuLists = Boolean(anchorElMenuLists);

    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);

    const popUpDeleteGeoZonesOverview = usePopUp();
    const popUpConfigViewCameraPTZ = usePopUp();

    const scaleNotFullCameraPTZ = widthImage / (imageElement?.width ?? 1920);
    const scaleFullCameraPTZ = 1280 / (imageElement?.width ?? 1920);

    const getDetailsCamera = async () => {
        setLoading(true);
        await cameraController
            .get(params.id!)
            .then(async (res) => {
                setCamera(res);
                if (Number(locationId) !== res.Zone?.LocationId) {
                    pushError('Camera does not exist in this location');
                    navigate(`/locations/${locationId}/cameras`);
                }

                const filteredCamera: ISendActionGet = {
                    ip: res.IP,
                    authenticate: {
                        password: res.PassWord,
                        username: res.UserName,
                    },
                };

                if (res.CameraViews?.length && res.CameraViews?.length > 0) {
                    const filteredListGeoZones: IGetGeoZoneProps = {
                        pageSize: 10000,
                        filter: {
                            CameraId: res.Id!,
                            CameraViewId: res.CameraViews?.[0].Id,
                        },
                    };

                    setFilteredListGeoZones(filteredListGeoZones);
                }

                setFilteredCamera(filteredCamera);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
                popUpConfigViewCameraPTZ.onClose();
            });
    };

    useEffect(() => {
        getDetailsCamera();
    }, [params.id]);

    const {
        solar4gCamera,
        setSolar4gCamera,
        valueImageSettings,
        isLoading,
        setIsLoading,
        partsTimeTrigger,
        Solar4gCaptureInfo,
        Solar4gSchedule,
        Solar4gAlarmROI,
        status,
        settings,
        settingsExpected,
        systemTime,
        refreshStatus,
        checkCellularStatus,
        updateCellularInfo,
        checkTimeInfo,
        updateTimeInfo,
        cellularStatus,
        systemTimeMilesight,
        setStatus,
        setSystemTime,
        getSolar4gCameraInfo,
    } = useMileSightSolar();

    // MileSight Solar
    useEffect(() => {
        if (camera.VendorCameraId !== null) {
            getSolar4gCameraInfo(Number(params.id));
        }
    }, [params.id]);

    useEffect(() => {
        if (cellularStatus) {
            setStatus(cellularStatus);
        }
    }, [cellularStatus]);

    useEffect(() => {
        if (systemTimeMilesight) {
            setSystemTime(systemTimeMilesight);
        }
    }, [systemTimeMilesight]);

    const handleGetListGeoZoneByView = async (_filter: IGetGeoZoneProps) => {
        setIsLoading(true);
        await geoZoneController
            .listGeoZoneByCameraView(_filter)
            .then((res) => {
                setListGeoZonesByView(res);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const isLastCall = filteredListGeoZones && Object.keys(filteredListGeoZones).length > 0;

        if (isLastCall) {
            handleGetListGeoZoneByView(filteredListGeoZones);
        }
    }, [filteredListGeoZones]);

    const handleUpdateCamera = async (_camera: CameraWithInfo) => {
        backdrop.setTrue();
        await cameraController
            .upsertWithSolarCamera(_camera as IUpsertCameraProps)
            .then((res) => {
                setCamera(res);
                pushSuccess('Saved successfully');
            })
            .catch((err) => {})
            .finally(() => backdrop.setFalse());
    };

    const handleAddExemption = (exemption: ExemptionPeriods) => {
        exemptionController.upsert(exemption).then((res) => {
            exemptionController
                .list({
                    filter: {
                        CameraId: camera.Id,
                    },
                })
                .then((res) => {
                    setCamera((prev) => ({ ...prev, ExemptionPeriods: res.rows }));
                    pushSuccess('Added successfully');
                });
        });
    };

    const handleDeleteExemption = (exemptionId: number) => {
        exemptionController.delete(exemptionId.toString()).then((res) => {
            exemptionController
                .list({
                    filter: {
                        CameraId: camera.Id,
                    },
                })
                .then((res) => {
                    setCamera((prev) => ({ ...prev, ExemptionPeriods: res.rows }));
                    pushSuccess('Deleted successfully');
                });
        });
    };

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabs: TabConfig[] = [
        {
            label: 'Base information',
            icon: <InformationTabsIcon color={value === 0 ? color.success : '#85858A'} />,
            ...a11yProps(0),
            index: 1,
        },
        {
            label: `Geo-zones ${camera.VendorCameraId !== null ? `(${camera.GeoZones?.length ?? 0})` : ''}`,
            icon: <PiCubeFocusThin color={value === 1 ? color.success : '#85858A'} size={22} />,
            sx: {
                '.MuiTab-iconWrapper': {
                    mb: '2px',
                },
            },
            ...a11yProps(1),
            index: 2,
        },
        {
            label: 'More information',
            icon: <ICNote color={value === 2 ? color.success : '#85858A'} width={17} height={17} />,
            sx: {
                '.MuiTab-iconWrapper': {
                    mb: '2px',
                },
            },
            ...a11yProps(2),
            index: 3,
        },
        {
            label: 'Event log',
            icon: <EventLogIcon color={value === 3 ? color.success : '#85858A'} width={18} height={17} />,
            ...a11yProps(3),
            index: 4,
        },
    ];

    const prevGeoZonePointsCameraSolar: GeoZoneCoordinates[][] =
        (camera.GeoZones?.map((gz) => {
            return gz.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(gz.GeoZoneCoordinates[0]);
        }) as GeoZoneCoordinates[][]) ?? [];

    const prevGeoZonePointsCameraPTZ: GeoZoneCoordinates[][] =
        (listGeoZonesByView.rows?.map((gz) => {
            return gz.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(gz.GeoZoneCoordinates[0]);
        }) as GeoZoneCoordinates[][]) ?? [];

    const prevGeoZonesCameraSolar = getGeoZone(scaleFullCameraSolar, prevGeoZonePointsCameraSolar);
    const scaledPrevGeoZoneCameraSolar = getGeoZone(scaleNotFullCameraSolar, prevGeoZonePointsCameraSolar);

    const prevGeoZonesCameraPTZ = getGeoZone(scaleFullCameraPTZ, prevGeoZonePointsCameraPTZ);
    const scaledPrevGeoZoneCameraPTZ = getGeoZone(scaleNotFullCameraPTZ, prevGeoZonePointsCameraPTZ);

    const handleClickImagePreview = () => {
        setOpenIMGPreview(true);
    };

    const handleRefreshImage = () => {
        return cameraController
            .getLatestCameraPhoto(camera.Id!)
            .then((res) => {
                const currentPhotoId = camera.CameraPhotos?.[0]?.Id;
                if (!res) {
                    pushWarning("Didn't receive new photos from camera. Please try again");
                } else if (res.Id === currentPhotoId) {
                    pushWarning("Didn't receive new photos from camera. Please try again");
                } else {
                    setCamera((prev) => ({ ...prev, CameraPhotos: [res] }));
                    pushSuccess('Latest photo has been updated');
                }
            })
            .catch((err) => {
                console.log({ err });
                pushError(err?.response?.data?.message || 'Have an error');
            });
    };

    const handleDeleteGeoZoneSuccess = (id: string) => {
        popUpDeleteGeoZonesOverview.onClose();
        pushSuccess('Delete geo-zone successfully');
        setListGeoZonesByView((prevList) => {
            const updatedRows = prevList.rows.filter((geoZone) => geoZone.Id !== Number(id));
            return {
                ...prevList,
                rows: updatedRows,
            };
        });
    };

    const handleDeleteGeoZonesOverView = async (id: string) => {
        setIsLoadingSave(true);
        await geoZoneController
            .deleteGeoZoneOverview(Number(id))
            .then(async (_) => {
                handleDeleteGeoZoneSuccess(id);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
                setAnchorElMenuLists(null);
            });
    };

    const handleDeleteGeoZonesDetail = async (id: string) => {
        setIsLoadingSave(true);
        await geoZoneController
            .delete(id)
            .then((_) => {
                handleDeleteGeoZoneSuccess(id);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
                setAnchorElMenuLists(null);
            });
    };

    const handleCloseMenuLists = () => {
        setAnchorElMenuLists(null);
    };

    const isPointInPolygon = (point: { x: number; y: number }, polygon: { x: number; y: number }[]) => {
        let isInside = false;
        const { x, y } = point;

        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].x,
                yi = polygon[i].y;
            const xj = polygon[j].x,
                yj = polygon[j].y;

            const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) isInside = !isInside;
        }

        return isInside;
    };

    const handleMouseMove = (event: any) => {
        const mousePos = {
            x: event.evt.layerX,
            y: event.evt.layerY,
        };

        const hovered = scaledPrevGeoZoneCameraPTZ.findIndex((frame) => isPointInPolygon(mousePos, frame));

        setHoveredFrame(hovered !== -1 ? hovered : null);

        if (hovered !== -1) {
            setAnchorElMenuLists({ mouseX: event.evt.clientX, mouseY: event.evt.clientY });
        } else {
            setAnchorElMenuLists(null);
        }
    };

    const handleResetStyledMenu = () => {
        handleCloseMenuLists();
        setHoveredFrame(null);
    };

    const handleUpsertView = (presetName: string) => {
        setIsLoadingSave(true);
        const _filter: CameraView = {
            Id: camera.CameraViews?.length && camera.CameraViews?.length > 0 ? camera.CameraViews[0].Id : undefined,
            CameraId: camera.Id!,
            CameraViewName: presetName,
            PtzPanPos: photoSnapShotByPTZ.PTZ?.PtzPanPos!,
            PtzZoomPos: photoSnapShotByPTZ.PTZ?.PtzZoomPos!,
            PtzTiltPos: photoSnapShotByPTZ.PTZ?.PtzTiltPos!,
        };
        cameraViewController
            .upsert(_filter)
            .then((res) => {
                pushSuccess('Config camera view successfully');
                setCamera((prevCamera) => {
                    const updatedCameraViews =
                        prevCamera.CameraViews?.map((view) => (view.Id === res.Id ? res : view)) ?? [];

                    if (!updatedCameraViews.some((view) => view.Id === res.Id)) {
                        updatedCameraViews.push(res);
                    }

                    return {
                        ...prevCamera,
                        CameraViews: updatedCameraViews,
                    };
                });

                popUpConfigViewCameraPTZ.onClose();
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
            });
    };

    useEffect(() => {
        if (filteredCamera && Object.keys(filteredCamera).length > 0) {
            if (camera.PTZCameraId !== null) {
                handleGetDetailsCameraPTZ();
            }
        }
    }, [filteredCamera]);

    const handleGetDetailsCameraPTZ = async () => {
        setIsLoadingDetailsCameraPTZ(true);
        await cameraPTZConfigureRequestHttpController
            .getPTZSystemInfo(filteredCamera)
            .then((res) => {
                setCameraDetailsPTZ(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoadingDetailsCameraPTZ(false);
            });
    };

    return {
        breadcrumbs,
        navigate,
        camera,
        setCamera,
        value,
        handleChangeTabs,
        loading,
        setLoading,
        isLoadingSave,
        setIsLoadingSave,
        wakeUpLoading,
        setWakeUpLoading,
        openIMGPreview,
        setOpenIMGPreview,
        solar4gCamera,
        setSolar4gCamera,
        hoveredFrame,
        setHoveredFrame,
        anchorElMenuLists,
        imageElement,
        setImageElement,
        filteredCamera,
        photoSnapShotByPTZ,
        setPhotoSnapShotByPTZ,
        listGeoZonesByView,
        filteredListGeoZones,
        setFilteredListGeoZones,
        isLoadingDetailsCameraPTZ,
        cameraDetailsPTZ,

        //Milesight Solar
        valueImageSettings,
        isLoading,
        setIsLoading,
        partsTimeTrigger,
        Solar4gCaptureInfo,
        Solar4gSchedule,
        Solar4gAlarmROI,
        // Cellular status
        status,
        settings,
        settingsExpected,
        //Set the system time
        systemTime,

        image,
        loadingImage,
        isError,
        blobName,
        notAvailable,
        tabs,
        scaleFullCameraSolar,
        prevGeoZonesCameraSolar,
        scaleNotFullCameraSolar,
        scaledPrevGeoZoneCameraSolar,
        scaleFullCameraPTZ,
        prevGeoZonesCameraPTZ,
        scaledPrevGeoZoneCameraPTZ,
        widthImage,
        timeZone,
        captureAt,
        openMenuLists,

        // Cellular status
        refreshStatus,
        checkCellularStatus,
        updateCellularInfo,
        //Set the system time
        checkTimeInfo,
        updateTimeInfo,

        handleAddExemption,
        handleDeleteExemption,
        handleUpdateCamera,
        handleClickImagePreview,
        handleRefreshImage,
        handleDeleteGeoZonesOverView,
        handleDeleteGeoZonesDetail,
        handleMouseMove,
        handleResetStyledMenu,
        handleUpsertView,
        setAnchorElMenuLists,

        popUpDeleteGeoZonesOverview,
        popUpConfigViewCameraPTZ,
    };
}

type CameraDetailProviderContextType = ReturnType<typeof useCameraDetailProvider>;

export const cameraDetailContext = createContext<CameraDetailProviderContextType>(
    {} as CameraDetailProviderContextType
);

export const useCameraDetailContext = () => useContext(cameraDetailContext);
