import PopUpBase from '@components/PopUpBase';
import { BaseHead, CellOfRow } from '@components/tables';
import { pushError } from '@components/toast';
import { cameraExemptionPeriodAuditEventHttpController } from '@controllers/index';
import { Paging } from '@Core';
import { AuditEventType, CameraExemptionPeriodAuditEvent, ExemptionPeriods } from '@EcamModel/model';
import { IPopUp } from '@hooks/usePopUp';
import {
    Box,
    Button,
    CircularProgress,
    Fade,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = Omit<IPopUp, 'onConfirm'>;

const formatDate = (date?: Date) => {
    if (!date) {
        return;
    }
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
};

export default function PopUpHistoryExemption(props: Props) {
    const { open, onClose } = props;
    const { id } = useParams();

    const [historyExemption, setHistoryExemption] = useState<Paging<CameraExemptionPeriodAuditEvent>>(
        {} as Paging<CameraExemptionPeriodAuditEvent>
    );
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const EnumTypeExemption = (typeExemption: number) => {
        const type = {
            [AuditEventType.CREATE]: 'Created',
            [AuditEventType.UPDATE]: 'Updated',
            [AuditEventType.DELETE]: 'Deleted',
        };
        return type[typeExemption];
    };

    const getHistoryExemption = async () => {
        setIsLoading(true);
        await cameraExemptionPeriodAuditEventHttpController
            .list({
                page: page,
                pageSize: 5,
                filter: {
                    CameraId: Number(id),
                },
                sorts: ['-Created'],
            })
            .then((res) => {
                setHistoryExemption(res);
            })
            .catch((error) => {
                pushError(error?.response?.data?.message?.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        if (!open) return;
        getHistoryExemption();
    }, [open, page]);

    return (
        <PopUpBase
            dialogProps={{
                maxWidth: 'lg',
                PaperProps: {
                    style: { minWidth: 700 },
                },
            }}
            open={open}
            title={<Typography variant="h4">History exemption</Typography>}
            desc={
                isLoading ? (
                    <Stack direction={'row'} justifyContent={'center'} height={100} alignItems={'center'}>
                        <CircularProgress />
                    </Stack>
                ) : (
                    <>
                        {historyExemption.total ? (
                            <TableContainer component={Box}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <BaseHead align="left" title="Date/time" />
                                            <BaseHead align="left" title="User" />
                                            <BaseHead align="left" title="Type" />
                                            <BaseHead align="left" title="Details" />
                                        </TableRow>
                                    </TableHead>
                                    <Fade in={open}>
                                        <TableBody>
                                            {historyExemption?.rows?.map((item) => {
                                                const auditEventCommon = (typeEvent: AuditEventType) => {
                                                    switch (typeEvent) {
                                                        case AuditEventType.CREATE:
                                                            return AuditEnventCreateOrDetete(item.NewValues, {
                                                                updatedBy: item?.UpdatedBy ?? '',
                                                                eventDesc: 'created',
                                                            });
                                                        case AuditEventType.DELETE:
                                                            return AuditEnventCreateOrDetete(item.OldValues, {
                                                                updatedBy: item?.UpdatedBy ?? '',
                                                                eventDesc: 'deleted',
                                                            });
                                                        case AuditEventType.UPDATE:
                                                            return AuditEnventUpdate(item);
                                                        default:
                                                            return <></>;
                                                    }
                                                };
                                                return (
                                                    <TableRow>
                                                        <CellOfRow
                                                            value={formatDate(item?.Created) || ''}
                                                            align="left"
                                                        />
                                                        <CellOfRow value={item?.UpdatedBy || ''} align="left" />
                                                        <CellOfRow
                                                            value={EnumTypeExemption(item.AuditEventType)}
                                                            align="left"
                                                        />

                                                        <CellOfRow
                                                            value={<>{auditEventCommon(item.AuditEventType)}</>}
                                                            align="left"
                                                        />
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Fade>
                                </Table>
                            </TableContainer>
                        ) : (
                            <NoDataCameras />
                        )}
                        {historyExemption.total > historyExemption.pageSize && !isLoading && (
                            <Stack mt={3} direction={'row'} justifyContent="center">
                                <Pagination
                                    count={historyExemption.totalPages || 1}
                                    page={historyExemption.page}
                                    onChange={(_, page) => setPage(page)}
                                    variant="outlined"
                                    shape="rounded"
                                />
                            </Stack>
                        )}
                    </>
                )
            }
            customActions={
                <Button
                    sx={{ minWidth: 130 }}
                    variant="cancel"
                    onClick={() => {
                        setPage(1);
                        onClose?.();
                    }}
                >
                    Cancel
                </Button>
            }
            minWidthButton={150}
        />
    );
}

function AuditEnventCreateOrDetete(
    item: Partial<ExemptionPeriods>,
    title: {
        updatedBy: string;
        eventDesc: string;
    }
) {
    return (
        <>
            <Typography>{`${title.updatedBy?.replace('@ukparkingcontrol.com', '')} ${
                title.eventDesc
            } exemption:`}</Typography>
            <ul
                style={{
                    margin: 0,
                }}
            >
                <li>
                    <Typography>Effective from: {formatDate(item?.EffectiveFrom)}</Typography>
                </li>
                <li>
                    <Typography>Effective to: {formatDate(item?.EffectiveTo)}</Typography>
                </li>
            </ul>
            <Typography>Description: {item?.Notes}</Typography>
        </>
    );
}

function AuditEnventUpdate(auditEvent: CameraExemptionPeriodAuditEvent) {
    const isDiffEffectiveFrom =
        new Date(auditEvent.OldValues.EffectiveFrom as Date).getTime() !==
        new Date(auditEvent.NewValues.EffectiveFrom as Date).getTime();

    const isDiffEffectiveTo =
        new Date(auditEvent.OldValues.EffectiveTo as Date).getTime() !==
        new Date(auditEvent.NewValues.EffectiveTo as Date).getTime();

    const isDiffSameDesc = auditEvent.OldValues.Notes !== auditEvent.NewValues.Notes;

    return (
        <>
            <Typography>{auditEvent.UpdatedBy?.replace('@ukparkingcontrol.com', '')} updated exemption:</Typography>
            <ul
                style={{
                    margin: 0,
                }}
            >
                <li>
                    <Typography>
                        Effective from:{' '}
                        {isDiffEffectiveFrom
                            ? `From "${formatDate(auditEvent?.OldValues?.EffectiveFrom)}" to "${formatDate(
                                  auditEvent?.NewValues?.EffectiveFrom
                              )}"`
                            : formatDate(auditEvent?.OldValues?.EffectiveFrom)}
                    </Typography>
                </li>
                <li>
                    <Typography>
                        Effective to:{' '}
                        {isDiffEffectiveTo
                            ? `From "${formatDate(auditEvent?.OldValues?.EffectiveTo)}" to "${formatDate(
                                  auditEvent?.NewValues?.EffectiveTo
                              )}"`
                            : formatDate(auditEvent?.OldValues?.EffectiveTo)}
                    </Typography>
                </li>
            </ul>
            <Typography>
                Description:{' '}
                {isDiffSameDesc
                    ? `From "${auditEvent?.OldValues?.Notes}" to "${auditEvent?.NewValues?.Notes}"`
                    : `${auditEvent?.OldValues?.Notes}`}
            </Typography>
        </>
    );
}
