import color from '@theme/Colors';
import React, { SVGProps } from 'react';

type Props = {
    isDisable?: boolean;
} & SVGProps<SVGSVGElement>;

export default function CheckedConfigCam(props: Props) {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.0199 5.06001C14.4214 4.68079 14.4395 4.04788 14.0603 3.64637C13.6811 3.24486 13.0482 3.22679 12.6467 3.60601L5.56889 10.2908L3.35327 8.19821C2.95176 7.81899 2.31885 7.83706 1.93963 8.23857C1.56041 8.64008 1.57848 9.27298 1.97999 9.6522L4.88225 12.3933C5.26765 12.7573 5.87013 12.7573 6.25552 12.3933L14.0199 5.06001Z"
                fill="#009D4F"
            />
        </svg>
    );
}
