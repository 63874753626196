import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import {
    GroupPotentialContraventions,
    PCNDuplicate,
    PcnDuplicateWithChangePlate,
    PotentialContraventions,
} from '@EcamModel/model';
import { AxiosInstance } from 'axios';
import {
    IApproveGroupPotentialContravention,
    ICancelGroupPcnForChangePlate,
    ICancelGroupPotentialContraventionDetail,
    IChangePlateGroupPotentialContravention,
    IGetGroupPotentialContraventionIds,
    IGetGroupPotentialContraventionsCancelled,
    IGroupPotentialContraventionHttpController,
    IReOpenGroupPotentialContraventionDetail,
    ISplitPCintoMultiplePCs,
    IUpdateEvidencePhoto,
} from '../IGroupPotentialContraventionHttpController';

export type CancelPCGroupProps = {
    id: number;
    cancellationReasonId: number;
    cancellationNotes: string;
};
export class GroupPotentialContraventionController
    extends BaseHttpController<PotentialContraventions>
    implements IGroupPotentialContraventionHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'group-potential-contravention', client);
    }

    async getListCancelled(
        props: IGetGroupPotentialContraventionsCancelled
    ): Promise<Paging<GroupPotentialContraventions>> {
        return await this.doPost({ path: `list-cancelled`, body: props }).then((res) => res.data);
    }

    async getListCancelledNotAuditIds(props: IGetGroupPotentialContraventionsCancelled): Promise<number[]> {
        return await this.doPost({ path: `list-cancelled-not-audit-ids`, body: props }).then((res) => res.data);
    }

    async getGroupPotentialContravention(id: number): Promise<GroupPotentialContraventions> {
        return await this.doGet({ path: `${id}` }).then((res) => res.data);
    }

    async getGroupPotentialContraventionIds(props: IGetGroupPotentialContraventionIds): Promise<{ Ids: number[] }> {
        return await this.doPost({ path: `next`, body: props }).then((res) => res.data);
    }

    async doApproveGroupPotentialContravention(props: IApproveGroupPotentialContravention): Promise<boolean> {
        return await this.doPost({ path: `approve`, body: props }).then((res) => res.data);
    }

    async doCancelGroupPotentialContravention(props: ICancelGroupPotentialContraventionDetail): Promise<boolean> {
        return await this.doPost({ path: `cancel`, body: props }).then((res) => res.data);
    }

    async doReOpenGroupPotentialContravention(props: IReOpenGroupPotentialContraventionDetail): Promise<boolean> {
        return await this.doPost({ path: `reopen`, body: props }).then((res) => res.data);
    }

    async doChangePlateGroupPotentialContravention(
        props: IChangePlateGroupPotentialContravention
    ): Promise<PcnDuplicateWithChangePlate> {
        return await this.doPost({ path: `change-plate`, body: props }).then((res) => res.data);
    }

    async doCancelGroupPcnForChangePlate(props: ICancelGroupPcnForChangePlate): Promise<boolean> {
        return await this.doPost({ path: `cancel-not-reason`, body: props }).then((res) => res.data);
    }

    async getGroupPotentialContraventionDuplications(props: {
        GroupPotentialContraventionId: number;
        Plate?: string;
    }): Promise<PCNDuplicate[]> {
        return await this.doPost({ path: 'get-group-potential-contravention-duplications', body: props }).then(
            (res) => res.data
        );
    }

    async doSplitPCintoMultiplePCs(props: ISplitPCintoMultiplePCs): Promise<boolean> {
        return await this.doPost({ path: 'split-pc-into-multiple-pcs', body: props }).then((res) => res.data);
    }

    async doUpdateEvidencePhoto(props: IUpdateEvidencePhoto): Promise<boolean> {
        return await this.doPost({ path: `update-evidence-photo`, body: props }).then((res) => res.data);
    }
}
