export default function WifiCritical(props: { status: number }) {
    const { status } = props;
    return (
        <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16Z"
                fill={status < 1 ? '#dddddd' : '#009D4F'}
            />
            <path
                d="M9.99998 10.0001C8.70571 10 7.46185 10.5019 6.52998 11.4001C6.33902 11.5844 6.22911 11.837 6.22442 12.1024C6.21973 12.3678 6.32065 12.6241 6.50498 12.8151C6.6893 13.006 6.94193 13.1159 7.2073 13.1206C7.47266 13.1253 7.72902 13.0244 7.91998 12.8401C8.48788 12.3201 9.22996 12.0316 9.99998 12.0316C10.77 12.0316 11.5121 12.3201 12.08 12.8401C12.2709 13.0244 12.5273 13.1253 12.7927 13.1206C13.058 13.1159 13.3107 13.006 13.495 12.8151C13.6793 12.6241 13.7802 12.3678 13.7755 12.1024C13.7708 11.837 13.6609 11.5844 13.47 11.4001C12.5381 10.5019 11.2942 10 9.99998 10.0001ZM9.99998 5.00006C8.79219 5.00086 7.59691 5.24475 6.48536 5.7172C5.37381 6.18965 4.36872 6.881 3.52998 7.75006C3.43871 7.84659 3.36734 7.96014 3.31996 8.08424C3.27258 8.20834 3.2501 8.34056 3.25381 8.47335C3.25753 8.60614 3.28736 8.73689 3.34161 8.85815C3.39586 8.97941 3.47345 9.0888 3.56998 9.18006C3.76491 9.36439 4.02508 9.46373 4.29326 9.45623C4.42605 9.45251 4.5568 9.42268 4.67806 9.36843C4.79932 9.31419 4.90871 9.23659 4.99998 9.14006C5.65296 8.46259 6.43583 7.92372 7.3018 7.55565C8.16776 7.18758 9.09904 6.99788 10.04 6.99788C10.9809 6.99788 11.9122 7.18758 12.7782 7.55565C13.6441 7.92372 14.427 8.46259 15.08 9.14006C15.1726 9.23455 15.283 9.30972 15.4049 9.36122C15.5268 9.41272 15.6577 9.43951 15.79 9.44006C15.9854 9.43932 16.1763 9.38134 16.3391 9.27327C16.502 9.16521 16.6296 9.01181 16.7062 8.83202C16.7828 8.65223 16.805 8.45394 16.7701 8.26166C16.7353 8.06937 16.6448 7.89151 16.51 7.75006C15.6666 6.87615 14.655 6.18198 13.5362 5.70936C12.4174 5.23675 11.2145 4.99547 9.99998 5.00006Z"
                fill={status < 3 ? '#dddddd' : '#009D4F'}
            />
            <path
                d="M19.7199 3.93007C17.1103 1.41264 13.6258 0.00585938 9.99989 0.00585938C6.37397 0.00585938 2.88947 1.41264 0.279894 3.93007C0.111131 4.11772 0.0195652 4.36225 0.0235631 4.61459C0.027561 4.86694 0.126827 5.10844 0.30145 5.29066C0.476072 5.47287 0.713134 5.58232 0.965082 5.59705C1.21703 5.61178 1.46523 5.53069 1.65989 5.37007C3.89749 3.20719 6.88784 1.99818 9.99989 1.99818C13.112 1.99818 16.1023 3.20719 18.3399 5.37007C18.5253 5.54875 18.7724 5.64904 19.0299 5.65007C19.1646 5.64955 19.2978 5.62183 19.4215 5.56856C19.5453 5.51529 19.6569 5.43757 19.7499 5.34007C19.9322 5.14877 20.0314 4.89308 20.0258 4.62888C20.0202 4.36467 19.9102 4.11344 19.7199 3.93007Z"
                fill={status < 4 ? '#dddddd' : '#009D4F'}
            />
        </svg>
    );
}
