import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Backdrop, CircularProgress, Stack, Typography } from '@mui/material';
import color from '@theme/Colors';
import { useCameraConfig } from '../hook/CameraConfigProvider';
import { ChartItem } from './PopUpAvgWorkedHours';

type Props = IPopUp & {};

export default function PopupDailyAverageWorkedHours(props: Props) {
    const cameraConfigContext = useCameraConfig();
    const {
        loadingDailyWorkedHours,
        cameraAllDailyAverageWorkedHour,
        averageHoursInAllCamera,
        cameraV1DailyAverageWorkedHour,
        averageHoursInCameraV1,
        cameraV2DailyAverageWorkedHour,
        averageHoursInCameraV2,
    } = cameraConfigContext;

    return (
        <PopUpBase
            title={<Typography variant="h4">Daily average uptime for all cameras</Typography>}
            dialogProps={{
                fullWidth: true,
                maxWidth: 'lg',
                sx: {
                    '& .MuiDialog-paper': {
                        borderRadius: '10px',
                    },
                },
            }}
            open={props.open}
            onClose={props.onClose}
            hideConfirm
            desc={
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loadingDailyWorkedHours}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Stack gap={2}>
                        <ChartItem
                            dataAxisY={true}
                            title={<Typography variant="h5">Daily average uptime for V1 and V2</Typography>}
                            chartColor={color.secondary}
                            data={cameraAllDailyAverageWorkedHour}
                            label="Daily average uptime for V1 and V2"
                            avgUptime={
                                <Typography variant="h5">
                                    Avg. Uptime for time period = {Number(averageHoursInAllCamera.toFixed(1)) || 0}{' '}
                                    {Number.isNaN(averageHoursInAllCamera) || averageHoursInAllCamera < 1.05
                                        ? 'hour'
                                        : 'hours'}
                                </Typography>
                            }
                        />
                        <ChartItem
                            dataAxisY={true}
                            title={<Typography variant="h5">Daily average uptime for camera V1</Typography>}
                            chartColor={color.primary}
                            data={cameraV1DailyAverageWorkedHour}
                            label="Number of daily average uptime for camera V1"
                            avgUptime={
                                <Typography variant="h5">
                                    Avg. Uptime for time period = {Number(averageHoursInCameraV1.toFixed(1)) || 0}{' '}
                                    {Number.isNaN(averageHoursInCameraV1) || averageHoursInCameraV1 < 1.05
                                        ? 'hour'
                                        : 'hours'}
                                </Typography>
                            }
                        />
                        <ChartItem
                            dataAxisY={true}
                            title={<Typography variant="h5">Daily average uptime for camera V2</Typography>}
                            chartColor={color.priText}
                            data={cameraV2DailyAverageWorkedHour}
                            label="Number of daily average uptime for camera V2"
                            avgUptime={
                                <Typography variant="h5">
                                    Avg. Uptime for time period = {Number(averageHoursInCameraV2.toFixed(1)) || 0}{' '}
                                    {Number.isNaN(averageHoursInCameraV2) || averageHoursInCameraV2 < 1.05
                                        ? 'hour'
                                        : 'hours'}
                                </Typography>
                            }
                        />
                    </Stack>
                </>
            }
        />
    );
}
