import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { PTZCamera } from '@EcamModel/model/PTZCamera';
import { PTZSysInfo } from '@EcamModel/model/PTZSysInfo';
import { AxiosInstance } from 'axios';
import {
    IPTZCamConfigureRequestHttpController,
    ISendActionGet,
    ISendActionSet,
} from '../IPTZCamConfigureRequestHttpController';
import { PTZAudio } from '@EcamModel/model/PTZAudio';
import { PTZEventSnap } from '@EcamModel/model/PTZEventSnap';
import { PTZSnapShotSetting } from '@EcamModel/model/PTZSnapshotSetting';
import { PTZStorageSetting } from '@EcamModel/model/PTZStorageSetting';
import { PTZVideoGeneral } from '@EcamModel/model/PTZVideoGeneral';
import { PTZCameraDayNight } from '@EcamModel/model/PTZCameraDayNight';
import { PTZEventMotionDetection } from '@EcamModel/model/PTZEventMotionDetection';
import { PTZScheduledTask } from '@EcamModel/model/PTZScheduledTask';
import { PTZAutoHome } from '@EcamModel/model/PTZAutoHome';
import { PTZAutoTracking } from '@EcamModel/model/PTZAutoTracking';
import { PTZRecordSetting } from '@EcamModel/model/PTZRecordSetting';
import { PTZLimit } from '@EcamModel/model/PTZLimit';
import { PTZBasic } from '@EcamModel/model/PTZBasic';
import { PTZSysDateTime } from '@EcamModel/model/PTZSysDateTime';
import { PTZCameraIrValue } from '@EcamModel/model/PTZCameraIrValue';
import { PTZCameraSetting } from '@EcamModel/model/PTZCameraSetting';
import { PTZCameraWhiteLed } from '@EcamModel/model/PTZCameraWhiteLed';

export class PTZCamConfigureRequestHttpController
    extends BaseHttpController<PTZCamera>
    implements IPTZCamConfigureRequestHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'ptz-cam-configure-request', client);
    }

    async getPhotoSnapShot(props: ISendActionGet): Promise<string> {
        return await this.doPost({ path: 'get-photo-snapshot', body: props }).then((res) => res.data);
    }

    async getPTZSystemInfo(props: ISendActionGet): Promise<PTZSysInfo> {
        return await this.doPost({ path: 'get-ptz-sys-info', body: props }).then((res) => res.data);
    }

    async getPTZSysDateAndTime(props: ISendActionGet): Promise<PTZSysDateTime> {
        return await this.doPost({ path: 'get-ptz-sys-date-and-time', body: props }).then((res) => res.data);
    }

    async getPTZBasic(props: ISendActionGet): Promise<PTZBasic> {
        return await this.doPost({ path: 'get-ptz-basic', body: props }).then((res) => res.data);
    }

    async getPTZLimit(props: ISendActionGet): Promise<PTZLimit> {
        return await this.doPost({ path: 'get-ptz-limit', body: props }).then((res) => res.data);
    }

    async getPTZRecordSetting(props: ISendActionGet): Promise<PTZRecordSetting> {
        return await this.doPost({ path: 'get-ptz-record-setting', body: props }).then((res) => res.data);
    }

    async getPTZAutoTracking(props: ISendActionGet): Promise<PTZAutoTracking> {
        return await this.doPost({ path: 'get-ptz-auto-tracking', body: props }).then((res) => res.data);
    }

    async getPTZAutoHome(props: ISendActionGet): Promise<PTZAutoHome> {
        return await this.doPost({ path: 'get-ptz-auto-home', body: props }).then((res) => res.data);
    }

    async getPTZScheduledTask(props: ISendActionGet): Promise<PTZScheduledTask> {
        return await this.doPost({ path: 'get-ptz-scheduled-task', body: props }).then((res) => res.data);
    }

    async getPTZCameraDayNight(props: ISendActionGet): Promise<PTZCameraDayNight> {
        return await this.doPost({ path: 'get-ptz-camera-day-night', body: props }).then((res) => res.data);
    }

    async getPTZVideoGeneral(props: ISendActionGet): Promise<PTZVideoGeneral> {
        return await this.doPost({ path: 'get-ptz-video-general', body: props }).then((res) => res.data);
    }

    async getPTZStorageSetting(props: ISendActionGet): Promise<PTZStorageSetting> {
        return await this.doPost({ path: 'get-ptz-storage-setting', body: props }).then((res) => res.data);
    }

    async getPTZSnapShotSetting(props: ISendActionGet): Promise<PTZSnapShotSetting> {
        return await this.doPost({ path: 'get-ptz-snapshot-setting', body: props }).then((res) => res.data);
    }

    async getPTZEventSnap(props: ISendActionGet): Promise<PTZEventSnap> {
        return await this.doPost({ path: 'get-ptz-event-snap', body: props }).then((res) => res.data);
    }

    async getPTZAudio(props: ISendActionGet): Promise<PTZAudio> {
        return await this.doPost({ path: 'get-ptz-audio', body: props }).then((res) => res.data);
    }

    async getPTZCameraIrValue(props: ISendActionGet): Promise<PTZCameraIrValue> {
        return await this.doPost({ path: 'get-ptz-camera-ir-value', body: props }).then((res) => res.data);
    }

    async getPTZEventMotionDetection(props: ISendActionGet): Promise<PTZEventMotionDetection> {
        return await this.doPost({ path: 'get-ptz-event-motion-detection', body: props }).then((res) => res.data);
    }

    async getPTZCameraSetting(props: ISendActionGet): Promise<PTZCameraSetting> {
        return await this.doPost({ path: 'get-ptz-camera-setting', body: props }).then((res) => res.data);
    }

    async getPTZCameraWhiteLed(props: ISendActionGet): Promise<PTZCameraWhiteLed> {
        return await this.doPost({ path: 'get-ptz-camera-white-led', body: props }).then((res) => res.data);
    }

    async getPTZSysDateTime(props: ISendActionGet): Promise<PTZSysDateTime> {
        return await this.doPost({ path: 'get-ptz-sys-date-time', body: props }).then((res) => res.data);
    }

    async setDateAndTime(props: ISendActionSet<PTZSysDateTime>): Promise<PTZSysDateTime> {
        return await this.doPost({ path: 'set-date-and-time', body: props }).then((res) => res.data);
    }

    async setPTZBasic(props: ISendActionSet<PTZBasic>): Promise<PTZBasic> {
        return await this.doPost({ path: 'set-ptz-basic', body: props }).then((res) => res.data);
    }

    async setPTZEventMotionDetection(props: ISendActionSet<PTZEventMotionDetection>): Promise<PTZEventMotionDetection> {
        return await this.doPost({ path: 'set-ptz-event-motion-detection', body: props }).then((res) => res.data);
    }

    async setPTZLimit(props: ISendActionSet<PTZLimit>): Promise<PTZLimit> {
        return await this.doPost({ path: 'set-ptz-limit', body: props }).then((res) => res.data);
    }
}
