import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function ArrowForwardIosIcon(props: Props) {
    return (
        <svg width="18" height="29" viewBox="0 0 18 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.25 -0.000957489L0 14.1192L17.25 28.2394V22.591L6.90039 14.1192L17.25 5.64744V-0.000957489Z"
                fill="white"
            />
        </svg>
    );
}
