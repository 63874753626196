import { SvgIconProps } from '@mui/material';
import React from 'react';

export default function IcEdit(props: SvgIconProps) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M7.53404 1.52661H4.95824C2.83986 1.52661 1.51166 3.02635 1.51166 5.14955V10.8771C1.51166 13.0003 2.83366 14.5 4.95824 14.5H11.0371C13.1624 14.5 14.4844 13.0003 14.4844 10.8771V8.10218"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.6985 7.12868L10.8467 1.98051C11.488 1.33983 12.5276 1.33983 13.169 1.98051L14.0073 2.8189C14.6487 3.46027 14.6487 4.50051 14.0073 5.14119L8.83438 10.3142C8.554 10.5945 8.17372 10.7523 7.77692 10.7523H5.19629L5.26105 8.14825C5.27069 7.76522 5.42707 7.4001 5.6985 7.12868Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0643 2.77594L13.2099 5.92147"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
