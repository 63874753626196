import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import { AxiosInstance } from 'axios';
import { ICameraNoteController, IGetCameraNoteProps } from '../ICameraNoteHttpController';

export class CameraNoteHttpController extends BaseHttpController<CameraNotes> implements ICameraNoteController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-notes', client);
    }

    async list(props: IGetCameraNoteProps): Promise<Paging<CameraNotes>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }
}
