import PopUpBase from '@components/PopUpBase';
import { IPopUp } from '@hooks/usePopUp';
import { Button, Stack, TextField, Typography } from '@mui/material';
import color from '@theme/Colors';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Mode } from './CameraInformation';
import { CameraWithInfo } from '@EcamModel/model';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import { Paging } from '@Core';

type Props = Omit<IPopUp, 'onConfirm'> & {
    mode: Mode | null;
    idNote: number;
    listNotes: Paging<CameraNotes>;
    camera: CameraWithInfo;
    handleAddOrUpdateNote: (idCamera: number, note: string, idNote?: number) => Promise<void>;
};

export default function PopUpNoteHistory(props: Props) {
    const { open, onClose, mode, camera, idNote, listNotes } = props;
    const [note, setNote] = useState<string>('');
    const filteredNote = listNotes?.rows?.find((n) => n.Id === idNote);

    useEffect(() => {
        if (!open) return;

        if (mode === Mode.edit) {
            setNote(filteredNote?.Notes ?? '');
        } else {
            setNote('');
        }
    }, [open]);

    return (
        <PopUpBase
            open={open}
            onClose={onClose}
            dialogProps={{
                maxWidth: 'lg',
                PaperProps: {
                    style: { minWidth: 700 },
                },
            }}
            title={<Typography variant="h4">{mode === Mode.create ? 'Add note' : 'Edit note'}</Typography>}
            desc={
                <>
                    <Stack direction={'column'} spacing={1} mt={1}>
                        <TextField
                            autoFocus
                            required
                            label="Note"
                            error={note.length > 255}
                            multiline
                            sx={{
                                '& .MuiInputBase-input': {
                                    padding: 0,
                                    borderRadius: 0,
                                },
                            }}
                            rows={8}
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            placeholder="Enter note"
                        />
                        <Typography textAlign={'end'} color={note.length > 255 ? color.danger : color.textPrimary}>
                            {note.length} / 255
                        </Typography>
                    </Stack>
                </>
            }
            customActions={
                <Stack direction={'row'} width="100%" justifyContent="space-between">
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="cancel"
                        onClick={() => {
                            props.onClose?.();
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ minWidth: 130 }}
                        variant="contained"
                        disabled={note.length > 255 || note === '' || note === filteredNote?.Notes}
                        onClick={() => {
                            const data: CameraNotes = {
                                CameraId: camera.Id!,
                                Notes: note,
                                Id: mode === Mode.edit ? idNote : undefined,
                            };
                            props.handleAddOrUpdateNote(data.CameraId, data.Notes, data.Id);
                            props.onClose?.();
                        }}
                    >
                        Save
                    </Button>
                </Stack>
            }
            minWidthButton={150}
        />
    );
}
